const comesFromURL = (state = undefined, action) => {
  switch (action.type) {
    case "comesFromURL":
      return action.params;
    default:
      return state;
  }
};

export default comesFromURL;
