import React from "react";
import { connect } from "react-redux";
import { setFilter } from "../../../../../redux/actions/screen";
import { GETFILTERS } from "../../../../../redux/selectors";

const S1CheckBox = ({ element, filters, handleSetFilters }) => {
  const [value, setViewValue] = React.useState(false);

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const handleChangeField = (e) => {
    setViewValue(e.target.checked);
    handleSetFilters(table, key, e.target.checked ? 1 : 0);
  };

  React.useEffect(() => {
    var value = "";
    if (filters?.text) {
      const araryFilters = filters.text.split("&");
      araryFilters.every((x) => {
        if (x.split("=")[0].split(".")[0] == table && x.split("=")[0].split(".")[1] == key) {
          value = x.split("=")[1];
          return false;
        } else {
          return true;
        }
      });
    } else {
      value = element.value;
    }
    setViewValue(value == 1);
    handleSetFilters(table, key, value == 1);
  }, []);

  React.useEffect(() => {
    if (filters?.reset) {
      setViewValue(false);
    }
  }, [filters?.reset]);

  return (
    <div
      className={`s1checkbox ${
        element.visible ? "" : "s1-hidden"
      } w-100 form-check d-flex flex-column flex-wrap p-0 m-0`}
      style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}
    >
      <label
        className="form-check-label py-1"
        htmlFor={element.index}
        style={{
          whiteSpace: "nowrap",
          opacity: "0.65",
          fontSize: "0.85rem",
        }}
      >
        {element.caption}
      </label>
      <input
        id={element.index}
        className="form-check-input m-0 ps-1"
        type="checkbox"
        name={element.name}
        disabled={element.readOnly}
        readOnly={element.readOnly}
        checked={value}
        onChange={handleChangeField}
        style={{ minHeight: "2rem", minWidth: "2rem" }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  filters: GETFILTERS(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetFilters: (table, key, value) => dispatch(setFilter(table, key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1CheckBox);
