import React from "react";
import { connect } from "react-redux";
import BatchJob from "./batch job/BatchJob";
import EditMaster from "./edit master/EditMaster";
import { GETSCREENOBJ } from "../../../redux/selectors";
import Report from "./report/Report";

const SoftoneObject = ({ screen, MainPanelRef }) => {
  return {
    editmaster: <EditMaster MainPanelRef={MainPanelRef} />,
    report: <Report />,
    dialog: <BatchJob />,
  }[screen.cmd.toLowerCase()];
};

const mapStateToProps = (state) => ({
  screen: GETSCREENOBJ(state),
});

export default connect(mapStateToProps)(SoftoneObject);
