const showRoom = (state = undefined, action) => {
  switch (action.type) {
    case "showRoom/set":
      return action.value;
    case "showRoom/itemQty":
      return {
        ...state,
        basket: {
          ...state?.basket,
          [action.item]: action.obj,
        },
      };
    default:
      return state;
  }
};

export default showRoom;
