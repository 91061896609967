import React from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import { GETBROWSERDATA, GETBROWSERINFO, GETSN } from "../../../../../../redux/selectors";
import CardFooter from "./CardFooter";
import { FiCameraOff } from "react-icons/fi";

const ItemCard = React.forwardRef(
  ({ serialnumber, item, hasImage, imageIndex, nameIndex, codeIndex, selectedRow }, ref) => {
    const body = (
      <>
        <Card.Body className="p-0 d-flex justify-content-center align-items-center">
          {hasImage ? (
            item[imageIndex] != "" ? (
              <div
                className="crm-image-on-showroom"
                style={{
                  height: "120px",
                  width: "120px",
                  backgroundImage: `url("https://${serialnumber}.oncloud.gr/s1services/?filename=${item[imageIndex]}")`,
                }}
              />
            ) : (
              <div className="crm-no-image-on-list">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <FiCameraOff size={50} />
                  <span>NO IMAGE</span>
                </div>
              </div>
            )
          ) : (
            <div className="crm-no-image-on-list">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <FiCameraOff size={50} />
                <span>NO IMAGE</span>
              </div>
            </div>
          )}
        </Card.Body>
        <Card.Footer>
          <div>{`${item[codeIndex]} - ${item[nameIndex]}`}</div>
          <CardFooter item={item} code={item[codeIndex]} name={item[nameIndex]} image={item[imageIndex]} />
        </Card.Footer>
      </>
    );

    return ref ? (
      <Card className="m-3 bg-dark text-light shadow" ref={ref}>
        {body}
      </Card>
    ) : (
      <Card className="m-3 bg-dark text-light shadow">{body}</Card>
    );
  }
);

const mapStateToProps = (state) => ({
  serialnumber: GETSN(state),
  columns: GETBROWSERINFO(state)?.columns,
  selectedRow: GETBROWSERDATA(state)?.selectedrow,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(ItemCard);
