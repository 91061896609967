import React from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import {
  GETBUFFDATA,
  GETCURRENTLINE,
  GETCURRENTLINEINDEX,
  GETCURRENTSCREEN,
  GETDATA,
  GETLINESCOUNT,
  GETNEWDATA,
  GETOBJECTSTATE,
  GETSCREENOBJ,
} from "../../../../../../redux/relJobSelectors";
import S1CalendarDate from "../S1 Inputs/S1CalendarDate";
import S1CheckBox from "../S1 Inputs/S1CheckBox";
import S1DateTime from "../S1 Inputs/S1DateTime";
import S1Email from "../S1 Inputs/S1Email";
import S1MemoryTable from "../S1 Inputs/S1MemoryTable";
import S1NumberField from "../S1 Inputs/S1NumberField";
import S1Selector from "../S1 Inputs/S1Selector";
import S1Strings from "../S1 Inputs/S1Strings";
import S1TextArea from "../S1 Inputs/S1TextArea";
import S1TextField from "../S1 Inputs/S1TextField";
import S1WebPage from "../S1 Inputs/S1WebPage";
import { HiX, HiCheck } from "react-icons/hi";
import { AddLineToGrid, TriggerGridChange, UpdateLineOfGrid } from "../../../../../../redux/actions/relJob";
import { calculate } from "../../functions/calculate";

const AddLine = ({
  grid,
  show,
  setShow,
  objectState,
  fields,
  data,
  data_buff,
  newdata,
  getCurentLine,
  getCurentLineIndex,
  getLinesCount,
  handleAddLine,
  handleUpdateLine,
  handleTrigger,
}) => {
  const { model } = grid;

  const [Data, setData] = React.useState("");
  const Recursive = (element) => {
    switch (element.xtype) {
      // case "s1textarea":
      //   return <S1TextArea element={element} key={element.index} />;
      case "s1textfield":
        return <S1TextField element={element} key={element.index} grid={model} />;
      // case "s1webpage":
      //   return <S1WebPage element={element} key={element.index} />;
      // case "s1email":
      //   return <S1Email element={element} key={element.index} />;
      case "s1numberfield":
        return (
          <S1NumberField element={element} key={element.index} grid={model} currentLine={getCurentLineIndex(model)} />
        );
      // case "s1checkbox":
      //   return <S1CheckBox element={element} key={element.index} />;
      case "s1memorytable":
        return <S1MemoryTable element={element} key={element.index} grid={model} />;
      case "s1selector":
        return <S1Selector element={element} key={element.index} gridName={model} />;
      // case "s1datetime":
      //   return <S1DateTime element={element} key={element.index} />;
      case "s1calendardate":
        return <S1CalendarDate element={element} key={element.index} />;
      case "s1strings":
        return <S1Strings element={element} key={element.index} grid={model} />;
      default:
        return (
          <div key={element.index} className="bg-danger">
            {element.xtype}
          </div>
        );
    }
  };

  React.useEffect(() => {
    switch (objectState) {
      case "new":
        setData("newdata");
        break;

      default:
        setData("data");
        break;
    }
  }, [objectState]);

  const handleAccept = async () => {
    const line = getCurentLine(model);
    var gridLines = newdata?.[model] ?? [];
    if (gridLines.length == 0) {
      handleTrigger(model);
    }
    if (line?.LINENUM) {
      //Αν έχει LINENUM τοτε ΔΕΝ είναι καινουργια γραμμή
      //και θα κάνω locate για να την κάνω update
      handleUpdateLine(model, getCurentLineIndex(model), line);
    } else {
      //Αλλιώς πρέπει να την προσθέσω στον reducer
      line.LINENUM = 9000001 + getLinesCount(model);
      handleAddLine(model, line);
    }
    setShow(false);
    await calculate();
  };

  return (
    <Modal
      animation={false}
      show={show}
      centered
      fullscreen
      dialogClassName="p-3"
      contentClassName="rounded border border-warning shadow overflow-hidden bg-dark"
    >
      <Modal.Body className="p-3">{fields.map((item) => Recursive(item))}</Modal.Body>
      <Modal.Footer className="p-0 w-100 bg-dark shadow border-0">
        <ButtonGroup className="m-0 w-100">
          <Button
            variant="outline-warning"
            className="w-50 d-flex justify-content-center align-items-center rounded-0"
            onClick={() => {
              setShow(false);
            }}
          >
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Κλείσμο
            </div>
          </Button>
          <Button
            className="w-50 d-flex justify-content-center align-items-center rounded-0"
            variant="outline-warning"
            onClick={handleAccept}
            disabled={objectState == "view"}
          >
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Εφαρμογή
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
  data: GETDATA(state),
  data_buff: GETBUFFDATA(state),
  newdata: GETNEWDATA(state),
  screen: GETSCREENOBJ(state),
  currentscreen: GETCURRENTSCREEN(state),
  getCurentLine: (grid) => GETCURRENTLINE(state, grid),
  getCurentLineIndex: (grid) => GETCURRENTLINEINDEX(state, grid),
  getLinesCount: (grid) => GETLINESCOUNT(state, grid),
});

const mapDispatchToProps = (dispatch) => ({
  handleAddLine: (gridname, line) => dispatch(AddLineToGrid(gridname, line)),
  handleUpdateLine: (gridname, index, line) => dispatch(UpdateLineOfGrid(gridname, index, line)),
  handleTrigger: (gridName) => dispatch(TriggerGridChange(gridName)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddLine);
