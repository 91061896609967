import React, { useState } from "react";
import { Button, Row } from "react-bootstrap";
import { connect } from "react-redux";
import ModalPassWord from "./ModalPassWord";

const UserDetails = ({ login }) => {
  const [changePass, setChangePass] = useState(false);
  return (
    <>
      <Row>
        <span
          style={{
            color: "#b1b1b1",
            fontWeight: "400",
          }}
        >
          Όνομα Χρήστη:
        </span>
      </Row>
      <Row className="text-light">
        <span style={{ fontWeight: "600" }}>{login.username}</span>
      </Row>
      <Button variant="warning" className="mt-2" onClick={() => setChangePass(true)}>
        Αλλαγή Κωδικού
      </Button>
      <ModalPassWord show={changePass} setShow={setChangePass} />
    </>
  );
};

const mapStateToProps = (state) => ({
  login: state.login,
});

export default connect(mapStateToProps)(UserDetails);
