import axios from "axios";
import React from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";
import { HiX, HiCheck } from "react-icons/hi";
import { connect } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import { setError } from "../../../redux/actions/modals";
import {
  AddLineToGrid,
  SetCurrentLine,
  setGetData,
  SetGridChanges,
  TriggerGridChange,
} from "../../../redux/actions/screen";
import {
  GETCLIENTID,
  GETCOMMAND,
  GETDATA,
  GETFORMDESIGN,
  GETLINESCOUNT,
  GETNEWDATA,
  GETOBJECTSTATE,
  GETSN,
  GETURL,
} from "../../../redux/selectors";
import Spinner from "../Spinner";

const ModalSignature = ({
  show,
  setShow,
  clientID,
  url,
  formdesign,
  serialnumber,
  command,
  dropbox,
  hideSideBar,
  objectState,
  newdata,
  getLinesCount,
  handleSetCurrentLine,
  handleSetGridChanges,
  handleTriggerGridChange,
  handleAddLineToGrid,
  handleSetError,
  handleSetGetData,
}) => {
  const canvasRef = React.useRef();
  const [loading, setLoading] = React.useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    const config = {
      clientId: process.env.REACT_APP_DROPBOX_CLIENT_ID,
      clientSecret: process.env.REACT_APP_DROPBOX_CLIENT_SECRET,
      refreshToken: dropbox?.token,
    };
    var Dropbox = require("dropbox").Dropbox;
    var dbx = new Dropbox(config);
    const base64Response = await fetch(canvasRef.current.getTrimmedCanvas().toDataURL("image/png"));
    const blob = await base64Response.blob();
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    await dbx
      .filesUpload({
        path: `/Apps/Web CRM/Signature${timestamp}.png`,
        contents: blob,
      })
      .then(async (response) => {
        await dbx
          .sharingCreateSharedLinkWithSettings({
            path: response.result.path_display,
          })
          .then((res) => {
            console.log(res.result.url);
            addSignature2Doc(res.result.url, `Signature${timestamp}.png`);
          })
          .catch((er) => console.log(er));
      })
      .catch((er) => {
        console.log(er);
        setLoading(false);
      });
  };

  const addSignature2Doc = async (link, name) => {
    if (objectState == "view") {
      const key = formdesign.key;
      const table = key.split(".")[0];
      const field = key.split(".")[1];
      const keyValue = newdata?.[table][0]?.[field];
      var XTRDOCDATA = [];
      newdata.XTRDOCDATA.forEach((element) => {
        XTRDOCDATA.push({ LINENUM: element.LINENUM });
      });
      XTRDOCDATA.push({
        SOFNAME: link,
        NAME: name,
        LINENUM: 9000001 + newdata.XTRDOCDATA.length,
      });
      const object = `${serialnumber}/object=${command}`;
      var locateinfo = "";
      Object.entries(formdesign.model).forEach(([key, value], index) => {
        locateinfo += index == 0 ? `${key}:` : `;${key}:`;
        if (key == "XTRDOCDATA") locateinfo += "NAME,";
        value.fields.map((item) => {
          locateinfo += `${item.name},`;
        });
      });
      const setData = await axios
        .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
          url,
          data: {
            service: "setData",
            clientID,
            object,
            key: keyValue,
            locateinfo,
            data: { XTRDOCDATA },
            version: 2,
            appID: process.env.REACT_APP_SOFTONE_APPID,
          },
        })
        .then((res) => res.data)
        .catch((er) => ({ success: false, error: er.message }));
      if (setData.success) {
        handleSetGetData(setData);
        handleSetError({ error: `Επιτυχής καταχώρηση Υπογραφής` });
        hideSideBar(false);
      } else {
        handleSetError(setData);
      }
    } else {
      handleSetCurrentLine(-1, {});
      handleSetGridChanges(link);
      var gridLines = newdata?.XTRDOCDATA ?? [];
      if (gridLines.length == 0) {
        handleTriggerGridChange();
      }
      const line = {
        SOFNAME: link,
        NAME: name,
        LINENUM: 9000001 + getLinesCount(),
      };
      handleAddLineToGrid(line);
    }
    setLoading(false);
    setShow(false);
  };

  return (
    <Modal
      animation={false}
      show={show}
      centered
      fullscreen
      dialogClassName="p-3"
      contentClassName="rounded border border-warning shadow overflow-hidden bg-dark"
    >
      {loading && <Spinner />}
      <Modal.Body>
        <Button
          onClick={() => setShow(false)}
          variant="outline-warning"
          className="closeButton bg-dark rounded-0 shadow"
        >
          <AiOutlineClose />
        </Button>
        <div className="h-100 bg-light">
          <SignatureCanvas
            ref={canvasRef}
            // penColor="white"
            canvasProps={{
              className: "h-100 w-100",
            }}
            // backgroundColor="#212529"
            // backgroundColor="white"
          />
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{
          background: "transparent",
          borderBlockColor: "transparent",
        }}
        className="p-0 w-100 shadow border-0"
      >
        <ButtonGroup className="m-0 w-100">
          <Button
            variant="outline-warning"
            className="w-50 d-flex justify-content-center align-items-center rounded-0"
            onClick={(e) => canvasRef.current.clear()}
          >
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Καθαρισμός
            </div>
          </Button>
          <Button
            className="w-50 d-flex justify-content-center align-items-center rounded-0"
            variant="outline-warning"
            onClick={handleConfirm}
          >
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center " style={{ fontSize: 20, color: "white" }}>
              Εφαρμογή
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  dropbox: state.settings?.dropbox,
  newdata: GETOBJECTSTATE(state) == "view" ? GETDATA(state).data : GETNEWDATA(state),
  objectState: GETOBJECTSTATE(state),
  url: GETURL(state),
  clientID: GETCLIENTID(state),
  serialnumber: GETSN(state),
  command: GETCOMMAND(state),
  formdesign: GETFORMDESIGN(state),
  getLinesCount: () => GETLINESCOUNT(state, "XTRDOCDATA"),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetCurrentLine: (index, line) => dispatch(SetCurrentLine("XTRDOCDATA", index, line)),
  handleSetGridChanges: (value) => dispatch(SetGridChanges("XTRDOCDATA", "SOFNAME", -1, value)),
  handleTriggerGridChange: () => dispatch(TriggerGridChange("XTRDOCDATA")),
  handleAddLineToGrid: (value) => dispatch(AddLineToGrid("XTRDOCDATA", value)),
  handleSetGetData: (value) => dispatch(setGetData(value)),
  handleSetError: (value) => dispatch(setError(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalSignature);
