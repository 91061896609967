import React, { useEffect, useState } from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import MultiStringEditorModal from "../modals/MultiStringEditorModal";
import { GETFILTERS } from "../../../../../redux/selectors";
import { setBatchFilter, setFilter } from "../../../../../redux/actions/screen";

const S1StringMulti = ({ element, handleSetFilters, filters }) => {
  const [viewValue, setViewValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [editorData, setEditorData] = useState({});

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const handleSelect = (e) => {
    e.target.blur();
    !element.readOnly && setOpenModal(true);
  };

  useEffect(() => {
    var stringDataObj = {};
    element.editor.split("|").forEach((i) => (stringDataObj[i.split("=")[0]] = i.split("=")[1]));
    setEditorData(stringDataObj);

    setViewValue(element.value);
    handleSetFilters(table, key, element.value);
  }, []);

  const handleChange = (val) => {
    setViewValue(val);
    handleSetFilters(table, key, val);
  };

  return (
    <div className="s1stringmulti w-100" style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}>
      <InputGroup className="crm-floating-with-icon" hidden={!element.visible}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
          onClick={(e) => handleSelect(e)}
        >
          <Form.Control
            className="crm-input ps-1"
            autoCapitalize="off"
            inputMode="none"
            placeholder={element.caption}
            disabled={element.readOnly}
            readOnly={element.readOnly}
            name={element.name}
            style={{ border: "0" }}
            value={viewValue}
            onChange={(e) => setViewValue(e.target.value)}
            // onBlur={(e) => handleChangeField(e)}
          />
        </FloatingLabel>
        {viewValue != "" && !element.readOnly && (
          <div className="d-flex align-items-center justify-content-center" onClick={() => handleChange("")}>
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
      {openModal && (
        <MultiStringEditorModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          data={editorData}
          setViewValue={setViewValue}
          selected={viewValue}
          element={element}
          handleChange={handleChange}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  filters: GETFILTERS(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetFilters: (table, key, value) => dispatch(setBatchFilter(table, key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1StringMulti);
