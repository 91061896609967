import React from "react";
import { connect } from "react-redux";
import { GETBROWSERDATA, GETBROWSERINFO, GETSN } from "../../../../../redux/selectors";
import { listEditor } from "../../functions";
import { FiCameraOff } from "react-icons/fi";

const RowWithImage = React.forwardRef(
  ({ selectedRow, columns, serialnumber, row, AAIndex, onClick, imageIndex }, ref) => {
    const imageIsHeader = AAIndex + 3 >= imageIndex;

    const rowBody = (
      // <div className="d-flex flex-row">

      <div style={{ minHeight: "132px" }}>
        <div
          style={{
            border: "2px dotted #41464b",
            float: "left",
          }}
          className="mt-3 mx-2 shadow"
        >
          {row[imageIndex] != "" ? (
            <div
              className="crm-image-on-list"
              style={{
                backgroundImage: `url("https://${serialnumber}.oncloud.gr/s1services/?filename=${row[imageIndex]}")`,
              }}
            />
          ) : (
            <div className="crm-no-image-on-list">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <FiCameraOff size={50} />
                <span>NO IMAGE</span>
              </div>
            </div>
          )}
        </div>
        <div className="crm-list-data">
          <div className="crm-list-data-header">
            <span>
              {imageIsHeader
                ? listEditor(columns[AAIndex + 1 + (imageIsHeader ? 1 : 0)], row[AAIndex + 2 + (imageIsHeader ? 1 : 0)])
                : listEditor(columns[AAIndex + 1], row[AAIndex + 2])}
            </span>
            <span>
              {imageIsHeader
                ? listEditor(columns[AAIndex + 2 + (imageIsHeader ? 1 : 0)], row[AAIndex + 3 + (imageIsHeader ? 1 : 0)])
                : listEditor(columns[AAIndex + 2], row[AAIndex + 3])}
            </span>
          </div>

          {/* <div className="p-1" style={{ color: "white" }}> */}
          {columns.map((column, y) => {
            if (y > AAIndex + 2) {
              if (imageIsHeader) {
                if (y == AAIndex + 3) {
                  return;
                } else {
                  return (
                    <div key={`${y + 1}`} className="crm-list-data-detail">
                      <span>{`${column.header}: `}</span>
                      <span>{listEditor(column, row[y + 1])}</span>
                    </div>
                  );
                }
              } else {
                if (y != imageIndex - 1) {
                  return (
                    <div key={`${y + 1}`} className="crm-list-data-detail">
                      <span>{`${column.header}: `}</span>
                      <span>{listEditor(column, row[y + 1])}</span>
                    </div>
                  );
                }
              }
            }
          })}
          {/* </div> */}
        </div>
      </div>
    );

    return ref ? (
      <div
        className={`crm-browser-row${selectedRow == row[0] ? " selected-row" : ""}`}
        onClick={onClick}
        ref={ref}
        lastlast={`lastlast`}
      >
        {rowBody}
      </div>
    ) : (
      <div className={`crm-browser-row${selectedRow == row[0] ? " selected-row" : ""}`} onClick={onClick}>
        {rowBody}
      </div>
    );
  }
);

const mapStateToProps = (state) => ({
  serialnumber: GETSN(state),
  columns: GETBROWSERINFO(state)?.columns,
  selectedRow: GETBROWSERDATA(state)?.selectedrow,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(RowWithImage);
