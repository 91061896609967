import axios from "axios";
import React, { useEffect, useState } from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { setLoading } from "../../../../../redux/actions/modals";
import MemoryTableModal from "../modals/MemoryTableModal";
import { TiDeleteOutline } from "react-icons/ti";
import { setBatchFilter } from "../../../../../redux/actions/screen";

const S1MemoryTable = ({ clientID, serialnumber, element, handleLoading, handleSetBatchFilter }) => {
  const [viewValue, setViewValue] = useState("");
  const [keyValue, setKeyValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [editorData, setEditorData] = useState({});
  const [editorString, setEditorString] = useState("");

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const handleEditor = async (e) => {
    e.target.blur();
    handleLoading(true);
    try {
      const EditorArguments = Array.from(element.editor.matchAll(/\[([^\][]*)]/g), (x) => x[1]);
      var editor = element.editor;
      EditorArguments.forEach((y) => {
        var splitted = y.split(".");
        var tableName = splitted[0];
        var fieldName = splitted[1];
        var replaceValue = fieldName == "COMPANY" ? ":X.SYS.MAINCOMPANY" : undefined;
        editor = editor.replaceAll(`[${y}]`, replaceValue);
      });
      const GetSelectorData = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
        url: `http://${serialnumber}.oncloud.gr/s1services`,
        data: {
          service: "GetSelectorData",
          clientID,
          appId: process.env.REACT_APP_SOFTONE_APPID,
          editor,
          VALUE: "",
        },
      });
      if (GetSelectorData.data.success) {
        setEditorData(GetSelectorData.data);
        setEditorString(editor);
        setOpenModal(true);
      } else {
        console.log(GetSelectorData.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleLoading(false);
    }
  };
  useEffect(() => {
    handleSetBatchFilter(table, key, element.value);
    setViewValue(element.value);
    setKeyValue(element.value);
  }, []);

  return (
    <div
      className="s1memorytable w-100"
      style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}
      key={element.index}
    >
      <InputGroup
        className={`crm-floating-with-icon${element.required && viewValue == "" ? " input-required" : ""}`}
        hidden={!element.visible}
      >
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            value={viewValue}
            editor={element.editor}
            inputMode="none"
            className="crm-input form-control ps-1"
            placeholder={element.caption}
            name={element.name}
            disabled={element.readOnly}
            readOnly={element.readOnly}
            onClick={handleEditor}
            style={{ border: "0" }}
            onChange={(e) => setViewValue(e.target.value)}
          />
        </FloatingLabel>
        {viewValue != "" && !element.readOnly && (
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={() => {
              setViewValue("");
              setKeyValue(undefined);
              handleSetBatchFilter(table, key, "");
            }}
          >
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
      {openModal && (
        <MemoryTableModal
          element={element}
          openModal={openModal}
          setOpenModal={setOpenModal}
          data={editorData}
          editor={editorString}
          setKeyValue={setKeyValue}
          setViewValue={setViewValue}
          predifinedKey={keyValue}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  serialnumber: state.systemparams.serialnumber,
  clientID: state.session.clientID,
});

const mapDispatchToProps = (dispatch) => ({
  handleLoading: (value) => dispatch(setLoading(value)),
  handleSetBatchFilter: (table, key, value) => dispatch(setBatchFilter(table, key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1MemoryTable);
