import React from "react";
import { IoWarningOutline } from "react-icons/io5";

const Error = ({ error }) => {
  return (
    <div className="text-light text-center d-flex flex-column justify-content-center align-items-center h-100">
      <div className="mb-4">
        <IoWarningOutline fontSize={"6rem"} />
      </div>
      <h5 className="mx-2">{error}</h5>
    </div>
  );
};

export default Error;
