import React from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import StringEditorModal from "../input modals/StringEditorModal";
import { GETBUFFDATA, GETDATA, GETFORMDESIGN, GETNEWDATA, GETOBJECTSTATE } from "../../../../../../redux/selectors";
import { SetChanges } from "../../../../../../redux/actions/screen";
import { calculate } from "../../functions/calculate";

const S1Strings = ({ element, data, objectState, formdesign, handleSetChange }) => {
  const [viewValue, setViewValue] = React.useState("");
  const [keyValue, setKeyValue] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  // const [editorData, setEditorData] = React.useState({});
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const model = formdesign.model[table].fields.filter((el) => el.name == key)[0];
  const editorData = {};
  element.editor.split("|").forEach((i) => (editorData[i.split("=")[0]] = i.split("=")[1]));
  const handleClick = (e) => {
    e.target.blur();
    objectState != "view" && !element.readOnly && setOpenModal(true);
  };

  const handleChange = (value) => {
    handleSetChange(table, key, 0, value);
    model?.updates != "" && calculate();
  };

  // React.useEffect(() => {
  //   var stringDataObj = {};
  //   element.editor.split("|").forEach((i) => (stringDataObj[i.split("=")[0]] = i.split("=")[1]));
  //   setEditorData(stringDataObj);
  // }, []);

  //calculate Changes & Initialization
  React.useEffect(() => {
    if (data?.[table]?.[0]?.[key]) {
      var value = data?.[table]?.[0]?.[key] ?? "";
      setKeyValue(value);
      value != "" && setViewValue(editorData[value]);
    }
  }, [data?.[table]?.[0]?.[key]]);

  return (
    <div className="s1strings w-100" style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}>
      <InputGroup className="crm-floating-with-icon" hidden={!element.visible}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
          onClick={(e) => handleClick(e)}
        >
          <Form.Control
            className="crm-input ps-1"
            autoCapitalize="off"
            inputMode="none"
            placeholder={element.caption}
            disabled={objectState === "view"}
            readOnly={element.readOnly}
            name={element.name}
            style={{ border: "0" }}
            value={viewValue}
            onChange={(e) => setViewValue(e.target.value)}
            // onBlur={(e) => handleChangeField(e)}
          />
        </FloatingLabel>
        {objectState != "view" && viewValue != "" && !element.readOnly && (
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={() => {
              setViewValue("");
              setKeyValue("");
              handleChange("");
            }}
          >
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
      {openModal && (
        <StringEditorModal
          show={openModal}
          setShow={setOpenModal}
          data={editorData}
          setKeyValue={setKeyValue}
          setViewValue={setViewValue}
          predifinedKey={keyValue}
          handleChange={handleChange}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
  data: { view: GETDATA(state)?.data, edit: GETBUFFDATA(state), new: GETNEWDATA(state) }[GETOBJECTSTATE(state)],
  formdesign: GETFORMDESIGN(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetChange: (table, key, index, value) => dispatch(SetChanges(table, key, index, value)),
});
export default connect(mapStateToProps, mapDispatchToProps)(S1Strings);
