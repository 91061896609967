import React from "react";
import { connect } from "react-redux";
import { SetChanges } from "../../../../../../redux/actions/relJob";
import {
  GETBUFFDATA,
  GETDATA,
  GETFORMDESIGN,
  GETNEWDATA,
  GETOBJECTSTATE,
} from "../../../../../../redux/relJobSelectors";
import { calculate } from "../../functions/calculate";

const S1CheckBox = ({ element, objectState, data, handleSetChanges, formDesign }) => {
  const [value, setViewValue] = React.useState(false);
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const model = formDesign.model[table].fields.filter((el) => el.name == key)[0];

  const handleChangeField = async () => {
    setViewValue(!value);
    handleSetChanges(table, key, 0, !value ? 1 : 0);
    model?.updates != "" && calculate();
  };

  React.useEffect(() => {
    const valuue = data?.[table]?.[0]?.[key] ?? 0;
    setViewValue(valuue == 1);
  }, [data?.[table]?.[0]?.[key]]);

  return (
    <div
      className="s1checkbox w-100 form-check d-flex flex-column flex-wrap p-0 m-0"
      style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}
      hidden={!element.visible}
    >
      <label
        className="form-check-label py-1"
        htmlFor={element.index}
        style={{
          whiteSpace: "nowrap",
          opacity: "0.65",
          fontSize: "0.85rem",
        }}
      >
        {element.caption}
      </label>
      <input
        id={element.index}
        className="form-check-input m-0 ps-1"
        type="checkbox"
        name={element.name}
        disabled={objectState === "view" || element.readOnly}
        readOnly={element.readOnly}
        checked={value}
        onChange={handleChangeField}
        style={{ minHeight: "2rem", minWidth: "2rem" }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
  formDesign: GETFORMDESIGN(state),
  data: { view: GETDATA(state)?.data, edit: GETBUFFDATA(state), new: GETNEWDATA(state) }[GETOBJECTSTATE(state)],
  newdata: GETNEWDATA(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetChanges: (table, key, index, value) => dispatch(SetChanges(table, key, index, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1CheckBox);
