import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { setDateRanges } from "../../../../../../redux/actions/relJob";
import { GETFILTERS } from "../../../../../../redux/relJobSelectors";
import { HiX, HiCheck } from "react-icons/hi";

const DateRangeModal = ({ openModal, setOpenModal, dateranges, handleSetDateRange, parentIndex, filters }) => {
  const [selectedRow, setSelectedRow] = useState();

  useEffect(() => {
    setSelectedRow(filters?.daterange?.[parentIndex] ?? "");
  }, [filters?.daterange?.[parentIndex]]);

  const handleAccept = () => {
    handleSetDateRange(parentIndex, selectedRow);
    setOpenModal(false);
  };
  return (
    <Modal
      animation={false}
      show={openModal}
      centered
      fullscreen
      className="overlapped-on-another-modal"
      dialogClassName="p-3"
      contentClassName="rounded border border-warning shadow overflow-hidden bg-dark"
      backdropClassName="overlapped-on-another-modal"
    >
      <Modal.Body className="p-0">
        <div>
          {Object.keys(dateranges).map((key) => {
            return (
              <div
                key={key}
                onClick={() => setSelectedRow(key)}
                className={`s1editor-row border px-1 ${selectedRow == key ? "rounded border-warning shadow" : ""}`}
                style={{
                  backgroundColor: selectedRow == key ? "var(--bs-gray-400)" : "transparent",
                  color: selectedRow == key ? "var(--bs-dark)" : "white",
                }}
              >
                <div className="my-3 mx-4">{dateranges[key].name}</div>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer className="p-0 w-100 bg-dark shadow border-0">
        <ButtonGroup className="m-0 w-100">
          <Button
            variant="outline-warning"
            className="w-50 d-flex justify-content-center align-items-center rounded-0"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Κλείσμο
            </div>
          </Button>
          <Button
            className="w-50 d-flex justify-content-center align-items-center rounded-0"
            variant="outline-warning"
            onClick={handleAccept}
          >
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Εφαρμογή
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
      {/* <Modal.Footer className="w-100 bg-dark shadow">
        <ButtonGroup className="w-100">
          <Button
            variant="outline-warning"
            className="w-50"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Ακύρωση
          </Button>
          <Button className="w-50" variant="warning" onClick={handleAccept}>
            Εφαρμογή
          </Button>
        </ButtonGroup>
      </Modal.Footer> */}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  dateranges: state.systemparams.dateranges,
  filters: GETFILTERS(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetDateRange: (key, value) => dispatch(setDateRanges(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DateRangeModal);
