export const SetFormPageY = (value) => ({ type: "general/setFormPageY", value });

export const OpenRelJob = () => ({ type: "relJob/Open" });

export const SetScreenState = (value) => ({ type: "relJob/screenState", value });

export const SetObjectState = (value) => ({ type: "relJob/objectState", value });

export const setAccessRights = (value) => ({ type: "relJob/setAccessRights", value });

export const SetBrowserInfo = (value) => ({ type: "relJob/setBrowserInfo", value });

export const SetBrowserData = (value) => ({ type: "relJob/setBrowserData", value });

export const SetNextBrowserData = (value, nextstart) => ({ type: "relJob/setNextBrowserData", value, nextstart });

export const setGetData = (value) => ({ type: "relJob/setData", value });

export const setFormDesign = (value) => ({ type: "relJob/setFormDesign", value });

export const setPrepareNew = (value, caption) => ({ type: "relJob/prepareNew", value, caption });

export const CancelEdits = () => ({ type: "relJob/cancelEdits" });

export const changeToBuff = () => ({ type: "relJob/changeToBuffer" });

export const SetChanges = (table, key, index, value) => ({
  type: "relJob/setChanges",
  table,
  key,
  index,
  value,
});

export const setCalculateData = (value) => ({ type: "relJob/setCalculateData", value });

export const SetGridChanges = (table, key, index, value) => ({
  type: "relJob/setGridChanges",
  table,
  key,
  index,
  value,
});

export const UpdateLineOfGrid = (gridName, index, line) => ({
  type: "relJob/UpdateLineOfGrid",
  gridName,
  index,
  line,
});

export const TriggerGridChange = (gridName) => ({ type: "relJob/triggerGridChange", gridName });

export const AddLineToGrid = (gridName, value) => ({ type: "relJob/addLineToGrid", gridName, value });

export const SetCurrentLine = (gridName, lineIndex, line) => ({
  type: "relJob/setCurrentLine",
  gridName,
  line,
  lineIndex,
});

export const DeleteGridLine = (grid, lineIndex) => ({ type: "relJob/deleteGridLine", grid, lineIndex });

export const AddDetailGrid = (gridName, gridValue) => ({ type: "relJob/AddDetailGrid", gridValue, gridName });

//---------------------------- REPORT ---------------------------

export const InitReport = () => ({ type: "relJob/report/initialize" });

export const SetReportInfo = (value) => ({ type: "relJob/report/setReportInfo", value });

export const setReportData = (value) => ({ type: "relJob/report/setReportData", value });

export const setReportPage = (value) => ({ type: "relJob/report/setReportPage", value });

export const setReportError = (value) => ({ type: "relJob/report/error", value });

export const setReportNoData = (value) => ({ type: "relJob/report/noData", value });

//---------------------------- DIALOG ---------------------------

export const setShowDialog = (value) => ({ type: "relJob/dialog/show", value });

export const setGetDialog = (value) => ({ type: "relJob/dialog/set", value });

export const setFilter = (table, key, value) => ({ type: "relJob/dialog/setFilter", table, key, value });

export const SetSearchFilter = (value) => ({ type: "relJob/dialog/setSearchFilter", value });

export const setSelectorFilter = (table, key, keyValue, viewValue) => ({
  type: "relJob/dialog/setSelectorText",
  table,
  key,
  keyValue,
  viewValue,
});

export const setFilterText = (value, selectorValue) => ({ type: "relJob/dialog/setFilterText", value, selectorValue });

export const resetBuffFilters = () => ({ type: "relJob/dialog/resetBuffFilters" });

export const setDateRanges = (key, value) => ({ type: "relJob/dialog/setDateRanges", key, value });
