import React, { useEffect, useState } from "react";
import { Button, Modal, ButtonGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { setFilter } from "../../../../../../redux/actions/relJob";
import { GETFILTERS } from "../../../../../../redux/relJobSelectors";

const MultiStringEditorModal = ({
  setViewValue,
  openModal,
  setOpenModal,
  data,
  selected,
  element,
  filters,
  handleSetFilters,
  handleChange,
}) => {
  const [selectedRow, setSelectedRow] = useState("");

  const handleClickOnRow = (key) => {
    const SelectedArray = selectedRow.split(",");
    if (SelectedArray.indexOf(key) >= 0) {
      SelectedArray.splice(SelectedArray.indexOf(key), 1);
    } else {
      SelectedArray.push(key);
    }
    setSelectedRow(SelectedArray.filter((x) => x != "").join(","));
  };

  useEffect(() => {
    setSelectedRow(selected);
  }, []);

  return (
    <Modal
      animation={false}
      show={openModal}
      centered
      fullscreen
      className="overlapped-on-another-modal"
      dialogClassName="p-2"
      contentClassName="rounded overflow-hidden"
      backdropClassName="overlapped-on-another-modal"
    >
      <Modal.Body className="p-0">
        <div id="s1Editor-list-items">
          {Object.keys(data).map((key) => {
            return (
              <div
                key={key}
                onClick={() => handleClickOnRow(key)}
                className={`s1editor-row border px-1 ${
                  selectedRow.split(",").indexOf(key) >= 0 ? "rounded border-warning shadow" : ""
                }`}
                style={{
                  backgroundColor: selectedRow.split(",").indexOf(key) >= 0 ? "var(--bs-gray-400)" : "transparent",
                }}
              >
                <div className="my-3 mx-4">{data[key]}</div>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer className="w-100 bg-dark shadow">
        <ButtonGroup className="w-100">
          <Button
            variant="outline-warning"
            className="w-50"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Ακύρωση
          </Button>
          <Button
            className="w-50"
            variant="warning"
            onClick={() => {
              handleChange(selectedRow.replaceAll("&", "_"));
              setOpenModal(false);
            }}
          >
            Εφαρμογή
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  filters: GETFILTERS(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetFilters: (value) => dispatch(setFilter(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MultiStringEditorModal);
