import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, ButtonGroup } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "../../../../Spinner";

function MaskSelectorEditorModal({
  serialnumber,
  clientID,
  setViewValue,
  setKeyValue,
  show,
  setShow,
  element,
  data,
  editor,
}) {
  const [loading, setLoading] = useState(false);
  const [editorData, setEditorData] = useState(data);
  const [selectedRow, setSelectedRow] = useState();
  const [hasIdentifier, setHasIdentifier] = useState(false);
  const [identifierCol, setIdentifierCol] = useState(false);

  const setCurrentVal = async (index, val) => {
    setSelectedRow(index);
    setLoading(true);
    try {
      var filters = editor.split("|")[4];
      var tablename = editor.split("|")[1];
      var keyname = editor.split("|")[2];
      var keyvalue = val[0];
      var resultfields = [...new Set(data.model.map((i) => i.name))];
      var keyForVF = [];
      const selectorFields = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
        url: `http://${serialnumber}.oncloud.gr/s1services`,
        data: {
          service: "selectorFields",
          clientID,
          APPID: process.env.REACT_APP_SOFTONE_APPID,
          filters,
          tablename,
          keyname,
          keyvalue,
          resultfields: resultfields.join(","),
        },
      });
      if (selectorFields.data.success) {
        var value = "";
        if (element.selectorVF != "") {
          element.selectorVF.split("|").forEach((i) => {
            var dbIndex = i.split(".")[1].split("_");
            keyForVF.push(dbIndex[dbIndex.length - 1]);
            value += selectorFields.data.rows[0][dbIndex[dbIndex.length - 1]] + " ";
          });
        } else {
          value = selectorFields.data.rows[0].hasOwnProperty(keyname) ? selectorFields.data.rows[0][keyname] : keyvalue;
          keyname = selectorFields.data.rows[0].hasOwnProperty(keyname)
            ? selectorFields.data.rows[0][keyname]
            : keyvalue;
        }
        setViewValue(value);
        setKeyValue(selectorFields.data.rows[0][keyname]);
        setLoading(false);
        setShow(false);
      } else {
        setLoading(false);
        console.log(selectorFields.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSearch = async (e) => {
    setLoading(true);
    try {
      const GetSelectorData = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
        url: `http://${serialnumber}.oncloud.gr/s1services`,
        data: {
          service: "GetSelectorData",
          clientID,
          appId: process.env.REACT_APP_SOFTONE_APPID,
          editor,
          VALUE: e.target.value,
        },
      });
      if (GetSelectorData.data.success) {
        setEditorData(GetSelectorData.data);
      } else {
        console.log(GetSelectorData.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setEditorData(data);
    var keyname = editor.split("|")[2];
    for (let index = 0; index < data.model.length; index++) {
      if (data.model[index].name == keyname) {
        setHasIdentifier(true);
        setIdentifierCol(index);
        break;
      }
    }
  }, []);
  return (
    <Modal
      animation={false}
      show={show}
      centered
      fullscreen
      dialogClassName="p-2"
      contentClassName="rounded overflow-hidden"
      // onHide={() => {
      //   setEditorDone(true);
      //   setShow(false);
      // }}
    >
      {loading && <Spinner colorSpin="#ffc107" />}
      <Modal.Header>
        <div className="form-floating w-100 ">
          <input
            id={`Editor-${element.name}`}
            type="search"
            className="form-control"
            // onChange={handleClearEditor}
            placeholder="Αναζήτηση"
            ref={(r) => ((r || {}).onsearch = (e) => handleSearch(e))}
          />
          <label htmlFor={`Editor-${element.name}`}>Αναζήτηση</label>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div id="s1Editor-list-items">
          {show &&
            editorData.data.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => setCurrentVal(index, item)}
                  className={`s1editor-row border px-1 ${selectedRow == index ? "rounded border-warning shadow" : ""}`}
                  style={{
                    backgroundColor: selectedRow == index ? "var(--bs-gray-400)" : "transparent",
                  }}
                >
                  <div className="my-3 ms-4">
                    {hasIdentifier ? (
                      <span key={index} className="me-2">
                        {item[identifierCol]}
                      </span>
                    ) : (
                      editorData.model.map((yy, i) => {
                        return (
                          <span key={i} className="me-2">
                            {item[i]}
                          </span>
                        );
                      })
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </Modal.Body>
      <Modal.Footer className="w-100 bg-dark shadow">
        <ButtonGroup className="w-100">
          <Button
            variant="outline-warning"
            className="w-50"
            onClick={() => {
              setShow(false);
            }}
          >
            Ακύρωση
          </Button>
          <Button
            className="w-50"
            variant="warning"
            onClick={() => {
              setShow(false);
            }}
          >
            Εφαρμογή
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  serialnumber: state.systemparams.serialnumber,
  clientID: state.session.clientID,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MaskSelectorEditorModal);
