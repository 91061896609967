import React from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import BatchJobLoader from "./BatchJobLoader";

const BatchResults = ({ open, setOpen, screen }) => {
  return (
    <Modal
      show={open}
      scrollable={true}
      onHide={() => setOpen(false)}
      dialogClassName="p-4"
      contentClassName="border border-dark rounded"
      fullscreen
      centered
      backdrop="static"
    >
      <Modal.Header className="d-flex justify-content-center align-items-center">{screen?.dialog?.title}</Modal.Header>
      <Modal.Body>
        <div>Start : {String(formatDate(screen?.start) ?? "")}</div>
        <div>End : {String(formatDate(screen?.end) ?? "")}</div>
        <div>
          Duration{" "}
          {screen?.start && screen?.end && Math.abs(screen.start.getTime() - screen.end.getTime()) / 1000 + "S"}
        </div>
        <div
          className="d-flex flex-column text-center p-4 mt-4"
          style={{
            borderBottom: `${screen?.results?.success ? "4px solid var(--bs-success)" : "4px solid var(--bs-primary)"}`,
            backgroundColor: "var(--bs-gray-200)",
          }}
        >
          {!screen?.end && (
            <div>
              <BatchJobLoader colorSpin="#ffc107" />
            </div>
          )}
          {screen?.results?.success ? (
            <>
              <br />
              Process has been completed.
            </>
          ) : (
            ""
          )}
          {screen?.results?.data && screen?.results?.data != '""' ? (
            <>
              <br />
              {screen?.results?.data}
            </>
          ) : (
            ""
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-evenly">
        <Button variant="secondary" onClick={() => setOpen(false)}>
          Κλείσιμο
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  screen: state.screen.screens[state.screen.currentscreen],
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BatchResults);

const formatDate = (date) => {
  if (date) {
    return `${[padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join(
      "/"
    )} ${padTo2Digits(date.getHours())}:${padTo2Digits(date.getMinutes())}:${padTo2Digits(date.getSeconds())}`;
  } else {
    return "";
  }
};

const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};
