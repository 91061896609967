const session = (state = JSON.parse(localStorage.getItem("session")), action) => {
  switch (action.type) {
    case "session/set":
      localStorage.setItem("session", JSON.stringify(action.value));
      return action.value;
    case "session/clear":
      localStorage.setItem("session", null);
      return null;
    default:
      return state;
  }
};

export default session;
