import React from "react";
import { IoClose, IoCheckmarkSharp, IoPencilSharp } from "react-icons/io5";
import { connect } from "react-redux";
import { setError, setLoading } from "../../../../../redux/actions/modals";
import {
  CancelEdits,
  changeToBuff,
  SetBrowserData,
  SetBrowserInfo,
  setGetData,
  SetObjectState,
  SetScreenState,
} from "../../../../../redux/actions/screen";
import { GETBUFFDATA, GETDATA, GETFORMDESIGN, GETNEWDATA, GETOBJECTSTATE } from "../../../../../redux/selectors";

import getBrowserInfo from "../../../../../services/getBrowserInfo";
import getBrowserData from "../../../../../services/getBrowserData";
import setData from "../../../../../services/setData";

function EditButton({
  handleSetObjectState,
  handleSetBackToList,
  handleCancelEdits,
  handleChangeToBuff,
  objectState,
  handleLoading,
  formdesign,
  data,
  buffData,
  newdata,
  handleSetGetData,
  handleSetError,
  handleSetBrowserInfo,
  handleSetBrowserData,
}) {
  const handleEdit = async () => {
    if (objectState == "view") {
      handleChangeToBuff();
      handleSetObjectState("edit");
    } else {
      const gridTtables = Object.keys(formdesign.model).filter((x) => formdesign.model[x].relationship == "OneToMany");
      const gridTablesToUpdate = Object.keys(newdata ?? {}).filter((x) => gridTtables.indexOf(x) > -1);
      var updData = JSON.parse(JSON.stringify(newdata));
      // if (gridTablesToUpdate.length > 0) {
      //   gridTablesToUpdate.forEach((x) => {
      //     buffData[x].forEach((y) => {
      //       if (y.LINENUM < 9000000) {
      //         updData[x].push({ LINENUM: y.LINENUM });
      //       }
      //     });
      //   });
      // }
      try {
        handleLoading(true);
        const key = formdesign.key;
        const tableName = key.split(".")[0];
        const fieldName = key.split(".")[1];
        const keyValue = buffData?.[tableName]?.[0]?.[fieldName] ?? "";
        var locateinfo = "";
        Object.entries(formdesign.model).forEach(([key, value], index) => {
          locateinfo += index == 0 ? `${key}:` : `;${key}:`;
          if (key == "XTRDOCDATA") locateinfo += "NAME,";
          value.fields.map((item) => {
            locateinfo += `${item.name},`;
          });
        });
        const SetData = await setData({
          key: keyValue > 0 ? keyValue : "",
          data: updData,
          locateinfo,
        });

        if (SetData.success) {
          // const browserInfo = await getBrowserInfo();
          // if (browserInfo.success) {
          //   browserInfo.upddate = new Date();
          //   handleSetBrowserInfo(browserInfo);
          //   const browserData = await getBrowserData({
          //     reqID: browserInfo.reqID,
          //     start: 0,
          //   });
          //   handleSetBrowserData(browserData);
          // }
          handleSetGetData(SetData);
          handleSetObjectState("view");
          if (SetData?.message) {
            handleSetError({ error: `<div>${SetData.message.replaceAll("\r\n", "</br>")}</div>` });
          }
          var panel = document.querySelector("#crm-main-panel");
          panel.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        } else {
          SetData.error = `<div>${SetData.error.replaceAll("\r\n", "</br>")}</div>`;
          handleSetError(SetData);
        }
      } catch (error) {
        console.log(error);
      } finally {
        handleLoading(false);
      }
    }
  };

  const handleCancel = () => {
    handleCancelEdits();
    objectState == "new" && handleSetBackToList("list");
  };

  return (
    <nav className="crm-edit-button menu">
      <input
        type="checkbox"
        href="#"
        className="menu-open"
        name="menu-open"
        id="menu-open"
        checked={objectState != "view"}
        readOnly={true}
      />
      <label
        className={`menu-open-button shadow  ${objectState != "view" ? "bg-success" : ""}`}
        htmlFor="menu-open"
        onClick={handleEdit}
        style={{ opacity: objectState != "view" ? (newdata ? 1 : 0.7) : 1 }}
      >
        {objectState == "view" ? (
          <IoPencilSharp style={{ fontSize: "2.5rem" }} className="pe-none" />
        ) : (
          <IoCheckmarkSharp className="pe-none" />
        )}
      </label>

      <a className="menu-item shadow bg-danger" onClick={handleCancel}>
        <IoClose className="pe-none" />
      </a>
    </nav>
  );
}

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
  newdata: GETNEWDATA(state),
  formdesign: GETFORMDESIGN(state),
  buffData: GETBUFFDATA(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetObjectState: (value) => dispatch(SetObjectState(value)),
  handleSetBackToList: (value) => dispatch(SetScreenState(value)),
  handleChangeToBuff: () => dispatch(changeToBuff()),
  handleCancelEdits: () => dispatch(CancelEdits()),
  handleLoading: (value) => dispatch(setLoading(value)),
  handleSetGetData: (value) => dispatch(setGetData(value)),
  handleSetError: (value) => dispatch(setError(value)),
  handleSetBrowserInfo: (value) => dispatch(SetBrowserInfo(value)),
  handleSetBrowserData: (value) => dispatch(SetBrowserData(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditButton);
