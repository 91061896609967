import React, { useEffect, useState } from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import { setBatchFilter } from "../../../../../redux/actions/screen";
import { GETFILTERS } from "../../../../../redux/selectors";

const S1TextField = ({ element, handleSetFilters }) => {
  const [viewValue, setViewValue] = useState("");

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const handleChange = (val) => {
    setViewValue(val);
    handleSetFilters(table, key, val);
  };

  useEffect(() => {
    handleSetFilters(table, key, element.value);
    setViewValue(element.value);
  }, []);

  return (
    <div className="s1textfield w-100" style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}>
      <InputGroup
        className={`crm-floating-with-icon${element.required && viewValue == "" ? " input-required" : ""}`}
        hidden={!element.visible}
      >
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            className="crm-input ps-1"
            autoCapitalize="off"
            placeholder={element.caption}
            disabled={element.readOnly}
            readOnly={element.readOnly}
            name={element.name}
            style={{ border: "0" }}
            value={viewValue}
            onChange={(e) => setViewValue(e.target.value)}
            onBlur={(e) => handleChange(e.target.value.replaceAll("&", "_"))}
          />
        </FloatingLabel>
        {viewValue != "" && !element.readOnly && (
          <div className="d-flex align-items-center justify-content-center" onClick={() => handleChange("")}>
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  handleSetFilters: (value) => dispatch(setBatchFilter(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1TextField);
