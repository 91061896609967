import getBrowserInfo from "../../../../../../services/relJob/getBrowserInfo";
import getBrowserData from "../../../../../../services/relJob/getBrowserData";
import getReportInfo from "../../../../../../services/relJob/getReportInfo";
import getReportData from "../../../../../../services/relJob/getReportData";
import store from "../../../../../../redux/store";
import { GETFILTERS, GETSCREENOBJ } from "../../../../../../redux/relJobSelectors";
import {
  resetBuffFilters,
  SetBrowserData,
  SetBrowserInfo,
  setFilterText,
  setReportData,
  SetReportInfo,
  setReportNoData,
  setShowDialog,
} from "../../../../../../redux/actions/relJob";

const acceptDialog = async ({ setLoading }) => {
  const state = store.getState();
  const filters = GETFILTERS(state);
  const cmd = GETSCREENOBJ(state).cmd;
  const handleSetFilterText = (value, selectorValue) => store.dispatch(setFilterText(value, selectorValue));
  const handleSetBrowserInfo = (value) => store.dispatch(SetBrowserInfo(value));
  const handleSetBrowserData = (value) => store.dispatch(SetBrowserData(value));
  const handleSetReportInfo = (value) => store.dispatch(SetReportInfo(value));
  const handleSetReportData = (value) => store.dispatch(setReportData(value));
  const handleSetShowDialog = (value) => store.dispatch(setShowDialog(value));
  const handleResetBuffFilters = () => store.dispatch(resetBuffFilters());
  const handleSetReportNoData = (value) => store.dispatch(setReportNoData(value));

  if (filters?.buffdata) {
    setLoading(true);
    var text = "";
    var selectorText = "";
    for (const [tablename] of Object.entries(filters.buffdata)) {
      for (const [field, value] of Object.entries(filters.buffdata[tablename])) {
        text += value != "" && value != "" ? `${tablename}.${field}=${value}&` : `${tablename}.${field}=*&`;
      }
    }

    if (filters?.selector)
      for (const [tablename] of Object.entries(filters.selector)) {
        for (const [field, value] of Object.entries(filters.selector[tablename])) {
          text += value != "" && value != "" ? `${tablename}.${field}=${value}&` : `${tablename}.${field}=*&`;
        }
      }
    handleSetFilterText(text, selectorText);
    switch (cmd) {
      case "EditMaster":
        const browserInfo = await getBrowserInfo();
        if (browserInfo.success) {
          browserInfo.upddate = new Date();
          handleSetBrowserInfo(browserInfo);
          if (browserInfo.totalcount > 0) {
            const browserData = await getBrowserData({
              reqID: browserInfo.reqID,
              start: 0,
            });
            if (browserData.success) {
              handleSetBrowserData(browserData);
              handleResetBuffFilters();
              handleSetShowDialog(false);
              setLoading(false);
            } else {
              console.log(browserData);
              setLoading(false);
            }
          } else {
            console.log("no data");
            setLoading(false);
            handleSetShowDialog(false);
          }
        } else {
          console.log(browserInfo);
          setLoading(false);
          handleSetShowDialog(false);
        }
        break;
      case "Report":
        setLoading(true);
        const reportInfo = await getReportInfo();
        if (reportInfo.success) {
          handleSetReportInfo(reportInfo);
          if (reportInfo.npages > 0) {
            handleSetReportNoData(false);
            const reportData = await getReportData({
              reqID: reportInfo.reqID,
              pagenum: 1,
            });
            if (reportData.success) {
              reportData.pagenum = 1;
              handleSetReportData(reportData);
              handleResetBuffFilters();
              handleSetShowDialog(false);
              setLoading(false);
            } else {
              setLoading(false);
              console.log(reportData);
            }
          } else {
            console.log("no data");
            handleSetReportNoData(true);
            setLoading(false);
            handleSetShowDialog(false);
          }
        } else {
          setLoading(false);
          console.log(reportInfo);
        }
        break;
      default:
        console.log("exeis ksexasei na kaneis to ", cmd);
        break;
    }
  }
};

export default acceptDialog;
