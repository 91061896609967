import React from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { DeleteGridLine, TriggerGridChange } from "../../../../../redux/actions/screen";
import { HiX, HiCheck } from "react-icons/hi";
import { calculate } from "../functions/calculate";
import { setLoading } from "../../../../../redux/actions/modals";
import { GETNEWDATA } from "../../../../../redux/selectors";

const ModalDeleteLine = ({ show, setShow, grid, newdata, handleDeleteLine, index, handleLoading, handleTrigger }) => {
  const handleConfirm = async () => {
    setShow(false);
    handleLoading(true);
    var gridLines = newdata?.[grid] ?? [];
    if (gridLines.length == 0) {
      handleTrigger(grid);
    }
    handleDeleteLine(grid, index);
    await calculate();
    handleLoading(false);
  };
  return (
    <Modal
      show={show}
      contentClassName="bg-dark text-light border-warning mx-3"
      className="crm-delete-grid-line"
      onHide={() => setShow(false)}
      centered
    >
      <Modal.Body className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
        Επιβεβαίωση διαγραφής;
      </Modal.Body>
      <Modal.Footer className="p-0 w-100 bg-dark shadow border-0">
        <ButtonGroup className="m-0 w-100">
          <Button
            variant="outline-warning"
            className="w-50 d-flex justify-content-center align-items-center rounded-0"
            onClick={() => {
              setShow(false);
            }}
          >
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Κλείσμο
            </div>
          </Button>
          <Button
            className="w-50 d-flex justify-content-center align-items-center rounded-0"
            variant="outline-warning"
            onClick={handleConfirm}
          >
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Εφαρμογή
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  newdata: GETNEWDATA(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleDeleteLine: (grid, index) => dispatch(DeleteGridLine(grid, index)),
  handleLoading: (value) => dispatch(setLoading(value)),
  handleTrigger: (grid) => dispatch(TriggerGridChange(grid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalDeleteLine);
