import React, { useState } from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { GETDIALOG, GETFILTERS } from "../../../../redux/relJobSelectors";
import Spinner from "../../Spinner";
import S1StringMulti from "./S1 inputs/rel job/S1StringMulti";
import S1MultiSelect from "./S1 inputs/rel job/S1MultiSelect";
import S1DateRange from "./S1 inputs/rel job/S1DateRange";
import S1TextField from "./S1 inputs/rel job/S1TextField";
import S1Selector from "./S1 inputs/rel job/S1Selector";
import S1CheckBox from "./S1 inputs/rel job/S1CheckBox";
import S1DateRangeDropDown from "./S1 inputs/rel job/S1DateRangeDropDown";
import S1CalendarDate from "./S1 inputs/rel job/S1CalendarDate";
import { resetBuffFilters, setShowDialog } from "../../../../redux/actions/relJob";
import acceptDialog from "./functions/rel job/acceptDialog";
import { HiX, HiCheck } from "react-icons/hi";

const ModalDialog = ({ dialog, filters, handleSetShowDialog, handleResetBuffFilters }) => {
  const [loading, setLoading] = useState(false);

  const Recursive = (data, parentIndex) => {
    return data.map((element) => {
      switch (element.xtype) {
        case "s1cont":
          return (
            <React.Fragment key={element.index}>
              <div className={`s1cont${element.items.every((x) => x.xtype == "s1daterange") ? " daterange" : ""}`}>
                {Recursive(element.items, element.index)}
                {element.items.every((x) => x.xtype == "s1daterange") && (
                  <S1DateRangeDropDown parentIndex={element.index} />
                )}
              </div>
            </React.Fragment>
          );
        case "s1daterange":
          return <S1DateRange element={element} key={element.index} parentIndex={parentIndex} />;
        case "s1calendardate":
          return <S1CalendarDate element={element} key={element.index} />;
        case "s1multiselect":
          return <S1MultiSelect element={element} key={element.index} />;
        case "s1stringsmulti":
          return <S1StringMulti element={element} key={element.index} />;
        case "s1spacer":
          return <div className="s1spacer" key={element.index} />;
        case "s1selector":
          return <S1Selector element={element} key={element.index} />;
        case "s1textfield":
          return <S1TextField element={element} key={element.index} />;
        case "s1checkbox":
          return <S1CheckBox element={element} key={element.index} />;
        default:
          return (
            <div className="bg-danger" key={element.index}>
              {element.xtype}
            </div>
          );
      }
    });
  };

  return (
    <Modal
      show={dialog?.show}
      className="d-flex align-items-end"
      dialogClassName="modalDialog"
      contentClassName="modalDialog-content rounded overflow-hidden"
      fullscreen={true}
      onHide={() => {
        handleSetShowDialog(false);
        filters?.buffdata && handleResetBuffFilters();
      }}
    >
      {loading && <Spinner colorSpin="#ffc107" />}
      <Modal.Body>{dialog && Recursive(dialog.form)}</Modal.Body>
      <Modal.Footer className="p-0 w-100 shadow border-0">
        <ButtonGroup className="m-0 w-100">
          <Button
            variant="outline-warning"
            className="w-50 d-flex justify-content-center align-items-center border-start-0 rounded-0"
            onClick={handleResetBuffFilters}
          >
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Reset
            </div>
          </Button>
          <Button
            className="w-50 d-flex justify-content-center align-items-center border-end-0 rounded-0"
            variant="outline-warning"
            onClick={() => acceptDialog({ setLoading: setLoading })}
          >
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Εφαρμογή
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  dialog: GETDIALOG(state),
  filters: GETFILTERS(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetShowDialog: (value) => dispatch(setShowDialog(value)),
  handleResetBuffFilters: () => dispatch(resetBuffFilters()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalDialog);
