import React, { useEffect, useRef, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { connect } from "react-redux";
import { SetErganiModal } from "../../redux/actions/modals";
import { GETSN } from "../../redux/selectors";
import { motion } from "framer-motion";
import QRCode from "react-qr-code";
import axios from "axios";
import AddToWalletApple from "../ui/buttons/AddToWalletApple";

const ErganiModal = ({ show, user, username, prsn, serialnumber, setShow, companyinfo }) => {
  const [QRData, setQRData] = useState("");
  const [OS, setOS] = useState(null);

  const bodyRef = useRef();
  useEffect(() => {
    setOS(getOS());
    const text = {
      user,
      username,
      serialnumber,
    };
    setQRData(JSON.stringify(text));
  }, []);

  const fetchPass = async () => {
    const route = OS === "iOS" || OS === "MacOS" ? "ios" : "android";
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/generate-pass/${route}`, {
      serialnumber,
      company: companyinfo,
      user: {
        ...prsn,
        username,
        user,
      },
    });
    if (response.data.success) {
      setShow(false);
      window.open(response.data.data);
    } else {
      console.log(response.data);
    }
  };

  const variants = {
    open: { opacity: 1, pointerEvents: "auto" },
    closed: {
      opacity: 0,
      pointerEvents: "none",
    },
  };
  return (
    <motion.div
      variants={variants}
      initial="closed"
      exit="closed"
      transition={{ duration: 0.5, ease: "easeInOut" }}
      animate={`${show ? "open" : "closed"}`}
      className="crm-modal-ergani Overlay d-flex align-items-center justify-content-center p-4"
    >
      <Card className="bg-dark w-100 h-100 shadow border border-warning rounded d-flex align-items-center justify-content-center">
        <Card.Body
          ref={bodyRef}
          className="w-100 h-100 flex-column d-flex align-items-center justify-content-center p-4"
        >
          <div
            className="bg-light p-2 border border-warning border-2 rounded w-100"
            style={{ height: "auto", maxWidth: "100%" }}
          >
            <QRCode
              size={256}
              style={{
                height: "auto",
                maxWidth: "100%",
                width: "100%",
                maxHeight: `calc(${bodyRef?.current?.offsetHeight ?? 0}px - 12rem)`,
              }}
              viewBox={`0 0 256 256`}
              value={QRData}
            />
          </div>
          {(OS === "iOS" || OS === "MacOS" || OS === "Windows") && (
            <div className="m-0 p-0">
              <AddToWalletApple className="mt-4" onClick={fetchPass} />
            </div>
          )}
        </Card.Body>
        <Card.Footer className=" w-100 d-flex align-items-center justify-content-center py-3">
          <Button onClick={() => setShow(false)}> OK </Button>
        </Card.Footer>
      </Card>
    </motion.div>
  );
};

const mapStateToProps = (state) => ({
  show: state.modals.ergani,
  username: state.login.username,
  user: state.systemparams.userid,
  serialnumber: GETSN(state),
  companyinfo: state.systemparams.companyinfo,
  prsn: state.session.prsn,
});

const mapDispatchToProps = (dispatch) => ({ setShow: (value) => dispatch(SetErganiModal(value)) });

export default connect(mapStateToProps, mapDispatchToProps)(ErganiModal);

function getOS() {
  var userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "MacOS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
}
