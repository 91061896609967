import axios from "axios";
import { GETCLIENTID, GETURL } from "../redux/selectors";
import store from "../redux/store";

const loadGadgets = async ({ codes }) => {
  const state = store.getState();
  const url = GETURL(state);
  const clientID = GETCLIENTID(state);

  return await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url,
      data: {
        service: "loadGadgets",
        clientID,
        codes,
        appID: process.env.REACT_APP_SOFTONE_APPID,
      },
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
};

export default loadGadgets;
