import React from "react";
import TopBarOnList from "./TopBarOnList";
import TopBarOnForm from "./TopBarOnForm";
import { GETSCREENSTATE } from "../../../../redux/relJobSelectors";
import { connect } from "react-redux";

const TopBarOnEditMaster = ({ screenState }) => {
  return { list: <TopBarOnList />, form: <TopBarOnForm /> }[screenState];
};

const mapStateToProps = (state) => ({
  screenState: GETSCREENSTATE(state),
});

export default connect(mapStateToProps)(TopBarOnEditMaster);
