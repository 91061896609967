import React from "react";
import { connect } from "react-redux";

const NoData = ({ screen }) => {
  return (
    screen.screenState == "list" && (
      <div className="text-light d-flex flex-column justify-content-center align-items-center h-100">
        <div className="s1-no-report-data" />
        <h2 className="mt-3">No data for this report</h2>
        <h5>Please review filters</h5>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  serialnumber: state.systemparams.serialnumber,
  clientID: state.session.clientID,
  currentscreen: state.screen.currentscreen,
  screen: state.screen.screens[`${state.screen.currentscreen}`],
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NoData);
