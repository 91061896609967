import React from "react";
import { FloatingLabel, Form } from "react-bootstrap";
import { connect } from "react-redux";

export const S1WebPage = ({ element, defaultValue, objectState }) => {
  return (
    <div className="s1webpage w-100" style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}>
      <FloatingLabel
        controlId={element.index}
        label={element.caption}
        hidden={!element.visible}
        style={{ whiteSpace: "nowrap" }}
      >
        <Form.Control
          className="my-1"
          placeholder={element.caption}
          disabled={element.readOnly || objectState === "view"}
          name={element.name}
          rows={4}
          defaultValue={defaultValue}
          //   onBlur={(e) => handleChangeField(e)}
        />
      </FloatingLabel>
    </div>
  );
};

const mapStateToProps = (state) => ({
  objectState: state.screen.screens[`${state.screen.currentscreen}`].objectState,
});

export default connect(mapStateToProps)(S1WebPage);
