import React from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import { SetGridChanges } from "../../../../../../redux/actions/relJob";
import {
  GETBUFFDATA,
  GETCURRENTLINE,
  GETCURRENTLINEINDEX,
  GETDATA,
  GETFORMDESIGN,
  GETNEWDATA,
  GETOBJECTSTATE,
} from "../../../../../../redux/relJobSelectors";

const S1CalendarDate = ({
  element,
  objectState,
  data,
  grid,
  currentLine,
  formdesign,
  handleSetChanges,
  currentLineIndex,
}) => {
  const [viewValue, setViewValue] = React.useState("");
  const { caption, flex, index, name, readOnly, redirector, required, selectorVF, value, visible, xtype } = element;

  const table = name.split(".")[0];
  const key = name.split(".")[1];
  const model = formdesign.model[table].fields.filter((el) => el.name == key)[0];

  React.useEffect(() => {
    if (data?.[table]?.[currentLineIndex(grid)]?.[key] || data?.[table]?.[currentLineIndex(grid)]?.[key] == "") {
      var value = data?.[table][currentLineIndex(grid)][key];
      value != "" && setViewValue(dateConverter(value));
    }
  }, [data?.[table]?.[currentLineIndex(grid)]?.[key]]);

  React.useEffect(() => {
    if (currentLine(grid)?.[key] || currentLine(grid)?.[key] == "") {
      var value = currentLine(grid)?.[key];
      value != "" && setViewValue(dateConverter(value));
    }
  }, [currentLine(grid)?.[key]]);

  return (
    <div className="s1calendardate w-100" style={{ flex: `${flex * 100}%`, overflow: "hidden" }}>
      <InputGroup
        className={`crm-floating-with-icon ${required && viewValue == "" ? " input-required" : ""}`}
        hidden={!visible}
      >
        <FloatingLabel controlId={index} label={caption} style={{ whiteSpace: "nowrap" }} className="w-100">
          <Form.Control
            className="crm-input ps-1"
            type="date"
            placeholder={caption}
            disabled={objectState === "view"}
            readOnly={readOnly}
            name={name}
            style={{ border: "0" }}
            value={viewValue}
            onChange={(e) => {
              setViewValue(e.target.value);
              handleSetChanges(table, key, currentLine, e.target.value);
            }}
            // onBlur={handleChangeField}
          />
        </FloatingLabel>
        {objectState == "edit" && viewValue != "" && !readOnly && (
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={() => {
              setViewValue("");
              handleSetChanges(table, key, currentLine, "");
              //   handleChangeField(true);
            }}
          >
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
  data: { view: GETDATA(state)?.data, edit: GETBUFFDATA(state), new: GETNEWDATA(state) }[GETOBJECTSTATE(state)],
  formdesign: GETFORMDESIGN(state),
  currentLineIndex: (grid) => GETCURRENTLINEINDEX(state, grid),
  currentLine: (grid) => GETCURRENTLINE(state, grid),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetChanges: (table, key, index, value) => dispatch(SetGridChanges(table, key, index, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1CalendarDate);

const dateConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-");
  } else {
    return value;
  }
};
