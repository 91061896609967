import React from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import { SetChanges } from "../../../../../../redux/actions/screen";
import { setLoading } from "../../../../../../redux/actions/modals";
import {
  GETBUFFDATA,
  GETCLIENTID,
  GETDATA,
  GETFORMDESIGN,
  GETNEWDATA,
  GETOBJECTSTATE,
  GETSN,
} from "../../../../../../redux/selectors";
import { BsPinMapFill, BsChevronDown, BsTelephone } from "react-icons/bs";
import clearEditor from "../../functions/clearEditor";
import axios from "axios";
import MaskTextFieldModal from "../input modals/MaskTextFieldModal";
import { calculate } from "../../functions/calculate";

const S1TextField = ({
  serialnumber,
  clientID,
  element,
  objectState,
  data,
  formdesign,
  handleLoading,
  handleSetChanges,
}) => {
  const { index, flex, name, caption, maskEnabled, visible, required, readOnly } = element;

  const [viewValue, setViewValue] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [editorData, setEditorData] = React.useState({});
  const [editorString, setEditorString] = React.useState("");
  const [isMap, setIsMap] = React.useState(false);
  const [isPhone, setIsPhone] = React.useState(false);

  const table = name.split(".")[0];
  const key = name.split(".")[1];
  const model = formdesign.model[table].fields.filter((el) => el.name == key)[0];

  const handleEditor = async (e) => {
    e.target.blur();
    handleLoading(true);
    try {
      const editor = clearEditor(model.editor, data);
      const GetSelectorData = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
        url: `http://${serialnumber}.oncloud.gr/s1services`,
        data: {
          service: "GetSelectorData",
          clientID,
          appId: process.env.REACT_APP_SOFTONE_APPID,
          editor,
          VALUE: "",
        },
      });
      if (GetSelectorData.data.success) {
        setEditorData(GetSelectorData.data);
        setEditorString(editor);
        setOpenModal(true);
      } else {
        console.log(GetSelectorData.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleLoading(false);
    }
  };

  const handleChangeField = async (table, key, index, value) => {
    handleSetChanges(table, key, index, value);
    model.updates != "" && (await calculate());
  };

  React.useEffect(() => {
    name == "CUSTOMER.PHONE01" && console.log(element);
    setIsMap(formdesign?.extrainfo?.map?.ADDRESS == name);
    setIsPhone(name.toUpperCase().indexOf("PHONE") > -1);
  }, []);

  React.useEffect(() => {
    if (data?.[table]?.[0]?.[key]) {
      const values = (data?.[table]?.[0]?.[key] ?? "").split("|");
      setViewValue(values[values.length - 1]);
    }
  }, [data?.[table]?.[0]?.[key]]);

  const openMap = () => {
    var text = "";
    Object.entries(formdesign.extrainfo.map).forEach(([key, value], index) => {
      const field = value;
      const splitted = field.split(".");
      const tableName = splitted[0];
      const fieldName = splitted[1];
      var valuee = data[tableName][0][fieldName] ?? "";

      text += valuee != "" ? (index == 0 ? `${valuee}` : `,${valuee}`) : 0;
    });
    const url = `http://maps.google.com/?q=${text} `;
    window.open(url);
  };

  const callNumber = () => {
    window.location.href = `tel:${viewValue}`;
  };

  return (
    <div className="s1textfield w-100" style={{ flex: `${flex * 100}%`, overflow: "hidden" }}>
      <InputGroup
        className={`crm-floating-with-icon${required && viewValue == "" ? " input-required" : ""}`}
        hidden={!visible}
      >
        <FloatingLabel controlId={index} label={caption} style={{ whiteSpace: "nowrap" }} className="w-100">
          <Form.Control
            className="crm-input ps-1"
            autoCapitalize="off"
            placeholder={caption}
            disabled={objectState === "view"}
            readOnly={readOnly}
            name={name}
            style={{ border: "0" }}
            value={viewValue}
            onChange={(e) => setViewValue(e.target.value)}
            onBlur={() => handleChangeField(table, key, 0, viewValue)}
          />
        </FloatingLabel>
        {objectState != "view" && viewValue != "" && !readOnly && (
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={() => {
              setViewValue("");
              handleChangeField(table, key, 0, "");
            }}
          >
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
        {objectState == "view" && isMap && viewValue != "" && (
          <div className="d-flex align-items-center justify-content-center" onClick={openMap}>
            <BsPinMapFill size="1.6rem" color={"var(--bs-primary)"} />
          </div>
        )}
        {objectState == "view" && isPhone && viewValue != "" && (
          <div className="d-flex align-items-center justify-content-center" onClick={callNumber}>
            <BsTelephone size="1.6rem" color={"var(--bs-primary)"} />
          </div>
        )}
        {objectState != "view" && !readOnly && maskEnabled && (
          <div className="d-flex align-items-center justify-content-center" onClick={handleEditor}>
            <div className="vr m-1"></div>
            <BsChevronDown size="1.5rem" color={"var(--bs-primary)"} />
          </div>
        )}
      </InputGroup>
      {openModal && (
        <MaskTextFieldModal
          element={element}
          show={openModal}
          setShow={setOpenModal}
          data={editorData}
          editor={editorString}
          setViewValue={setViewValue}
          handleChange={handleChangeField}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  serialnumber: GETSN(state),
  clientID: GETCLIENTID(state),
  objectState: GETOBJECTSTATE(state),
  data: { view: GETDATA(state)?.data, edit: GETBUFFDATA(state), new: GETNEWDATA(state) }[GETOBJECTSTATE(state)],
  formdesign: GETFORMDESIGN(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetChanges: (table, key, index, value) => dispatch(SetChanges(table, key, index, value)),
  handleLoading: (value) => dispatch(setLoading(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1TextField);
