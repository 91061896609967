import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { SetBrowserData, SetBrowserInfo, setFilter } from "../../../../../redux/actions/relJob";
import {
  GETCLIENTID,
  GETFILTERS,
  GETOUTPUTFORMATS,
  GETSCREENOBJ,
  GETSN,
  GETBROWSERINFO,
} from "../../../../../redux/relJobSelectors";
import Spinner from "../../../Spinner";
import { HiX, HiCheck } from "react-icons/hi";

const SortList = ({
  sort,
  setSort,
  screen,
  clientID,
  serialnumber,
  filters,
  browserInfo,
  handleSortiing,
  handleSetGetBrowserInfo,
  handleSetGetBrowserData,
}) => {
  const [loading, setLoading] = useState(false);
  const [order, setsOrder] = useState("up");
  const [selectedRow, setSelectedRow] = useState("");

  const handleSort = async () => {
    if (selectedRow != "") {
      setLoading(true);
      var nfilters = filters ?? {};
      nfilters["sort"] = `${order == "up" ? "" : "*"}${selectedRow}`;
      handleSortiing(nfilters);
      try {
        const getBrowserInfo = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
          url: `http://${serialnumber}.oncloud.gr/s1services`,
          data: {
            service: "GetBrowserInfo",
            clientID,
            filters: `${filters?.text ?? ""}${filters?.search ?? ""}`,
            sort: `${order == "up" ? "" : "*"}${selectedRow}`,
            object: `${serialnumber}/object=${screen.command}`,
            appID: process.env.REACT_APP_SOFTONE_APPID,
          },
        });
        if (getBrowserInfo.data.success) {
          if (getBrowserInfo.data.totalcount > 0) {
            getBrowserInfo.data.upddate = new Date();
            handleSetGetBrowserInfo(getBrowserInfo.data);
            const getBrowserData = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
              url: `http://${serialnumber}.oncloud.gr/s1services`,
              data: {
                service: "GetBrowserData",
                clientID,
                reqid: getBrowserInfo.data.reqID,
                start: 0,
                limit: process.env.REACT_APP_GETBROWSERDATA_LIMIT,
                APPID: process.env.REACT_APP_SOFTONE_APPID,
              },
            });
            if (getBrowserData.data.success) {
              setLoading(false);
              handleSetGetBrowserData(getBrowserData.data);
              setSort(false);
            } else {
              setLoading(false);
              console.log(getBrowserData.data);
            }
          } else {
            setLoading(false);
            console.log("no data");
          }
        } else {
          setLoading(false);
          console.log(getBrowserInfo.data);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      setSort(false);
    }
  };

  useEffect(() => {
    if (sort) {
      if (filters?.sort) {
        setsOrder(filters.sort.substring(0, 1) == "*" ? "down" : "up");
        setSelectedRow(filters.sort.replaceAll("*", ""));
      } else {
        setsOrder("up");
        setSelectedRow("");
      }
    }
  }, [sort]);

  return (
    <Modal
      contentClassName="bg-dark text-light border-warning"
      className="crm-print-sort"
      show={sort}
      scrollable={true}
      onHide={() => setSort(false)}
      size="lg"
      centered
      backdrop="static"
    >
      {loading && <Spinner colorSpin="#ffc107" />}
      <Modal.Header>
        <Form.Select className="bg-dark text-light" onChange={(e) => setsOrder(e.target.value)} value={order}>
          <option value="up">Αύξουσα</option>
          <option value="down">Φθίνουσα</option>
        </Form.Select>
      </Modal.Header>
      <Modal.Body className="p-0">
        {browserInfo?.columns.map((item, index) => {
          return (
            <div
              key={index}
              onClick={() => setSelectedRow(item.dataIndex)}
              className={`s1editor-row border border-start-0 border-end-0 px-1 ${
                selectedRow == item.dataIndex ? "border-warning shadow" : ""
              }`}
              style={{
                backgroundColor: selectedRow == item.dataIndex ? "var(--bs-gray-400)" : "transparent",
                color: selectedRow == item.dataIndex ? "var(--bs-dark)" : "white",
              }}
            >
              <div className="my-3 mx-4">{item.header}</div>
            </div>
          );
        })}
      </Modal.Body>
      <Modal.Footer className="p-0 w-100 bg-dark shadow border-0">
        <ButtonGroup className="m-0 w-100">
          <Button
            variant="outline-warning"
            className="w-50 d-flex justify-content-center align-items-center rounded-0"
            onClick={() => setSort(false)}
          >
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Ακύρωση
            </div>
          </Button>
          <Button
            className="w-50 d-flex justify-content-center align-items-center rounded-0"
            variant="outline-warning"
            disabled={selectedRow == ""}
            onClick={handleSort}
          >
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Εφαρμογή
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  outputformats: GETOUTPUTFORMATS(state),
  serialnumber: GETSN(state),
  clientID: GETCLIENTID(state),
  screen: GETSCREENOBJ(state),
  filters: GETFILTERS(state),
  browserInfo: GETBROWSERINFO(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSortiing: (value) => dispatch(setFilter(value)),
  handleSetGetBrowserInfo: (value) => dispatch(SetBrowserInfo(value)),
  handleSetGetBrowserData: (value) => dispatch(SetBrowserData(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SortList);
