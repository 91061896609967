import React from "react";
import { connect } from "react-redux";
import { SetGridChanges } from "../../../../../../redux/actions/screen";
import SelectorEditorModal from "../input modals/SelectorEditorModal";
import MaskSelectorEditorModal from "../input modals/MaskSelectorEditorModal";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { TiDeleteOutline } from "react-icons/ti";
import { BsChevronDown } from "react-icons/bs";
import {
  GETBUFFDATA,
  GETCURRENTLINE,
  GETCURRENTLINEINDEX,
  GETDATA,
  GETFORMDESIGN,
  GETNEWDATA,
  GETOBJECTSTATE,
  GETSCREENOBJ,
} from "../../../../../../redux/selectors";
import getSelectorData from "../../../../../../services/getSelectorData";
import Spinner from "../../../../Spinner";

const S1Selector = ({ element, objectState, data, handleSetChanges, gridName, currentLine, currentLineIndex }) => {
  const { name, caption, flex, index, editor, maskEnabled, visible, readOnly, selectorVF } = element;
  const [loading, setLoading] = React.useState(false);

  const [viewValue, setViewValue] = React.useState("");
  const [keyValue, setKeyValue] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [editorData, setEditorData] = React.useState({});
  const [editorString, setEditorString] = React.useState("");

  const table = name.split(".")[0];
  const key = name.split(".")[1];

  //Εάν έχει _ στο όνομα πχ ITELINES.MTRL_ITEM_NAME
  const hasSelectorVf = selectorVF != "";
  const keyname = editor.split("|")[2];
  const tablename = editor.split("|")[1];
  const underScore1 = keyname;
  const underScore2 = tablename;

  const handleEditor = async (e) => {
    e.target.blur();
    setLoading(true);
    try {
      var Editor = editor;
      const EditorArguments = Array.from(editor.matchAll(/\[([^\][]*)]/g), (x) => x[1]);
      EditorArguments.forEach((y) => {
        var splitted = y.split(".");
        var tableName = splitted[0];
        var fieldName = splitted[1];
        var replaceValue = data?.[tableName]?.[0].hasOwnProperty(fieldName)
          ? data[tableName][0][fieldName].split("|")[0]
          : "";
        Editor = editor.replaceAll(`[${y}]`, replaceValue);
      });

      const GetSelectorData = await getSelectorData({ value: "", editor: Editor });
      if (GetSelectorData.success) {
        setEditorData(GetSelectorData);
        setEditorString(Editor);
        setOpenModal(true);
      } else {
        console.log(GetSelectorData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    data?.[table]?.[currentLineIndex(gridName)] &&
      Object.keys(data?.[table]?.[0]).forEach((x) => {
        if (x.indexOf("_") > -1) {
          if (x.split("_")[0] == underScore1 && x.split("_")[1] == underScore2) handleSetChanges(table, x, 0, "");
        }
      });
    currentLine(gridName) &&
      Object.keys(currentLine(gridName)).forEach((x) => {
        if (x.indexOf("_") > -1) {
          if (x.split("_")[0] == underScore1 && x.split("_")[1] == underScore2) handleSetChanges(table, x, 0, "");
        }
      });
    handleSetChanges(table, keyname, 0, "");
    setViewValue("");
    setKeyValue("");
  };

  React.useEffect(() => {
    if (
      data?.[table]?.[currentLineIndex(gridName)]?.[key] ||
      data?.[table]?.[currentLineIndex(gridName)]?.[key] == ""
    ) {
      var value = "";
      if (hasSelectorVf) {
        selectorVF.split("|").forEach((i) => {
          var dbIndex = i.split(".")[1];
          value +=
            data?.[table][currentLineIndex(gridName)][dbIndex] == ""
              ? ""
              : data?.[table][currentLineIndex(gridName)][dbIndex] + " ";
        });
      } else {
        value = data[table][currentLineIndex(gridName)][key];
      }
      setViewValue(value);
    }
  }, [data?.[table]?.[currentLineIndex(gridName)]?.[key]]);

  React.useEffect(() => {
    if (currentLine(gridName)?.[key] || currentLine(gridName)?.[key] == "") {
      var value = "";
      if (hasSelectorVf) {
        selectorVF.split("|").forEach((i) => {
          var dbIndex = i.split(".")[1];
          value += data?.[table][0][dbIndex] == "" ? "" : data?.[table][0][dbIndex] + " ";
        });
      } else {
        value = currentLine(gridName)?.[key];
      }
      setViewValue(value);
    }
  }, [currentLine(gridName)?.[key]]);

  return (
    <div className="s1selector w-100" style={{ flex: `${flex * 100}%`, overflow: "hidden" }} key={index}>
      {loading && <Spinner />}
      <InputGroup className="crm-floating-with-icon" hidden={!visible}>
        <FloatingLabel controlId={index} label={caption} style={{ whiteSpace: "nowrap" }} className="w-100">
          <Form.Control
            value={viewValue}
            onChange={(e) => {
              setViewValue(e.target.value);
              setKeyValue(e.target.value);
            }}
            inputMode={maskEnabled ? "text" : "none"}
            className="crm-input form-control ps-1"
            placeholder={caption}
            name={name}
            disabled={objectState == "view" || readOnly}
            readOnly={readOnly}
            onClick={(e) => !maskEnabled && !readOnly && handleEditor(e)}
            style={{ border: "0" }}
          />
        </FloatingLabel>
        {objectState != "view" && viewValue != "" && !readOnly && (
          <div className="d-flex align-items-center justify-content-center" onClick={handleClear}>
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
        {objectState != "view" && !readOnly && maskEnabled && (
          <div className="d-flex align-items-center justify-content-center" onClick={handleEditor}>
            <div className="vr m-1"></div>
            <BsChevronDown size="1.5rem" color={"var(--bs-primary)"} />
          </div>
        )}
      </InputGroup>
      {openModal &&
        (maskEnabled ? (
          <MaskSelectorEditorModal
            element={element}
            openModal={openModal}
            setOpenModal={setOpenModal}
            data={editorData}
            editor={editorString}
            setKeyValue={setKeyValue}
            setViewValue={setViewValue}
            predifinedKey={keyValue}
          />
        ) : (
          <SelectorEditorModal
            element={element}
            show={openModal}
            setShow={setOpenModal}
            data={editorData}
            editor={editorString}
            setKeyValue={setKeyValue}
            setViewValue={setViewValue}
            predifinedKey={keyValue}
            currentLine={currentLine(gridName)}
          />
        ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
  screen: GETSCREENOBJ(state),
  data: { view: GETDATA(state)?.data, edit: GETBUFFDATA(state), new: GETNEWDATA(state) }[GETOBJECTSTATE(state)],
  formdesign: GETFORMDESIGN(state),
  currentLineIndex: (grid) => GETCURRENTLINEINDEX(state, grid),
  currentLine: (grid) => GETCURRENTLINE(state, grid),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetChanges: (table, key, index, value) => dispatch(SetGridChanges(table, key, index, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1Selector);
