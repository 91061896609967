import React, { useEffect, useState } from "react";
import { Button, Modal, ButtonGroup } from "react-bootstrap";

export default function StringMultiEditorModal({
  setKeyValue,
  openModal,
  setOpenModal,
  data,
  predifinedKey,
  element,
  handleChange,
}) {
  const [selectedRow, setSelectedRow] = useState("");

  const handleClickOnRow = (key) => {
    const SelectedArray = selectedRow != "" ? selectedRow.split(",") : [];
    if (SelectedArray.indexOf(key) >= 0) {
      SelectedArray.splice(SelectedArray.indexOf(key), 1);
    } else {
      SelectedArray.push(key);
    }
    setSelectedRow(SelectedArray.filter((x) => x != "").join(","));
  };

  const handleAccept = () => {
    setKeyValue(selectedRow);
    setOpenModal(false);
    handleChange(element.name.split(".")[0], element.name.split(".")[1], 0, selectedRow);
  };

  useEffect(() => {
    setSelectedRow(predifinedKey);
  }, []);

  return (
    <Modal
      animation={false}
      show={openModal}
      centered
      fullscreen
      dialogClassName="p-2"
      contentClassName="rounded overflow-hidden"
    >
      <Modal.Header />
      <Modal.Body className="p-0">
        <div id="s1Editor-list-items">
          {Object.keys(data).map((key) => {
            return (
              <div
                key={key}
                onClick={() => handleClickOnRow(key)}
                className={`s1editor-row border px-1 ${
                  selectedRow != "" && selectedRow.split(",").indexOf(key) >= 0 ? "rounded border-warning shadow" : ""
                }`}
                style={{
                  backgroundColor:
                    selectedRow != "" && selectedRow.split(",").indexOf(key) >= 0
                      ? "var(--bs-gray-400)"
                      : "transparent",
                }}
              >
                <div className="my-3 mx-4">{data[key]}</div>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer className="w-100 bg-dark shadow">
        <ButtonGroup className="w-100">
          <Button
            variant="outline-warning"
            className="w-50"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Ακύρωση
          </Button>
          <Button className="w-50" variant="warning" onClick={handleAccept}>
            Εφαρμογή
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}
