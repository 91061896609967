import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { HiOutlineRefresh } from "react-icons/hi";
import { connect } from "react-redux";
import { gadgetsData } from "../../../../redux/actions/gadget";
import getGadgetData from "../../../../services/getGadgetData";
import GadgetLoader from "../../../../screens/dashboard/GadgetLoader";
import GadgetNoData from "../../GadgetNoData";
import Category1Data from "./Category1Data";

const Category1 = ({ gadgets, property, handleGadgetData }) => {
  const [loading, setLoading] = useState();
  const [hasData, setHasData] = useState();
  const [error, setError] = useState(undefined);

  const fetchGadgetData = async () => {
    setLoading(true);
    setError(undefined);
    try {
      const gadgetData = await getGadgetData({ cstID: property });
      if (gadgetData.success) {
        handleGadgetData(property, gadgetData);
        setLoading(false);
        setHasData(gadgetData.totalcount > 0);
      } else {
        setLoading(false);
        setError(gadgetData.error);
        console.log(getGadgetData);
      }
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(async () => {
    setHasData(gadgets?.[property]?.gadgetdata?.totalcount > 0);
    !gadgets?.[property]?.gadgetdata && (await fetchGadgetData());
  }, []);
  return (
    gadgets && (
      <div className="d-flex position-relative justify-content-center align-items-center flex-column bg-dark h-100">
        <div className="gadget-bar text-warning bg-dark d-flex align-items-center justify-content-between w-100 ">
          <div className="ms-2">{gadgets[property].title ?? gadgets[property].data.title}</div>
          <Button
            className="me-2 my-2 crm-button-with-icon"
            style={{
              borderColor: "transparent",
              backgroundColor: "transparent",
            }}
            onClick={fetchGadgetData}
          >
            <HiOutlineRefresh color="var(--bs-warning)" />
          </Button>
        </div>
        {loading && <GadgetLoader colorSpin="#ffc107" />}
        {/* {<GadgetLoader colorSpin="#ffc107" />} */}

        {error ? { error } : hasData ? <Category1Data property={property} /> : <GadgetNoData />}
        {/* {`Total Count : ${gadgets?.[property]?.browserinfo?.totalcount ?? 0}`} */}
      </div>
    )
  );
};

const mapStateToProps = (state) => ({ gadgets: state?.gadget?.gadgets });

const mapDispatchToProps = (dispatch) => ({
  handleGadgetData: (key, value) => dispatch(gadgetsData(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Category1);
