import React, { useEffect, useRef, useState } from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import { GETFILTERS } from "../../../../../redux/selectors";
import { setFilter } from "../../../../../redux/actions/screen";
import { BsCalendar3 } from "react-icons/bs";
import getValueFromFilterArray from "../functions/getValueFromFilterArray";

const S1CalendarDate = ({ element, filters, handleSetFilters }) => {
  const dateRef = useRef();
  const [viewValue, setViewValue] = useState("");
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  useEffect(() => {
    var value = filters?.text ? getValueFromFilterArray(table, key) : element.value;
    value = value != "" ? dateConverter(value) : "";
    setViewValue(value);
    value != "" && handleSetFilters(table, key, value);
  }, []);

  //For Reset
  useEffect(() => {
    if (filters?.reset) {
      setViewValue("");
    }
  }, [filters?.reset]);

  const handleChange = (value) => {
    setViewValue(value);
    handleSetFilters(table, key, value);
  };

  return (
    <div className="s1calendardate w-100" style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}>
      <InputGroup
        className={`crm-floating-with-icon${element.required && viewValue == "" ? " input-required" : ""}`}
        hidden={!element.visible}
      >
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            className={`crm-input ps-1 ${viewValue != "" ? "date-input-has-value" : ""}`}
            type="date"
            ref={dateRef}
            placeholder={element.caption}
            disabled={element.readOnly}
            readOnly={element.readOnly}
            name={element.name}
            style={{ border: "0" }}
            value={viewValue}
            onChange={(e) => handleChange(e.target.value)}
          />
        </FloatingLabel>

        {viewValue != "" && !element.readOnly && (
          <div className="d-flex align-items-center justify-content-center pt-3" onClick={() => handleChange("")}>
            <TiDeleteOutline size="1.75rem" />
            <div className="vr m-1 me-0"></div>
          </div>
        )}
        {!element.readOnly && (
          <div
            className="d-flex align-items-center justify-content-center m-1 pt-3"
            onClick={() => {
              dateRef.current.showPicker();
              dateRef.current.click();
              dateRef.current.focus();
            }}
          >
            <BsCalendar3 size="1.5rem" color={"var(--bs-primary)"} />
          </div>
        )}
      </InputGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  filters: GETFILTERS(state),
});
const mapDispatchToProps = (dispatch) => ({
  handleSetFilters: (table, key, value) => dispatch(setFilter(table, key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1CalendarDate);

const dateConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-");
  } else {
    return value;
  }
};
