export const setAccessRights = (state, action) => ({
  ...state,
  screens: {
    ...state.screens,
    [state.currentscreen]: {
      ...state.screens[state.currentscreen],
      accessrights: action.value,
    },
  },
});
