import React, { useEffect, useState } from "react";
import { Button, Modal, ButtonGroup } from "react-bootstrap";
import { HiX, HiCheck } from "react-icons/hi";

export default function StringEditorModal({
  setViewValue,
  setKeyValue,
  show,
  setShow,
  data,
  predifinedKey,
  handleChange,
}) {
  const [selectedRow, setSelectedRow] = useState();

  const handleAccept = () => {
    setKeyValue(selectedRow);
    setViewValue(data[selectedRow]);
    setShow(false);
    handleChange(selectedRow);
  };

  useEffect(() => {
    setSelectedRow(predifinedKey);
  }, []);

  return (
    <Modal
      animation={false}
      show={show}
      centered
      fullscreen
      dialogClassName="p-3"
      contentClassName="rounded border border-warning shadow overflow-hidden bg-dark"
    >
      <Modal.Body className="p-0">
        <div id="s1Editor-list-items" className="pt-1">
          {Object.keys(data).map((key) => {
            return (
              <div
                key={key}
                onClick={() => setSelectedRow(key)}
                className={`s1editor-row border border-start-0 border-end-0 px-1 ${
                  selectedRow == key ? "border-warning shadow" : ""
                }`}
                style={{
                  backgroundColor: selectedRow == key ? "var(--bs-gray-400)" : "transparent",
                  color: selectedRow == key ? "var(--bs-dark)" : "white",
                }}
              >
                <div className="my-3 mx-4">{data[key]}</div>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer className="p-0 w-100 bg-dark shadow border-0">
        <ButtonGroup className="m-0 w-100">
          <Button
            variant="outline-warning"
            className="w-50 d-flex justify-content-center align-items-center rounded-0"
            onClick={() => {
              setShow(false);
            }}
          >
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Κλείσμο
            </div>
          </Button>
          <Button
            className="w-50 d-flex justify-content-center align-items-center rounded-0"
            variant="outline-warning"
            onClick={handleAccept}
          >
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Εφαρμογή
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}
