import React from "react";
import { connect } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom/dist";
import { setDropBox } from "../../redux/actions/settings";
import { SiDropbox } from "react-icons/si";
import Spinner from "../ui/Spinner";
const Auth = ({ handleSetDropBox }) => {
  const { provider, type } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const code = searchParams.get("code");
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (code) {
      const config = {
        clientId: process.env.REACT_APP_DROPBOX_CLIENT_ID,
        clientSecret: process.env.REACT_APP_DROPBOX_CLIENT_SECRET,
      };
      var Dropbox = require("dropbox").Dropbox;
      var dbx = new Dropbox(config);
      dbx.auth
        .getAccessTokenFromCode(process.env.REACT_APP_DROPBOX_REDIRECT_URL, code)
        .then((token) => {
          dbx.auth.setRefreshToken(token.result.refresh_token);
          dbx
            .usersGetCurrentAccount()
            .then((response) => {
              handleSetDropBox({
                token: token.result.refresh_token,
                account: response.result,
              });
              navigate(`/`);
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      navigate(`/`);
    }
  }, []);
  return (
    <div className="logInBack d-flex align-items-center justify-content-center h-100 bg-dark text-dark">
      {loading && <Spinner />}
      {provider == "dropbox" && <SiDropbox size="7rem" color="#0061fe" />}
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  handleSetDropBox: (value) => dispatch(setDropBox(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
