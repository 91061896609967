import axios from "axios";
import React from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { setLoading } from "../../../../../../redux/actions/modals";
import MemoryTableEditorModal from "../input modals/MemoryTableEditorModal";
import { TiDeleteOutline } from "react-icons/ti";
import {
  GETBUFFDATA,
  GETCLIENTID,
  GETCURRENTLINE,
  GETCURRENTLINEINDEX,
  GETDATA,
  GETNEWDATA,
  GETOBJECTSTATE,
  GETSN,
} from "../../../../../../redux/selectors";
import { SetGridChanges } from "../../../../../../redux/actions/screen";

const S1MemoryTable = ({
  clientID,
  serialnumber,
  element,
  grid,
  objectState,
  data,
  currentLineIndex,
  currentLine,
  handleLoading,
  handleSetChanges,
}) => {
  const [viewValue, setViewValue] = React.useState("");
  const [keyValue, setKeyValue] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [editorData, setEditorData] = React.useState({});
  const [editorString, setEditorString] = React.useState("");

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const handleEditor = async (e) => {
    e.target.blur();
    handleLoading(true);
    try {
      const EditorArguments = Array.from(element.editor.matchAll(/\[([^\][]*)]/g), (x) => x[1]);
      var editor = element.editor;
      EditorArguments.forEach((y) => {
        var splitted = y.split(".");
        var tableName = splitted[0];
        var fieldName = splitted[1];
        var replaceValue = data[tableName][0].hasOwnProperty(fieldName)
          ? data[tableName][0][fieldName].split("|")[0]
          : "";
        editor = editor.replaceAll(`[${y}]`, replaceValue);
      });
      const GetSelectorData = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
        url: `http://${serialnumber}.oncloud.gr/s1services`,
        data: {
          service: "GetSelectorData",
          clientID,
          appId: process.env.REACT_APP_SOFTONE_APPID,
          editor,
          VALUE: "",
        },
      });
      if (GetSelectorData.data.success) {
        setEditorData(GetSelectorData.data);
        setEditorString(editor);
        setOpenModal(true);
      } else {
        console.log(GetSelectorData.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleLoading(false);
    }
  };

  React.useEffect(() => {
    if (data?.[table]?.[currentLineIndex(grid)]?.[key] || data?.[table]?.[currentLineIndex(grid)]?.[key] == "") {
      var values = data?.[table]?.[currentLineIndex(grid)]?.[key] ?? "";
      values = values.split("|");
      if (values.length > 0) {
        var value = values[1];
        setViewValue(value);
        setKeyValue(values[0]);
      } else {
        setViewValue(data?.[table]?.[currentLineIndex(grid)]?.[key] ?? "");
        setKeyValue(data?.[table]?.[currentLineIndex(grid)]?.[key] ?? "");
      }
    }
  }, [data?.[table]?.[currentLineIndex(grid)]?.[key]]);

  React.useEffect(() => {
    if (currentLine(grid)?.[key] || currentLine(grid)?.[key] == "") {
      var values = currentLine(grid)?.[key] ?? "";
      values = values.split("|");
      if (values.length > 1) {
        var value = values[1];
        setViewValue(value);
        setKeyValue(values[0]);
      } else {
        setViewValue(currentLine(grid)?.[key] ?? "");
        setKeyValue(currentLine(grid)?.[key] ?? "");
      }
    }
  }, [currentLine(grid)?.[key]]);

  return (
    <div
      className="s1memorytable w-100"
      style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}
      key={element.index}
    >
      <InputGroup className="crm-floating-with-icon" hidden={!element.visible}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            defaultValue={viewValue}
            editor={element.editor}
            inputMode="none"
            className="crm-input form-control ps-1"
            placeholder={element.caption}
            name={element.name}
            disabled={objectState == "view" || element.readOnly}
            readOnly={element.readOnly}
            onClick={(e) => !element.readOnly && handleEditor(e)}
            style={{ border: "0" }}
            //   onBlur={(e) => handleChangeField(e)}
          />
        </FloatingLabel>
        {objectState == "edit" && keyValue != "" && !element.readOnly && (
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={() => {
              handleSetChanges(table, key, currentLine, "");
            }}
          >
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
      {openModal && (
        <MemoryTableEditorModal
          element={element}
          openModal={openModal}
          setOpenModal={setOpenModal}
          data={editorData}
          editor={editorString}
          setKeyValue={setKeyValue}
          setViewValue={setViewValue}
          predifinedKey={keyValue}
          currentLine={currentLine}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  serialnumber: GETSN(state),
  clientID: GETCLIENTID(state),
  objectState: GETOBJECTSTATE(state),
  data: { view: GETDATA(state)?.data, edit: GETBUFFDATA(state), new: GETNEWDATA(state) }[GETOBJECTSTATE(state)],
  currentLineIndex: (grid) => GETCURRENTLINEINDEX(state, grid),
  currentLine: (grid) => GETCURRENTLINE(state, grid),
});

const mapDispatchToProps = (dispatch) => ({
  handleLoading: (value) => dispatch(setLoading(value)),
  handleSetChanges: (table, key, index, value) => dispatch(SetGridChanges(table, key, index, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1MemoryTable);
