export const gadgetEditor = (type, value) => {
  if (value != "") {
    switch (type) {
      case "date":
        return formatDate(new Date(value));
      case "dateTime":
        return fomrmatDateTime(new Date(value));
      case "float":
        return parseFloat(value).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        });
      default:
        return value;
    }
  } else {
    return "";
  }
};

const formatDate = (date) => {
  if (date && date != "") {
    return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join("/");
  } else {
    return "";
  }
};

const fomrmatDateTime = (date) => {
  if (date && date != "") {
    return `${[padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join(
      "/"
    )} ${padTo2Digits(date.getHours())}:${padTo2Digits(date.getMinutes())}:${padTo2Digits(date.getSeconds())}`;
  } else {
    return "";
  }
};

const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};
