const gadget = (state = undefined, action) => {
  switch (action.type) {
    case "gadgets/setParams":
      return { ...state, params: action.value };
    case "gadgets/load":
      return { ...state, ...action.value };
    case "gadgets/setBrowserInfo":
      return {
        ...state,
        gadgets: {
          ...state.gadgets,
          [action.key]: {
            ...state.gadgets[action.key],
            browserinfo: action.value,
          },
        },
      };
    case "gadgets/setBrowserData":
      return {
        ...state,
        gadgets: {
          ...state.gadgets,
          [action.key]: {
            ...state.gadgets[action.key],
            browserdata: action.value,
          },
        },
      };
    case "gadgets/setData":
      return {
        ...state,
        gadgets: {
          ...state.gadgets,
          [action.key]: {
            ...state.gadgets[action.key],
            gadgetdata: action.value,
          },
        },
      };
    case "gadgets/setPage":
      return {
        ...state,
        gadgets: {
          ...state.gadgets,
          [action.key]: {
            ...state.gadgets[action.key],
            browserinfo: {
              ...state.gadgets[action.key]?.browserinfo,
              page: action.value,
            },
          },
        },
      };
    case "session/clear":
      return undefined;
    default:
      return state;
  }
};

export default gadget;
