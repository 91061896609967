import React from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { HiX, HiCheck } from "react-icons/hi";
import { CloseRelJob } from "../../../../redux/actions/screen";
import EditMaster from "./edit master/EditMaster";
import Report from "./report/Report";

const RelJob = ({ isOnRelJob, screen, handleCloseRelJob }) => {
  React.useEffect(() => {
    if (isOnRelJob) {
      const obj = JSON.parse(JSON.stringify(screen));
      let params = new URLSearchParams(screen.command);
      obj.screenState = "list";
      obj.objectState = "view";
      obj.list = params.get("list");
      obj.form = params.get("form");
      obj.forcedValues = params.get("fv");
      obj.autoLocate = params.get("ae");
    }
  }, [isOnRelJob]);

  return (
    {
      EditMaster: <EditMaster />,
      Report: <Report />,
      BatchJob: <>BatchJob</>,
    }[screen?.cmd] || <>Deafult</>
  );
};

const mapStateToProps = (state) => ({
  isOnRelJob: state.screen.isOnRelJob,
  screen: state.screen.relJob,
});

const mapDispatchToProps = (dispatch) => ({
  handleCloseRelJob: () => dispatch(CloseRelJob()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RelJob);
