import React from "react";
import { connect } from "react-redux";
import Spinner from "./components/ui/Spinner";
import MainNavigator from "./components/ui/MainNavigator";
import MainPanel from "./components/pages/MainPanel";
import TopBar from "./components/ui/bars/TopBar";
import { AddURLScreen, SetCurrentScreen, SetWebMenu } from "./redux/actions/screen";
import getWebMenu from "./services/getWebMenu";
import { SetOutputFormats, SetSystemParams } from "./redux/actions/systemparams";
import getSystemParams from "./services/getSystemParams";
import { GETCLIENTID, GETURL } from "./redux/selectors";
import Modals from "./components/ui/modals/Modals";
import { SetShowRoom } from "./redux/actions/showRoom";

function App({
  url,
  clientID,
  paramsDate,
  isLoading,
  user,
  ergani,
  comesFromURL,
  handleSetSystemParams,
  handleSetoutputFormats,
  handleSetWebMenu,
  handleSetShowRoom,
  handleAddScreenFromURL,
  handleSetCurrentScreen,
}) {
  const [webMenuData, setWebMenuData] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [showSideBar, setShowSideBar] = React.useState(false);
  const [mainNavigatorHeight, setMainNavigatiorHeight] = React.useState(0);
  const [topBarHeight, setTopBarHeight] = React.useState(0);
  let WebMenu = {};

  const currentUrl = window.location.href;
  const searchParams = new URLSearchParams(currentUrl.split("?")[1]);
  const cmd = searchParams.get("cmd");
  const object = searchParams.get("object");
  const list = searchParams.get("list");
  const form = searchParams.get("form");
  const id = searchParams.get("id");

  const redirectScreen = {
    cmd: cmd ?? comesFromURL?.cmd,
    idx: "URLScreen",
    command: `${object ?? comesFromURL?.object}&list=${list ?? comesFromURL?.list}&form=${form ?? comesFromURL?.form}`,
    isFromURL: true,
    objectState: "view",
    screenState: "form",
    list: list ?? comesFromURL?.list,
    form: form ?? comesFromURL?.form,
    id: id ?? comesFromURL?.id,
    searchParams: window.location.search,
  };

  const fetchWebMenu = async () => {
    setLoading(true);
    const webMenu = await getWebMenu({ user });
    if (webMenu.success) {
      // ergani && webMenu.menu.push({ text: "myErgani", idx: "ergani", cmd: "Ergani", command: "ergani" });
      setWebMenuData(webMenu.menu);
      deserializeMenu(webMenu.menu);
      handleSetWebMenu(WebMenu);
      (cmd || comesFromURL) && handleAddScreenFromURL(redirectScreen);
      handleSetoutputFormats(webMenu.outputformats);
    } else {
      console.log(webMenu);
    }
    setLoading(false);
  };

  const fetchSystemParams = async () => {
    const today = new Date();
    const pDate = new Date(paramsDate);
    if (
      !(
        today.getFullYear() === pDate.getFullYear() &&
        today.getMonth() === pDate.getMonth() &&
        today.getDate() === pDate.getDate()
      )
    ) {
      const systemParams = await getSystemParams({ url, clientID });
      systemParams.paramsDate = new Date();
      systemParams.success && handleSetSystemParams(systemParams);
    }
  };

  const deserializeMenu = (elements) => {
    elements.forEach((item) => {
      if (!item?.children) {
        const index = item.command.toLowerCase().indexOf("&rj=");
        if (index >= 0) {
          const remainLeft = item.command.substring(index + 4);
          const remainIndex = remainLeft.indexOf("&");
          const relJob = remainIndex >= 0 ? remainLeft.substring(0, remainIndex) : remainLeft;
          item.relJob = relJob;
        }
        const isShowRoom = item.command.substring(0, 9) == "_showroom";
        item.screenState = item.cmd === "EditMaster" ? "list" : undefined;
        item.objectState = item.cmd === "EditMaster" ? "view" : undefined;
        item.isShowRoom = isShowRoom;
        item.browseExpandable = item.cmd === "EditMaster" ? true : undefined;
        isShowRoom && handleSetShowRoom(item);
        WebMenu[item.idx] = item;
      } else {
        deserializeMenu(item.children);
      }
    });
  };

  React.useEffect(() => {
    window.scrollTo(0, 1);
    fetchSystemParams();
    fetchWebMenu();
    (cmd || comesFromURL) && handleSetCurrentScreen("URLScreen");
  }, []);

  return (
    <div className="App">
      {/* <Spinner colorSpin="#ffc107" /> */}
      {loading ? (
        <Spinner />
      ) : (
        <>
          {isLoading && <Spinner />}
          <nav id="crm-bars" style={{ position: "sticky", top: 0, zIndex: 1030 }}>
            <MainNavigator
              setMainNavigatiorHeight={setMainNavigatiorHeight}
              webMenuData={webMenuData}
              show={showSideBar}
              setShow={setShowSideBar}
            />
            <TopBar mainNavigatorHeight={mainNavigatorHeight} setTopBarHeight={setTopBarHeight} />
          </nav>
          <MainPanel mainNavigatorHeight={mainNavigatorHeight} topBarHeight={topBarHeight} />
        </>
      )}
      <Modals />
    </div>
  );
}

const mapStateToProps = (state) => ({
  url: GETURL(state),
  clientID: GETCLIENTID(state),
  paramsDate: state.systemparams.paramsDate,
  isLoading: state.modals.loading,
  user: state.systemparams.userid,
  ergani: state.session.ergani,
  comesFromURL: state.comesFromURL,
});

const mapDispatchToProps = (dispatch) => ({
  handleSetSystemParams: (value) => dispatch(SetSystemParams(value)),
  handleSetoutputFormats: (value) => dispatch(SetOutputFormats(value)),
  handleSetWebMenu: (value) => dispatch(SetWebMenu(value)),
  handleSetShowRoom: (value) => dispatch(SetShowRoom(value)),
  handleAddScreenFromURL: (screen) => dispatch(AddURLScreen(screen)),
  handleSetCurrentScreen: (value) => dispatch(SetCurrentScreen(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
