export const setGetBrowserData = (state, action) => ({
  ...state,
  screens: {
    ...state.screens,
    [state.currentscreen]: {
      ...state.screens[state.currentscreen],
      browserdata: action.value,
    },
  },
});

export const setNextBrowserData = (state, action) => ({
  ...state,
  screens: {
    ...state.screens,
    [state.currentscreen]: {
      ...state.screens[state.currentscreen],
      browserdata: {
        ...state.screens[state.currentscreen].browserdata,
        rows: [...state.screens[state.currentscreen].browserdata.rows, ...action.value],
        nextstart: action.nextstart,
      },
    },
  },
});
