//------------------------------------------- GENERAL ------------------------------------------------
export const GETCURRENTSCREEN = (state) => state.screen.currentscreen;
export const GETSCREENOBJ = (state) => state.screen.relJob;
export const GETURL = (state) => state.session.url;
export const GETOUTPUTFORMATS = (state) => state.systemparams?.outputformats?.list;
export const GETSN = (state) => state.systemparams.serialnumber;
export const GETCLIENTID = (state) => state.session.clientID;
//------------------------------------------- SCREEN ------------------------------------------------
export const GETCOMMAND = (state) => GETSCREENOBJ(state).command;
export const GETOBJECTSTATE = (state) => GETSCREENOBJ(state).objectState;
export const GETSCREENSTATE = (state) => GETSCREENOBJ(state).screenState;
//----------------------------------------- EDITMASTER -----------------------------------------------
//FILTERS
export const GETDIALOG = (state) => GETSCREENOBJ(state).dialog;
export const GETFILTERS = (state) => GETSCREENOBJ(state).filters;
export const GETACCESSRIGHTS = (state) => GETSCREENOBJ(state).accessrights;
//BROWSER
export const GETBROWSERINFO = (state) => GETSCREENOBJ(state).browserinfo;
export const GETBROWSERDATA = (state) => GETSCREENOBJ(state).browserdata;
//FORM
export const GETPREPARENEW = (state) => GETSCREENOBJ(state).prepare_new;
export const GETFORMDESIGN = (state) => GETSCREENOBJ(state).formDesign;
export const GETDATA = (state) => GETSCREENOBJ(state)?.getdata;
export const GETBUFFDATA = (state) => GETSCREENOBJ(state)?.data_buffer;
export const GETNEWDATA = (state) => GETSCREENOBJ(state)?.newdata;

export const GETRELJOBMENU = (state) => GETSCREENOBJ(state)?.reljobmenu;

export const GETGRIDS = (state) => GETSCREENOBJ(state)?.grids;
export const GETCURRENTLINE = (state, grid) => GETSCREENOBJ(state)?.grids[grid]?.currentLine;
export const GETCURRENTLINEINDEX = (state, grid) => GETSCREENOBJ(state)?.grids[grid]?.currentLineIndex;
export const GETLINESCOUNT = (state, grid) => {
  switch (GETOBJECTSTATE(state)) {
    case "view":
      return GETDATA(state).data[grid].length;
    case "new":
      return GETNEWDATA(state)[grid].length;
    case "edit":
      return GETBUFFDATA(state)[grid].length;
    default:
      break;
  }
};
//------------------------------------------- REPORT ------------------------------------------------
export const GETREPORTINFO = (state) => GETSCREENOBJ(state).reportinfo;
export const GETREPORTDATA = (state) => GETSCREENOBJ(state).reportdata;
