import React, { useEffect, useState } from "react";
import { Button, Modal, ButtonGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { HiX, HiCheck } from "react-icons/hi";
import { SetGridChanges } from "../../../../../../redux/actions/screen";

const StringEditorModal = ({ show, setShow, data, element, predifinedKey, handleSetChanges, currentLine }) => {
  const [selectedRow, setSelectedRow] = useState();
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const setCurrentVal = async (keyV, val) => {
    setSelectedRow(keyV);
    handleSetChanges(table, key, currentLine, keyV);
    setShow(false);
  };

  useEffect(() => {
    setSelectedRow(predifinedKey);
  }, []);

  return (
    <Modal
      animation={false}
      show={show}
      centered
      fullscreen
      dialogClassName="p-3"
      contentClassName="rounded border border-warning shadow overflow-hidden bg-dark"
    >
      <Modal.Header />
      <Modal.Body className="p-0 text-light">
        <div id="s1Editor-list-items">
          {Object.keys(data).map((key) => {
            return (
              <div
                key={key}
                onClick={() => setCurrentVal(key, data[key])}
                className={`s1editor-row border border-start-0 border-end-0 px-1 ${
                  selectedRow == key ? "border-warning shadow" : ""
                }`}
                style={{
                  backgroundColor: selectedRow == key ? "var(--bs-gray-400)" : "transparent",
                  color: selectedRow == key ? "var(--bs-dark)" : "white",
                }}
              >
                <div className="my-3 mx-4">{data[key]}</div>
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer className="p-0 w-100 bg-dark shadow border-0">
        <ButtonGroup className="m-0 w-100">
          <Button
            variant="outline-warning"
            className="w-50 d-flex justify-content-center align-items-center"
            onClick={() => setShow(false)}
          >
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Ακύρωση
            </div>
          </Button>
          <Button
            className="w-50 d-flex justify-content-center align-items-center"
            variant="outline-warning"
            onClick={() => setShow(false)}
          >
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Εφαρμογή
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  handleSetChanges: (table, key, index, value) => dispatch(SetGridChanges(table, key, index, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StringEditorModal);
