import axios from "axios";
import store from "../redux/store";
import { GETURL, GETCLIENTID } from "../redux/selectors";

const selectorFields = async (data) => {
  const state = store.getState();
  const url = GETURL(state);
  const clientID = GETCLIENTID(state);
  const { filters, tablename, keyname, keyvalue, resultfields } = data;

  return await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url,
      data: {
        service: "selectorFields",
        clientID,
        filters,
        tablename,
        keyname,
        keyvalue,
        resultfields,
        appID: process.env.REACT_APP_SOFTONE_APPID,
      },
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
};

export default selectorFields;
