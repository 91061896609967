import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";

const AuthenticateRoute = ({ session, login }) => {
  if (session !== null) {
    return <Navigate to="/" />;
  } else {
    if (login !== null) {
      return <Outlet />;
    } else {
      return <Navigate to="/login" />;
    }
  }
};

const mapStateToProps = (state) => ({
  session: state.session,
  login: state.login,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticateRoute);
