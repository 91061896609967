import React from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import { SetGridChanges } from "../../../../../../redux/actions/screen";
import {
  GETBUFFDATA,
  GETCURRENTLINE,
  GETCURRENTLINEINDEX,
  GETDATA,
  GETFORMDESIGN,
  GETNEWDATA,
  GETOBJECTSTATE,
} from "../../../../../../redux/selectors";

export const S1WebPage = ({
  element,
  objectState,
  data,
  grid,
  currentLine,
  formdesign,
  handleSetChanges,
  currentLineIndex,
}) => {
  const [viewValue, setViewValue] = React.useState("");
  const {
    caption,
    dataType,
    flex,
    index,
    decimals,
    editor,
    maskEnabled,
    name,
    readOnly,
    redirector,
    required,
    selectorVF,
    value,
    visible,
    xtype,
  } = element;

  const table = name.split(".")[0];
  const key = name.split(".")[1];
  const model = formdesign.model[table].fields.filter((el) => el.name == key)[0];

  const isValidUrl = (urlString) => {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  };

  const handleCLick = () => {
    console.log(viewValue);
    if (isValidUrl(viewValue) && objectState == "view") {
      window.open(viewValue);
    }
  };

  React.useEffect(() => {
    if (data?.[table]?.[currentLineIndex(grid)]?.[key] || data?.[table]?.[currentLineIndex(grid)]?.[key] == "") {
      var value = data?.[table][currentLineIndex(grid)][key];
      value != "" && setViewValue(value);
    }
  }, [data?.[table]?.[currentLineIndex(grid)]?.[key]]);

  React.useEffect(() => {
    if (currentLine(grid)?.[key] || currentLine(grid)?.[key] == "") {
      var value = currentLine(grid)?.[key];
      value != "" && setViewValue(value);
    }
  }, [currentLine(grid)?.[key]]);

  return (
    <div className="s1webpage w-100" onClick={handleCLick} style={{ flex: `${flex * 100}%`, overflow: "hidden" }}>
      <InputGroup className="crm-floating-with-icon" hidden={!visible}>
        <FloatingLabel controlId={index} label={caption} style={{ whiteSpace: "nowrap" }} className="w-100">
          <Form.Control
            className="crm-input ps-1"
            autoCapitalize="off"
            placeholder={caption}
            disabled={objectState === "view" || readOnly}
            readOnly={readOnly}
            name={name}
            style={{ border: "0" }}
            value={viewValue}
            onChange={(e) => setViewValue(e.target.value)}
            onBlur={() => !readOnly && handleSetChanges(table, key, currentLineIndex(grid), viewValue)}
          />
        </FloatingLabel>
        {objectState !== "view" && viewValue != "" && !element.readOnly && (
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={() => handleSetChanges(table, key, currentLineIndex(grid), "")}
          >
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
  data: { view: GETDATA(state)?.data, edit: GETBUFFDATA(state), new: GETNEWDATA(state) }[GETOBJECTSTATE(state)],
  formdesign: GETFORMDESIGN(state),
  currentLineIndex: (grid) => GETCURRENTLINEINDEX(state, grid),
  currentLine: (grid) => GETCURRENTLINE(state, grid),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetChanges: (table, key, index, value) => dispatch(SetGridChanges(table, key, index, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1WebPage);
