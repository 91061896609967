import React from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import { BsEnvelope } from "react-icons/bs";
import { GETBUFFDATA, GETDATA, GETFORMDESIGN, GETNEWDATA, GETOBJECTSTATE } from "../../../../../../redux/selectors";

const S1Email = ({ element, objectState, data, formdesign }) => {
  const [viewValue, setViewValue] = React.useState("");
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const model = formdesign.model[table].fields.filter((el) => el.name == key)[0];

  const openEmail = () => {
    window.location.href = `mailto:${viewValue}`;
  };

  //calculate Changes And Initialization
  React.useEffect(() => {
    console.log(element);
    if (data?.[table]?.[0]?.[key]) {
      const value = data?.[table]?.[0]?.[key] ?? "";
      setViewValue(value);
    }
  }, [data?.[table]?.[0]?.[key]]);

  return (
    <div className="s1email w-100" style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}>
      <InputGroup className="crm-floating-with-icon" hidden={!element.visible}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            className="crm-input ps-1"
            type="email"
            autoCapitalize="off"
            placeholder={element.caption}
            disabled={objectState === "view"}
            readOnly={element.readOnly}
            name={element.name}
            style={{ border: "0" }}
            value={viewValue}
            onChange={(e) => setViewValue(e.target.value)}
            //   onBlur={(e) => handleChangeField(e)}
          />
        </FloatingLabel>
        {objectState != "view" && viewValue != "" && !element.readOnly && (
          <div className="d-flex align-items-center justify-content-center" onClick={() => setViewValue("")}>
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
        {objectState == "view" && viewValue != "" && (
          <div className="d-flex align-items-center justify-content-center" onClick={openEmail}>
            <BsEnvelope size="1.6rem" color={"var(--bs-primary)"} />
          </div>
        )}
      </InputGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
  data: { view: GETDATA(state)?.data, edit: GETBUFFDATA(state), new: GETNEWDATA(state) }[GETOBJECTSTATE(state)],
  formdesign: GETFORMDESIGN(state),
});

export default connect(mapStateToProps)(S1Email);
