import React from "react";

import { BsFileEarmarkMedical, BsFolder2, BsReverseLayoutTextWindowReverse } from "react-icons/bs";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import { GiGearHammer } from "react-icons/gi";
import { IoIosArrowBack } from "react-icons/io";
import { connect } from "react-redux";
import { SetCurrentScreen } from "../../../../redux/actions/screen";
import { SetErganiModal } from "../../../../redux/actions/modals";

const S1WebMenu = ({
  setShow,
  currentMenus,
  setCurrentMenus,
  previousStack,
  setPreviousStack,
  handleSetCurrentScreen,
  currrentTitle,
  setCurrentTitle,
  handleSetErganiModal,
}) => {
  const renderMenuItems = (data) => {
    return data.map((item, index) =>
      item?.children && item?.children.length ? (
        <div
          key={index}
          onClick={() => {
            previousStack.push(data);
            setPreviousStack(previousStack);
            setCurrentMenus(item.children);
            setCurrentTitle(item.text);
          }}
          className="s1-web-menu-item"
        >
          <div className="d-flex">
            <BsFolder2 color="var(--bs-gray-500)" />
          </div>
          <span className="text-truncate">{item?.text}</span>
        </div>
      ) : (
        <div
          key={index}
          onClick={() => {
            item.cmd === "Ergani" ? handleSetErganiModal(true) : handleSetCurrentScreen(item.idx);
            setShow(false);
          }}
          className="s1-web-menu-item"
        >
          <div className="d-flex">
            {{
              EditMaster: <BsReverseLayoutTextWindowReverse color="var(--bs-orange)" />,
              Report: <BsFileEarmarkMedical color="var(--bs-green)" />,
              Dialog: <GiGearHammer color="var(--bs-danger)" />,
              Ergani: <MdOutlineQrCodeScanner color="var(--bs-gray-600)" />,
            }[item.cmd] ?? "default value"}
          </div>
          <span className="text-truncate">{item?.text}</span>
        </div>
      )
    );
  };

  return (
    <div className="s1-web-menu">
      {previousStack.length ? (
        <div className="s1-web-menu-item current-folder ps-0 text-light">
          <div
            className="d-flex h-100 justify-content-center align-items-center"
            onClick={() => {
              const prevState = previousStack.pop();
              setPreviousStack(previousStack);
              setCurrentMenus(prevState);
            }}
          >
            <IoIosArrowBack size="1.5rem" className="me-4 ms-3" />
          </div>

          <div className="vr my-2 me-2"></div>
          <div className="ms-2">{currrentTitle}</div>
        </div>
      ) : null}
      {currentMenus ? (
        renderMenuItems(currentMenus)
      ) : (
        <div className="d-flex justify-content-center align-items-center">No data</div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  handleSetCurrentScreen: (value) => dispatch(SetCurrentScreen(value)),
  handleSetErganiModal: (value) => dispatch(SetErganiModal(value)),
});
export default connect(null, mapDispatchToProps)(S1WebMenu);
