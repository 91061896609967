import React, { useEffect, useState } from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import { MdOutlineMailOutline } from "react-icons/md";

const S1Email = ({ element, objectState, data }) => {
  const [viewValue, setViewValue] = useState("");
  useEffect(() => {
    var splitted = element.name.split(".");
    var tableName = splitted[0];
    var fieldName = splitted[1];
    var value = data[tableName][0].hasOwnProperty(fieldName) ? data[tableName][0][fieldName] + " " : "";
    setViewValue(value);
  }, []);

  const openEmail = () => {
    window.location.href = `mailto:${viewValue}`;
  };
  return (
    <div className="s1email w-100" style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}>
      <InputGroup className="crm-floating-with-icon" hidden={!element.visible}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            className="crm-input ps-1"
            type="email"
            autoCapitalize="off"
            placeholder={element.caption}
            disabled={objectState === "view"}
            readOnly={element.readOnly}
            name={element.name}
            style={{ border: "0" }}
            value={viewValue}
            onChange={(e) => setViewValue(e.target.value)}
            //   onBlur={(e) => handleChangeField(e)}
          />
        </FloatingLabel>
        {objectState == "edit" && viewValue != "" && !element.readOnly && (
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={() => {
              setViewValue("");
            }}
          >
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
        {objectState == "view" && viewValue != "" && (
          <div className="d-flex align-items-center justify-content-center" onClick={openEmail}>
            <MdOutlineMailOutline size="1.75rem" color={"var(--bs-primary)"} />
          </div>
        )}
      </InputGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  objectState: state.screen.screens[state.screen.currentscreen].objectState,
  data: state.screen.screens[state.screen.currentscreen].getdata.data,
});

export default connect(mapStateToProps)(S1Email);
