import React from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import {
  GETBUFFDATA,
  GETCURRENTLINE,
  GETCURRENTLINEINDEX,
  GETDATA,
  GETFORMDESIGN,
  GETNEWDATA,
  GETOBJECTSTATE,
} from "../../../../../../redux/selectors";
import { SetGridChanges } from "../../../../../../redux/actions/screen";

const S1IntField = ({
  element,
  grid,
  objectState,
  data,
  formdesign,
  handleSetChange,
  currentLine,
  currentLineIndex,
}) => {
  const [viewValue, setViewValue] = React.useState("");
  const {
    caption,
    dataType,
    flex,
    index,
    decimals,
    editor,
    maskEnabled,
    name,
    readOnly,
    redirector,
    required,
    selectorVF,
    value,
    visible,
    xtype,
  } = element;

  const table = name.split(".")[0];
  const key = name.split(".")[1];
  const model = formdesign.model[table].fields.filter((el) => el.name == key)[0];

  const handleChange = (value) => {
    !readOnly && objectState !== "view" && handleSetChange(table, key, currentLine, value);
  };

  const change = (e) => {
    const regEx = /^[-+]?\d+$/;
    (regEx.test(e.target.value) || e.target.value == "") && setViewValue(e.target.value);
  };

  React.useEffect(() => {
    if (data?.[table]?.[currentLineIndex(grid)]?.[key] || data?.[table]?.[currentLineIndex(grid)]?.[key] == "") {
      var value = data?.[table][currentLineIndex(grid)][key];
      value != "" && setViewValue(value);
    }
  }, [data?.[table]?.[currentLineIndex(grid)]?.[key]]);

  React.useEffect(() => {
    if (currentLine(grid)?.[key] || currentLine(grid)?.[key] == "") {
      var value = currentLine(grid)?.[key];
      value != "" && setViewValue(value);
    }
  }, [currentLine(grid)?.[key]]);

  return (
    <div className="s1infield w-100" style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}>
      <InputGroup className="crm-floating-with-icon" hidden={!element.visible}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            className="crm-input ps-1"
            type="text"
            step="1"
            placeholder={element.caption}
            disabled={objectState === "view"}
            readOnly={element.readOnly}
            name={element.name}
            style={{ border: "0" }}
            value={viewValue}
            onChange={change}
            onBlur={(e) => handleChange(e.target.value)}
          />
        </FloatingLabel>
        {objectState != "view" && viewValue != "" && !element.readOnly && (
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={() => {
              setViewValue("");
              handleChange(null);
            }}
          >
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
  data: { view: GETDATA(state)?.data, edit: GETBUFFDATA(state), new: GETNEWDATA(state) }[GETOBJECTSTATE(state)],
  formdesign: GETFORMDESIGN(state),
  currentLineIndex: (grid) => GETCURRENTLINEINDEX(state, grid),
  currentLine: (grid) => GETCURRENTLINE(state, grid),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetChange: (table, key, index, value) => dispatch(SetGridChanges(table, key, index, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1IntField);
