import login from "./state/login";
import session from "./state/session";
import systemparams from "./state/systemparams";
import gadget from "./state/gadget";
import screen from "./state/screen";
import stackscreens from "./state/stackscreens";
import modals from "./state/modals";
import showRoom from "./state/showRoom";
import comesFromURL from "./state/comesFromURL";
import settings from "./state/settings";

const reducer = (state = {}, action) => ({
  login: login(state.login, action),
  session: session(state.session, action),
  systemparams: systemparams(state.systemparams, action),
  gadget: gadget(state.gadget, action),
  screen: screen(state.screen, action),
  stackscreens: stackscreens(state.stackscreens, action),
  modals: modals(state.modals, action),
  showRoom: showRoom(state.showRoom, action),
  comesFromURL: comesFromURL(state.comesFromURL, action),
  settings: settings(state.settings, action),
});

export default reducer;
