import React from "react";
import { ImArrowUp } from "react-icons/im";
import { connect } from "react-redux";
import { GETBROWSERINFO } from "../../../../../redux/selectors";

const RefreshingContent = ({ browserinfo }) => {
  return (
    <div className="text-light pt-3 w-100 d-flex justify-content-center flex-row align-items-center">
      <ImArrowUp fontSize={"26px"} />
      <div className="ms-4 d-flex justify-content-center flex-column align-items-center">
        <div>Release to refresh</div>
        <small>{`Last Update: ${browserinfo?.upddate ? dateTimeConverter(browserinfo?.upddate) : ""}`}</small>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  browserinfo: GETBROWSERINFO(state),
});

export default connect(mapStateToProps)(RefreshingContent);

const dateTimeConverter = (d) => {
  return (
    [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/") +
    " " +
    [d.getHours().padLeft(), d.getMinutes().padLeft(), d.getSeconds().padLeft()].join(":")
  );
};
