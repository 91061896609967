import React from "react";
import { AiOutlinePrinter } from "react-icons/ai";
import { BsTrash } from "react-icons/bs";
import { IoPencilSharp } from "react-icons/io5";
import { connect } from "react-redux";
import { setLoading } from "../../../../redux/actions/modals";
import { changeToBuff, SetObjectState } from "../../../../redux/actions/screen";
import { GETDATA, GETFORMDESIGN, GETSCREENOBJ, GETRELJOBMENU } from "../../../../redux/selectors";
import { TbSignature } from "react-icons/tb";
import RelJoob from "./rel job/RelJoob";
import ModalSignature from "../../modals/ModalSignature";

const SideMenuOnForm = ({
  screen,
  reljobmenu,
  fetchTemplates,
  handleSetObjectstate,
  handleChangeToBuff,
  data,
  formdesign,
  setShow,
  setDelete,
  setId,
}) => {
  const [signature, setSignature] = React.useState(false);
  const handleDelete = () => {
    const key = formdesign.key;
    const tableName = key.split(".")[0];
    const fieldName = key.split(".")[1];
    const keyValue = data?.data?.[tableName]?.[0]?.[fieldName] ?? "";
    setShow(false);
    setDelete(true);
    setId(keyValue);
  };

  return (
    <div className="text-light crm-relative-job-menu-header p-2">
      <div style={{ fontSize: "1.7em" }}>Ενέργειες</div>
      <hr />
      {screen?.canSignature && (
        <div
          className="crm-relative-job-action rel-job-signature"
          onClick={() => screen.objectState == "view" && setSignature(true)}
          style={{
            color: screen.objectState == "view" ? "var(--bs-primary)" : "var(--bs-gray-500)",
          }}
        >
          <TbSignature className="me-4" />
          <span>Υπογραφή</span>
        </div>
      )}
      <div
        className="crm-relative-job-action rel-job-print"
        onClick={() => screen.objectState == "view" && fetchTemplates()}
        style={{
          color: screen.objectState == "view" ? "var(--bs-primary)" : "var(--bs-gray-500)",
        }}
      >
        <AiOutlinePrinter className="me-4" />
        <span>Εκτύπωση</span>
      </div>
      {screen?.accessrights?.update && (
        <div
          className="crm-relative-job-action rel-job-edit"
          onClick={() => {
            if (screen.objectState == "view") {
              handleChangeToBuff();
              setShow(false);
              handleSetObjectstate("edit");
            }
          }}
          style={{
            color: screen.objectState == "view" ? "var(--bs-primary)" : "var(--bs-gray-500)",
          }}
        >
          <IoPencilSharp className="me-4" />
          <span>Επεξεργασία</span>
        </div>
      )}
      {screen?.accessrights?.remove && (
        <div
          className="crm-relative-job-action rel-job-delete"
          style={{
            color: screen.objectState == "view" ? "var(--bs-primary)" : "var(--bs-gray-500)",
          }}
          onClick={() => screen.objectState == "view" && handleDelete()}
        >
          <BsTrash className="me-4" />
          <span>Διαγραφή</span>
        </div>
      )}
      {reljobmenu && <RelJoob setShow={(x) => setShow(x)} />}
      {signature && <ModalSignature show={signature} setShow={setSignature} hideSideBar={setShow} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  screen: GETSCREENOBJ(state),
  data: GETDATA(state),
  formdesign: GETFORMDESIGN(state),
  reljobmenu: GETRELJOBMENU(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetLoading: (value) => dispatch(setLoading(value)),
  handleSetObjectstate: (value) => dispatch(SetObjectState(value)),
  handleChangeToBuff: () => dispatch(changeToBuff()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenuOnForm);
