import React from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { GETLINESCOUNT, GETNEWDATA, GETOBJECTSTATE } from "../../../../../../../redux/selectors";
import { ImFilm } from "react-icons/im";
import { setError, setLoading } from "../../../../../../../redux/actions/modals";
import {
  AddLineToGrid,
  SetCurrentLine,
  SetGridChanges,
  TriggerGridChange,
} from "../../../../../../../redux/actions/screen";

const AddVideo = ({
  objectState,
  newdata,
  getLinesCount,
  dropbox,
  handleLoading,
  handleSetError,
  handleSetCurrentLine,
  handleSetGridChanges,
  handleTriggerGridChange,
  handleAddLineToGrid,
}) => {
  const UPLOAD_FILE_SIZE_LIMIT = 150 * 1024 * 1024;
  const libraryRef = React.useRef();

  const handlePhotoUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size < UPLOAD_FILE_SIZE_LIMIT) {
        upload2Dropbox(file);
      } else {
        handleSetError({ error: "Max Size 150MB" });
      }
    }
  };

  const upload2Dropbox = async (file) => {
    handleLoading(true);
    console.log(dropbox?.token);
    const config = {
      clientId: process.env.REACT_APP_DROPBOX_CLIENT_ID,
      clientSecret: process.env.REACT_APP_DROPBOX_CLIENT_SECRET,
      refreshToken: dropbox?.token,
    };
    var Dropbox = require("dropbox").Dropbox;
    var dbx = new Dropbox(config);
    const photoUrl = URL.createObjectURL(file);
    const base64Response = await fetch(photoUrl);
    const blob = await base64Response.blob();
    const currentDate = new Date();
    const timestamp = currentDate.getTime();
    await dbx
      .filesUpload({
        path: `/Apps/Web CRM/${timestamp}_${file.name}`,
        contents: blob,
      })
      .then(async (response) => {
        await dbx
          .sharingCreateSharedLinkWithSettings({
            path: response.result.path_display,
          })
          .then((res) => {
            console.log(res.result.url);
            handleSetCurrentLine(-1, {});
            handleSetGridChanges(res.result.url);
            var gridLines = newdata?.XTRDOCDATA ?? [];
            if (gridLines.length == 0) {
              handleTriggerGridChange();
            }
            const line = {
              SOFNAME: res.result.url,
              NAME: `${timestamp}_${file.name}`,
              LINENUM: 9000001 + getLinesCount(),
            };
            handleAddLineToGrid(line);
            handleLoading(false);
          })
          .catch((er) => console.log(er));
      })
      .catch((er) => {
        console.log(er);
        handleLoading(false);
      });
  };

  return (
    <>
      <Button
        className="d-flex align-content-center align-items-center me-2"
        variant="primary"
        disabled={objectState == "view"}
        onClick={() => {
          if (!dropbox) {
            handleSetError({ error: "Δεν έχετε συνδεδεμένο λογαριασμό dropbox." });
          } else {
            libraryRef.current.click();
          }
        }}
      >
        <ImFilm size="1.5rem" />
      </Button>
      <input ref={libraryRef} hidden={true} type="file" onChange={handlePhotoUpload} accept="video/*" />
    </>
  );
};

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
  newdata: GETNEWDATA(state),
  dropbox: state.settings?.dropbox,
  getLinesCount: () => GETLINESCOUNT(state, "XTRDOCDATA"),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetError: (value) => dispatch(setError(value)),
  handleLoading: (value) => dispatch(setLoading(value)),
  handleSetCurrentLine: (index, line) => dispatch(SetCurrentLine("XTRDOCDATA", index, line)),
  handleSetGridChanges: (value) => dispatch(SetGridChanges("XTRDOCDATA", "SOFNAME", -1, value)),
  handleTriggerGridChange: () => dispatch(TriggerGridChange("XTRDOCDATA")),
  handleAddLineToGrid: (value) => dispatch(AddLineToGrid("XTRDOCDATA", value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddVideo);
