import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import Browser from "../dashboard/gadgets/browser/Browser";
import { LoadGadgets, SetGadgetParams } from "../../redux/actions/gadget";
import GadgetLoader from "./GadgetLoader";
import Category1 from "../dashboard/gadgets/category 1/Category1";
import loadGadgetsParams from "../../services/loadGadgetsParams";
import loadGadgets from "../../services/loadGadgets";

const DashBoard = ({ gadget, handleSetGadgetParams, handleLoadGadgets }) => {
  const [gadgetTab, setGadgetTab] = useState();
  const [loading, setLoading] = useState(false);
  const tabRef = useRef();

  const fetchGadget = async () => {
    setLoading(true);
    try {
      const gadgetparams = await loadGadgetsParams();
      if (gadgetparams.success && Object.keys(gadgetparams.data).length > 0) {
        var codes = Object.keys(gadgetparams.data.portlets)
          .map((x) => x.substring(2, x.length))
          .join(",");
        if (codes != "") {
          const LoadGadgets = await loadGadgets({ codes });
          if (LoadGadgets.success) {
            // var tempGadgets = loadGadgets.data.gadgets;
            // for (var key in tempGadgets) {
            //   if (tempGadgets?.[key]) {
            //     if (
            //       tempGadgets[key].name == "Application messages" ||
            //       (tempGadgets[key].category != 1 &&
            //         tempGadgets[key].category != 2 &&
            //         tempGadgets[key].category != 5)
            //     )
            //       delete tempGadgets[key];
            //   }
            // }
            // loadGadgets.data.gadgets = tempGadgets;
            // console.log(LoadGadgets);
            handleSetGadgetParams(gadgetparams.data);
            handleLoadGadgets(LoadGadgets);
            setGadgetTab(LoadGadgets.codes.split(",")[0]);
          } else {
            console.log(LoadGadgets);
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    gadget?.codes && setGadgetTab(gadget.codes.split(",")[0]);
    !gadget && fetchGadget();

    const removeQueryParams = () => {
      const urlWithoutQueryParams = window.location.pathname;
      window.history.pushState({}, "", urlWithoutQueryParams);
    };

    removeQueryParams();
  }, []);

  return (
    <div className="s1gadegpanels h-100">
      {loading ? (
        <GadgetLoader colorSpin="#ffc107" />
      ) : (
        gadget?.codes && (
          <>
            <nav className="overflow-auto bg-secondary" ref={tabRef}>
              <div className="nav nav-tabs m-2" role="tablist" style={{ inlineSize: "max-content", gridGap: "0.3rem" }}>
                {gadget?.codes.split(",").map((x) => {
                  switch (gadget.gadgets[x]?.category) {
                    //Browser
                    case 5:
                    case "5":
                      return (
                        <Button
                          className={`nav-link  ${gadgetTab === x ? "active" : ""}`}
                          key={x}
                          onClick={() => setGadgetTab(x)}
                        >
                          {gadget?.gadgets?.[x]?.data.title ?? gadget.gadgets[x].data.LIST}
                        </Button>
                      );
                    case 1:
                    case "1":
                      return (
                        <Button
                          className={`nav-link  ${gadgetTab === x ? "active" : ""}`}
                          key={x}
                          onClick={() => setGadgetTab(x)}
                        >
                          {gadget.gadgets[x].title ?? gadget.gadgets[x].config.title}
                        </Button>
                      );
                    default:
                      return (
                        <Button
                          className={`nav-link  ${gadgetTab === x ? "active" : ""}`}
                          key={x}
                          onClick={() => setGadgetTab(x)}
                        >
                          category 2
                        </Button>
                      );
                  }
                })}
              </div>
            </nav>
            <div
              className="tab-content"
              style={{
                height: `calc(100% - ${tabRef?.current?.offsetHeight ?? "0"}px)`,
              }}
            >
              {gadget?.codes.split(",").map((x) => {
                switch (gadget.gadgets[x]?.category) {
                  case "5":
                  case 5:
                    return (
                      <div
                        className={`tab-pane fade h-100 ${gadgetTab === x ? "active show" : ""}`}
                        role="tabpanel"
                        key={x}
                      >
                        <Browser property={x} />
                      </div>
                    );
                  case 1:
                  case "1":
                    return (
                      <div
                        className={`tab-pane fade h-100 ${gadgetTab === x ? "active show" : ""}`}
                        role="tabpanel"
                        key={x}
                      >
                        <Category1 property={x} />
                      </div>
                    );
                  default:
                    return (
                      <div className={`tab-pane fade ${gadgetTab === x ? "active show" : ""}`} role="tabpanel" key={x}>
                        {gadget.gadgets[x].title ?? x}
                      </div>
                    );
                }
              })}
            </div>
          </>
        )
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  gadget: state.gadget,
});

const mapDispatchToProps = (dispatch) => ({
  handleSetGadgetParams: (value) => dispatch(SetGadgetParams(value)),
  handleLoadGadgets: (value) => dispatch(LoadGadgets(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);
