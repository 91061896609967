import React, { useState } from "react";
import { AiOutlineArrowLeft, AiOutlineInfoCircle } from "react-icons/ai";
import { Button, ButtonGroup, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { SetCurrentScreen } from "../../../redux/actions/screen";
import { setLoading } from "../../../redux/actions/modals";
import BatchResults from "../object/batch job/BatchResults";
import { setBatchEnd, setBatchStart } from "../../../redux/actions/screen";
import batchStatus from "../../../services/batchStatus";
import execBatch from "../../../services/execBatch";
import { GETSCREENOBJ } from "../../../redux/selectors";

const TopBarOnBatchJob = ({ screen, handleGoToHome, handleSetStart, handleSetEnd, handleLoading }) => {
  const [openResults, setOpenResults] = useState(false);

  const checkStatus = async (reqID) => await batchStatus({ reqID });

  const handleRun = async () => {
    handleLoading(true);
    var text = "";
    Object.keys(screen.filters.buffdata).forEach((x, Findex) => {
      Object.keys(screen.filters.buffdata[x]).forEach((item, Sindex) => {
        if (Findex === 0 && Sindex === 0) {
          text += `${x}.${item}=${screen.filters.buffdata[x][item]}`;
        } else {
          text += `&${x}.${item}=${screen.filters.buffdata[x][item]}`;
        }
      });
    });
    const ExecBatch = await execBatch({ filters: text });

    if (ExecBatch.success) {
      handleLoading(false);
      handleSetStart(new Date());
      setOpenResults(true);
      var check = await checkStatus(ExecBatch.reqID);
      while (check.status === "pending") {
        check = await checkStatus(ExecBatch.reqID);
      }
      handleSetEnd(check);
    } else {
      handleLoading(false);
      console.log(ExecBatch);
    }
  };
  return (
    <Container fluid className="d-flex align-items-center" style={{ flexWrap: "nowrap" }}>
      <Button variant="warning" className="me-2" onClick={handleGoToHome}>
        <AiOutlineArrowLeft />
      </Button>

      <div className="text-light text-center me-2 overflow-hidden text-nowrap ">{screen.text}</div>

      <div style={{ flexWrap: "nowrap", display: "flex" }}>
        {/* {screen?.start && (
          <Button
            variant="warning"
            className="me-2"
            onClick={() => setOpenResults(true)}
          >
            Αποτελέσματα
          </Button>
        )} */}
        <ButtonGroup>
          {screen?.start && (
            <Button
              variant="warning d-flex justify-content-center align-items-center"
              onClick={() => setOpenResults(true)}
            >
              <AiOutlineInfoCircle />
            </Button>
          )}
          <Button variant="warning" onClick={handleRun}>
            Εκτέλεση
          </Button>
        </ButtonGroup>
      </div>
      <BatchResults open={openResults} setOpen={setOpenResults} />
    </Container>
  );
};

const mapStateToProps = (state) => ({ screen: GETSCREENOBJ(state) });

const mapDispatchToProps = (dispatch) => ({
  handleGoToHome: () => dispatch(SetCurrentScreen("dashboard")),
  handleSetStart: (value) => dispatch(setBatchStart(value)),
  handleSetEnd: (value) => dispatch(setBatchEnd(value)),
  handleLoading: (value) => dispatch(setLoading(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBarOnBatchJob);
