import React from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";

const ModalWarningForBlock = ({ blcdate }) => {
  const [isBlocked, setIsBlocked] = React.useState(false);
  const [blcdateDD, setBlcDate] = React.useState();

  React.useEffect(() => {
    const today = new Date(`${formatUSDate(new Date())}`);
    const blckdate = new Date(blcdate);
    var Difference_In_Time = blckdate.getTime() - today.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    setBlcDate(formatDate(blckdate));
    setIsBlocked(Difference_In_Days <= 15 && Difference_In_Days >= 0);
  }, []);
  return (
    <Modal
      show={isBlocked}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
      //   dialogClassName="Login-error-Modal"
    >
      <Modal.Body className="d-flex align-items-center justify-content-center">
        <div>
          Η άδεια χρήσης του λογισμικού σας λήγει την{" "}
          <u>
            <b>{blcdateDD}</b>
          </u>
          .
          <br />
          <br />
          Παρακαλούμε, επικοινωνήστε έγκαιρα με την DAYONE στο: <a href="tel:2165005060">216 5005060</a> για ανανέωση.
          <br />
          <br />
          Σημειώνεται πως με την λήξη, παύει αυτομάτως η πρόσβαση στην Web πλατφόρμα.
        </div>
      </Modal.Body>
      <Modal.Footer className="p-0 d-flex align-items-center justify-content-center">
        <Button variant="primary" onClick={() => setIsBlocked(false)}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  blcdate: state.session.blcdate,
});

export default connect(mapStateToProps)(ModalWarningForBlock);

const formatDate = (date) => {
  return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join("/");
};

const formatUSDate = (date) => {
  return [padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate()), date.getFullYear()].join("/");
};

const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};
