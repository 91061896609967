import React from "react";

const BatchJobLoader = ({ colorSpin, height, width }) => {
  return (
    <div
      className="d-flex position-absolute flex-column justify-content-center align-items-center h-100 w-100"
      style={{ backgroundColor: "#000000cc", zIndex: 1000 }}
    >
      <div className="bouncing-loader ">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default BatchJobLoader;
