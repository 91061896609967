import React from "react";
import { connect } from "react-redux";
import { GETCURRENTSCREEN } from "../../redux/selectors";
import SoftoneObject from "../ui/object/SoftoneObject";
import DashBoard from "../../screens/dashboard/DashBoard";
import Settings from "../user/Settings";

const MainPanel = ({ screen, currentscreen, mainNavigatorHeight, topBarHeight, handleAddScreenFromURL }) => {
  const MainPanelRef = React.useRef();

  React.useEffect(() => {
    document.title = screen.text ?? "D1  Web CRM";
  }, [currentscreen]);

  React.useEffect(() => {}, []);

  return (
    <div
      id="crm-main-panel"
      ref={MainPanelRef}
      style={{
        height: `${window.innerHeight - mainNavigatorHeight - topBarHeight}px`,
        width: "100%",
        overflow: "auto",
        backgroundColor: "var(--bs-gray-dark)",
      }}
      className="d-block position-relative"
    >
      {screen?.cmd ? (
        <SoftoneObject MainPanelRef={MainPanelRef} />
      ) : (
        { user: <Settings />, dashboard: <DashBoard /> }[screen]
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  screen: state.screen.screens[state.screen.currentscreen],
  currentscreen: GETCURRENTSCREEN(state),
});

export default connect(mapStateToProps)(MainPanel);
