import React from "react";
import { BsPlus } from "react-icons/bs";
import { connect } from "react-redux";
import {
  prepareNew,
  SetFormDesign,
  SetObjectState,
  SetPageY,
  SetScreenState,
} from "../../../../../redux/actions/screen";
import { setLoading } from "../../../../../redux/actions/modals";
import preparNew from "../../../../../services/prepareNew";
import getFormDesign from "../../../../../services/getFormDesign";
import { GETFORMDESIGN, GETPREPARENEW, GETSCREENOBJ } from "../../../../../redux/selectors";

function NewButton({
  MainPanelRef,
  formdesign,
  preparenew,
  handleSetObjectState,
  handleSetFormDesign,
  handleSetScreenState,
  handlePrepareNew,
  screen,
  handleSetPageY,
  handleSetLoading,
}) {
  const handleNew = async () => {
    handleSetPageY(MainPanelRef.current.scrollTop);
    handleSetLoading(true);
    const FormDesign = formdesign ?? (await getFormDesign());
    if (FormDesign.success) {
      handleSetFormDesign(FormDesign);
      var locateinfo = "";
      if (!preparenew) {
        Object.entries(FormDesign.model).forEach(([key, value], index) => {
          locateinfo += index == 0 ? `${key}:` : `;${key}:`;
          value.fields.map((item) => {
            locateinfo += `${item.name},`;
          });
        });
        const PrepareNew = await preparNew({ locateinfo });
        PrepareNew.success && handlePrepareNew(PrepareNew.data);
      } else {
        handlePrepareNew(preparenew);
      }
      MainPanelRef.current.scrollTo({ top: 0, left: 0 });
      handleSetObjectState("new");
      handleSetScreenState("form");
      handleSetLoading(false);
    } else {
      handleSetLoading(false);
      console.log(FormDesign);
    }
  };
  return (
    <>
      <nav className="menu" onClick={handleNew} hidden={screen.screenState === "form"}>
        <label className="menu-open-button shadow ">
          <BsPlus className="pe-none" />
        </label>
      </nav>
    </>
  );
}

const mapStateToProps = (state) => ({
  screen: GETSCREENOBJ(state),
  formdesign: GETFORMDESIGN(state),
  preparenew: GETPREPARENEW(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetObjectState: (value) => dispatch(SetObjectState(value)),
  handleSetFormDesign: (value) => dispatch(SetFormDesign(value)),
  handleSetScreenState: (value) => dispatch(SetScreenState(value)),
  handleSetPageY: (value) => dispatch(SetPageY(value)),
  handleSetLoading: (value) => dispatch(setLoading(value)),
  handlePrepareNew: (value) => dispatch(prepareNew(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewButton);
