import React from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import StringEditorModal from "../input modals/StringEditorModal";
import {
  GETBUFFDATA,
  GETCURRENTLINE,
  GETCURRENTLINEINDEX,
  GETDATA,
  GETFORMDESIGN,
  GETNEWDATA,
  GETOBJECTSTATE,
} from "../../../../../../redux/relJobSelectors";
import { SetGridChanges } from "../../../../../../redux/actions/relJob";

const S1Strings = ({ element, data, grid, objectState, currentLine, currentLineIndex, handleSetChanges }) => {
  const [viewValue, setViewValue] = React.useState("");
  const [keyValue, setKeyValue] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);

  var editorData = {};
  element.editor.split("|").forEach((i) => (editorData[i.split("=")[0]] = i.split("=")[1]));

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const handleSelect = (e) => {
    e.target.blur();
    objectState != "view" && !element.readOnly && setOpenModal(true);
  };

  const handleClear = () => {
    handleSetChanges(table, key, currentLineIndex(grid), "");
    setViewValue("");
    setKeyValue("");
  };

  React.useEffect(() => {
    if (data?.[table]?.[currentLineIndex(grid)]?.[key] || data?.[table]?.[currentLineIndex(grid)]?.[key] == "") {
      var value = data?.[table][currentLineIndex(grid)][key];
      value != "" && setKeyValue(value);
      value != "" && setViewValue(editorData[value]);
    }
  }, [data?.[table]?.[currentLineIndex(grid)]?.[key]]);

  React.useEffect(() => {
    if (currentLine(grid)?.[key] || currentLine(grid)?.[key] == "") {
      var value = currentLine(grid)?.[key];
      value != "" && setKeyValue(value);
      value != "" && setViewValue(editorData[value]);
    }
  }, [currentLine(grid)?.[key]]);

  return (
    <div className="s1strings w-100" style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}>
      <InputGroup className="crm-floating-with-icon" hidden={!element.visible}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
          onClick={(e) => handleSelect(e)}
        >
          <Form.Control
            className="crm-input ps-1"
            inputMode="none"
            placeholder={element.caption}
            disabled={objectState === "view" || element.readOnly}
            readOnly={element.readOnly}
            name={element.name}
            style={{ border: "0" }}
            value={viewValue}
            onChange={(e) => setViewValue(e.target.value)}
            // onBlur={(e) => handleChangeField(e)}
          />
        </FloatingLabel>
        {objectState != "view" && viewValue != "" && !element.readOnly && (
          <div className="d-flex align-items-center justify-content-center" onClick={handleClear}>
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
      {openModal && (
        <StringEditorModal
          show={openModal}
          element={element}
          setShow={setOpenModal}
          data={editorData}
          predifinedKey={keyValue}
          currentLine={currentLine}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
  data: { view: GETDATA(state)?.data, edit: GETBUFFDATA(state), new: GETNEWDATA(state) }[GETOBJECTSTATE(state)],
  formdesign: GETFORMDESIGN(state),
  currentLineIndex: (grid) => GETCURRENTLINEINDEX(state, grid),
  currentLine: (grid) => GETCURRENTLINE(state, grid),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetChanges: (table, key, index, value) => dispatch(SetGridChanges(table, key, index, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1Strings);
