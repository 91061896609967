import React from "react";

const NoReportData = () => {
  return (
    <div className=" text-light d-flex flex-column justify-content-center align-items-center h-100">
      <div className="s1-no-report-data" />
      <h2 className="mt-3">No data for this report</h2>
      <h5>Please review filters</h5>
    </div>
  );
};

export default NoReportData;
