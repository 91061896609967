import { setError, setLoading } from "../../../../../redux/actions/modals";
import { setCalculateData } from "../../../../../redux/actions/screen";
import { GETBUFFDATA, GETFORMDESIGN } from "../../../../../redux/selectors";
import store from "../../../../../redux/store";
import { calculate as calculateService } from "../../../../../services/calculate";

export const calculate = async () => {
  const state = store.getState();
  const formdesign = GETFORMDESIGN(state);
  const data = GETBUFFDATA(state);
  const key = formdesign.key;
  const tableName = key.split(".")[0];
  const fieldName = key.split(".")[1];
  const keyValue = data?.[tableName]?.[0]?.[fieldName] ?? "";
  const handleIncoming = (data) => store.dispatch(setCalculateData(data));
  const handleLoading = (value) => store.dispatch(setLoading(value));
  const handleSetError = (value) => store.dispatch(setError(value));

  var locateinfo = "";
  Object.entries(formdesign.model).forEach(([key, value], index) => {
    locateinfo += index == 0 ? `${key}:` : `;${key}:`;
    value.fields.map((item) => {
      locateinfo += `${item.name},`;
    });
  });

  handleLoading(true);
  const response = await calculateService({ data, locateinfo, key: keyValue });
  if (response.success) {
    handleIncoming(response.data);
  } else {
    handleSetError(response);
  }
  handleLoading(false);
};
