import axios from "axios";
import store from "../redux/store";
import { GETURL, GETCLIENTID } from "../redux/selectors";

const getSelectorData = async ({ value, editor }) => {
  const state = store.getState();
  const url = GETURL(state);
  const clientID = GETCLIENTID(state);

  return await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url,
      data: {
        service: "GetSelectorData",
        clientID,
        editor,
        value,
        appId: process.env.REACT_APP_SOFTONE_APPID,
      },
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
};

export default getSelectorData;
