import React from "react";
import ModalAbout from "../../../screens/dashboard/ModalAbout";
import ErganiModal from "../../myErgani/ErganiModal";
import ModalBlocked from "./ModalBlocked";
import ModalClearCache from "./ModalClearCache";
import ModalGeneralError from "./ModalGeneralError";
import ModalLogOut from "./ModalLogOut";
import ModalNoInternet from "./ModalNoInternet";
import ModalWarningForBlock from "./ModalWarningForBlock";

const Modals = () => {
  return (
    <div className="app-modals">
      <ModalNoInternet />
      <ModalClearCache />
      <ModalLogOut />
      <ModalAbout />
      <ModalBlocked />
      <ModalWarningForBlock />
      <ModalGeneralError />
      <ErganiModal />
    </div>
  );
};

export default Modals;
