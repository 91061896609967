import axios from "axios";
import { GETCLIENTID, GETCOMMAND, GETSN, GETURL } from "../../../../../redux/selectors";
import store from "../../../../../redux/store";

const getData = async ({ key, locateinfo }) => {
  const state = store.getState();
  const url = GETURL(state);
  const clientID = GETCLIENTID(state);
  const serialnumber = GETSN(state);
  const command = GETCOMMAND(state);
  const object = `${serialnumber}/object=${command}`;

  try {
    const data = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url,
      data: {
        service: "getData",
        clientID,
        object,
        key,
        locateinfo,
        appID: process.env.REACT_APP_SOFTONE_APPID,
      },
    });
    return data.data;
  } catch (error) {
    console.log(error);
    return { success: false, error: error.message };
  }
};

export default getData;
