import React from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import MultiStringEditorModal from "../../modals/rel job/MultiStringEditorModal";
import { GETFILTERS } from "../../../../../../redux/relJobSelectors";
import { setFilter } from "../../../../../../redux/actions/relJob";
import getValueFromFilterArray from "../../functions/rel job/getValueFromFilterArray";

const S1StringMulti = ({ element, handleSetFilters, filters }) => {
  const [viewValue, setViewValue] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [editorData, setEditorData] = React.useState({});
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const handleChange = (value) => {
    setViewValue(value);
    handleSetFilters(table, key, value);
  };

  const handleSelect = (e) => {
    e.target.blur();
    !element.readOnly && setOpenModal(true);
  };

  React.useEffect(() => {
    var stringDataObj = {};
    element.editor.split("|").forEach((i) => (stringDataObj[i.split("=")[0]] = i.split("=")[1]));
    setEditorData(stringDataObj);

    const value = filters?.text ? getValueFromFilterArray(table, key) : element.value;
    setViewValue(value);
    value != "" && handleSetFilters(table, key, value);
  }, []);

  //For Reset
  React.useEffect(() => {
    if (filters?.reset) {
      setViewValue("");
    }
  }, [filters?.reset]);

  return (
    <div className="s1stringmulti w-100" style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}>
      <InputGroup className="crm-floating-with-icon" hidden={!element.visible}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
          onClick={(e) => handleSelect(e)}
        >
          <Form.Control
            className="crm-input ps-1"
            autoCapitalize="off"
            inputMode="none"
            placeholder={element.caption}
            disabled={element.readOnly}
            readOnly={element.readOnly}
            name={element.name}
            style={{ border: "0" }}
            value={viewValue}
            onChange={(e) => setViewValue(e.target.value)}
            // onBlur={(e) => handleChangeField(e)}
          />
        </FloatingLabel>
        {viewValue != "" && !element.readOnly && (
          <div className="d-flex align-items-center justify-content-center pt-3" onClick={() => handleChange("")}>
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
      {openModal && (
        <MultiStringEditorModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          data={editorData}
          setViewValue={setViewValue}
          selected={viewValue}
          element={element}
          handleChange={handleChange}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  filters: GETFILTERS(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetFilters: (table, key, value) => dispatch(setFilter(table, key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1StringMulti);
