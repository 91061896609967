import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { HiDotsVertical } from "react-icons/hi";
import { FaFilter } from "react-icons/fa";
import { Button, Container, Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import SearchOnList from "./top bar/SearchOnList";
import SideMenuOnList from "./side menus/SideMenuOnList";
import ModalPrintList from "../modals/ModalPrintList";
import SortList from "./modals/SortList";
import getDialog from "../../../services/getDialog";
import { GETBROWSERDATA, GETBROWSERINFO, GETFILTERS, GETSCREENOBJ } from "../../../redux/selectors";
import { SetCurrentScreen } from "../../../redux/actions/screen";
import { setLoading } from "../../../redux/actions/modals";
import { setGetDialog, setShowDialog } from "../../../redux/actions/screen";
import Cart from "./Cart";

const TopBarOnList = ({ screen, handleGoToHome, handleSetShowDialog, handleSetGetDialog, handleLoading }) => {
  const [print, setPrint] = useState(false);
  const [sort, setSort] = useState(false);
  const [searching, setSearching] = useState(false);

  const [showSideMenu, setShowSideMenu] = useState(false);
  const [barHeight, setBarHeight] = useState(0);
  const { innerWidth } = window;

  useEffect(() => {
    const navBar = document.getElementById("crm-bars");
    setBarHeight(navBar.offsetHeight);
  }, []);

  const handleClickOnDialog = async () => {
    if (screen?.dialog) {
      handleSetShowDialog(true);
    } else {
      handleLoading(true);
      const dialog = await getDialog();
      if (dialog.success) {
        handleLoading(false);
        handleSetGetDialog(dialog);
        handleSetShowDialog(true);
      } else {
        handleLoading(false);
        console.log("Dialog error : ", dialog);
      }
    }
  };

  return (
    <Container fluid>
      <Button variant="warning" className="me-2" onClick={handleGoToHome}>
        <AiOutlineArrowLeft />
      </Button>

      {!searching && <div className="text-light text-center me-2 overflow-hidden text-nowrap ">{screen.text}</div>}

      <div className={`d-flex justify-content-end ${searching ? "w-100" : ""}`}>
        <SearchOnList searching={searching} setSearching={setSearching} />
        {screen?.isShowRoom && <Cart />}
        <Button className="ms-1" variant="warning" onClick={handleClickOnDialog}>
          <FaFilter />
        </Button>
        <Button className="ms-1" variant="warning" onClick={() => setShowSideMenu((e) => !e)}>
          <HiDotsVertical />
        </Button>
        <Offcanvas
          show={showSideMenu}
          onHide={() => setShowSideMenu(false)}
          placement="end"
          style={{
            top: barHeight,
            maxWidth: innerWidth * 0.8,
            backgroundColor: "var(--bs-gray-900)",
            borderLeft: "1px solid var(--bs-warning",
            borderTop: "1px solid var(--bs-warning",
          }}
        >
          <Offcanvas.Body>
            <SideMenuOnList setShow={setShowSideMenu} setPrint={setPrint} setSort={setSort} />
          </Offcanvas.Body>
        </Offcanvas>
      </div>
      <ModalPrintList show={print} setShow={setPrint} />
      <SortList sort={sort} setSort={setSort} />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  screen: GETSCREENOBJ(state),
  browserinfo: GETBROWSERINFO(state),
  browserdata: GETBROWSERDATA(state),
  filters: GETFILTERS(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleGoToHome: () => dispatch(SetCurrentScreen("dashboard")),
  handleSetShowDialog: (value) => dispatch(setShowDialog(value)),
  handleSetGetDialog: (value) => dispatch(setGetDialog(value)),
  handleLoading: (value) => dispatch(setLoading(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBarOnList);
