export const setGetDialog = (state, action) => ({
  ...state,
  screens: {
    ...state.screens,
    [state.currentscreen]: {
      ...state.screens[state.currentscreen],
      dialog: action.value,
    },
  },
});

export const showDialog = (state, action) => ({
  ...state,
  screens: {
    ...state.screens,
    [state.currentscreen]: {
      ...state.screens[state.currentscreen],
      dialog: {
        ...state.screens[state.currentscreen].dialog,
        show: action.value,
      },
      filters: {
        ...state.screens[state.currentscreen].filters,
        reset: false,
      },
    },
  },
});
