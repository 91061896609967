const clearEditor = (x, data) => {
  const EditorArguments = Array.from(x.matchAll(/\[([^\][]*)]/g), (x) => x[1]);
  var editor = x;
  EditorArguments.forEach((y) => {
    var splitted = y.split(".");
    var tableName = splitted[0];
    var fieldName = splitted[1];
    var replaceValue = (data?.[tableName]?.[0]?.[fieldName] ?? "").split("|")[0];
    editor = editor.replaceAll(`[${y}]`, replaceValue);
  });
  return editor;
};

export default clearEditor;
