import React from "react";
import { BsChevronDown } from "react-icons/bs";
import DateRangeModal from "../modals/DateRangeModal";

const S1DateRangeDropDown = ({ parentIndex }) => {
  const [openModal, setOpenModal] = React.useState(false);

  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-center pt-3" onClick={() => setOpenModal(true)}>
        <div className="vr m-1" />
        <BsChevronDown size="1.5rem" color={"var(--bs-primary)"} />
      </div>
      <DateRangeModal parentIndex={parentIndex} openModal={openModal} setOpenModal={setOpenModal} />
    </React.Fragment>
  );
};

export default S1DateRangeDropDown;
