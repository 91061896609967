import React from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import {
  GETBUFFDATA,
  GETCURRENTLINE,
  GETCURRENTLINEINDEX,
  GETDATA,
  GETFORMDESIGN,
  GETNEWDATA,
  GETOBJECTSTATE,
} from "../../../../../../redux/relJobSelectors";
import { SetGridChanges } from "../../../../../../redux/actions/relJob";

const S1NumberField = ({ element, objectState, data, grid, handleSetChanges, currentLine }) => {
  const [viewValue, setViewValue] = React.useState("");
  const {
    caption,
    dataType,
    flex,
    index,
    decimals,
    editor,
    maskEnabled,
    name,
    readOnly,
    redirector,
    required,
    selectorVF,
    value,
    visible,
    xtype,
  } = element;

  const table = name.split(".")[0];
  const key = name.split(".")[1];

  const handleChange = (value) => {
    !readOnly && objectState !== "view" && handleSetChanges(table, key, currentLine, value);
  };

  React.useEffect(() => {
    if (data?.[table]?.[currentLine]?.[key] || data?.[table]?.[currentLine]?.[key] == "") {
      console.log(data[table][currentLine][key]);
      setViewValue(data[table][currentLine][key]);
    }
  }, [data?.[table]?.[currentLine]?.[key]]);

  return (
    <div className="s1numberfield w-100" style={{ flex: `${flex * 100}%`, overflow: "hidden" }}>
      <InputGroup className="crm-floating-with-icon" hidden={!visible}>
        <FloatingLabel controlId={index} label={caption} style={{ whiteSpace: "nowrap" }} className="w-100">
          <Form.Control
            className="crm-input ps-1"
            decimal={dataType == "Float" ? "true" : "false"}
            inputMode={readOnly ? "none" : dataType == "Float" ? "decimal" : "numeric"}
            placeholder={caption}
            disabled={objectState === "view"}
            readOnly={readOnly}
            name={name}
            style={{ border: "0" }}
            value={viewValue}
            onChange={(e) => setViewValue(e.target.value)}
            onBlur={(e) => handleChange(e.target.value)}
          />
        </FloatingLabel>
        {objectState != "view" && viewValue != "" && !readOnly && (
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={() => {
              setViewValue("");
              handleChange(null);
            }}
          >
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
  formdesign: GETFORMDESIGN(state),
  getCurrentLineIndex: (grid) => GETCURRENTLINEINDEX(state, grid),
  getCurrentLine: (grid) => GETCURRENTLINE(state, grid),
  data: { view: GETDATA(state)?.data, edit: GETBUFFDATA(state), new: GETNEWDATA(state) }[GETOBJECTSTATE(state)],
});

const mapDispatchToProps = (dispatch) => ({
  handleSetChanges: (table, key, index, value) => dispatch(SetGridChanges(table, key, index, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1NumberField);
