import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import CloudStorage from "./settiings/CloudStorage";
import User from "./User";

const Settings = () => {
  return (
    <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3 mt-2">
      <Tab eventKey="home" title="Βασικές Πληροφορίες">
        <User />
      </Tab>
      <Tab eventKey="profile" title="Cloud Storage">
        <CloudStorage />
      </Tab>
    </Tabs>
  );
};

export default Settings;
