import { useEffect, useState } from "react";
import { GETBROWSERDATA, GETBROWSERINFO } from "../redux/selectors";
import { SetNextBrowserData } from "../redux/actions/screen";
import store from "../redux/store";
import getBrowserData from "../services/getBrowserData";

const useFetch = ({ start }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState({});

  const state = store.getState();
  const reqID = GETBROWSERINFO(state)?.reqID;
  const handleSetNextBrowserData = (value) => store.dispatch(SetNextBrowserData(value));
  const browserinfo = GETBROWSERINFO(state);
  const browserdata = GETBROWSERDATA(state);

  const [hasNextPage, setHasNextPage] = useState(
    browserdata?.rows && browserdata.rows.length < browserinfo?.totalcount
  );

  useEffect(() => {
    setIsError(false);
    setError({});
    // const controller = new AbortController();
    // const { signal } = controller;
    if (start == 0) {
      browserdata?.rows && setHasNextPage(browserdata.rows.length < browserinfo?.totalcount);
      return;
    }

    if (browserdata?.rows && browserdata?.rows.length < browserinfo?.totalcount) {
      setHasNextPage(true);
      setIsLoading(true);
      getBrowserData({
        reqID,
        start: start * parseInt(process.env.REACT_APP_GETBROWSERDATA_LIMIT),
      })
        .then((data) => {
          if (data.success) {
            handleSetNextBrowserData(data.rows);
            setHasNextPage(browserdata.rows.length + data.rows.length < browserinfo.totalcount);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            setIsError(true);
            setError({ message: data.error });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          //   if (signal.aborted) return;
          setIsError(true);
          setError({ message: error.message });
        });
    } else {
      setHasNextPage(false);
    }

    // return () => controller.abort();
  }, [start]);

  return { isLoading, isError, error, hasNextPage };
};

export default useFetch;
