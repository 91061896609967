const modals = (
  state = {
    loading: false,
    cache: false,
    abot: false,
    logout: false,
    error: false,
    ergani: false,
  },
  action
) => {
  switch (action.type) {
    case "modals/setLoading":
      return { ...state, loading: action.value };
    case "modals/setError":
      return { ...state, error: action.value };
    case "modals/setAbout":
      return { ...state, about: action.value };
    case "modals/setLogout":
      return { ...state, logout: action.value };
    case "modals/setCache":
      return { ...state, cache: action.value };
    case "modals/setErgani":
      return { ...state, ergani: action.value };
    default:
      return state;
  }
};

export default modals;
