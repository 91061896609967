import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { setSelectorFilter } from "../../../../../../redux/actions/relJob";
import SelectorEditorModal from "../../modals/rel job/SelectorEditorModal";
import MaskSelectorEditorModal from "../../modals/rel job/MaskSelectorEditorModal";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { TiDeleteOutline } from "react-icons/ti";
import { BsChevronDown } from "react-icons/bs";
import { GETFILTERS } from "../../../../../../redux/relJobSelectors";
import Spinner from "../../../../Spinner";
import getValueFromFilterArray from "../../functions/rel job/getValueFromFilterArray";
import getValueFromSelectorArray from "../../functions/rel job/getValueFromSelectorArray";

const S1Selector = ({ clientID, serialnumber, element, handleSetFilters, filters }) => {
  const [viewValue, setViewValue] = React.useState("");
  const [keyValue, setKeyValue] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [editorData, setEditorData] = React.useState({});
  const [editorString, setEditorString] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const handleEditor = async (e) => {
    e.target.blur();

    setLoading(true);
    try {
      const EditorArguments = Array.from(element.editor.matchAll(/\[([^\][]*)]/g), (x) => x[1]);
      var editor = element.editor;
      EditorArguments.forEach((y) => {
        if (y == "LOGINCOMPANY") {
          editor = editor.replaceAll(`[${y}]`, ":X.SYS.COMPANY");
        } else {
          var splitted = y.split(".");
          var tableName = splitted[0];
          var fieldName = splitted[1];
          var replaceValue = filters?.buffdata?.[tableName]?.hasOwnProperty(fieldName)
            ? filters?.buffdata?.[tableName]?.[fieldName].split("|")[0]
            : "";
          editor = editor.replaceAll(`[${y}]`, replaceValue);
        }
      });
      console.log(editor);
      const GetSelectorData = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
        url: `http://${serialnumber}.oncloud.gr/s1services`,
        data: {
          service: "GetSelectorData",
          clientID,
          appId: process.env.REACT_APP_SOFTONE_APPID,
          editor,
          VALUE: "",
        },
      });
      if (GetSelectorData.data.success) {
        setEditorData(GetSelectorData.data);
        setEditorString(editor);
        setOpenModal(true);
      } else {
        console.log(GetSelectorData.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (viewVal, keyVal) => {
    setViewValue(viewVal);
    setKeyValue(keyValue);
    handleSetFilters(table, key, keyVal, viewVal);
  };

  React.useEffect(() => {
    const keyValue = filters?.text ? getValueFromFilterArray(table, key) : element.value;
    const viewValue = filters?.selectorText ? getValueFromSelectorArray(table, key) : element.value;
    setKeyValue(keyValue);
    setViewValue(viewValue);
    keyValue != "" && handleSetFilters(table, key, viewValue, keyValue);
  }, []);

  //For Reset
  React.useEffect(() => {
    if (filters?.reset) {
      setViewValue("");
    }
  }, [filters?.reset]);

  return (
    <div
      className="s1selector w-100"
      style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}
      key={element.index}
    >
      {loading && <Spinner colorSpin="#ffc107" />}
      <InputGroup className="crm-floating-with-icon" hidden={!element.visible}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            value={viewValue}
            onChange={(e) => {
              setViewValue(e.target.value);
              setKeyValue(e.target.value);
            }}
            editor={element.editor}
            inputMode={element.maskEnabled ? "text" : "none"}
            className="crm-input form-control ps-1"
            placeholder={element.caption}
            name={element.name}
            disabled={element.readOnly}
            readOnly={element.readOnly}
            onClick={(e) => !element.maskEnabled && handleEditor(e)}
            onBlur={(e) => element.maskEnabled && handleChange(e.target.value, e.target.value.replaceAll("&", "_"))}
            style={{ border: "0" }}
          />
        </FloatingLabel>
        {viewValue != "" && !element.readOnly && (
          <div className="d-flex align-items-center justify-content-center pt-3" onClick={() => handleChange("", "")}>
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
        {!element.readOnly && element.maskEnabled && (
          <div className="d-flex align-items-center justify-content-center pt-3" onClick={handleEditor}>
            <div className="vr m-1"></div>
            <BsChevronDown size="1.5rem" color={"var(--bs-primary)"} />
          </div>
        )}
      </InputGroup>
      {openModal &&
        (element.maskEnabled ? (
          <MaskSelectorEditorModal
            element={element}
            openModal={openModal}
            setOpenModal={setOpenModal}
            data={editorData}
            editor={editorString}
            setKeyValue={setKeyValue}
            setViewValue={setViewValue}
            predifinedKey={keyValue}
            handleChange={handleChange}
          />
        ) : (
          <SelectorEditorModal
            element={element}
            openModal={openModal}
            setOpenModal={setOpenModal}
            data={editorData}
            editor={editorString}
            setKeyValue={setKeyValue}
            setViewValue={setViewValue}
            predifinedKey={keyValue}
            handleChange={handleChange}
          />
        ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  serialnumber: state.systemparams.serialnumber,
  clientID: state.session.clientID,
  filters: GETFILTERS(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetFilters: (table, key, keyValue, viewValue) => dispatch(setSelectorFilter(table, key, keyValue, viewValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1Selector);
