export const setFilter = (state, action) => ({
  ...state,
  screens: {
    ...state.screens,
    [state.currentscreen]: {
      ...state.screens[state.currentscreen],
      filters: {
        ...state.screens[state.currentscreen]?.filters,
        reset: false,
        buffdata: {
          ...state.screens[state.currentscreen]?.filters?.buffdata,
          [action.table]: {
            ...state.screens[state.currentscreen]?.filters?.buffdata?.[action.table],
            [action.key]: action.value,
          },
        },
      },
    },
  },
});

export const setSelectorFilter = (state, action) => ({
  ...state,
  screens: {
    ...state.screens,
    [state.currentscreen]: {
      ...state.screens[state.currentscreen],
      filters: {
        ...state.screens[state.currentscreen]?.filters,
        reset: false,
        selector: {
          ...state.screens[state.currentscreen]?.filters?.selector,
          [action.table]: {
            ...state.screens[state.currentscreen]?.filters?.selector?.[action.table],
            [action.key]: action.viewValue,
          },
        },
        buffdata: {
          ...state.screens[state.currentscreen]?.filters?.buffdata,
          [action.table]: {
            ...state.screens[state.currentscreen]?.filters?.buffdata?.[action.table],
            [action.key]: action.keyValue,
          },
        },
      },
    },
  },
});

export const setSearchFilter = (state, action) => ({
  ...state,
  screens: {
    ...state.screens,
    [state.currentscreen]: {
      ...state.screens[state.currentscreen],
      filters: {
        ...state.screens[state.currentscreen]?.filters,
        search: action.value,
      },
    },
  },
});

export const setFilterText = (state, action) => ({
  ...state,
  screens: {
    ...state.screens,
    [state.currentscreen]: {
      ...state.screens[state.currentscreen],
      filters: {
        ...state.screens[state.currentscreen].filters,
        text: action.value,
        selectorText: action.selectorValue,
        search: undefined,
      },
    },
  },
});

export const resetBuffFilters = (state) => ({
  ...state,
  screens: {
    ...state.screens,
    [state.currentscreen]: {
      ...state.screens[state.currentscreen],
      filters: {
        ...state.screens[state.currentscreen].filters,
        buffdata: undefined,
        selector: undefined,
        reset: true,
        daterange: undefined,
      },
    },
  },
});

export const setDateRanges = (state, action) => ({
  ...state,
  screens: {
    ...state.screens,
    [state.currentscreen]: {
      ...state.screens[state.currentscreen],
      filters: {
        ...state.screens[state.currentscreen]?.filters,
        daterange: {
          ...state.screens[state.currentscreen]?.filters?.daterange,
          [action.key]: action.value,
        },
      },
    },
  },
});
