import React, { useEffect, useState } from "react";
import { ButtonGroup, Modal, Button } from "react-bootstrap";

const ModalSelectERP = ({ openModal, setOpenModal, setCurrentVal, selected }) => {
  const [startHeight, setStartHeight] = useState(0);

  useEffect(() => {
    if (openModal) {
      const height = document.getElementById("erp-select");
      height && setStartHeight(height.offsetHeight);
      const container = document.getElementById("carousel");
      [].slice.call(container.children).forEach(function (ele) {
        if (ele.innerText == selected) {
          container.scrollTo(0, ele.offsetTop - ele.offsetHeight);
        }
      });
    }
  });

  const handleSelect = () => {
    const container = document.getElementById("carousel");
    const line = document.getElementById("carousel-select-rowline");
    [].slice.call(container.children).forEach(function (ele) {
      var top = line.getBoundingClientRect().top;
      var bottom = line.getBoundingClientRect().bottom;
      var eleTop = ele.getBoundingClientRect().top;
      var eleBottom = ele.getBoundingClientRect().bottom;
      if (eleBottom < bottom && eleTop > top) {
        setOpenModal(false);
        setCurrentVal(ele.innerText);
      }
    });
  };

  return (
    <Modal
      show={openModal}
      fullscreen
      className="d-flex align-items-end"
      dialogClassName="login-selects"
      contentClassName="login-selects-content rounded overflow-hidden"
    >
      <Modal.Body id="erp-select" className="p-0">
        <div
          className="position-absolute"
          id="carousel-select-rowline"
          style={{
            width: "100%",
            height: "40%",
            top: "30%",
            background: "var(--bs-gray-400)",
          }}
        ></div>
        <div id="carousel" style={{ padding: `${startHeight / 3}px 0px` }} className="snap vertical">
          <div className="snap-item" key="Softone">
            Softone
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="w-100 bg-dark shadow p-0">
        <ButtonGroup className="w-100 m-0">
          <Button
            variant="secondary"
            className="w-50 p-0"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Ακύρωση
          </Button>
          <Button
            className="w-50"
            // variant="warning"
            onClick={() => {
              handleSelect();
            }}
          >
            Εφαρμογή
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSelectERP;

// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import { Button, Modal, ButtonGroup } from "react-bootstrap";
// import { connect } from "react-redux";
// import Spinner from "./../Spinner";

// function MemoryTableEditorModal({
//   serialnumber,
//   clientID,
//   setViewValue,
//   setKeyValue,
//   openModal,
//   setOpenModal,
//   element,
//   data,
//   editor,
//   predifinedKey,
// }) {
//   const [loading, setLoading] = useState(false);
//   const [editorData, setEditorData] = useState(data);
//   const [selectedRow, setSelectedRow] = useState();

//   const setCurrentVal = async (index, val) => {
//     setSelectedRow(index);
//     setLoading(true);
//     try {
//       var filters = editor.split("|")[4];
//       var tablename = editor.split("|")[1];
//       var keyname = editor.split("|")[2];
//       var keyvalue = val[0];
//       var resultfields = element.selectorVF
//         .split("|")
//         .map((i) => i.split(".")[1]);
//       var key = resultfields[0];
//       var value = resultfields[resultfields.length - 1];
//       const selectorFields = await axios.post(
//         `${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`,
//         {
//           url: `http://${serialnumber}.oncloud.gr/s1services`,
//           data: {
//             service: "selectorFields",
//             clientID,
//             APPID: process.env.REACT_APP_SOFTONE_APPID,
//             filters,
//             tablename,
//             keyname,
//             keyvalue,
//             resultfields: resultfields.join(","),
//           },
//         }
//       );
//       if (selectorFields.data.success) {
//         setViewValue(selectorFields.data.rows[0][value]);
//         setKeyValue(selectorFields.data.rows[0][key]);
//         setLoading(false);
//         setOpenModal(false);
//       } else {
//         setLoading(false);
//         console.log(selectorFields.data);
//       }
//     } catch (error) {
//       setLoading(false);
//       console.log(error);
//     }
//   };

//   const handleSearch = (e) => {
//     var filter, list, span, i, txtValue;
//     var is = [];
//     filter = e.target.value.toUpperCase();
//     list = document.getElementById("s1Editor-list-items");
//     span = list.querySelectorAll("span");
//     for (i = 0; i < span.length; i++) {
//       span[i].parentElement.parentElement.style.display = "none";
//       txtValue = span[i].textContent || span[i].innerText;
//       if (txtValue.toUpperCase().indexOf(filter) > -1) {
//         is.push(span[i].parentElement.parentElement);
//       } else {
//         span[i].parentElement.parentElement.style.display = "none";
//       }
//       is.forEach((t) => (t.style.display = ""));
//     }
//   };
//   useEffect(() => {
//     setEditorData(data);
//     if (predifinedKey != "") {
//       var keyname = editor.split("|")[2];
//       var keyIndex, i;
//       for (i = 0; i < data.model.length; i++) {
//         if (data.model[i].name == keyname) {
//           keyIndex = i;
//           break;
//         }
//       }
//       for (i = 0; i < data.data.length; i++) {
//         if (data.data[i][keyIndex] == predifinedKey) {
//           setSelectedRow(i);
//           break;
//         }
//       }
//     }
//     console.log(data);
//   }, []);

//   const handleClearEditor = async (e) => {
//     if (e.target.value == "") {
//       var list = document.getElementById("s1Editor-list-items");
//       list.childNodes.forEach((t) => (t.style.display = ""));
//     }
//   };
//   return (
//     <Modal
//       animation={false}
//       show={openModal}
//       centered
//       fullscreen
//       dialogClassName="p-2"
//       contentClassName="rounded overflow-hidden"
//       // onHide={() => {
//       //   setEditorDone(true);
//       //   setOpenModal(false);
//       // }}
//     >
//       {loading && <Spinner colorSpin="#ffc107" />}
//       <Modal.Header>
//         <div className="form-floating w-100 ">
//           <input
//             id={`Editor-${element.name}`}
//             type="search"
//             className="form-control"
//             onKeyUp={(e) => handleSearch(e)}
//             // onChange={handleClearEditor}
//             placeholder="Αναζήτηση"
//             ref={(r) => ((r || {}).onsearch = (e) => handleClearEditor(e))}
//           />
//           <label htmlFor={`Editor-${element.name}`}>Αναζήτηση</label>
//         </div>
//       </Modal.Header>
//       <Modal.Body className="p-0">
//         <div id="s1Editor-list-items">
//           {openModal &&
//             editorData.data.map((item, index) => {
//               return (
//                 <div
//                   key={index}
//                   onClick={() => setCurrentVal(index, item)}
//                   className={`s1editor-row border px-1 ${
//                     selectedRow == index ? "rounded border-warning shadow" : ""
//                   }`}
//                   style={{
//                     backgroundColor:
//                       selectedRow == index
//                         ? "var(--bs-gray-400)"
//                         : "transparent",
//                   }}
//                 >
//                   <div className="my-3 mx-4">
//                     {editorData.model.slice(1).map((yy, i) => {
//                       return (
//                         <span
//                           key={i}
//                           className="me-2"
//                           style={{ display: "inline-block" }}
//                         >
//                           {item[i + 1]}
//                         </span>
//                       );
//                     })}
//                   </div>
//                 </div>
//               );
//             })}
//         </div>
//       </Modal.Body>
//       <Modal.Footer className="w-100 bg-dark shadow">
//         <ButtonGroup className="w-100">
//           <Button
//             variant="outline-warning"
//             className="w-50"
//             onClick={() => {
//               setOpenModal(false);
//             }}
//           >
//             Ακύρωση
//           </Button>
//           <Button
//             className="w-50"
//             variant="warning"
//             onClick={() => {
//               setOpenModal(false);
//             }}
//           >
//             Εφαρμογή
//           </Button>
//         </ButtonGroup>
//       </Modal.Footer>
//     </Modal>
//   );
// }
