import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Button, Card, FloatingLabel, Form, InputGroup } from "react-bootstrap";
import Spinner from "../components/ui/Spinner";
import { TiDeleteOutline } from "react-icons/ti";
import login from "../services/login";
import { connect } from "react-redux";
import { SetLogin } from "../redux/actions/login";
import ModalSelectERP from "./login/ModalSelectERP";
import ErrorModal from "./login/ErrorModal";

const Login = ({ handleSetLogin }) => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const [erp, setErp] = React.useState("Softone");
  const [connection, setConnection] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const [openERP, setOpenERP] = React.useState(false);

  const [hasError, setHasError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [wrongConnection, setWrongConnection] = React.useState(false);
  const [wrongPassUser, setWrongPassUser] = React.useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setHasError(false);
    setLoading(true);
    setWrongConnection(false);
    setWrongPassUser(false);

    try {
      const Login = await login({
        url: `https://${connection}.oncloud.gr/s1services`,
        username,
        password,
      });
      if (Login.success) {
        handleSetLogin({
          username,
          password,
          sn: Login.sn,
          objs: Login.objs,
          clientID: Login.clientID,
        });
        localStorage.setItem("lastlogin", JSON.stringify({ connection, username, password }));
        setLoading(false);
        navigate("/authenticate");
      } else {
        setHasError(true);
        setErrorMessage(Login.error);
        console.log(Login);
        if (Login.error === "Request failed with status code 404") {
          setWrongConnection(true);
        } else if (Login.error == "Login fails due to invalid login credentials.") {
          setWrongPassUser(true);
        }
        setLoading(false);
      }
    } catch (error) {
      setHasError(true);
      setErrorMessage(error);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const previous = JSON.parse(localStorage.getItem("lastlogin"));
    setConnection(previous?.connection ?? "");
    setUsername(previous?.username ?? "");
    setPassword(previous?.password ?? "");
  }, []);

  const component = [
    {
      controlId: "connection",
      label: "Σύνδεση",
      type: "text",
      value: connection,
      inputMode: "",
      condition: wrongConnection,
      function: setConnection,
    },
    {
      controlId: "username",
      label: "Username",
      type: "text",
      value: username,
      inputMode: "",
      condition: wrongPassUser,
      function: setUsername,
    },
    {
      controlId: "password",
      label: "Password",
      type: "password",
      value: password,
      inputMode: "",
      condition: wrongPassUser,
      function: setPassword,
    },
  ];

  return (
    <div className="logInBack d-flex align-items-center justify-content-center h-100 bg-dark text-dark">
      <motion.div
        className="w-100 p-2"
        style={{ maxWidth: "460px" }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Card className="bg-dark shadow p-2">
          {loading && <Spinner />}

          {/* <h2 className="text-center text-light mb-4">Login</h2> */}
          <Form onSubmit={handleLogin}>
            <InputGroup className="crm-floating-with-icon my-1 text-light">
              <FloatingLabel controlId="erp" label="ERP" style={{ whiteSpace: "nowrap" }} className="w-100">
                <Form.Control
                  className="crm-input ps-1 mt-1 bg-dark text-light"
                  autoCapitalize="off"
                  placeholder="ERP"
                  type="text"
                  name="ERP"
                  required
                  inputMode="none"
                  value={erp}
                  onChange={(e) => setErp(e.target.value)}
                  style={{
                    border: "0",
                  }}
                  onClick={(e) => {
                    e.target.blur();
                    setOpenERP(true);
                  }}
                />
              </FloatingLabel>
            </InputGroup>
            {component.map((item, index) => {
              return (
                <InputGroup
                  className="crm-floating-with-icon my-1 text-light"
                  style={{
                    borderBottom: item.condition ? "2px solid var(--bs-danger)" : "1px solid #d7d7d7",
                  }}
                  key={index}
                >
                  <FloatingLabel
                    controlId={item.controlId}
                    label={item.label}
                    style={{ whiteSpace: "nowrap" }}
                    className="w-100"
                  >
                    <Form.Control
                      className="crm-input ps-1 mt-1 bg-dark text-light"
                      autoCapitalize="off"
                      placeholder={item.label}
                      type={item.type}
                      name={item.controlId}
                      inputMode={item.inputMode}
                      required
                      value={item.value}
                      style={{
                        border: "0",
                      }}
                      onChange={(e) => {
                        item.inputMode == "none" && e.target.blur();
                        item.function(e.target.value);
                      }}
                    />
                  </FloatingLabel>
                  {item.value != "" && (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      onClick={() => {
                        item.function("");
                      }}
                    >
                      <TiDeleteOutline size="1.75rem" />
                    </div>
                  )}
                </InputGroup>
              );
            })}
            <Button disabled={loading} className="w-100 hoverIconAnimation-s mt-1" type="submit" variant="warning">
              Next
            </Button>
          </Form>
          <ErrorModal show={hasError} handleClose={setHasError} text={errorMessage} />
        </Card>
      </motion.div>
      <ModalSelectERP openModal={openERP} setOpenModal={setOpenERP} setCurrentVal={setErp} selected={erp} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  session: state.session,
});

const mapDispatchToProps = (dispatch) => ({
  handleSetLogin: (value) => dispatch(SetLogin(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
