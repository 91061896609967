import React from "react";
import { AiOutlineHome, AiOutlineInfoCircle, AiOutlinePoweroff, AiOutlineUser } from "react-icons/ai";
import { BiCookie } from "react-icons/bi";
import { connect } from "react-redux";
import { SetCurrentScreen } from "../../../redux/actions/screen";
import { setAboutModal, setCacheModal, setLogoutModal } from "../../../redux/actions/modals";

const TopBarOnMenu = ({ handleSetCurrentScreen, setDisconnect, setAbout, setClear, setShow }) => {
  return (
    <div
      className="crm-menu-bar gx-4"
      style={{ backgroundColor: "var(--bs-gray-900)", borderBottom: "1px solid white" }}
    >
      <div
        onClick={() => {
          setShow(false);
          handleSetCurrentScreen("dashboard");
        }}
      >
        <AiOutlineHome color="var(--bs-primary)" className="pe-none" />
      </div>
      <div
        onClick={() => {
          setShow(false);
          handleSetCurrentScreen("user");
        }}
      >
        <AiOutlineUser color="var(--bs-primary)" className="pe-none" />
      </div>
      <div
        onClick={() => {
          setShow(false);
          setClear(true);
        }}
      >
        <BiCookie color="var(--bs-primary)" className="pe-none" />
      </div>
      <div
        onClick={() => {
          setAbout(true);
          setShow(false);
        }}
      >
        <AiOutlineInfoCircle color="var(--bs-primary)" className="pe-none" />
      </div>
      <div
        onClick={() => {
          setShow(false);
          setDisconnect(true);
        }}
      >
        <AiOutlinePoweroff className="pe-none" color="var(--bs-danger)" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  handleSetCurrentScreen: (value) => dispatch(SetCurrentScreen(value)),
  setDisconnect: (value) => dispatch(setLogoutModal(value)),
  setAbout: (value) => dispatch(setAboutModal(value)),
  setClear: (value) => dispatch(setCacheModal(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBarOnMenu);
