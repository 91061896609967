Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

export const listEditor = (column, value) => {
  if (value != "") {
    switch (column.decs) {
      case 0: //STRINGLIST
        var index = value.indexOf("|");
        if (index > -1) {
          return value.substring(index + 1, value.length);
        } else {
          return value;
        }
      case 10: //DATETIME
        var d = new Date(value);
        return (
          [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/") +
          " " +
          [d.getHours().padLeft(), d.getMinutes().padLeft()].join(":")
        );
      case 11: //DATE
        var d = new Date(value);
        return [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/");
      case 2: //Float
        return parseFloat(value)
          .toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
          .replace("€", "");
      case 3: //Float
        return parseFloat(value)
          .toLocaleString("de-DE", {
            style: "currency",
            currency: "EUR",
            minimumFractionDigits: 3,
            maximumFractionDigits: 3,
          })
          .replace("€", "");
      default:
        return value;
    }
  } else {
    return value;
  }
};
const dateConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-");
  } else {
    return value;
  }
};

const dateTimeConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return (
      [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-") +
      "T" +
      [d.getHours().padLeft(), d.getMinutes().padLeft()].join(":")
    );
  } else {
    return value;
  }
};
