import React from "react";
import { connect } from "react-redux";
import { GETOBJECTSTATE } from "../../../../../../redux/selectors";

const S1CheckBox = ({ element, defaultValue, objectState }) => {
  return (
    <div
      className="s1checkbox w-100 form-check d-flex flex-column flex-wrap p-0 m-0"
      style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}
      hidden={!element.visible}
    >
      <label
        className="form-check-label py-1"
        htmlFor={element.index}
        style={{
          whiteSpace: "nowrap",
          opacity: "0.65",
          fontSize: "0.85rem",
        }}
      >
        {element.caption}
      </label>
      <input
        id={element.index}
        className="form-check-input m-0 ps-1"
        type="checkbox"
        name={element.name}
        disabled={element.readOnly || objectState === "view"}
        defaultChecked={defaultValue}
        // onBlur={(e) => handleChangeField(e)}
        // onChange={(e) => handleChangeField(e)}
        style={{ minHeight: "2rem", minWidth: "2rem" }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
});

export default connect(mapStateToProps)(S1CheckBox);
