import axios from "axios";
import store from "../../redux/store";
import { GETCLIENTID, GETCOMMAND, GETSN, GETURL } from "../../redux/relJobSelectors";

const delData = async ({ key }) => {
  const state = store.getState();
  const url = GETURL(state);
  const clientID = GETCLIENTID(state);
  const serialnumber = GETSN(state);
  const command = GETCOMMAND(state);
  const object = `${serialnumber}/object=${command}`;

  return await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url,
      data: {
        service: "delData",
        clientID,
        object,
        key,
        appID: process.env.REACT_APP_SOFTONE_APPID,
      },
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
};

export default delData;
