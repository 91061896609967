import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setLoading } from "../../../../redux/actions/modals";
import { InitBatchJob, SetBatchDialog } from "../../../../redux/actions/screen";
import { GETDIALOG } from "../../../../redux/selectors";
import S1CheckBox from "./S1 inputs/S1CheckBox";
import S1Selector from "./S1 inputs/S1Selector";
import S1TextField from "./S1 inputs/S1TextField";
import S1MultiSelect from "./S1 inputs/S1MultiSelect";
import S1StringMulti from "./S1 inputs/S1StringMulti";
import S1DateRange from "./S1 inputs/S1DateRange";
import S1DateRangeDropDown from "./S1 inputs/S1DateRangeDropDown";
import S1MemoryTable from "./S1 inputs/S1MemoryTable";
import getDialog from "../../../../services/getDialog";

const BatchJob = ({ handleSetBatchDialog, handleLoading, dialog, handleInit }) => {
  const Recursive = (data, parentIndex) => {
    return data.map((element) => {
      switch (element.xtype) {
        case "s1cont":
          return (
            <React.Fragment key={element.index}>
              <div
                className={`${
                  element.items.every((x) => x.xtype == "s1daterange") ? "s1cont-daterange" : "s1cont"
                } d-flex`}
                style={{
                  gap: "0.5rem",
                  flex: "100%",
                  borderBottom: element.items.every((x) => x.xtype == "s1daterange") ? "1px solid #d7d7d7" : "none",
                }}
              >
                {Recursive(element.items, element.index)}
                {element.items.every((x) => x.xtype == "s1daterange") && (
                  <S1DateRangeDropDown parentIndex={element.index} />
                )}
              </div>
            </React.Fragment>
          );
        case "container":
          return (
            element.items.length > 0 && (
              <div
                className={`s1-container ${
                  element.items.every((x) => x.xtype == "s1daterange") ? "s1cont-daterange" : "s1cont"
                } d-flex`}
                style={{
                  gap: "0.5rem",
                  flex: "100%",
                  borderBottom: element.items.every((x) => x.xtype == "s1daterange") ? "1px solid #d7d7d7" : "none",
                }}
              >
                {Recursive(element.items, element.index)}
                {element.items.every((x) => x.xtype == "s1daterange") && (
                  <S1DateRangeDropDown parentIndex={element.index} />
                )}
              </div>
            )
          );
        case "s1daterange":
          return <S1DateRange element={element} key={element.index} parentIndex={parentIndex} />;
        case "s1multiselect":
          return <S1MultiSelect element={element} key={element.index} />;
        case "s1stringsmulti":
          return <S1StringMulti element={element} key={element.index} />;
        case "s1spacer":
          return <div className="s1spacer" key={element.index} />;
        case "s1selector":
          return <S1Selector element={element} key={element.index} />;
        case "s1textfield":
          return <S1TextField element={element} key={element.index} />;
        case "s1checkbox":
          return <S1CheckBox element={element} key={element.index} />;
        case "s1memorytable":
          return <S1MemoryTable element={element} key={element.index} />;
        default:
          return (
            <div className="bg-danger" key={element.index}>
              {element.xtype}
            </div>
          );
      }
    });
  };

  useEffect(async () => {
    handleLoading(true);
    handleInit();
    const Dialog = dialog ?? (await getDialog());
    if (Dialog.success) {
      handleSetBatchDialog(Dialog);
    } else {
      console.log("error on dialog", Dialog);
    }
    handleLoading(false);
  }, []);

  return <div className="p-2">{dialog && Recursive(dialog.form)}</div>;
};

const mapStateToProps = (state) => ({ dialog: GETDIALOG(state) });

const mapDispatchToProps = (dispatch) => ({
  handleSetBatchDialog: (value) => dispatch(SetBatchDialog(value)),
  handleLoading: (value) => dispatch(setLoading(value)),
  handleInit: () => dispatch(InitBatchJob()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BatchJob);
