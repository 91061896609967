import React, { useState } from "react";
import { AiOutlineArrowLeft, AiOutlinePrinter } from "react-icons/ai";
import { FaFilter } from "react-icons/fa";
import { Button, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { SetCurrentScreen } from "../../../redux/actions/screen";
import { setLoading } from "../../../redux/actions/modals";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import ModalPrintList from "../modals/ModalPrintList";
import { GETDIALOG, GETREPORTDATA, GETREPORTINFO, GETSCREENOBJ } from "../../../redux/selectors";
import { setGetDialog, setReportData, setShowDialog } from "../../../redux/actions/screen";
import getDialog from "../../../services/getDialog";
import getReportData from "../../../services/getReportData";

const TopBarOnReport = ({
  screen,
  reportinfo,
  reportdata,
  dialog,
  handleGoToHome,
  handleSetGetReportData,
  handleSetLoading,
  handleSetShowDialog,
  handleSetGetDialog,
}) => {
  const [print, setPrint] = useState(false);

  const fetchNextPage = async () => {
    if (reportdata.pagenum < reportinfo.npages) {
      handleSetLoading(true);
      const reportData = await getReportData({
        reqID: reportinfo.reqID,
        pagenum: reportdata.pagenum + 1,
      });
      if (reportData.success) {
        reportData.pagenum = reportdata.pagenum + 1;
        handleSetGetReportData(reportData);
      } else {
        console.log(reportData);
      }
      handleSetLoading(false);
    }
  };

  const fetchPreviousPage = async () => {
    if (reportdata.pagenum > 1) {
      handleSetLoading(true);
      const reportData = await getReportData({
        reqID: reportinfo.reqID,
        pagenum: reportdata.pagenum - 1,
      });
      if (reportData.success) {
        reportData.pagenum = reportdata.pagenum - 1;
        handleSetGetReportData(reportData);
      } else {
        console.log(reportData);
      }
      handleSetLoading(false);
    }
  };

  const handleClickOnDialog = async () => {
    if (!dialog) {
      handleSetLoading(true);
      const Dialog = await getDialog();
      if (Dialog.success) {
        Dialog.show = true;
        handleSetGetDialog(Dialog);
      } else {
        console.log("Dialog error:", Dialog);
      }
      handleSetLoading(false);
    } else {
      handleSetShowDialog(true);
    }
  };

  return (
    <Container fluid>
      <Button variant="warning" className="me-2" onClick={handleGoToHome}>
        <AiOutlineArrowLeft />
      </Button>
      <div className="text-light me-2 overflow-hidden text-nowrap">{screen.text}</div>
      <div className="d-flex">
        {reportdata?.success && reportinfo?.npages > 1 && reportinfo?.success && (
          <div className="border border-warning rounded d-flex flex-row">
            <div className="btn" onClick={fetchPreviousPage}>
              <FiArrowLeft
                style={{
                  color: reportdata.pagenum == 1 ? "var(--bs-gray-500)" : "var(--bs-warning)",
                }}
              />
            </div>
            <div className="text-light text-center d-flex flex-row justify-content-center align-items-center">
              {reportdata.pagenum}/{reportinfo.npages}
            </div>
            <div className="btn" onClick={fetchNextPage}>
              <FiArrowRight
                style={{
                  color:
                    screen.reportdata.pagenum == screen.reportinfo.npages ? "var(--bs-gray-500)" : "var(--bs-warning)",
                }}
              />
            </div>
          </div>
        )}
        <Button className="ms-1" variant="warning" onClick={handleClickOnDialog}>
          <FaFilter />
        </Button>
        {screen?.noData != true && (
          <Button className="ms-1" variant="warning" onClick={() => setPrint(true)}>
            <AiOutlinePrinter />
          </Button>
        )}
      </div>
      <ModalPrintList show={print} setShow={setPrint} />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  screen: GETSCREENOBJ(state),
  reportinfo: GETREPORTINFO(state),
  reportdata: GETREPORTDATA(state),
  dialog: GETDIALOG(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleGoToHome: () => dispatch(SetCurrentScreen("dashboard")),
  handleSetGetReportData: (value) => dispatch(setReportData(value)),
  handleSetLoading: (value) => dispatch(setLoading(value)),
  handleSetShowDialog: (value) => dispatch(setShowDialog(value)),
  handleSetGetDialog: (value) => dispatch(setGetDialog(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBarOnReport);
