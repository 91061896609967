import React, { useEffect, useState } from "react";
import { Button, Card, FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { BiLogIn } from "react-icons/bi";
import { BsChevronDown } from "react-icons/bs";
import { connect } from "react-redux";
import { SetSession } from "../redux/actions/session";
import { SetSystemParams } from "../redux/actions/systemparams";
import authenticate from "../services/authenticate";
import getSystemParams from "../services/getSystemParams";
import Spinner from "../components/ui/Spinner";
import { motion } from "framer-motion";
import ErrorModal from "./login/ErrorModal";
import ModalSelectAuth from "./login/ModalSelectAuth";

const Authenticate = ({ handleSetSystemParams, handleSetSession, login }) => {
  const [loading, setLoading] = useState(false);
  const [uniqueAuth, setUniqueAuth] = useState(false);

  const [openCompanies, setOpenCompanies] = useState(false);
  const [openBranches, setOpenBranches] = useState(false);
  const [openUsers, setOpenUsers] = useState(false);

  const [company, setCompany] = useState({ code: "", name: "" });
  const [branch, setBranch] = useState({ code: "", name: "" });
  const [user, setUser] = useState({ code: "", name: "" });

  const [companies, setCompanies] = useState([]);
  const [branches, setBranches] = useState([]);
  const [users, setUsers] = useState([]);

  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onCompanyChange = (company) => {
    let branchesArray = [];
    for (let i = 0; i < login.objs.length; i++) {
      if (login.objs[i].COMPANY == company.code) {
        branchesArray.push(login.objs[i]);
      }
    }
    branchesArray = [...new Map(branchesArray.map((item) => [item.BRANCH, item])).values()];
    setBranches(branchesArray);
    setBranch({
      code: branchesArray[0].BRANCH,
      name: branchesArray[0].BRANCHNAME,
    });

    let userArray = [];
    for (let i = 0; i < login.objs.length; i++) {
      if (login.objs[i].COMPANY == company.code && login.objs[i].BRANCH == branch.code) {
        userArray.push(login.objs[i]);
      }
    }
    userArray = [...new Map(userArray.map((item) => [item.REFID, item])).values()];
    setUsers(userArray);
    setUser({
      code: userArray[0].REFID,
      name: userArray[0].REFIDNAME,
    });
  };

  const onBranchChange = (branch) => {
    let userArray = [];
    for (let i = 0; i < login.objs.length; i++) {
      if ((login.objs[i].COMPANY = company.code) && (login.objs[i].BRANCH = branch.code)) {
        userArray.push(login.objs[i]);
      }
    }
    setUsers([...new Map(userArray.map((item) => [item.REFID, item])).values()]);
  };

  const handleAuthenticate = async (e) => {
    e && e.preventDefault();
    setHasError(false);
    setErrorMessage("");
    setLoading(true);
    try {
      const Company = company.code === "" ? login.objs[0].COMPANY : company.code;
      const CompanyName = company.name === "" ? login.objs[0].COMPANYNAME : company.name;
      const Branch = branch.code === "" ? login.objs[0].BRANCH : branch.code;
      const BranchName = branch.name === "" ? login.objs[0].BRANCHNAME : branch.name;
      const RefID = user.code === "" ? login.objs[0].REFID : user.code;
      const RefIDName = user.name === "" ? login.objs[0].REFIDNAME : user.name;
      const Authenticate = await authenticate({
        log: {
          username: RefIDName,
          company: CompanyName,
          branch: BranchName,
        },
        username: login.username,
        company: Company,
        branch: Branch,
        refID: RefID,
        module: 0,
        sn: login.sn,
        clientID: login.clientID,
      });
      if (Authenticate.success) {
        const systhemParams = await getSystemParams({
          url: `http://${login.sn}.oncloud.gr/s1services`,
          clientID: Authenticate.clientID,
        });
        if (systhemParams.success) {
          systhemParams.paramsDate = new Date();
          handleSetSystemParams(systhemParams);
          setLoading(false);
          handleSetSession(Authenticate);
        } else {
          setHasError(true);
          setErrorMessage(systhemParams.error);
          setLoading(false);
        }
      } else {
        setHasError(true);
        setErrorMessage(Authenticate.error);
        setLoading(false);
      }
    } catch (error) {
      setHasError(true);
      setErrorMessage(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    var coomm = login?.objs ? [...new Map(login.objs.map((item) => [item.COMPANY, item])).values()] : [];
    setCompanies(coomm);
    setCompany({
      code: coomm[0].COMPANY,
      name: coomm[0].COMPANYNAME,
    });
    let objs = login.objs;
    let branchesArray = [];
    for (let i = 0; i < objs.length; i++) {
      if (objs[i].COMPANY == coomm[0].COMPANY) {
        branchesArray.push(objs[i]);
      }
    }
    branchesArray = [...new Map(branchesArray.map((item) => [item.BRANCH, item])).values()];
    setBranches(branchesArray);
    setBranch({
      code: branchesArray[0].BRANCH,
      name: branchesArray[0].BRANCHNAME,
    });

    let userArray = [];
    for (let i = 0; i < objs.length; i++) {
      if (objs[i].COMPANY == coomm[0].COMPANY && objs[i].BRANCH == branchesArray[0].BRANCH) {
        userArray.push(objs[i]);
      }
    }
    userArray = [...new Map(userArray.map((item) => [item.REFID, item])).values()];
    setUsers(userArray);
    setUser({
      code: userArray[0].REFID,
      name: userArray[0].REFIDNAME,
    });
    if (coomm.length === 1) {
      setTimeout(() => setUniqueAuth(true), 500);
      setTimeout(handleAuthenticate, 1000); // eslint-disable-line
    }
    setLoading(false);
  }, []);

  const component = [
    {
      controlId: "company",
      label: "Εταιρεία",
      type: "text",
      value: company.name,
      keyValue: company.code,
      inputMode: "none",
      function: setCompany,
      functionModal: setOpenCompanies,
      functionOnChange: onCompanyChange,
    },
    {
      controlId: "branch",
      label: "Υπ/μα",
      type: "text",
      value: branch.name,
      keyValue: branch.code,
      inputMode: "none",
      function: setBranch,
      functionModal: setOpenBranches,
      functionOnChange: onBranchChange,
    },
    {
      controlId: "user",
      label: "Χρήστης",
      type: "text",
      value: user.name,
      keyValue: user.code,
      inputMode: "none",
      function: setUser,
      functionModal: setOpenUsers,
      functionOnChange: () => {},
    },
  ];

  return (
    <div className="logInBack d-flex align-items-center justify-content-center h-100 bg-dark text-dark">
      {loading && <Spinner colorSpin="#ffc107" />}
      <motion.div
        className="w-100 p-2"
        style={{ maxWidth: "460px" }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Card className="bg-dark shadow p-2">
          <Form onSubmit={handleAuthenticate}>
            {component.map((item, index) => {
              return (
                <InputGroup
                  className="crm-floating-with-icon my-1 text-light"
                  style={{
                    borderBottom: item.condition ? "2px solid var(--bs-danger)" : "1px solid #d7d7d7",
                  }}
                  onClick={() => item.functionModal(true)}
                  key={index}
                >
                  <FloatingLabel
                    controlId={item.controlId}
                    label={item.label}
                    style={{ whiteSpace: "nowrap" }}
                    className="w-100"
                  >
                    <Form.Control
                      className="crm-input ps-1 mt-1 bg-dark text-light"
                      autoCapitalize="off"
                      placeholder={item.label}
                      type={item.type}
                      name={item.controlId}
                      inputMode={item.inputMode}
                      required
                      value={item.value}
                      style={{
                        border: "0",
                      }}
                      onChange={(e) => {
                        e.target.blur();
                        item.function(e.target.value);
                      }}
                      onClick={(e) => {
                        e.target.blur();
                      }}
                    />
                  </FloatingLabel>
                  <div className="d-flex align-items-center justify-content-center">
                    <BsChevronDown size="1.5rem" color={"var(--bs-primary)"} />
                  </div>
                </InputGroup>
              );
            })}
            <Button
              disabled={loading}
              className="w-100 hoverIconAnimation-s mt-1"
              type="submit"
              variant={`${uniqueAuth ? "success" : "warning"}`}
            >
              <b>Σύνδεση</b> <BiLogIn />
            </Button>
          </Form>
        </Card>
      </motion.div>
      <ErrorModal show={hasError} handleClose={setHasError} text={errorMessage} />
      <ModalSelectAuth
        openModal={openCompanies}
        setOpenModal={setOpenCompanies}
        setCurrentVal={setCompany}
        selected={company}
        data={companies}
        locate="company"
        change={onCompanyChange}
      />
      <ModalSelectAuth
        openModal={openBranches}
        setOpenModal={setOpenBranches}
        setCurrentVal={setBranch}
        selected={branch}
        data={branches}
        locate="branch"
        change={onBranchChange}
      />
      <ModalSelectAuth
        openModal={openUsers}
        setOpenModal={setOpenUsers}
        setCurrentVal={setUser}
        selected={user}
        data={users}
        locate="user"
        change={() => {}}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  login: state.login,
});

const mapDispatchToProps = (dispatch) => ({
  handleSetSession: (value) => dispatch(SetSession(value)),
  handleSetSystemParams: (value) => dispatch(SetSystemParams(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Authenticate);
