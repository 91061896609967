import React, { useEffect, useState } from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";

const S1TextArea = ({ element, objectState, data, isForView }) => {
  const [viewValue, setViewValue] = useState("");

  useEffect(() => {
    if (isForView) {
      //Αν είναι για locate τότε θα ψάξω απο την GETDATA για να γεμίσω τα πεδία
    } else {
      // var splitted = element.name.split(".");
      // var tableName = splitted[0];
      // var fieldName = splitted[1];
      // var value = data[tableName][0].hasOwnProperty(fieldName)
      //   ? data[tableName][0][fieldName]
      //   : "";
    }
    var splitted = element.name.split(".");
    var tableName = splitted[0];
    var fieldName = splitted[1];
    var value = data[tableName][0].hasOwnProperty(fieldName) ? data[tableName][0][fieldName] : "";
    setViewValue(value);
  }, []);

  return (
    <div
      className="s1textarea w-100"
      style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}
      key={element.index}
    >
      <InputGroup className="crm-floating-with-icon" hidden={!element.visible}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            className="crm-input ps-1"
            type="textarea"
            as="textarea"
            rows={8}
            autoCapitalize="off"
            placeholder={element.caption}
            disabled={objectState === "view"}
            readOnly={element.readOnly}
            name={element.name}
            style={{
              border: "0",
              height: "auto",
              resize: "none",
            }}
            value={viewValue}
            onChange={(e) => setViewValue(e.target.value)}
            //   onBlur={(e) => handleChangeField(e)}
          />
        </FloatingLabel>
        {objectState == "edit" && viewValue != "" && !element.readOnly && (
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={() => {
              setViewValue("");
            }}
          >
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  objectState: state.screen.screens[`${state.screen.currentscreen}`].objectState,
  data: state.screen.screens[state.screen.currentscreen].getdata.data,
});

export default connect(mapStateToProps)(S1TextArea);
