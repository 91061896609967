import axios from "axios";
import React from "react";
import { Button } from "react-bootstrap";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { HiOutlineRefresh } from "react-icons/hi";
import { connect } from "react-redux";
import { gadgetsBrowserData, gadgetsBrowserInfo, gadgetsSetPage } from "../../../../redux/actions/gadget";
import GadgetLoader from "../../../../screens/dashboard/GadgetLoader";
import GadgetNoData from "../../GadgetNoData";
import GadgetData from "./GadgetData";

const Browser = ({ clientID, serialnumber, gadgets, property, handleBrowserInfo, handleBrowserData }) => {
  const [loading, setLoading] = React.useState();
  const [hasData, setHasData] = React.useState(false);
  const [error, setError] = React.useState(undefined);
  const [page, setPage] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);

  const fetchBrowser = async (x) => {
    setLoading(true);
    try {
      const browserInfo = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
        url: `http://${serialnumber}.oncloud.gr/s1services`,
        data: {
          service: "getBrowserInfo",
          clientID,
          version: 2,
          object: gadgets[property]?.OBJECT ?? gadgets[property]?.data?.OBJECT ?? "",
          list: gadgets[property].LIST ?? gadgets[property]?.data?.LIST ?? "",
          form: gadgets[property].FORM ?? gadgets[property]?.data?.FORM ?? "",
          filters: gadgets[property].FILTERS ?? gadgets[property]?.data?.FILTERS ?? "",
          appID: process.env.REACT_APP_SOFTONE_APPID,
        },
      });
      if (browserInfo.data.success) {
        handleBrowserInfo(property, browserInfo.data);
        if (browserInfo.data.totalcount > 0) {
          setTotalPages(
            Math.floor(browserInfo.data.totalcount / process.env.REACT_APP_GETBROWSERDATA_LIMIT) +
              (browserInfo.data.totalcount % process.env.REACT_APP_GETBROWSERDATA_LIMIT > 0 ? 1 : 0)
          );
          const browserData = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
            url: `http://${serialnumber}.oncloud.gr/s1services`,
            data: {
              service: "getBrowserData",
              clientID,
              reqid: browserInfo.data.reqID,
              start: x * process.env.REACT_APP_GETBROWSERDATA_LIMIT,
              limit: process.env.REACT_APP_GETBROWSERDATA_LIMIT,
              appID: process.env.REACT_APP_SOFTONE_APPID,
            },
          });
          if (browserData.data.success) {
            setError(undefined);
            setHasData(true);
            setLoading(false);
            handleBrowserData(property, browserData.data);
          } else {
            setHasData(false);
            setLoading(false);
            setError(browserData.data.error);
          }
        } else {
          setError(undefined);
          setLoading(false);
          setHasData(false);
          console.log("no data for ", gadgets[property]?.title ?? gadgets[property]?.data?.title);
        }
      } else {
        setLoading(false);
        setError(browserInfo.data.error);
        console.log(browserInfo.data);
      }
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  const fetchData = async (x) => {
    setLoading(true);
    setError(undefined);
    const browserData = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url: `http://${serialnumber}.oncloud.gr/s1services`,
      data: {
        service: "GetBrowserData",
        clientID,
        reqid: gadgets[property].browserinfo.reqID,
        start: x * process.env.REACT_APP_GETBROWSERDATA_LIMIT,
        limit: process.env.REACT_APP_GETBROWSERDATA_LIMIT,
        appID: process.env.REACT_APP_SOFTONE_APPID,
      },
    });
    if (browserData.data.success) {
      setLoading(false);
      handleBrowserData(property, browserData.data);
    } else {
      setLoading(false);
      setError(browserData.data.error);
    }
  };

  React.useEffect(async () => {
    setHasData(gadgets?.[property]?.browserinfo?.totalcount > 0);
    !gadgets?.[property]?.browserinfo && (await fetchBrowser(0));
    setPage(1);
  }, []);
  return (
    gadgets && (
      <div className="d-flex position-relative justify-content-center align-items-center flex-column h-100 bg-dark">
        <div className="gadget-bar text-warning bg-dark d-flex align-items-center justify-content-between w-100 ">
          <div className="ms-2">
            {gadgets?.[property]?.title ?? gadgets?.[property]?.name ?? gadgets?.[property]?.data.LIST ?? ""}
          </div>
          <div className="d-flex flex-row align-items-center">
            {gadgets?.[property]?.browserinfo?.totalcount > process.env.REACT_APP_GETBROWSERDATA_LIMIT &&
              gadgets?.[property]?.browserdata?.success &&
              totalPages > 1 &&
              !error && (
                <div className="border border-warning rounded d-flex flex-row ">
                  <div
                    className="btn"
                    onClick={async () => {
                      page > 1 && (await fetchData(page - 2));
                      page > 1 && setPage(page - 1);
                    }}
                  >
                    <FiArrowLeft
                      style={{
                        color: page == 1 ? "var(--bs-gray-500)" : "var(--bs-warning)",
                      }}
                    />
                  </div>
                  <div className="text-light text-center d-flex flex-row justify-content-center align-items-center">
                    {page}
                  </div>
                  <div className="text-light text-center d-flex flex-row justify-content-center align-items-center">
                    /
                  </div>
                  <div className="text-light text-center d-flex flex-row justify-content-center align-items-center">
                    {totalPages}
                  </div>
                  <div
                    className="btn"
                    onClick={async () => {
                      page < totalPages && (await fetchData(page));
                      page < totalPages && setPage(page + 1);
                    }}
                  >
                    <FiArrowRight
                      style={{
                        color: page == totalPages ? "var(--bs-gray-500)" : "var(--bs-warning)",
                      }}
                    />
                  </div>
                </div>
              )}
            <Button
              className="me-2 my-2 crm-button-with-icon"
              style={{
                borderColor: "transparent",
                backgroundColor: "transparent",
              }}
              onClick={async () => {
                await fetchBrowser(0);
                setPage(1);
              }}
            >
              <HiOutlineRefresh color="var(--bs-warning)" />
            </Button>
          </div>
        </div>
        {loading && <GadgetLoader colorSpin="#ffc107" />}
        {error ? (
          <div className="d-flex flex-column text-light text-center justify-content-center align-items-center h-100 w-100">
            <div>{error}</div>
          </div>
        ) : hasData ? (
          <GadgetData property={property} />
        ) : (
          <GadgetNoData />
        )}
        {/* {`Total Count : ${gadgets?.[property]?.browserinfo?.totalcount ?? 0}`} */}
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  serialnumber: state.systemparams.serialnumber,
  clientID: state.session.clientID,
  gadgets: state?.gadget?.gadgets,
});

const mapDispatchToProps = (dispatch) => ({
  handleBrowserInfo: (key, value) => dispatch(gadgetsBrowserInfo(key, value)),
  handleBrowserData: (key, value) => dispatch(gadgetsBrowserData(key, value)),
  handleSetPage: (key, page) => dispatch(gadgetsSetPage(key, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Browser);
