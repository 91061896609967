import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  InitObject,
  SetAccessRights,
  SetBrowserData,
  SetBrowserInfo,
  SetFormDesign,
  setGetData,
  setRelJobMenu,
} from "../../../../redux/actions/screen";
import { setLoading } from "../../../../redux/actions/modals";
import List from "./browser/List";
import FormScreen from "./form/FormScreen";
import ModalDialog from "../S1 Dialog/ModalDialog";
import NewButton from "./buttons/NewButton";
import {
  GETACCESSRIGHTS,
  GETBROWSERINFO,
  GETFILTERS,
  GETSCREENOBJ,
  GETCURRENTSCREEN,
  GETSCREENSTATE,
  GETRELJOBMENU,
} from "../../../../redux/selectors";
import getAccesRights from "../../../../services/getAccessRigths";
import getBrowserInfo from "../../../../services/getBrowserInfo";
import getBrowserData from "../../../../services/getBrowserData";
import getWebMenu from "../../../../services/getWebMenu";
import { IoWarningOutline } from "react-icons/io5";
import ShowRoomList from "./showrrom/ShowRoomList";
import getFormDesign from "../../../../services/getFormDesign";
import getData from "./functions/getData";
import Error from "./Error";

const EditMaster = ({
  MainPanelRef,
  currentscreen,
  isShowRoom,
  screen,
  screenState,
  accessrights,
  reljobmenu,
  browserinfo,
  handleLoading,
  handleInitialize,
  handleSetAccessRight,
  handleSetGetBrowserInfo,
  handleSetGetBrowserData,
  handleSetRelJobMenu,
  handleSetFormDesign,
  handleSetData,
}) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasData, setHasData] = useState(false);

  const fetchEditMaster = async () => {
    const browserInfo = await getBrowserInfo();
    if (browserInfo.success) {
      browserInfo.upddate = new Date();
      handleSetGetBrowserInfo(browserInfo);
      if (browserInfo.totalcount > 0) {
        const browserData = await getBrowserData({
          reqID: browserInfo.reqID,
          start: 0,
        });
        if (browserData.success) {
          setHasData(true);
          browserData.nextstart = parseInt(process.env.REACT_APP_GETBROWSERDATA_LIMIT);
          handleSetGetBrowserData(browserData);
        } else {
          setErrorMessage(browserData.error);
          setHasError(true);
          console.log(browserData);
        }
      } else {
        setHasData(false);
      }
    } else {
      setErrorMessage(browserInfo.error);
      setHasError(true);
      console.log(browserInfo);
    }
  };

  let relJobMenu = {};
  const checkRelJobMenu = (s) => {
    var y = s;
    y.forEach((item) => {
      if (!item?.children) {
        relJobMenu[item.idx] = item;
      } else {
        checkRelJobMenu(item.children);
      }
    });
  };

  const fetchRelJobMenu = async () => {
    const getRelJob = await getWebMenu({ user: undefined });
    if (getRelJob.success) {
      checkRelJobMenu(getRelJob.menu);
      handleSetRelJobMenu(relJobMenu);
    }
  };

  const openEditMaster = async () => {
    setLoading(true);
    handleLoading(true);
    if (!accessrights && !isShowRoom) {
      const accessRights = await getAccesRights();
      if (accessRights.success) {
        handleSetAccessRight(accessRights);
      } else {
        setHasError(true);
        setErrorMessage(accessRights.error);
        handleLoading(false);
        setLoading(false);
        return;
      }
    }
    await fetchEditMaster();
    screen.relJob && !reljobmenu && !isShowRoom && fetchRelJobMenu();
    handleLoading(false);
    setLoading(false);
  };

  const openEditMasterFromURL = async () => {
    openEditMaster();
    var locateinfo = "";
    const fetchFormDesign = await getFormDesign();
    if (fetchFormDesign.success) {
      handleSetFormDesign(fetchFormDesign);
      Object.entries(fetchFormDesign.model).forEach(([key, value], index) => {
        locateinfo += index == 0 ? `${key}:` : `;${key}:`;
        if (key == "XTRDOCDATA") locateinfo += "NAME,";
        value.fields.map((item) => {
          locateinfo += `${item.name},`;
        });
      });
      const data = await getData({ key: screen.id, locateinfo });
      if (data.success) {
        handleSetData(data);
      } else {
        setHasError(true);
        setErrorMessage(data.error);
        console.log(data);
      }
    } else {
      setHasError(true);
      setErrorMessage(fetchFormDesign.error);
      console.log(fetchFormDesign);
    }
  };

  useEffect(() => {
    setHasError(false);
    if (screen?.isFromURL) {
      openEditMasterFromURL();
    } else {
      setErrorMessage("");
      handleInitialize();
      openEditMaster();
    }
  }, [currentscreen]);

  return loading ? (
    <div />
  ) : hasError ? (
    <Error error={hasError} />
  ) : (
    <div className={`crm-editmaster h-100${browserinfo?.totalcount > 0 ? "" : " h-100"}`}>
      {isShowRoom ? (
        <ShowRoomList MainPanelRef={MainPanelRef} />
      ) : (
        <>
          <List MainPanelRef={MainPanelRef} />
          {screenState === "form" && <FormScreen MainPanelRef={MainPanelRef} />}
          {accessrights?.create && accessrights?.browserOnly != true && <NewButton MainPanelRef={MainPanelRef} />}
        </>
      )}
      <ModalDialog />
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentscreen: GETCURRENTSCREEN(state),
  isShowRoom: GETSCREENOBJ(state)?.isShowRoom,
  screenState: GETSCREENSTATE(state),
  accessrights: GETACCESSRIGHTS(state),
  reljobmenu: GETRELJOBMENU(state),
  filters: GETFILTERS(state),
  browserinfo: GETBROWSERINFO(state),
  screen: GETSCREENOBJ(state),
});
 
const mapDispatchToProps = (dispatch) => ({
  handleSetGetBrowserInfo: (value) => dispatch(SetBrowserInfo(value)),
  handleSetGetBrowserData: (value) => dispatch(SetBrowserData(value)),
  handleLoading: (value) => dispatch(setLoading(value)),
  handleSetRelJobMenu: (value) => dispatch(setRelJobMenu(value)),
  handleInitialize: () => dispatch(InitObject()),
  handleSetAccessRight: (value) => dispatch(SetAccessRights(value)),
  handleSetFormDesign: (index) => dispatch(SetFormDesign(index)),
  handleSetData: (index) => dispatch(setGetData(index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMaster);
