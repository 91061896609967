export const setLoading = (value) => ({
  type: "modals/setLoading",
  value,
});

export const setError = (value) => ({
  type: "modals/setError",
  value,
});

export const setCacheModal = (value) => ({
  type: "modals/setCache",
  value,
});

export const setAboutModal = (value) => ({
  type: "modals/setAbout",
  value,
});

export const setLogoutModal = (value) => ({
  type: "modals/setLogout",
  value,
});

export const SetErganiModal = (value) => ({
  type: "modals/setErgani",
  value,
});
