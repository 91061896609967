import React, { useEffect, useState } from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import { GETBUFFDATA, GETDATA } from "../../../../../../redux/selectors";

export const S1WebPage = ({ element, data, data_buffer, objectState }) => {
  const [viewValue, setViewValue] = useState("");
  useEffect(() => {
    var lookUpData = objectState == "view" ? data : data_buffer;
    var splitted = element.name.split(".");
    var tableName = splitted[0];
    var fieldName = splitted[1];
    var value = lookUpData?.[tableName]?.[0]?.[fieldName] ?? "";
    setViewValue(value);
  }, []);
  return (
    <div className="s1webpage w-100" style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}>
      <InputGroup className="crm-floating-with-icon" hidden={!element.visible}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            className="crm-input ps-1"
            autoCapitalize="off"
            placeholder={element.caption}
            disabled={objectState === "view"}
            readOnly={element.readOnly}
            name={element.name}
            style={{ border: "0" }}
            value={viewValue}
            onChange={(e) => setViewValue(e.target.value)}
            // onBlur={handleChangeField}
          />
        </FloatingLabel>
        {objectState != "view" && viewValue != "" && !element.readOnly && (
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={() => {
              setViewValue("");
              // handleChangeField(true);
            }}
          >
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  objectState: state.screen.screens[`${state.screen.currentscreen}`].objectState,
  data: GETDATA(state)?.data,
  data_buffer: GETBUFFDATA(state),
});

export default connect(mapStateToProps)(S1WebPage);
