import React, { useEffect, useState } from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import { SetGridChanges } from "../../../../../../redux/actions/screen";
import { GETCURRENTSCREEN, GETDATA, GETOBJECTSTATE, GETSCREENOBJ } from "../../../../../../redux/selectors";

const S1DateTime = ({ element, objectState, data, screen, handleSetChanges, currentscreen }) => {
  const [viewValue, setViewValue] = useState("");

  //   const handleChangeField = (clear) => {
  //     var fieldsToUpdate = null;
  //     var tableName = element.name.split(".")[0];
  //     var fieldName = element.name.split(".")[1];
  //     var relationship = screen.formdesign.model[tableName].relationship;
  //     var beforeValue;
  //     if (screen?.newdata) {
  //       //Αν έχω ήδη κάνει κάποια αλλαγή
  //       if (screen.newdata.hasOwnProperty(tableName)) {
  //         //Αν έχω ήδη κάνει κάποια αλλαγή για αυτον τον πίνακα
  //         if (relationship == "OneToOne") {
  //           //Όταν πρόκειται για 1προς1 πίνακα
  //           //απλά προσθέτω στην ίδια φέτα τις αλλαγές
  //           beforeValue = screen.newdata[tableName][0][fieldName];
  //           if (beforeValue != (clear === true ? "" : viewValue)) {
  //             fieldsToUpdate = screen.newdata[tableName];
  //             fieldsToUpdate[0] = {
  //               ...fieldsToUpdate[0],
  //               [fieldName]: clear === true ? "" : viewValue,
  //             };
  //             handleSetChanges(currentscreen, tableName, fieldsToUpdate);
  //           }
  //         } else {
  //           console.log("OneToMany");
  //         }
  //       } else {
  //         //Αν δεν έχω κάνει κάποια αλλαγή για αυτον τον πίνακα τον αρχοκοποιώ
  //         if (relationship == "OneToOne") {
  //           fieldsToUpdate = [{ [fieldName]: clear === true ? "" : viewValue }];
  //           handleSetChanges(currentscreen, tableName, fieldsToUpdate);
  //         } else {
  //           console.log("OneToMany");
  //         }
  //       }
  //     } else {
  //       //Αρχικοποιώ τις αλλαγές αυθαίρετα με τον πρώτο πινακα που αλλάζω
  //       fieldsToUpdate = [{ [fieldName]: clear === true ? "" : viewValue }];
  //       handleSetChanges(currentscreen, tableName, fieldsToUpdate);
  //     }
  //   };

  useEffect(() => {
    var splitted = element.name.split(".");
    var tableName = splitted[0];
    var fieldName = splitted[1];
    var value = data[tableName][0].hasOwnProperty(fieldName) ? data[tableName][0][fieldName] : "";
    value != "" && setViewValue(dateTimeConverter(value));
  }, []);

  return (
    <div className="s1datetime w-100" style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}>
      <InputGroup className="crm-floating-with-icon" hidden={!element.visible}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            className="crm-input ps-1"
            type="datetime-local"
            placeholder={element.caption}
            disabled={objectState === "view"}
            readOnly={element.readOnly}
            name={element.name}
            style={{ border: "0" }}
            value={viewValue}
            onChange={(e) => {
              setViewValue(e.target.value);
            }}
            // onBlur={handleChangeField}
          />
        </FloatingLabel>
        {objectState == "edit" && viewValue != "" && !element.readOnly && (
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={() => {
              setViewValue("");
              //   handleChangeField(true);
            }}
          >
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
  data: GETDATA(state),
  screen: GETSCREENOBJ(state),
  currentscreen: GETCURRENTSCREEN(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetChanges: (index, keyname, keyvalue) => dispatch(SetGridChanges(index, keyname, keyvalue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1DateTime);

const dateTimeConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return (
      [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-") +
      "T" +
      [d.getHours().padLeft(), d.getMinutes().padLeft()].join(":")
    );
  } else {
    return value;
  }
};
