import React from "react";
import { Button } from "react-bootstrap";
import { BsPlus } from "react-icons/bs";
import { connect } from "react-redux";
import { AddDetailGrid, SetCurrentLine } from "../../../../../redux/actions/relJob";
import {
  GETBUFFDATA,
  GETDATA,
  GETGRIDS,
  GETNEWDATA,
  GETOBJECTSTATE,
  GETSCREENOBJ,
} from "../../../../../redux/relJobSelectors";
import GridRows from "./GridRows";
import AddLine from "./input modals/AddLine";
import ViewGridModal from "./ViewGridModal";

const S1DetailGrid = ({ element, data, objectState, handleAddGrid, handleSetCurrentLine }) => {
  const [openGridModal, setOpenGridModal] = React.useState(false);
  const [openViewGridModal, setViewOpenGridModal] = React.useState(false);
  const [currentLine, setCurrentLine] = React.useState(-1);

  React.useEffect(() => {
    handleAddGrid(element.model, element);
    console.log(element.columns.length);
  }, []);

  const addNewLine = () => {
    setCurrentLine(-1);
    handleSetCurrentLine(element.model, -1, {});
    setOpenGridModal(true);
  };

  return (
    <>
      <div className="s1detailgrid w-100 my-2 p-2 bg-dark rounded text-light">
        <Button
          className="d-flex align-content-center align-items-center"
          variant="primary"
          disabled={objectState == "view"}
          onClick={addNewLine}
        >
          <BsPlus className="me-1" size="1.5rem" />
          <span>Εισαγωγή</span>
        </Button>
      </div>

      {element.columns.length > 4 ? (
        <div
          className="w-100 py-3 text-center text-light"
          style={{ borderBottom: "1px solid white" }}
          onClick={() => setViewOpenGridModal(true)}
        >
          Πλήθος
          <span className="ms-2 py-1 px-2 bg-primary rounded text-light">{data?.[element.model]?.length ?? 0}</span>
        </div>
      ) : (
        <GridRows grid={element} />
      )}
      {openGridModal && (
        <AddLine
          show={openGridModal}
          setShow={setOpenGridModal}
          grid={element}
          currentLine={currentLine}
          fields={element.columns}
          gridSelector={element.gridSelector}
        />
      )}
      {openViewGridModal && (
        <ViewGridModal
          element={element}
          show={openViewGridModal}
          setShow={setViewOpenGridModal}
          grid={element}
          gridSelector={element.gridSelector}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
  data: { view: GETDATA(state)?.data, edit: GETBUFFDATA(state), new: GETNEWDATA(state) }[GETOBJECTSTATE(state)],
  newdata: GETNEWDATA(state),
  grids: GETGRIDS(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleAddGrid: (gridName, value) => dispatch(AddDetailGrid(gridName, value)),
  handleSetCurrentLine: (gridName, lineIndex, line) => dispatch(SetCurrentLine(gridName, lineIndex, line)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1DetailGrid);
