import axios from "axios";
import { GETCLIENTID, GETURL } from "../../../../../redux/selectors";
import store from "../../../../../redux/store";

const getBrowserData = async ({ reqID, start }) => {
  const state = store.getState();
  const url = GETURL(state);
  const clientID = GETCLIENTID(state);

  try {
    const browserData = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url,
      data: {
        service: "getBrowserData",
        clientID,
        reqID,
        start,
        limit: process.env.REACT_APP_GETBROWSERDATA_LIMIT,
        appID: process.env.REACT_APP_SOFTONE_APPID,
      },
    });
    return browserData.data;
  } catch (error) {
    console.log(error);
    return { success: false, error: error.message };
  }
};

export default getBrowserData;
