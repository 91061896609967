import React from "react";
import { Button, Card, ButtonGroup } from "react-bootstrap";
import { motion } from "framer-motion";
import { AiOutlineClose } from "react-icons/ai";
import { connect } from "react-redux";
import { IoWarningOutline, IoWineSharp } from "react-icons/io5";
import { setCacheModal } from "../../../redux/actions/modals";
import { HiX, HiCheck } from "react-icons/hi";
import Spinner from "../Spinner";
import axios from "axios";
import { GETURL } from "../../../redux/selectors";

const ModalClearCache = ({ show, setShow, url }) => {
  const [loading, setLoading] = React.useState(false);
  const handleConfirm = async () => {
    setLoading(true);
    await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url: `${url}?refresh`,
      data: {
        service: "refresh",
      },
    });
    // setShow(false);
    // setLoading(false);
    window.location.reload(true);
  };

  const variants = {
    open: { opacity: 1, zIndex: "2", pointerEvents: "auto" },
    closed: {
      opacity: 0,
      zIndex: "2",
      pointerEvents: "none",
    },
  };

  return (
    <motion.div
      variants={variants}
      initial="closed"
      exit="closed"
      transition={{ duration: 0.5, ease: "easeInOut" }}
      animate={`${show ? "open" : "closed"}`}
      className="crm-modal-cache Overlay d-flex align-items-center justify-content-center"
    >
      {loading && <Spinner colorSpin="#ffc107" />}
      <div
        className="justify-content-center
        align-items-center bg-dark rounded shadow w-100 mx-2"
        style={{ maxWidth: "600px" }}
      >
        <div className="border border-warning border-1 rounded">
          <Card className="bg-dark pb-0 px-0 text-light shadow border border-warning rounded p-4">
            <Card.Body>
              <Button onClick={() => setShow(false)} variant="outline-warning" className="closeButton rounded-0 shadow">
                <AiOutlineClose />
              </Button>
              <div className="text-center text-light">
                <h4>
                  <b>Καθαρισμός Κρυφής Μνήμης</b>
                </h4>
                <div className="py-4">
                  <IoWarningOutline fontSize={"6rem"} />
                </div>

                <h5>Επιθυμείτε να συνεχίσετε;</h5>
              </div>
            </Card.Body>
            <Card.Footer
              style={{
                background: "transparent",
                borderBlockColor: "transparent",
              }}
              className="p-0 w-100 shadow border-0"
            >
              <ButtonGroup className="m-0 w-100">
                <Button
                  variant="outline-warning"
                  className="w-50 d-flex justify-content-center align-items-center rounded-0"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <HiX size={25} color="var(--bs-danger)" className="me-2" />
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ fontSize: 20, color: "white" }}
                  >
                    Ακύρωση
                  </div>
                </Button>
                <Button
                  className="w-50 d-flex justify-content-center align-items-center rounded-0"
                  variant="outline-warning"
                  onClick={handleConfirm}
                >
                  <HiCheck size={25} color="var(--bs-success)" className="me-2" />
                  <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ fontSize: 20, color: "white" }}
                  >
                    Συνέχεια
                  </div>
                </Button>
              </ButtonGroup>
            </Card.Footer>
          </Card>
        </div>
      </div>
    </motion.div>
  );
};

const mapStateToProps = (state) => ({
  show: state.modals.cache,
  url: GETURL(state),
});

const mapDispatchToProps = (dispatch) => ({
  setShow: (value) => dispatch(setCacheModal(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalClearCache);
