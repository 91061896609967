import React from "react";
import { Button, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { GETBUFFDATA, GETDATA, GETLINESCOUNT, GETNEWDATA, GETOBJECTSTATE } from "../../../../../../../redux/selectors";
import { TbSignature } from "react-icons/tb";
import { MdAttachFile } from "react-icons/md";
import load from "load-script";
import ModalSignature from "../../../../../modals/ModalSignature";
import AddVideo from "./AddVideo";
import { ImBin } from "react-icons/im";
import {
  AddDetailGrid,
  AddLineToGrid,
  CanSignature,
  DeleteGridLine,
  SetCurrentLine,
  SetGridChanges,
  TriggerGridChange,
  handleCanSignature,
} from "../../../../../../../redux/actions/screen";
import AddPhoto from "./AddPhoto";
import { setError } from "../../../../../../../redux/actions/modals";

const S1RelDocs = ({
  element,
  getLinesCount,
  newdata,
  objectState,
  dropbox,
  data,
  handleCanSignature,
  handleSetError,
  handleSetCurrentLine,
  handleSetGridChanges,
  handleAddGrid,
  handleTriggerGridChange,
  handleAddLineToGrid,
  handleDeleteLine,
}) => {
  const [signature, setSignature] = React.useState(false);
  const { model } = element;

  React.useEffect(() => {
    load(process.env.REACT_APP_DROPBOX_SDK_URL, {
      attrs: {
        id: process.env.REACT_APP_DROPBOX_ID,
        "data-app-key": process.env.REACT_APP_DROPBOX_CLIENT_ID,
      },
    });
    handleAddGrid(model, element);
    handleCanSignature();
  }, []);

  const options = {
    success: (files) => {
      const { link } = files[0];
      handleSetCurrentLine(model, -1, {});
      handleSetGridChanges(link);
      var gridLines = newdata?.[model] ?? [];
      if (gridLines.length == 0) {
        handleTriggerGridChange(model);
      }
      const line = {
        SOFNAME: link,
        NAME: getName(link),
        LINENUM: 9000001 + getLinesCount(),
      };
      handleAddLineToGrid(model, line);
    },
    cancel: () => console.log("cancel"),
    linkType: "preview", // or "direct"
    multiselect: false, // or true
    folderselect: true,
  };

  const handleChooser = React.useCallback(() => {
    if (window.Dropbox) {
      window.Dropbox.choose(options);
    }
  }, [options]);

  const handleDelete = (e, index) => {
    e.stopPropagation();
    const gridLines = newdata?.[model] ?? [];
    if (gridLines.length == 0) {
      handleTriggerGridChange(model);
    }
    handleDeleteLine(index);
  };

  const handleRowClick = (url) => {
    if (isValidUrl(url)) {
      window.open(url);
    }
  };

  return (
    <div className="s1reldocs w-100">
      <div className="w-100 my-2 p-2 bg-dark rounded text-light d-flex justify-content-end">
        <Button
          className="d-flex align-content-center align-items-center me-2"
          variant="primary"
          disabled={objectState == "view"}
          onClick={() => {
            if (!dropbox) {
              handleSetError({ error: "Δεν έχετε συνδεδεμένο λογαριασμό dropbox." });
            } else {
              setSignature(true);
            }
          }}
        >
          <TbSignature size="1.5rem" />
        </Button>
        <AddPhoto />
        <AddVideo />
        <Button
          className="d-flex align-content-center align-items-center"
          variant="primary"
          disabled={objectState == "view"}
          onClick={handleChooser}
        >
          <MdAttachFile size="1.5rem" />
        </Button>
        {signature && <ModalSignature show={signature} setShow={setSignature} />}
      </div>
      {data?.[model]?.length > 0 && (
        <div
          className="w-100 py-2 text-right text-light"
          style={{ borderBottom: "1px solid white" }}
          // onClick={() => setViewOpenGridModal(true)}
        >
          <Table responsive striped bordered hover variant="dark">
            <thead>
              <tr>
                {objectState != "view" && <th></th>}
                <th>Type</th>
                <th>Name</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              {(data?.[model] ?? []).map((item, i) => {
                return (
                  <tr key={i} onClick={() => handleRowClick(item.SOFNAME)}>
                    {objectState != "view" && (
                      <td onClick={(e) => handleDelete(e, i)}>
                        <div>
                          <ImBin color="var(--bs-danger)" size={"1rem"} />
                        </div>
                      </td>
                    )}
                    <td>
                      <div>{getType(item.SOFNAME)}</div>
                    </td>
                    <td className="text-truncate">
                      <div>{item?.NAME ?? item?.SOFNAME}</div>
                    </td>
                    <td className="text-truncate">
                      <div>{item?.SOFNAME}</div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
  dropbox: state?.settings?.dropbox,
  data: { view: GETDATA(state)?.data, edit: GETBUFFDATA(state), new: GETNEWDATA(state) }[GETOBJECTSTATE(state)],
  getLinesCount: () => GETLINESCOUNT(state, "XTRDOCDATA"),
  newdata: GETNEWDATA(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleCanSignature: () => dispatch(CanSignature()),
  handleSetError: (value) => dispatch(setError(value)),
  handleAddGrid: (name, value) => dispatch(AddDetailGrid(name, value)),
  handleSetCurrentLine: (grid, index, line) => dispatch(SetCurrentLine(grid, index, line)),
  handleSetGridChanges: (value) => dispatch(SetGridChanges("XTRDOCDATA", "SOFNAME", -1, value)),
  handleTriggerGridChange: (grid) => dispatch(TriggerGridChange(grid)),
  handleAddLineToGrid: (grid, value) => dispatch(AddLineToGrid(grid, value)),
  handleDeleteLine: (index) => dispatch(DeleteGridLine("XTRDOCDATA", index)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1RelDocs);

const isFolder = (string) => string.replace(/^.*[\\\/]/, "").indexOf(".") == -1;

const getType = (string) => {
  if (isFolder(string)) {
    return "folder";
  } else {
    const path = getPathFromUrl(string.replace(/^.*[\\\/]/, ""));
    return "." + path.split(".")[path.split(".").length - 1];
  }
};

const getName = (string) => getPathFromUrl(decodeURI(string).replace(/^.*[\\\/]/, ""));

const getPathFromUrl = (url) => url.split("?")[0];

const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};
