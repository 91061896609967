import React from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import NoReportData from "../../no data/NoReportData";
import ModalDialogRelJob from "../../S1 Dialog/ModalDialogRelJob";

const Report = ({ screen, error, reportinfo, reportdata }) => {
  return (
    <div className="h-100">
      {error !== "" ? (
        error
      ) : screen?.noData ? (
        <NoReportData />
      ) : reportinfo?.npages > 0 ? (
        <Container
          style={{
            overflow: "auto",
          }}
          fluid
        >
          <div
            dangerouslySetInnerHTML={{ __html: reportdata?.data }}
            style={{
              overflow: "scroll",
              overflowX: "scroll",
              overflowY: "scroll",
              height: "100%",
              width: "100%",
            }}
          />
        </Container>
      ) : (
        <NoReportData />
      )}
      <ModalDialogRelJob />
    </div>
  );
};

const mapStateToProps = (state) => ({
  screen: state.screen.relJob,
  error: state.screen.relJob?.error || "",
  reportinfo: state.screen.relJob?.reportinfo,
  reportdata: state.screen.relJob?.reportdata,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
