import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { TiDeleteOutline } from "react-icons/ti";
import { connect } from "react-redux";
import { SetBasketItemQty } from "../../../../../../redux/actions/showRoom";

const CardFooter = ({ item, handleBasket, basket, code, name, image }) => {
  const [qty, setQty] = React.useState(0);
  const id = item[0].split(";")[1];

  const handlePlus = () => {
    handleBasket(id, { code, name, image, qty: qty + 1 });
  };

  const handleMinus = () => {
    if (qty >= 1) {
      handleBasket(id, { code, name, image, qty: qty - 1 });
    }
  };

  React.useEffect(() => {
    setQty(basket?.[id]?.qty ?? 0);
  }, [basket?.[id]?.qty]);

  return (
    <div className="d-flex flex-row">
      <InputGroup className="crm-floating-with-icon" style={{ maxWidth: 100 }}>
        <Form.Control
          className="crm-input ps-1"
          type="number"
          inputMode="decimal"
          step="1"
          style={{ border: "0" }}
          value={qty}
          onChange={(e) => setQty(e.target.value)}
        />
        <div
          className="d-flex align-items-center justify-content-center"
          onClick={() => handleBasket(id, { code, name, image, qty: 0 })}
        >
          <TiDeleteOutline size="1.75rem" />
        </div>
      </InputGroup>

      <AiOutlineMinus size={30} onClick={handleMinus} className="ms-4 me-2" />
      <AiOutlinePlus size={30} onClick={handlePlus} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  basket: state.showRoom?.basket,
});
const mapDispatchToProps = (dispatch) => ({
  handleBasket: (item, obj) => dispatch(SetBasketItemQty(item, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CardFooter);
