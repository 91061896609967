import React from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { setError } from "../../../redux/actions/modals";

const ModalGeneralError = ({ handleSetError, error }) => {
  return (
    <Modal
      show={error}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
      contentClassName="border-warning bg-dark text-light shadow"
    >
      <Modal.Body
        className="d-flex justify-content-center align-content-center"
        dangerouslySetInnerHTML={{ __html: error?.error }}
      />
      <Modal.Footer className="p-1 d-flex justify-content-center align-content-center">
        <Button variant="primary" onClick={() => handleSetError(undefined)}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  error: state.modals.error,
});

const mapDispatchToProps = (dispatch) => ({
  handleSetError: (value) => dispatch(setError(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalGeneralError);
