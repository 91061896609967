import reducer from "./reducers";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
const store = createStore(
  reducer,
  composeWithDevTools(
    applyMiddleware()
    // other store enhancers if any
  )

  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
);

export default store;
