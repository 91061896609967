import React, { useEffect } from "react";
import { Container, Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import { SetCurrentScreen } from "../../../redux/actions/screen";
import TopBarOnReport from "./TopBarOnReport";
import TopBarOnBatchJob from "./TopBarOnBatchJob";
import TopBarOnEditMaster from "./TopBarOnEditMaster";
import { GETCURRENTSCREEN, GETOBJECTSTATE } from "../../../redux/selectors";
import TopBarOnRelJob from "./TopBarOnRelJob";

const TopBar = ({ screen, isOnRelJob, objectState, mainNavigatorHeight, setTopBarHeight }) => {
  useEffect(() => {
    const height = document.querySelector("#crm-top-bar").offsetHeight;
    setTopBarHeight(height);
  });

  return (
    <>
      {isOnRelJob ? (
        <TopBarOnRelJob mainNavigatorHeight={mainNavigatorHeight} setTopBarHeight={setTopBarHeight} />
      ) : (
        // objectState !== "new" ? (
        <div id="crm-top-bar" style={{ position: "sticky", top: mainNavigatorHeight, zIndex: 1000 }}>
          <Navbar
            style={{
              backgroundColor: "var(--bs-gray-800)",
              borderBottom: "2px solid var(--bs-gray-700)",
              borderTop: "2px solid var(--bs-gray-700)",
            }}
          >
            {screen?.cmd ? (
              // screen.cmd == "Report" ? (
              //   <TopBarOnReport />
              // ) : screen.screenState == "list" ? (
              //   <TopBarOnList />
              // ) : (
              //   <TopBarOnForm />
              // )
              {
                Report: <TopBarOnReport />,
                Dialog: <TopBarOnBatchJob />,
                EditMaster: <TopBarOnEditMaster />,
              }[screen.cmd]
            ) : screen === "dashboard" ? (
              <Container fluid className="text-light">
                Dashboard
              </Container>
            ) : (
              <Container fluid className="text-light">
                Connection Info
              </Container>
            )}
          </Navbar>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  screen: state.screen.screens[GETCURRENTSCREEN(state)],
  objectState: GETOBJECTSTATE(state),
  isOnRelJob: state.screen.isOnRelJob,
});

const mapDispatchToProps = (dispatch) => ({
  handleSetCurrentScreenToDashBoard: () => dispatch(SetCurrentScreen("dashboard")),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
