const Spinner = () => {
  return (
    // <div className="loadModal d-flex justify-content-center align-items-center">
    //   <Circles
    //     color={colorSpin}
    //     height={height === undefined ? 100 : height}
    //     width={width === undefined ? 100 : width}
    //     //   timeout={3000} //3 secs
    //   />
    // </div>
    <div className="loadModal d-flex justify-content-center align-items-center">
      <div className="bouncing-loader ">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
export default Spinner;
