import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, FloatingLabel, Form, InputGroup, Modal } from "react-bootstrap";
import { TiDeleteOutline } from "react-icons/ti";
import { connect } from "react-redux";
import { SetNewPassword } from "../../redux/actions/login";
import changePassword from "../../services/changePassword";
import Spinner from "../ui/Spinner";
import { HiX, HiCheck } from "react-icons/hi";

const ModalPassword = ({ show, setShow, login, handleSetNewPassword }) => {
  const [wrongOldPass, setWrongOld] = useState(false);
  const [wrongNewPass, setWrongNew] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const [curpass, setCurPass] = useState("");
  const [newpass, setNewPass] = useState("");
  const [valnewpass, setValNewPass] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setWrongOld(false);
    setWrongNew(false);
    if (curpass === login.password) {
      if (newpass === valnewpass) {
        setLoading(true);
        try {
          const response = await changePassword({
            oldPass: curpass,
            newPass: newpass,
          });
          if (response.success) {
            setShow(false);
            handleSetNewPassword({ ...login, password: newpass });
          } else {
            console.log(response);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      } else {
        setWrongNew(true);
      }
    } else {
      setWrongOld(true);
    }
  };

  useEffect(() => {
    setCurPass("");
    setNewPass("");
    setValNewPass("");
    setWrongNew(false);
    setWrongOld(false);
  }, [show]);

  const comp = [
    {
      controlId: "curpass",
      label: "Τρέχων Κωδικός",
      value: curpass,
      condition: wrongOldPass,
      function: setCurPass,
    },
    {
      controlId: "newpass",
      label: "Νέος Κωδικός",
      value: newpass,
      condition: wrongNewPass,
      function: setNewPass,
    },
    {
      controlId: "valnewpass",
      label: "Επιβεβαιώστε Νεό Κωδικό",
      value: valnewpass,
      condition: wrongNewPass,
      function: setValNewPass,
    },
  ];

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      dialogClassName="p-3"
      contentClassName="rounded border border-warning shadow overflow-hidden bg-dark"
      centered
    >
      {loading && <Spinner />}
      <Modal.Body className="pb-0 px-0">
        <Form onSubmit={handleSubmit}>
          <div className="px-3">
            {comp.map((item, index) => {
              return (
                <InputGroup className="crm-floating-with-icon" key={index}>
                  <FloatingLabel
                    controlId={item.controlId}
                    label={item.label}
                    style={{ whiteSpace: "nowrap" }}
                    className="w-100"
                  >
                    <Form.Control
                      className="crm-input ps-1 mt-1"
                      autoCapitalize="off"
                      placeholder={item.label}
                      type="password"
                      name={item.controlId}
                      required
                      value={item.value}
                      style={{
                        border: "0",
                        boxShadow: item.condition && "inset var(--bs-danger) 0px 0px 0px 2px",
                      }}
                      onChange={(e) => item.function(e.target.value)}
                    />
                  </FloatingLabel>
                  {item.value != "" && (
                    <div
                      className="d-flex align-items-center justify-content-center"
                      onClick={() => {
                        item.function("");
                      }}
                    >
                      <TiDeleteOutline size="1.75rem" />
                    </div>
                  )}
                </InputGroup>
              );
            })}
          </div>

          <div className="p-0 mt-3 w-100 bg-dark shadow border-0">
            <ButtonGroup className="m-0 w-100">
              <Button
                variant="outline-warning"
                className="w-50 d-flex justify-content-center align-items-center rounded-0"
                onClick={() => setShow(false)}
              >
                <HiX size={25} color="var(--bs-danger)" className="me-2" />
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ fontSize: 20, color: "white" }}
                >
                  Κλείσμο
                </div>
              </Button>
              <Button
                className="w-50 d-flex justify-content-center align-items-center rounded-0"
                variant="outline-warning"
                type="submit"
              >
                <HiCheck size={25} color="var(--bs-success)" className="me-2" />
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ fontSize: 20, color: "white" }}
                >
                  Εφαρμογή
                </div>
              </Button>
            </ButtonGroup>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({ login: state.login });

const mapDispatchToProps = (dispatch) => ({
  handleSetNewPassword: (value) => dispatch(SetNewPassword(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalPassword);
