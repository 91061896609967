import axios from "axios";
import React from "react";
import { Button, Modal, ButtonGroup, InputGroup, FloatingLabel, Form } from "react-bootstrap";
import { TiDeleteOutline } from "react-icons/ti";
import { connect } from "react-redux";
import Spinner from "../../../../Spinner";
import { HiX, HiCheck } from "react-icons/hi";
import { SetChanges } from "../../../../../../redux/actions/screen";

function SelectorEditorModal({
  serialnumber,
  clientID,
  setViewValue,
  setKeyValue,
  show,
  setShow,
  element,
  data,
  editor,
  predifinedKey,
  handleChange,
  handleSetChange,
  handleChangeObj,
}) {
  const [loading, setLoading] = React.useState(false);
  const [editorData, setEditorData] = React.useState(data);
  const [selectedRow, setSelectedRow] = React.useState();
  const [searchValue, setSearchValue] = React.useState("");

  const handleAccept = async () => {
    try {
      setLoading(true);
      var filters = editor.split("|")[4];
      var tablename = editor.split("|")[1];
      var keyname = editor.split("|")[2];
      var keyvalue = editorData.data[selectedRow][0];
      var resultfields = [...new Set(data.model.map((i) => i.name))];
      var keyForVF = [];
      const selectorFields = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
        url: `http://${serialnumber}.oncloud.gr/s1services`,
        data: {
          service: "selectorFields",
          clientID,
          APPID: process.env.REACT_APP_SOFTONE_APPID,
          // filters,
          tablename,
          keyname,
          keyvalue,
          resultfields: resultfields.join(","),
        },
      });
      if (selectorFields.data.success) {
        var value = "";
        if (element.selectorVF != "") {
          element.selectorVF.split("|").forEach((i) => {
            var dbIndex = i.split(".")[1].split("_");
            keyForVF.push(dbIndex[dbIndex.length - 1]);
            value += selectorFields.data.rows[0][dbIndex[dbIndex.length - 1]] + " ";
            handleSetChange(
              i.split(".")[0],
              i.split(".")[1],
              0,
              selectorFields.data.rows[0][dbIndex[dbIndex.length - 1]]
            );
          });
        } else {
          value = selectorFields.data.rows[0]?.[keyname] ?? selectorFields.data.rows[0]?.CODE;
          keyname = selectorFields.data.rows[0]?.[keyname] ?? selectorFields.data.rows[0].CODE;
        }
        handleChange(selectorFields.data.rows[0][keyname]);
        setViewValue(value);
        setKeyValue(selectorFields.data.rows[0][keyname]);
        // handleChangeObj(selectorFields.data.rows[0]);
        setLoading(false);
        setShow(false);
      } else {
        setLoading(false);
        console.log(selectorFields.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSearch = async (value) => {
    setLoading(true);
    try {
      const GetSelectorData = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
        url: `http://${serialnumber}.oncloud.gr/s1services`,
        data: {
          service: "GetSelectorData",
          clientID,
          appId: process.env.REACT_APP_SOFTONE_APPID,
          editor,
          value,
        },
      });
      if (GetSelectorData.data.success) {
        setEditorData(GetSelectorData.data);
      } else {
        console.log(GetSelectorData.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    setEditorData(data);
    if (predifinedKey != "") {
      var keyname = editor.split("|")[2];
      var keyIndex, i;
      for (i = 0; i < data.model.length; i++) {
        if (data.model[i].name == keyname) {
          keyIndex = i;
          break;
        }
      }
      for (i = 0; i < data.data.length; i++) {
        if (data.data[i][keyIndex] == predifinedKey) {
          setSelectedRow(i);
          break;
        }
      }
    }
  }, []);
  return (
    <Modal
      animation={false}
      show={show}
      centered
      fullscreen
      dialogClassName="p-3"
      contentClassName="rounded border border-warning shadow overflow-hidden bg-dark"
    >
      {loading && <Spinner colorSpin="#ffc107" />}
      <Modal.Header className="px-2 text-light pt-0">
        <InputGroup className="crm-floating-with-icon">
          <FloatingLabel
            controlId={`Editor-${element.name}`}
            label="Αναζητηση"
            style={{ whiteSpace: "nowrap" }}
            className="w-100"
          >
            <Form.Control
              value={searchValue}
              autoCapitalize="off"
              className="crm-input form-control ps-1"
              placeholder="Αναζητηση"
              style={{ border: "0" }}
              onChange={(e) => setSearchValue(e.target.value)}
              type="search"
              ref={(r) => ((r || {}).onsearch = (e) => handleSearch(e.target.value))}
            />
          </FloatingLabel>
          {searchValue != "" && (
            <div
              className="d-flex align-items-center justify-content-center"
              onClick={() => {
                setSearchValue("");
                handleSearch("");
              }}
            >
              <TiDeleteOutline size="1.75rem" />
            </div>
          )}
        </InputGroup>
      </Modal.Header>
      <Modal.Body className="p-0 text-light">
        <div id="s1Editor-list-items">
          {show &&
            editorData.data.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => setSelectedRow(index)}
                  className={`s1editor-row border-top border-bottom px-1 ${
                    selectedRow == index ? "rounded border-warning shadow" : ""
                  }`}
                  style={{
                    backgroundColor: selectedRow == index ? "var(--bs-gray-400)" : "transparent",
                    color: selectedRow == index ? "var(--bs-dark)" : "white",
                  }}
                >
                  <div className="my-3 mx-2">
                    {editorData.model.slice(1).map((yy, i) => {
                      return (
                        <span key={i} className="me-3" style={{ display: "inline-block" }}>
                          {item[i + 1]}
                        </span>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </Modal.Body>
      <Modal.Footer className="p-0 w-100 bg-dark shadow border-0">
        <ButtonGroup className="m-0 w-100">
          <Button
            variant="outline-warning"
            className="w-50 d-flex justify-content-center align-items-center rounded-0"
            onClick={() => setShow(false)}
          >
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Ακύρωση
            </div>
          </Button>
          <Button
            className="w-50 d-flex justify-content-center align-items-center rounded-0"
            variant="outline-warning"
            onClick={handleAccept}
          >
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Εφαρμογή
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  serialnumber: state.systemparams.serialnumber,
  clientID: state.session.clientID,
});

const mapDispatchToProps = (dispatch) => ({
  handleSetChange: (table, key, index, value) => dispatch(SetChanges(table, key, index, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectorEditorModal);
