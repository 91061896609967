import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { GETOUTPUTFORMATS, GETSCREENOBJ, GETSN } from "../../../redux/selectors";
import Spinner from "../Spinner";
import getReportInfo from "../../../services/getReportInfo";
import getBrowserInfo from "../../../services/getBrowserInfo";
import print from "../../../services/print";
import { HiX, HiCheck } from "react-icons/hi";

const ModalPrintList = ({ serialnumber, show, setShow, outputformats, cmd }) => {
  const [format, setFormat] = useState("none");
  const [loading, setLoading] = useState(false);
  const [printURL, setPrintURL] = React.useState(undefined);
  const [fileName, setFileName] = React.useState("");
  const [showModal, setShowModal] = React.useState(false);

  useEffect(() => {
    show && setFormat("none");
    show && setShowModal(false);
  }, [show]);

  const handlePrint = async () => {
    setLoading(true);
    var reqID = "";
    setLoading(true);
    switch (cmd) {
      case "Report":
        const reportInfo = await getReportInfo();
        if (reportInfo.success) {
          reqID = reportInfo.reqID;
        }
        break;
      case "EditMaster":
        const browserInfo = await getBrowserInfo();
        if (browserInfo.success) {
          reqID = browserInfo.reqID;
        }
        break;
      default:
        console.log("den exeis kanei print gia ", cmd);
        break;
    }
    const printDoc = await print({ reqID, format });
    if (printDoc.success) {
      // setShow(false);
      setPrintURL(`https://${serialnumber}.oncloud.gr/s1services/?filename=${printDoc.filename}`);
      setFileName(printDoc.filename.replace(/[<>:"/\\|?*\x00-\x1F]/g, "_"));
      setFormat(format);
      setShowModal(true);
      // window.open(
      //   `https://${serialnumber}.oncloud.gr/s1services/?filename=${printDoc.filename}`,
      //   "",
      //   "location=yes,scrollbars=yes,status=yes"
      // );
    } else {
      console.log("error while printing:", printDoc);
    }

    setLoading(false);
  };

  const handlePreview = () => {
    window.open(printURL, "", "location=yes,scrollbars=yes,status=yes");
  };

  const handleDownload = async () => {
    setLoading(true);

    await fetch(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      method: "POST",
      body: JSON.stringify({ url: printURL, data: { service: "download", format } }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
      });
    setShow(false);
    setLoading(false);
  };
  return (
    <Modal
      contentClassName="bg-dark text-light border-warning"
      className="crm-print-list"
      show={show}
      onHide={() => setShow(false)}
      size={!showModal ? "lg" : "md"}
      centered
    >
      {loading && <Spinner colorSpin="#ffc107" />}
      <Modal.Body className="d-flex justify-content-center align-items-center">
        {!showModal ? (
          <Form.Select className="bg-dark text-light" onChange={(e) => setFormat(e.target.value)} value={format}>
            <option value="none" disabled>
              Επιλέξτε εκτυπωτή
            </option>
            {Object.entries(outputformats ?? {}).map(([key, value], i) => {
              return (
                <option key={i} value={key}>
                  {value}
                </option>
              );
            })}
          </Form.Select>
        ) : (
          <div>Επιτυχής δημιουργία αρχείου</div>
        )}
      </Modal.Body>
      <Modal.Footer className="p-0 w-100 bg-dark shadow border-0">
        {!showModal ? (
          <ButtonGroup className="m-0 w-100">
            <Button
              variant="outline-warning"
              className="w-50 d-flex justify-content-center align-items-center rounded-0"
              onClick={() => setShow(false)}
            >
              <HiX size={25} color="var(--bs-danger)" className="me-2" />
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ fontSize: 20, color: "white" }}
              >
                Ακύρωση
              </div>
            </Button>
            <Button
              className="w-50 d-flex justify-content-center align-items-center rounded-0"
              variant="outline-warning"
              disabled={format == "none"}
              onClick={handlePrint}
            >
              <HiCheck size={25} color="var(--bs-success)" className="me-2" />
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ fontSize: 20, color: "white" }}
              >
                Εκτύπωση
              </div>
            </Button>
          </ButtonGroup>
        ) : (
          <ButtonGroup className="m-0 w-100">
            <Button
              variant="outline-warning"
              className="w-50 d-flex justify-content-center align-items-center rounded-0"
              onClick={handlePreview}
            >
              {/* <HiX size={25} color="var(--bs-danger)" className="me-2" /> */}
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ fontSize: 20, color: "white" }}
              >
                Preview
              </div>
            </Button>
            <Button
              className="w-50 d-flex justify-content-center align-items-center rounded-0"
              variant="outline-warning"
              // disabled={format == "none"}
              onClick={handleDownload}
            >
              {/* <HiCheck size={25} color="var(--bs-success)" className="me-2" /> */}
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ fontSize: 20, color: "white" }}
              >
                Download
              </div>
            </Button>
          </ButtonGroup>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  outputformats: GETOUTPUTFORMATS(state),
  cmd: GETSCREENOBJ(state)?.cmd,
  serialnumber: GETSN(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ModalPrintList);
