import React from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import { SetChanges } from "../../../../../../redux/actions/screen";
import {
  GETBUFFDATA,
  GETDATA,
  GETFORMDESIGN,
  GETNEWDATA,
  GETOBJECTSTATE,
} from "../../../../../../redux/relJobSelectors";
import { calculate } from "../../functions/calculate";

const S1CalendarDate = ({ element, objectState, data, formdesign, handleSetChange }) => {
  const [viewValue, setViewValue] = React.useState("");
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const model = formdesign.model[table].fields.filter((el) => el.name == key)[0];

  const handleChangeField = async (table, key, index, value) => {
    handleSetChange(table, key, index, value);
    model?.updates != "" && (await calculate());
  };

  //calculate Changes
  React.useEffect(() => {
    if (data?.[table]?.[0]?.[key]) {
      const value = data?.[table]?.[0]?.[key] ?? "";
      value != "" && setViewValue(dateConverter(value));
    }
  }, [data?.[table]?.[0]?.[key]]);

  return (
    <div className="s1calendardate w-100" style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}>
      <InputGroup className="crm-floating-with-icon" hidden={!element.visible}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            className={`crm-input ps-1 ${viewValue != "" ? "date-input-has-value" : ""}`}
            type="date"
            placeholder={element.caption}
            disabled={objectState === "view"}
            readOnly={element.readOnly}
            name={element.name}
            style={{ border: "0" }}
            value={viewValue}
            onClick={(e) => e.target.showPicker()}
            onChange={(e) => {
              setViewValue(e.target.value);
              handleChangeField(table, key, 0, e.target.value);
            }}
          />
        </FloatingLabel>
        {objectState != "view" && viewValue != "" && !element.readOnly && (
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={() => {
              setViewValue("");
              handleChangeField(table, key, 0, "");
            }}
          >
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
  data: { view: GETDATA(state)?.data, edit: GETBUFFDATA(state), new: GETNEWDATA(state) }[GETOBJECTSTATE(state)],
  formdesign: GETFORMDESIGN(state),
  screen: state.screen.screens[`${state.screen.currentscreen}`],
});

const mapDispatchToProps = (dispatch) => ({
  handleSetChange: (table, key, index, value) => dispatch(SetChanges(table, key, index, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1CalendarDate);

const dateConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-");
  } else {
    return value;
  }
};
