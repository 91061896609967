import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { motion } from "framer-motion";
import { IoWarningOutline } from "react-icons/io5";

const ModalNoInternet = () => {
  const [online, SetOnline] = useState(true);

  const variants = {
    open: { opacity: 1, pointerEvents: "auto" },
    closed: {
      opacity: 0,
      pointerEvents: "none",
    },
  };

  window.addEventListener("online", () => {
    SetOnline(true);
  });

  window.addEventListener("offline", () => {
    SetOnline(false);
  });

  return (
    <motion.div
      variants={variants}
      initial="closed"
      exit="closed"
      transition={{ duration: 0.5, ease: "easeInOut" }}
      animate={`${online ? "closed" : "open"}`}
      className="crm-modal-logout Overlay d-flex align-items-center justify-content-center"
    >
      <div
        className="justify-content-center
      align-items-center bg-dark rounded shadow w-100 mx-2"
        style={{ maxWidth: "600px" }}
      >
        <div className="border border-warning border-1  rounded">
          <Card className="bg-dark text-light shadow p-1 border border-warning rounded p-4">
            <Card.Body>
              <div className="text-center text-light">
                <h2>
                  <b>No Internet Connection</b>
                </h2>
                <div className="py-4">
                  <IoWarningOutline fontSize={"6rem"} />
                </div>

                <h5>Please check your internet connection</h5>
              </div>
            </Card.Body>
            {/* <Card.Footer className="d-flex justify-content-center align-items-center">
              <Button className="mt-3" onClick={() => window.location.reload()}>
                Reload
              </Button>
            </Card.Footer> */}
          </Card>
        </div>
      </div>
    </motion.div>
  );
};

export default ModalNoInternet;
