import React from "react";
import { Container } from "react-bootstrap";
import CompanyDetails from "./CompanyDetails";
import UserDetails from "./UserDetails";

const User = () => {
  return (
    <div className="crm-user-page text-light">
      <Container fluid className="mt-2">
        <legend>Στοιχεία Χρήστη</legend>
        <hr />
        <UserDetails />
      </Container>
      <Container fluid className="mt-2">
        <legend>Στοιχεία Σύνδεσης</legend>
        <hr />
        <CompanyDetails />
      </Container>
    </div>
  );
};

export default User;
