import React from "react";
import { connect } from "react-redux";
import { GETBROWSERDATA, GETBROWSERINFO } from "../../../../../redux/selectors";
import { listEditor } from "../../functions";

const Row = React.forwardRef(({ selectedRow, columns, row, AAIndex, onClick }, ref) => {
  const rowBody = (
    <div className="crm-list-data">
      <div className="crm-list-data-header">
        <span>{listEditor(columns[AAIndex + 1], row[AAIndex + 2])}</span>
        <span>{listEditor(columns[AAIndex + 2], row[AAIndex + 3])}</span>
      </div>

      {columns.map((column, y) => {
        if (y > AAIndex + 2) {
          return (
            <div key={`${y + 1}`} className="crm-list-data-detail">
              <span>{`${column.header}: `}</span>
              <span>{listEditor(column, row[y + 1])}</span>
            </div>
          );
        }
      })}
    </div>
  );

  return ref ? (
    <div
      className={`crm-browser-row${selectedRow == row[0] ? " selected-row" : ""}`}
      onClick={onClick}
      ref={ref}
      lastlast={`lastlast`}
    >
      {rowBody}
    </div>
  ) : (
    <div className={`crm-browser-row${selectedRow == row[0] ? " selected-row" : ""}`} onClick={onClick}>
      {rowBody}
    </div>
  );
});

const mapStateToProps = (state) => ({
  columns: GETBROWSERINFO(state)?.columns,
  selectedRow: GETBROWSERDATA(state)?.selectedrow,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(Row);
