import axios from "axios";
import { GETCLIENTID, GETSCREENOBJ, GETURL } from "../redux/selectors";
import store from "../redux/store";

const getWebMenu = async ({ user }) => {
  const state = store.getState();
  const url = GETURL(state);
  const clientID = GETCLIENTID(state);
  const menuname = user ? undefined : GETSCREENOBJ(state).relJob;

  return await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url,
      data: {
        service: "getWebMenu",
        clientID,
        format: 2,
        menuname,
        user,
        appID: process.env.REACT_APP_SOFTONE_APPID,
      },
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
};

export default getWebMenu;
