import React from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { CloseRelJob } from "../../../../../redux/actions/screen";
import S1CheckBox from "./S1 Inputs/S1CheckBox";
import S1Image from "./S1 Inputs/S1Image";
import S1CalendarDate from "./S1 Inputs/S1CalendarDate";
import S1TextField from "./S1 Inputs/S1TextField";
import S1WebPage from "./S1 Inputs/S1WebPage";
import S1NumberField from "./S1 Inputs/S1NumberField";
import S1TextArea from "./S1 Inputs/S1TextArea";
import S1MemoryTable from "./S1 Inputs/S1MemoryTable";
import S1Selector from "./S1 Inputs/S1Selector";
import S1DetailGrid from "../S1 DetailGrid/S1DetailGrid";
import S1DateTime from "./S1 Inputs/S1DateTime";
import S1Strings from "./S1 Inputs/S1Strings";
import S1IntField from "./S1 Inputs/S1IntField";
import S1Email from "./S1 Inputs/S1Email";
import S1Time from "./S1 Inputs/S1Time";
import S1StringsMulti from "./S1 Inputs/S1StringsMulti";
import EditButton from "./buttons/EditButton";

const FormScreen = ({ relJob, handleCloseRelJob }) => {
  var panels = {};
  const [activePanel, setActivePanel] = React.useState({});

  React.useEffect(() => {
    setActivePanel(panels);
  }, []);

  const Recursive = (data, panel) => {
    if (panel.isPanelHeader) {
      panels[`panel${panel.index}`] = 0;
      return (
        <Button
          className={`nav-link  ${activePanel[`panel${panel.index}`] === panel.i ? "active" : ""}`}
          key={`${data.sid}_${panel.i}`}
          onClick={() => setActivePanel({ ...activePanel, [`panel${panel.index}`]: panel.i })}
        >
          {data.title}
        </Button>
      );
    }
    if (panel.isPanelChild) {
      return (
        <div
          className={`tab-pane fade ${activePanel[`panel${panel.index}`] === panel.i ? "active show" : ""}`}
          role="tabpanel"
          key={`${data.sid}_${panel.i}`}
        >
          {Recursive(data.items, { isPanelHeader: false, isPanelChild: false })}
        </div>
      );
    }
    return data.map((element, i) => {
      switch (element.xtype) {
        case "s1cont":
          return (
            <div className="s1cont" style={{ alignItems: "flex-star" }} key={element.index}>
              {/* {element?.title ? (
                <div className="s1cont-title" style={{ flex: "100%" }}>
                  <legend className="my-1">{element.title}</legend>
                  <hr className="m-0 mb-1" />
                  {Recursive(
                    element.items,
                    { isPanelHeader: false, isPanelChild: false },
                  )}
                </div>
              ) : (
                Recursive(
                  element.items,
                  { isPanelHeader: false, isPanelChild: false },
                )
              )} */}

              {Recursive(element.items, {
                isPanelHeader: false,
                isPanelChild: false,
              })}
            </div>
          );
        case "container":
          return (
            <React.Fragment key={element.index}>
              {Recursive(element.items, {
                isPanelHeader: false,
                isPanelChild: false,
              })}
              {/* </div> */}
            </React.Fragment>
          );
        case "s1panel":
          return (
            <div className="s1panel" style={{ flex: `${element.flex * 100}%` }} key={element.index}>
              {element.title != "" && element.title && (
                <legend className="text-center bg-warning rounded text-dark py-2 mt-3">{element.title}</legend>
              )}
              {/* <hr className="text-light" /> */}
              {Recursive(element.items, {
                isPanelHeader: false,
                isPanelChild: false,
              })}
            </div>
          );
        case "s1tabpanel":
          return (
            <div className="s1tabpanel my-2" key={element.index}>
              <nav className="overflow-auto bg-secondary rounded">
                <div
                  className="nav nav-tabs m-2"
                  role="tablist"
                  style={{ inlineSize: "max-content", gridGap: "0.3rem" }}
                >
                  {element.items.map((item, i) =>
                    Recursive(item, {
                      isPanelHeader: true,
                      isPanelChild: false,
                      i,
                      index: element.index,
                    })
                  )}
                </div>
              </nav>
              <div className="tab-content">
                {element.items.map((item, i) =>
                  Recursive(item, {
                    isPanelHeader: false,
                    isPanelChild: true,
                    i,
                    index: element.index,
                  })
                )}
              </div>
            </div>
          );
        case "s1textarea":
          return <S1TextArea element={element} key={element.index} />;
        case "s1image":
          return <S1Image element={element} key={element.index} />;
        case "s1textfield":
          return <S1TextField element={element} key={element.index} />;
        case "s1webpage":
          return <S1WebPage element={element} key={element.index} />;
        case "s1email":
          return <S1Email element={element} key={element.index} />;
        case "s1numberfield":
          return <S1NumberField element={element} key={element.index} />;
        case "s1intfield":
          return <S1IntField element={element} key={element.index} />;
        case "s1checkbox":
          return <S1CheckBox element={element} key={element.index} />;
        case "s1memorytable":
          return <S1MemoryTable element={element} key={element.index} />;
        case "s1selector":
          return <S1Selector element={element} key={element.index} />;
        case "s1datetime":
          return <S1DateTime element={element} key={element.index} />;
        case "s1time":
          return <S1Time element={element} key={element.index} />;
        case "s1calendardate":
          return <S1CalendarDate element={element} key={element.index} />;
        case "s1strings":
          return <S1Strings element={element} key={element.index} />;
        case "s1stringsmulti":
          return <S1StringsMulti element={element} key={element.index} />;
        case "s1detailgrid":
          return <S1DetailGrid element={element} key={element.index} />;
        case "s1spacer":
          return <div className="s1sparcer" key={element.index} />;
        case "s1reldocs":
          return <div className="s1reldocs" key={element.index} />;
        default:
          return (
            <div className="bg-danger" key={element.index}>
              {element.xtype}
            </div>
          );
      }
    });
  };

  return (
    <>
      <div className="p-2">
        {Recursive(relJob.formDesign.form, {
          isPanelHeader: false,
          isPanelChild: false,
        })}
      </div>
      <div style={{ height: `${relJob.objectState == "view" ? "0px" : "86px"}` }} className="menuAnimation">
        {<EditButton />}
      </div>
    </>
  );
};

const mapStateTopProps = (state) => ({
  relJob: state.screen.relJob,
});

const mapDispatchToProps = (dispatch) => ({
  handleCloseRelJob: () => dispatch(CloseRelJob()),
});

export default connect(mapStateTopProps, mapDispatchToProps)(FormScreen);
