import React from "react";
import { Button, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { setLoading } from "../../../redux/actions/modals";
import { deleteDropBox } from "../../../redux/actions/settings";

const CloudStorage = ({ dropbox, handleDbxDisconnect, handleLoading }) => {
  const handleDbxConnect = () => {
    handleLoading(true);
    const config = {
      clientId: process.env.REACT_APP_DROPBOX_CLIENT_ID,
      clientSecret: process.env.REACT_APP_DROPBOX_CLIENT_SECRET,
    };
    var Dropbox = require("dropbox").Dropbox;
    var dbx = new Dropbox(config);
    dbx.auth
      .getAuthenticationUrl(process.env.REACT_APP_DROPBOX_REDIRECT_URL, null, "code", "offline", null, "none", false)
      .then((authUrl) => {
        window.location.href = authUrl;
      })
      .catch((er) => {
        handleLoading(false);
        console.log(er);
      });
  };

  return (
    <div className="crm-user-page text-light">
      <Container fluid className="mt-2">
        <legend>{`Dropbox ${dropbox ? ` - ${dropbox?.account?.email}` : ""}`}</legend>
        <hr />
        <Button onClick={handleDbxConnect} className="me-2" disabled={dropbox != undefined} variant="warning">
          Συνδεση Dropbox
        </Button>
        <Button disabled={dropbox == undefined} onClick={handleDbxDisconnect} variant="warning">
          Αποσύνδεση Dropbox
        </Button>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dropbox: state.settings?.dropbox,
});

const mapDispatchToProps = (dispatch) => ({
  handleLoading: (value) => dispatch(setLoading(value)),
  handleDbxDisconnect: () => dispatch(deleteDropBox()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CloudStorage);
