import React, { useEffect, useState } from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { setFilter } from "../../../../../redux/actions/screen";
import { GETBUFFDATA, GETCURRENTSCREEN, GETDATA, GETFILTERS, GETOBJECTSTATE } from "../../../../../redux/selectors";

const S1DateRange = ({ element, parentIndex, dateranges, handleSetFilters, filters }) => {
  const [viewValue, setViewValue] = useState("");
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  //For Reset
  React.useEffect(() => {
    if (filters?.reset) {
      setViewValue("");
    }
  }, [filters?.reset]);

  React.useEffect(() => {
    const getDtValue = () => {
      if (element.value != "") {
        const dtIndex = element.value.split(":")[1].slice(-2);
        const fromOrTo = element.value.split(":")[1].substring(0, 1) == "1" ? "from" : "to";
        var value = dateranges?.[`1${dtIndex}`]?.[fromOrTo];
        value && setViewValue(dateConverter(value));
      }
    };
    getDtValue();

    if (filters?.text) {
      var value = "";
      const araryFilters = filters.text.split("&");
      araryFilters.every((x) => {
        if (x.split("=")[0].split(".")[0] == table && x.split("=")[0].split(".")[1] == key) {
          value = x.split("=")[1] == "*" ? "" : x.split("=")[1];
          if (value != "") {
            setViewValue(dateConverter(value));
            handleSetFilters(table, key, value == 1);
          } else {
            setViewValue("");
          }
          return false;
        } else {
          return true;
        }
      });
    }
  }, []);

  React.useEffect(() => {
    if (filters?.daterange?.[parentIndex]) {
      const dtIndex = filters?.daterange?.[parentIndex].slice(-2);
      const fromOrTo = element.value.split(":")[1].substring(0, 1) == "1" ? "from" : "to";
      var value = dateranges?.[`1${dtIndex}`]?.[fromOrTo];
      handleChange(value == "1899-12-30" ? "" : value);
      setViewValue(value == "1899-12-30" ? "" : dateConverter(value));
    }
  }, [filters?.daterange?.[parentIndex]]);

  const handleChange = (val) => {
    setViewValue(val);
    const table = element.name.split(".")[0];
    const key = element.name.split(".")[1];
    const valueToSet = val == "" ? null : val;
    var nFilters = {};
    if (filters) {
      //Εαν Υπάρχει έστω κι ένα φίλτρο
      nFilters = filters;
      if (nFilters.buffdata) {
        nFilters.buffdata[table] = {
          ...filters.buffdata[table],
          [key]: valueToSet,
        };
      } else {
        nFilters.buffdata = { [table]: { [key]: valueToSet } };
      }
    } else {
      //Εαν δεν υπάρχει ούτε σαν property στο state
      nFilters.buffdata = { [table]: { [key]: valueToSet } };
    }
    console.log("before");
    handleSetFilters(nFilters);
    console.log("after");
  };

  return (
    <div
      className={`s1daterange ${element.visible ? "" : "s1-hidden"} w-100`}
      style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}
    >
      <InputGroup className="crm-floating-with-icon" style={{ borderBottom: "none" }}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            className="crm-input ps-1"
            type="date"
            placeholder={element.caption}
            disabled={element.readOnly}
            readOnly={element.readOnly}
            name={element.name}
            style={{ border: "0" }}
            value={viewValue}
            onChange={(e) => handleChange(e.target.value.replaceAll("&", "_"))}
            // onBlur={handleChangeField}
          />
        </FloatingLabel>
        {/* {viewValue != "" && !element.readOnly && (
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={() => handleChange("")}
          >
            <TiDeleteOutline size="1.75rem" />
          </div>
        )} */}
      </InputGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
  data: GETDATA(state)?.data,
  data_buffer: GETBUFFDATA(state),
  screen: state.screen.screens[`${state.screen.currentscreen}`],
  currentscreen: GETCURRENTSCREEN(state),
  dateranges: state.systemparams.dateranges,
  filters: GETFILTERS(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetFilters: (value) => dispatch(setFilter(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1DateRange);

const dateConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-");
  } else {
    return value;
  }
};
