import React from "react";
import { Button, Modal } from "react-bootstrap";

const ErrorModal = ({ show, handleClose, text }) => {
  return (
    <Modal
      show={show}
      centered
      onHide={() => handleClose(false)}
      backdrop="static"
      keyboard={false}
      size="sm"
      dialogClassName="Login-error-Modal"
    >
      <Modal.Body className="d-flex align-items-center justify-content-center">
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
      </Modal.Body>
      <Modal.Footer className="p-1 d-flex align-items-center justify-content-center">
        <Button variant="primary" onClick={() => handleClose(false)}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
