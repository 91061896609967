import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { ClearSession } from "../../../redux/actions/session";
import { setLoading } from "../../../redux/actions/modals";

const ModalBlocked = ({ blcdate, handleLogOut, loginid, handleLoading }) => {
  const [isBlocked, setIsBlocked] = useState(false);
  const [blcdateDD, setBlcDate] = useState();

  useEffect(() => {
    const today = new Date(`${formatUSDate(new Date())}`);
    const blckdate = new Date(blcdate);
    setBlcDate(formatDate(blckdate));
    setIsBlocked(blckdate < today);
  }, []);

  const logout = async () => {
    setIsBlocked(false);
    try {
      handleLoading(true);
      await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/logout`, {
        key: loginid,
      });
      handleLoading(false);
      handleLogOut();
    } catch (error) {
      handleLoading(false);
      console.log(error.message);
    }
  };
  return (
    <Modal
      show={isBlocked}
      centered
      backdrop="static"
      keyboard={false}
      size="lg"
      //   dialogClassName="Login-error-Modal"
    >
      <Modal.Body className="d-flex align-items-center justify-content-center">
        <div>
          Η άδεια χρήσης του λογισμικού σας έληξε.
          <br />
          Kαλέστε στο: <a href="tel:2165005060">216 5005060</a> για ανανέωση.
          <br />
          Λήξη: {blcdateDD}
        </div>
      </Modal.Body>
      <Modal.Footer className="p-1 d-flex justify-content-center align-items-center">
        <Button variant="primary" onClick={logout}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  blcdate: state.session.blcdate,
  loginid: state.session.loginid,
});

const mapDispatchToProps = (dispatch) => ({
  handleLogOut: () => dispatch(ClearSession()),
  handleLoading: (value) => dispatch(setLoading(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalBlocked);

const formatDate = (date) => {
  return [padTo2Digits(date.getDate()), padTo2Digits(date.getMonth() + 1), date.getFullYear()].join("/");
};

const formatUSDate = (date) => {
  return [padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate()), date.getFullYear()].join("/");
};

const padTo2Digits = (num) => {
  return num.toString().padStart(2, "0");
};
