import axios from "axios";
import store from "../../redux/store";
import { GETURL, GETCLIENTID } from "../../redux/relJobSelectors";

const getBrowserData = async ({ reqID, start }) => {
  const state = store.getState();
  const url = GETURL(state);
  const clientID = GETCLIENTID(state);

  return await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url,
      data: {
        service: "getBrowserData",
        clientID,
        reqID,
        start,
        limit: process.env.REACT_APP_GETBROWSERDATA_LIMIT,
        appID: process.env.REACT_APP_SOFTONE_APPID,
      },
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
};

export default getBrowserData;
