export const SetShowRoom = (value) => ({
  type: "showRoom/set",
  value,
});

export const SetBasketItemQty = (item, obj) => ({
  type: "showRoom/itemQty",
  item,
  obj,
});
