const systemparams = (state = JSON.parse(localStorage.getItem("systemparams")), action) => {
  switch (action.type) {
    case "general/setSystemParams":
      localStorage.setItem("systemparams", JSON.stringify(action.value));
      return action.value;
    case "general/setOutputFormats":
      return { ...state, outputformats: action.value };
    case "session/clear":
      localStorage.setItem("systemparams", null);
      return null;
    default:
      return state;
  }
};

export default systemparams;
