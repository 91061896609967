import React from "react";
import { connect } from "react-redux";
import { FiCameraOff } from "react-icons/fi";
import { GETDATA, GETOBJECTSTATE, GETSN } from "../../../../../../redux/selectors";

const S1Image = ({ element, data, serialnumber }) => {
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const [source, setSource] = React.useState(undefined);

  React.useEffect(() => {
    if (data?.[table].length > 0) {
      const src = data?.[table]?.[0]?.[key];
      setSource(src);
    }
  }, [data?.[table]?.[0]?.[key]]);

  return (
    <div
      className="s1image d-flex align-items-start justify-content-center pt-2"
      key={element.index}
      style={{
        flex: `${element.flex * 100}%`,
      }}
    >
      <div
        style={{
          border: "1px dotted #41464b",
        }}
        className="shadow"
      >
        {source ? (
          <div
            style={{
              backgroundImage: `url("https://${serialnumber}.oncloud.gr/s1services/?filename=${source}")`,
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundColor: "transparent",
              height: "250px",
              width: "250px",
            }}
          />
        ) : (
          <div
            style={{
              // backgroundImage: <FiCamera />,
              backgroundPosition: "50% 50%",
              backgrounRepeat: "no-repeat",
              backgroundColor: "transparent",
              height: "250px",
              width: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              color: "white",
            }}
          >
            <FiCameraOff size={140} />
            <span>NO IMAGE</span>
          </div>

          // <div
          // style={{
          //   backgroundImage: <FiCamera />,
          //   backgroundPosition: "50% 50%",
          //   backgrounRepeat: "no-repeat",
          //   backgroundColor: "transparent",
          //   height: "250px",
          //   width: "250px",
          // }}
          // />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: GETDATA(state)?.data,
  serialnumber: GETSN(state),
  objectState: GETOBJECTSTATE(state),
});

export default connect(mapStateToProps)(S1Image);
