import React from "react";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import {
  SetBrowserData,
  SetBrowserInfo,
  SetFormDesign,
  setGetData,
  SetPageY,
  SetScreenState,
  SetSelectedRecord,
} from "../../../../../redux/actions/screen";
import { setLoading } from "../../../../../redux/actions/modals";
import PullToRefresh from "react-simple-pull-to-refresh";
import NoData from "../NoData";
import {
  GETACCESSRIGHTS,
  GETBROWSERDATA,
  GETBROWSERINFO,
  GETCLIENTID,
  GETCURRENTSCREEN,
  GETFILTERS,
  GETFORMDESIGN,
  GETSCREENOBJ,
  GETSCREENSTATE,
  GETSN,
} from "../../../../../redux/selectors";
import PullingContent from "./PullingContent";
import RefreshingContent from "./RefreshingContent";
import Row from "./Row";
import useFetch from "../../../../../hooks/useFetch";
import RowWithImage from "./RowWithImage";
import getBrowserInfo from "../../../../../services/getBrowserInfo";
import getBrowserData from "../../../../../services/getBrowserData";
import getFormDesign from "../../../../../services/getFormDesign";
import getData from "../../../../../services/getData";

const List = ({
  MainPanelRef,
  screen,
  screenState,
  browserinfo,
  browserdata,
  formdesign,
  accessRights,
  handleSetFormDesign,
  handleSetData,
  handleSetScreenState,
  handleLoading,
  handleSetGetBrowserInfo,
  handleSetGetBrowserData,
  handleSetSelectedRow,
  handleSetPageY,
}) => {
  const [AAIndex, setAAIndex] = React.useState(-1);
  const [start, setStart] = React.useState(0);
  const [hasImage, setHasImage] = React.useState(false);
  const [imageIndex, setImageIndex] = React.useState(-1);
  const { isLoading, isError, error, hasNextPage } = useFetch({ start });

  const listRef = React.useRef();
  const intObserver = React.useRef();
  const lastRowRef = React.useCallback(
    (row) => {
      if (isLoading) return;
      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((rows) => {
        if (rows[0].isIntersecting && hasNextPage && screenState == "list") {
          browserinfo?.totalcount > parseInt(process.env.REACT_APP_GETBROWSERDATA_LIMIT) &&
            setStart((prev) => prev + 1);
        }
      });

      if (row) intObserver.current.observe(row);
    },
    [isLoading, hasNextPage]
  );

  const handleRefresh = async () => {
    handleLoading(true);
    const browserInfo = await getBrowserInfo();
    if (browserInfo.success) {
      console.log("New ReqID: ", browserInfo.reqID);
      browserInfo.upddate = new Date();
      handleSetGetBrowserInfo(browserInfo);
      if (browserInfo.totalcount > 0) {
        const browserData = await getBrowserData({
          reqID: browserInfo.reqID,
          start: 0,
        });
        if (browserData.success) {
          setStart(0);
          handleSetGetBrowserData(browserData);
          handleLoading(false);
        } else {
          handleLoading(false);
          console.log(browserData);
        }
      } else {
        handleLoading(false);
        console.log("no data");
      }
    } else {
      handleLoading(false);
      console.log(browserInfo);
    }
  };

  React.useEffect(() => {
    const Ccolumns = browserinfo?.columns;
    Ccolumns &&
      Ccolumns.map((element, index) => {
        if (element.dataIndex.includes("-")) {
          setAAIndex(index);
        }
      });

    if (browserinfo?.fields) {
      for (var i = 0; i < browserinfo.fields.length; i++) {
        if (browserinfo.fields[i].type == "image") {
          setHasImage(true);
          setImageIndex(i);
          break;
        }
      }
    }
  }, [browserinfo?.reqID, screenState]);

  //When Return to List from form , scroll to Selected Record
  React.useEffect(() => {
    if (!isNaN(screen.pageY) && screenState == "list") {
      MainPanelRef.current.scrollTo({ top: screen.pageY, left: 0 });
    }
  }, [screenState]);

  //When Return to List from form , scroll to Selected Record
  React.useEffect(() => {
    setStart(0);
  }, [browserinfo?.upddate]);

  const handleRowClick = async (str) => {
    var locateinfo = "";
    var FormDesign = JSON.parse(JSON.stringify(formdesign ?? {}));
    var noError = true;
    const id = str.substring(str.indexOf(";") + 1);
    handleSetSelectedRow(str);
    if (browserinfo.browserOnly != true) {
      if (accessRights?.browserOnly != true) {
        handleSetPageY(MainPanelRef.current.scrollTop);
        handleLoading(true);
        if (!FormDesign?.success) {
          const fetchFormDesign = await getFormDesign();
          if (fetchFormDesign.success) {
            handleSetFormDesign(fetchFormDesign);
            handleSetScreenState("form");
            MainPanelRef.current.scrollTo({ top: 0, left: 0 });
            FormDesign = fetchFormDesign;
          } else {
            noError = false;
            handleLoading(false);
            console.log(fetchFormDesign);
          }
        } else {
          handleSetScreenState("form");
          MainPanelRef.current.scrollTo({ top: 0, left: 0 });
        }

        if (noError) {
          Object.entries(FormDesign.model).forEach(([key, value], index) => {
            locateinfo += index == 0 ? `${key}:` : `;${key}:`;
            if (key == "XTRDOCDATA") locateinfo += "NAME,";
            value.fields.map((item) => {
              locateinfo += `${item.name},`;
            });
          });

          const data = await getData({ key: id, locateinfo });
          if (data.success) {
            handleSetData(data);
            handleLoading(false);
          } else {
            handleLoading(false);
            console.log(data);
          }
        }
      }
    }
  };

  return (
    <div
      id="crm-list"
      ref={listRef}
      className={`${browserinfo?.totalcount > 0 ? "" : "h-100"}`}
      style={{ display: screenState === "list" ? "block" : "none" }}
    >
      <PullToRefresh
        onRefresh={handleRefresh}
        maxPullDownDistance={130}
        pullDownThreshold={120}
        pullingContent={<PullingContent />}
        refreshingContent={<RefreshingContent />}
      >
        {browserinfo?.totalcount > 0 && browserdata ? (
          <>
            <Container fluid className="p-0">
              <hr className="m-0" />
              {browserdata?.rows &&
                browserdata.rows.map((item, i) => {
                  if (browserdata.rows.length === i + 1) {
                    return hasImage ? (
                      <RowWithImage
                        ref={lastRowRef}
                        key={item[0]}
                        row={item}
                        AAIndex={AAIndex}
                        onClick={() => handleRowClick(item[0])}
                        imageIndex={imageIndex}
                      />
                    ) : (
                      <Row
                        ref={lastRowRef}
                        key={item[0]}
                        row={item}
                        AAIndex={AAIndex}
                        onClick={() => handleRowClick(item[0])}
                        imageIndex={imageIndex}
                      />
                    );
                  }

                  return hasImage ? (
                    <RowWithImage
                      key={item[0]}
                      row={item}
                      AAIndex={AAIndex}
                      onClick={() => handleRowClick(item[0])}
                      hasImage={hasImage}
                      imageIndex={imageIndex}
                    />
                  ) : (
                    <Row
                      key={item[0]}
                      row={item}
                      AAIndex={AAIndex}
                      onClick={() => handleRowClick(item[0])}
                      hasImage={hasImage}
                    />
                  );
                })}
              {isLoading && start > 0 && (
                <div className="bouncing-loader ">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
            </Container>
            {!hasNextPage && (
              <>
                <div className="d-flex justify-content-center align-items-center text-center mt-1">
                  <div className="bg-secondary text-light w-100 py-2">Δεν βρέθηκαν άλλες εγγραφές</div>
                </div>
                {MainPanelRef.current.scrollTop > 30 && (
                  <div className="d-flex justify-content-center align-items-center text-center">
                    <div
                      className="text-primary w-100 py-2"
                      onClick={() =>
                        MainPanelRef.current.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        })
                      }
                    >
                      Back To Top
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <NoData />
        )}
      </PullToRefresh>
    </div>
  );
};

const mapStateToProps = (state) => ({
  serialnumber: GETSN(state),
  clientID: GETCLIENTID(state),
  currentscreen: GETCURRENTSCREEN(state),
  screen: GETSCREENOBJ(state),
  screenState: GETSCREENSTATE(state),
  filters: GETFILTERS(state),
  browserinfo: GETBROWSERINFO(state),
  browserdata: GETBROWSERDATA(state),
  formdesign: GETFORMDESIGN(state),
  accessRights: GETACCESSRIGHTS(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetFormDesign: (index) => dispatch(SetFormDesign(index)),
  handleSetData: (index) => dispatch(setGetData(index)),
  handleSetScreenState: (index) => dispatch(SetScreenState(index)),
  handleSetGetBrowserInfo: (value) => dispatch(SetBrowserInfo(value)),
  handleSetGetBrowserData: (value) => dispatch(SetBrowserData(value)),
  handleLoading: (value) => dispatch(setLoading(value)),
  handleSetSelectedRow: (value) => dispatch(SetSelectedRecord(value)),
  handleSetPageY: (value) => dispatch(SetPageY(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
