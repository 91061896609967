const settings = (state = JSON.parse(localStorage.getItem("settings")), action) => {
  switch (action.type) {
    case "settings/setDropBox":
      var settings = localStorage.getItem("settings") || "{}";
      settings = { ...JSON.parse(settings), dropbox: action.value };
      localStorage.setItem("settings", JSON.stringify(settings));
      return { ...state, dropbox: action.value };
    case "settings/deleteDropBox":
      var settings = localStorage.getItem("settings");
      settings = { ...JSON.parse(settings), dropbox: undefined };
      localStorage.setItem("settings", JSON.stringify(settings));
      return { ...state, dropbox: undefined };
    default:
      return state;
  }
};

export default settings;
