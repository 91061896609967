import React from "react";
import { Container, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { gadgetEditor } from "../../functions";

const Category1Data = ({ gadget, property }) => {
  return (
    <Container fluid className="p-0 h-100 overflow-auto bg-dark">
      <Table striped bordered hover variant="dark">
        <thead style={{ position: "sticky", top: 0 }}>
          <tr>
            {gadget[property]?.data?.structure.map((header, index) => (
              <th className=" border border-light text-nowrap overflow-hidden" key={index}>
                {header.caption}
              </th>
            ))}
          </tr>
          <tr>
            {gadget[property]?.data?.structure.map((header, index) => {
              switch (header.summary) {
                case "":
                  return <th className="border border-light" key={index} />;
                case "sum":
                  return (
                    <th className="border border-light" key={index}>
                      {gadgetEditor(
                        "float",
                        gadget[property].gadgetdata.rows.reduce(
                          (accumulator, currentValue) =>
                            currentValue[header.name] != "" && accumulator + parseFloat(currentValue[header.name]),
                          0
                        )
                      )}
                    </th>
                  );
                default:
                  return (
                    <th className="border border-light" key={index}>
                      {header.summary}
                    </th>
                  );

                  break;
              }
            })}
          </tr>
        </thead>

        <tbody>
          {gadget[property].gadgetdata &&
            gadget[property].gadgetdata.rows.map((row, Findex) => {
              return (
                <tr key={Findex}>
                  {Object.keys(row).map((columns, Sindex) => (
                    <td key={Sindex}>
                      {gadgetEditor(
                        gadget[property]?.data?.structure?.[Sindex]?.type,
                        row[gadget[property]?.data?.structure?.[Sindex]?.name]
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  gadget: state.gadget.gadgets,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Category1Data);
