import React from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { GETACCESSRIGHTS, GETFORMDESIGN, GETOBJECTSTATE } from "../../../../../redux/selectors";
import EditButton from "../buttons/EditButton";
import S1Strings from "./S1 Inputs/S1Strings";
import S1TextField from "./S1 Inputs/S1TextField";
import S1WebPage from "./S1 Inputs/S1WebPage";
import S1NumberField from "./S1 Inputs/S1NumberField";
import S1CheckBox from "./S1 Inputs/S1CheckBox";
import S1Selector from "./S1 Inputs/S1Selector";
import S1Email from "./S1 Inputs/S1Email";
import S1MemoryTable from "./S1 Inputs/S1MemoryTable";
import S1TextArea from "./S1 Inputs/S1TextArea";
import S1DateTime from "./S1 Inputs/S1DateTime";
import S1CalendarDate from "./S1 Inputs/S1CalendarDate";
import S1DetailGrid from "../S1 DetailGrid/S1DetailGrid";
import S1Image from "./S1 Inputs/S1Image";
import S1StringsMulti from "./S1 Inputs/S1StringsMulti";
import S1Time from "./S1 Inputs/S1Time";
import S1IntField from "./S1 Inputs/S1IntField";
import RelJob from "../../rel Job/RelJob";
import { GETSCREENOBJ } from "../../../../../redux/selectors";
import S1RelDocs from "../S1 DetailGrid/S1 Inputs/s1 rel docs/S1RelDocs";
import { SetCheckInOut } from "../../../../../redux/actions/screen";
import S1SubForm from "./S1 Inputs/S1SubForm";

function FormScreen({ objectState, screen, formdesign, accessrights, MainPanelRef, isOnRelJob, handleSetCheckInOut }) {
  var panels = {};
  const [activePanel, setActivePanel] = React.useState({});
  const [keyboard, setKeyBoard] = React.useState(1);

  React.useEffect(() => {
    setActivePanel(panels);
    if (screen?.reljobmenu) {
      Object.keys(screen.reljobmenu).map((key) => {
        const element = screen.reljobmenu[key];
        if ((element.command.split(":")[0] ?? "").toLowerCase() == "_checkin") {
          const checkIn = element.command.split(";")[0].substring(9, element.command.split(";")[0].length);
          handleSetCheckInOut("checkIn", checkIn);
        }
        if ((element.command.split(":")[0] ?? "").toLowerCase() == "_checkout") {
          const checkIn = element.command.split(";")[0].substring(10, element.command.split(";")[0].length);
          handleSetCheckInOut("checkOut", checkIn);
        }
      });
    }
    // const body = document.body;
    // const ff = () => window.scrollTo(0, 1);
    // const obs = new ResizeObserver(ff);

    // obs.observe(body);
    // return () => {
    //   console.log("efyga");
    //   obs.disconnect();
    // };
  }, []);

  const Recursive = (data, panel) => {
    if (panel.isPanelHeader) {
      panels[`panel${panel.index}`] = 0;
      return (
        <Button
          className={`nav-link  ${activePanel[`panel${panel.index}`] === panel.i ? "active" : ""}`}
          key={`${data.sid}_${panel.i}`}
          onClick={() => setActivePanel({ ...activePanel, [`panel${panel.index}`]: panel.i })}
        >
          {data.title}
        </Button>
      );
    }
    if (panel.isPanelChild) {
      return (
        <div
          className={`tab-pane fade ${activePanel[`panel${panel.index}`] === panel.i ? "active show" : ""}`}
          role="tabpanel"
          key={`${data.sid}_${panel.i}`}
        >
          {Recursive(data.items, { isPanelHeader: false, isPanelChild: false })}
        </div>
      );
    }
    return data.map((element, i) => {
      switch (element.xtype) {
        case "s1cont":
          return (
            <div className="s1cont" style={{ alignItems: "flex-star" }} key={element.index}>
              {/* {element?.title ? (
                <div className="s1cont-title" style={{ flex: "100%" }}>
                  <legend className="my-1">{element.title}</legend>
                  <hr className="m-0 mb-1" />
                  {Recursive(
                    element.items,
                    { isPanelHeader: false, isPanelChild: false },
                  )}
                </div>
              ) : (
                Recursive(
                  element.items,
                  { isPanelHeader: false, isPanelChild: false },
                )
              )} */}

              {Recursive(element.items, {
                isPanelHeader: false,
                isPanelChild: false,
              })}
            </div>
          );
        case "container":
          return (
            <React.Fragment key={element.index}>
              {Recursive(element.items, {
                isPanelHeader: false,
                isPanelChild: false,
              })}
              {/* </div> */}
            </React.Fragment>
          );
        case "s1panel":
          return (
            <div className="s1panel" style={{ flex: `${element.flex * 100}%` }} key={element.index}>
              {element.title != "" && element.title && (
                <legend className="text-center bg-warning rounded text-dark py-2 mt-3">{element.title}</legend>
              )}
              {/* <hr className="text-light" /> */}
              {Recursive(element.items, {
                isPanelHeader: false,
                isPanelChild: false,
              })}
            </div>
          );
        case "s1tabpanel":
          return (
            <div className="s1tabpanel my-2" key={element.index}>
              <nav className="overflow-auto bg-secondary rounded">
                <div
                  className="nav nav-tabs m-2"
                  role="tablist"
                  style={{ inlineSize: "max-content", gridGap: "0.3rem" }}
                >
                  {element.items.map((item, i) =>
                    Recursive(item, {
                      isPanelHeader: true,
                      isPanelChild: false,
                      i,
                      index: element.index,
                    })
                  )}
                </div>
              </nav>
              <div className="tab-content">
                {element.items.map((item, i) =>
                  Recursive(item, {
                    isPanelHeader: false,
                    isPanelChild: true,
                    i,
                    index: element.index,
                  })
                )}
              </div>
            </div>
          );
        case "s1textarea":
          return (
            // <div
            //   className="s1textarea w-100"
            //   style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}
            //   key={element.index}
            // >
            //   <legend>{element.caption}</legend>
            //   <hr />

            //   <FloatingLabel
            //     controlId={element.index}
            //     label={element.caption}
            //     hidden={!element.visible}
            //     style={{ whiteSpace: "nowrap" }}
            //   >
            //     <Form.Control
            //       type="textarea"
            //       placeholder={element.caption}
            //       disabled={element.readOnly || objectState == "view"}
            //       name={element.name}
            //       as="textarea"
            //       rows={4}
            //       style={{ height: "auto" }}
            //     />
            //   </FloatingLabel>
            // </div>
            <S1TextArea element={element} key={element.index} />
          );
        case "s1image":
          return <S1Image element={element} key={element.index} />;
        case "s1textfield":
          return <S1TextField element={element} key={element.index} />;
        case "s1webpage":
          return <S1WebPage element={element} key={element.index} />;
        case "s1email":
          return <S1Email element={element} key={element.index} />;
        case "s1numberfield":
          return <S1NumberField element={element} key={element.index} />;
        case "s1intfield":
          return <S1IntField element={element} key={element.index} />;
        case "s1checkbox":
          return <S1CheckBox element={element} key={element.index} />;
        case "s1memorytable":
          return <S1MemoryTable element={element} key={element.index} />;
        case "s1selector":
          return <S1Selector element={element} key={element.index} />;
        case "s1datetime":
          return <S1DateTime element={element} key={element.index} />;
        case "s1time":
          return <S1Time element={element} key={element.index} />;
        case "s1calendardate":
          return <S1CalendarDate element={element} key={element.index} />;
        case "s1strings":
          return <S1Strings element={element} key={element.index} />;
        case "s1stringsmulti":
          return <S1StringsMulti element={element} key={element.index} />;
        case "s1detailgrid":
          return <S1DetailGrid element={element} key={element.index} />;
        case "s1spacer":
          return <div className="s1sparcer" key={element.index} />;
        case "s1reldocs":
          return <S1RelDocs element={element} key={element.index} />;
        case "s1subform":
          return <S1SubForm element={element} key={element.index} />;
        default:
          return (
            <div className="bg-danger" key={element.index}>
              {element.xtype}
            </div>
          );
      }
    });
  };

  //When Return to Form from RelJob , scroll to Selected Record
  React.useEffect(() => {
    if (!isOnRelJob) {
      if (!isNaN(screen?.formPageY)) {
        console.log("Twra girisa apo relJob");
        MainPanelRef.current.scrollTo({ top: screen.formPageY, left: 0 });
      }
    }
  }, [isOnRelJob]);

  return (
    <div className="crm-form-screnn h-100">
      {isOnRelJob && <RelJob />}
      <div className="p-2" hidden={isOnRelJob}>
        {Recursive(formdesign.form, {
          isPanelHeader: false,
          isPanelChild: false,
        })}
      </div>

      <div style={{ height: `${objectState == "view" ? "0px" : "86px"}` }} className="menuAnimation">
        {!isOnRelJob && accessrights?.update != false && <EditButton />}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  formdesign: GETFORMDESIGN(state),
  objectState: GETOBJECTSTATE(state),
  accessrights: GETACCESSRIGHTS(state),
  isOnRelJob: state.screen.isOnRelJob,
  relJob: state.screen.relJob,
  screen: GETSCREENOBJ(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetCheckInOut: (type, value) => dispatch(SetCheckInOut(type, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormScreen);
