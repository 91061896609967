import React, { useEffect, useState } from "react";
import { ButtonGroup, Modal, Button } from "react-bootstrap";

const ModalSelectAuth = ({ openModal, setOpenModal, setCurrentVal, selected, data, locate, change }) => {
  const [startHeight, setStartHeight] = useState(0);

  useEffect(() => {
    if (openModal) {
      const height = document.getElementById("erp-select");
      height && setStartHeight(height.offsetHeight);
      const container = document.getElementById("carousel");
      [].slice.call(container.children).forEach(function (ele) {
        if (ele.attributes.keyvalue.value == selected.code) {
          container.scrollTo(0, ele.offsetTop - ele.offsetHeight);
        }
      });
    }
  });

  const handleSelect = () => {
    const container = document.getElementById("carousel");
    const line = document.getElementById("carousel-select-rowline");
    [].slice.call(container.children).forEach(function (ele) {
      var top = line.getBoundingClientRect().top;
      var bottom = line.getBoundingClientRect().bottom;
      var eleTop = ele.getBoundingClientRect().top;
      var eleBottom = ele.getBoundingClientRect().bottom;
      if (eleBottom < bottom && eleTop > top) {
        setOpenModal(false);
        setCurrentVal({
          name: ele.innerText,
          code: ele.attributes.keyvalue.value,
        });
        change({
          name: ele.innerText,
          code: ele.attributes.keyvalue.value,
        });
      }
    });
  };

  return (
    <Modal
      show={openModal}
      fullscreen
      className="d-flex align-items-end"
      dialogClassName="login-selects"
      contentClassName="login-selects-content rounded overflow-hidden"
      animationDirection="left"
    >
      <Modal.Body id="erp-select" className="p-0">
        <div
          className="position-absolute"
          id="carousel-select-rowline"
          style={{
            width: "100%",
            height: "40%",
            top: "30%",
            background: "var(--bs-gray-400)",
          }}
        ></div>
        <div id="carousel" style={{ padding: `${startHeight / 3}px 0px` }} className="snap vertical">
          {data.map((item, index) => {
            if (locate == "company") {
              return (
                <div className="snap-item" key={index} keyvalue={item.COMPANY}>
                  {item.COMPANYNAME}
                </div>
              );
            } else if (locate == "branch") {
              return (
                <div className="snap-item" key={index} keyvalue={item.BRANCH}>
                  {item.BRANCHNAME}
                </div>
              );
            } else if (locate == "user") {
              return (
                <div className="snap-item" key={index} keyvalue={item.REFID}>
                  {item.REFIDNAME}
                </div>
              );
            }
          })}
        </div>
      </Modal.Body>

      <Modal.Footer className="w-100 bg-dark shadow p-0">
        <ButtonGroup className="w-100 m-0">
          <Button
            variant="secondary"
            className="w-50 p-0"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Ακύρωση
          </Button>
          <Button
            className="w-50"
            // variant="warning"
            onClick={() => {
              handleSelect();
            }}
          >
            Εφαρμογή
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSelectAuth;
