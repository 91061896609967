import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import App from "../App";
import Login from "../screens/Login";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { AnimatePresence } from "framer-motion";
import AuthenticateRoute from "./AuthenticateRoute";
import Authenticate from "../screens/Authenticate";
import { connect } from "react-redux";
import { setComesFromURL } from "../redux/actions/comesFromURL";
import Auth from "../components/pages/Auth";

const AppRoutes = ({ handleComsFromURL }) => {
  const currentUrl = window.location.href;
  const searchParams = new URLSearchParams(currentUrl.split("?")[1]);
  const cmd = searchParams.get("cmd");
  const object = searchParams.get("object");
  const list = searchParams.get("list");
  const form = searchParams.get("form");
  const id = searchParams.get("id");

  React.useLayoutEffect(() => {
    if (cmd && object && form && list && id) {
      handleComsFromURL({
        cmd,
        object,
        list,
        form,
        id,
        searchParams: window.location.search,
      });
    }
  }, []);

  return (
    <Router>
      <AnimatePresence>
        <Routes>
          <Route exact path="/" element={<PrivateRoute />}>
            <Route exact path="/" element={<App />} />
          </Route>
          <Route exact path="/auth/:type/:provider" element={<PrivateRoute />}>
            <Route exact path="/auth/:type/:provider" element={<Auth />} />
          </Route>
          <Route path="/login" element={<PublicRoute />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route path="/authenticate" element={<AuthenticateRoute />}>
            <Route path="/authenticate" element={<Authenticate />} />
          </Route>
        </Routes>
      </AnimatePresence>
    </Router>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  handleComsFromURL: (params) => dispatch(setComesFromURL(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
