import React from "react";
import { BsFileEarmarkMedical, BsReverseLayoutTextWindowReverse } from "react-icons/bs";
import { GiGearHammer } from "react-icons/gi";
import { connect } from "react-redux";
import getBrowserInfo from "../../../../../services/relJob/getBrowserInfo";
import getFormDesign from "../../../../../services/relJob/getFormDesign";
import prepareNew from "../../../../../services/relJob/prepareNew";
import { InitRelJob, setGetData } from "../../../../../redux/actions/screen";
import {
  GETDATA,
  GETFORMDESIGN,
  GETRELJOBMENU,
  GETSCREENOBJ,
  GETSN,
  GETURL,
  GETCLIENTID,
} from "../../../../../redux/selectors";
import {
  OpenRelJob,
  setAccessRights,
  setFormDesign,
  SetFormPageY,
  setPrepareNew,
  setReportData,
  setReportError,
  SetReportInfo,
  setReportNoData,
} from "../../../../../redux/actions/relJob";
import { setError, setLoading } from "../../../../../redux/actions/modals";
import getReportInfo from "../../../../../services/relJob/getReportInfo";
import getReportData from "../../../../../services/relJob/getReportData";
import axios from "axios";

const RelJoob = ({
  screen,
  serialnumber,
  data,
  clientID,
  url,
  setShow,
  formdesign,
  reljobmenu,
  handleInitRelJob,
  handleOpenRelJob,
  handleSetLoading,
  // handleSetAccessRights,
  handleSetFormDesign,
  handleSetPrepareNew,
  handleSetFormPageY,
  handleSetGetReportInfo,
  handleSetGetReportData,
  handleSetReportNodata,
  handleSetReportError,
  handleSetGetData,
  handleSetError,
}) => {
  const handleClickForRelJob = async (relJob) => {
    setShow(false);
    handleSetLoading(true);
    const EditorArguments = Array.from(relJob.command.matchAll(/\[([^\][]*)]/g), (x) => x[1]);
    var command = JSON.parse(JSON.stringify(relJob.command));
    EditorArguments.forEach((y) => {
      var splitted = y.split(".");
      var tableName = splitted[0];
      var fieldName = splitted[1];
      var replaceValue;
      if (data.data[tableName][0].hasOwnProperty(fieldName)) {
        replaceValue = data.data[tableName][0][fieldName].split("|")[0];
      } else {
        const replaceModel = formdesign.model[tableName].fields.filter((el) => el.name == fieldName)[0];
        if (replaceModel?.editType == "Selector") {
          replaceValue = 0;
        } else {
          replaceValue = "";
        }
      }

      command = command.replaceAll(`[${y}]`, replaceValue);
    });
    const JobScreen = {
      ...JSON.parse(JSON.stringify(relJob)),
      command,
    };
    const obj = JSON.parse(JSON.stringify(relJob));
    let params = new URLSearchParams(JobScreen.command);
    obj.screenState = params.get("ae") == 2 ? "form" : "list";
    obj.objectState = "view";
    obj.list = params.get("list");
    obj.form = params.get("form");
    obj.forcedValues = params.get("fv");
    obj.forcedFilters = params.get("ff");
    obj.autoExec = params.get("ae");
    obj.command = command;
    handleInitRelJob(obj);
    switch (obj?.cmd.toLowerCase()) {
      case "editmaster":
        if (obj.autoExec == 2) {
          //OpenForm
          // const accessRights = await getAccesRights();
          // if (accessRights.success) {
          // console.log(accessRights);
          // handleSetAccessRights(accessRights);
          // } else {
          //   // setHasError(true);
          //   // setErrorMessage(accessRights.error);
          //   console.log(accessRights.error);
          //   return;
          // }
          const formDesign = await getFormDesign();
          if (formDesign.success) {
            handleSetFormDesign(formDesign);
            var locateinfo = "";
            Object.entries(formDesign.model).forEach(([key, value], index) => {
              locateinfo += index == 0 ? `${key}:` : `;${key}:`;
              value.fields.map((item) => {
                locateinfo += `${item.name},`;
              });
            });
            const PrepareNew = await prepareNew({ locateinfo });
            if (PrepareNew.success) {
              handleSetPrepareNew(PrepareNew.data, PrepareNew.caption);
              const panel = document.querySelector("#crm-main-panel");
              handleSetFormPageY(panel.scrollTop);
              handleOpenRelJob();
              panel.scrollTo({ top: 0, left: 0 });
            } else {
              console.log(PrepareNew.error);
            }
          } else {
            console.log(formDesign.error);
          }
        } else {
          console.log("autoExec = 1");
        }
        break;
      case "report":
        console.log("open report");
        await fetchReport();
        handleOpenRelJob();
        break;
      default:
        console.log(`default: ${obj?.cmd}`);
        break;
    }

    handleSetLoading(false);
  };

  const fetchReport = async () => {
    const reportInfo = await getReportInfo();
    if (reportInfo.success) {
      if (reportInfo.npages > 0) {
        handleSetGetReportInfo(reportInfo);
        const reportData = await getReportData({
          reqID: reportInfo.reqID,
          pagenum: 1,
        });
        if (reportData.success) {
          reportData.pagenum = 1;
          handleSetGetReportData(reportData);
        } else {
          handleSetReportError(reportData.error);
          // setHasError(reportData.error);
          console.log(reportData);
        }
      } else {
        handleSetReportNodata(true);
      }
    } else {
      handleSetReportError(reportInfo.error);
      // setHasError(reportInfo.error);
      console.log(reportInfo);
    }
  };

  const handleClickOnCheckInOut = async (type) => {
    handleSetLoading(true);
    const field2Update = screen?.checkInOut[type];
    const now = getNow();
    const updateTable = field2Update.split(".")[0];
    const updateField = field2Update.split(".")[1];
    var dataForUpdate = {};
    if (formdesign.model[updateTable].relationship == "OneToOne") {
      dataForUpdate[updateTable] = {
        [updateField]: now,
      };
      const key = formdesign.key;
      const table = key.split(".")[0];
      const field = key.split(".")[1];
      const keyValue = data.data?.[table][0]?.[field];
      const object = `${serialnumber}/object=${screen.command}`;
      var locateinfo = "";
      Object.entries(formdesign.model).forEach(([key, value], index) => {
        locateinfo += index == 0 ? `${key}:` : `;${key}:`;
        if (key == "XTRDOCDATA") locateinfo += "NAME,";
        value.fields.map((item) => {
          locateinfo += `${item.name},`;
        });
      });
      const setData = await axios
        .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
          url,
          data: {
            service: "setData",
            clientID,
            object,
            key: keyValue,
            locateinfo,
            version: 2,
            data: dataForUpdate,
            appID: process.env.REACT_APP_SOFTONE_APPID,
          },
        })
        .then((res) => res.data)
        .catch((er) => ({ success: false, error: er.message }));
      if (setData.success) {
        handleSetGetData(setData);
        handleSetError({ error: `Επιτυχής καταχώρηση ${type == "checkIn" ? "Check In" : "Check In"}` });
        setShow(false);
      } else {
        handleSetError(setData);
      }
    }

    handleSetLoading(false);
  };

  const CheckInOutComponent = ({ type, element }) => {
    return (
      <div
        className="crm-relative-job-action rel-job"
        onClick={() => screen.objectState == "view" && handleClickOnCheckInOut(type)}
        style={{
          color: screen.objectState == "view" ? "var(--bs-primary)" : "var(--bs-gray-500)",
        }}
      >
        <div>
          <BsReverseLayoutTextWindowReverse
            color={screen.objectState == "view" ? "var(--bs-orange)" : "var(--bs-gray-500)"}
          />
        </div>
        <span className="text-truncate ps-4">{element.text}</span>
      </div>
    );
  };

  return (
    <>
      <div style={{ fontSize: "1.7em" }} className="mt-2">
        Σχετικές εργασίες
      </div>
      <hr />
      {Object.keys(reljobmenu).map((key, index) => {
        if ((reljobmenu[key].command.split(":")[0] ?? "").toLowerCase() == "_checkin") {
          var checkInField = screen?.checkInOut?.checkIn;
          if (checkInField) {
            var table = checkInField.split(".")[0];
            var field = checkInField.split(".")[1];
            var hasValue = data.data?.[table]?.[0]?.[field];
            if (hasValue) {
              return;
            } else {
              return <CheckInOutComponent type="checkIn" key={index} element={reljobmenu[key]} />;
            }
          } else {
            return;
          }
        }
        if ((reljobmenu[key].command.split(":")[0] ?? "").toLowerCase() == "_checkout") {
          var checkOutField = screen?.checkInOut?.checkOut;
          if (checkOutField) {
            var table = checkOutField.split(".")[0];
            var field = checkOutField.split(".")[1];
            var hasValue = data.data?.[table]?.[0]?.[field];
            if (hasValue) {
              return;
            } else {
              var checkInField = screen?.checkInOut?.checkIn;
              if (checkInField) {
                table = checkInField.split(".")[0];
                field = checkInField.split(".")[1];
                var hasValue = data.data?.[table]?.[0]?.[field];
                if (hasValue) {
                  return <CheckInOutComponent type="checkOut" key={index} element={reljobmenu[key]} />;
                } else {
                  return;
                }
              } else {
                return;
              }
            }
          } else {
            return;
          }
        }
        return (
          <div
            className="crm-relative-job-action rel-job"
            key={index}
            onClick={() => screen.objectState == "view" && handleClickForRelJob(reljobmenu[key])}
            style={{
              color: screen.objectState == "view" ? "var(--bs-primary)" : "var(--bs-gray-500)",
            }}
          >
            <div>
              {{
                EditMaster: (
                  <BsReverseLayoutTextWindowReverse
                    color={screen.objectState == "view" ? "var(--bs-orange)" : "var(--bs-gray-500)"}
                  />
                ),
                Report: (
                  <BsFileEarmarkMedical
                    color={screen.objectState == "view" ? "var(--bs-green)" : "var(--bs-gray-500)"}
                  />
                ),
                Dialog: (
                  <GiGearHammer color={screen.objectState == "view" ? "var(--bs-danger)" : "var(--bs-gray-500)"} />
                ),
              }[reljobmenu[key].cmd] ?? "default value"}
            </div>
            <span className="text-truncate ps-4">{reljobmenu[key].text}</span>
          </div>
        );
      })}
    </>
  );
};

const mapStateToProps = (state) => ({
  screen: GETSCREENOBJ(state),
  reljobmenu: GETRELJOBMENU(state),
  data: GETDATA(state),
  formdesign: GETFORMDESIGN(state),
  serialnumber: GETSN(state),
  url: GETURL(state),
  clientID: GETCLIENTID(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetLoading: (value) => dispatch(setLoading(value)),
  handleInitRelJob: (value) => dispatch(InitRelJob(value)),
  handleOpenRelJob: () => dispatch(OpenRelJob()),
  handleSetAccessRights: (value) => dispatch(setAccessRights(value)),
  handleSetFormDesign: (value) => dispatch(setFormDesign(value)),
  handleSetPrepareNew: (value, caption) => dispatch(setPrepareNew(value, caption)),
  handleSetFormPageY: (value) => dispatch(SetFormPageY(value)),
  handleSetGetReportInfo: (value) => dispatch(SetReportInfo(value)),
  handleSetGetReportData: (value) => dispatch(setReportData(value)),
  handleSetReportError: (value) => dispatch(setReportError(value)),
  handleSetReportNodata: (value) => dispatch(setReportNoData(value)),
  handleSetGetData: (value) => dispatch(setGetData(value)),
  handleSetError: (value) => dispatch(setError(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RelJoob);

const getNow = () => {
  var d = new Date();
  return (
    [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-") +
    " " +
    [d.getHours().padLeft(), d.getMinutes().padLeft(), d.getSeconds().padLeft()].join(":")
  );
};
