import React, { useEffect, useState } from "react";
import { Button, Modal, ButtonGroup, InputGroup, FloatingLabel, Form } from "react-bootstrap";
import { TiDeleteOutline } from "react-icons/ti";
import { connect } from "react-redux";
import { setFilter } from "../../../../../redux/actions/screen";
import { GETFILTERS } from "../../../../../redux/selectors";

const MultiSelectModal = ({
  setViewValue,
  openModal,
  setOpenModal,
  element,
  data,
  selected,
  handleSetFilters,
  filters,
  handleChange,
}) => {
  const [editorData, setEditorData] = useState(data);
  const [selectedRow, setSelectedRow] = useState("");
  const [returnIndex, setReturnIndex] = useState();
  const [searchValue, setSearchValue] = useState("");

  const handleClickOnRow = (row) => {
    const SelectedArray = selectedRow != "" ? selectedRow.split(",") : [];
    if (SelectedArray.indexOf(row[returnIndex]) >= 0) {
      SelectedArray.splice(SelectedArray.indexOf(row[returnIndex]), 1);
    } else {
      SelectedArray.push(row[returnIndex]);
    }
    setSelectedRow(SelectedArray.filter((x) => x != "").join(","));
  };

  const handleSearch = (searchText) => {
    setSearchValue(searchText);
    var filter, list, span, i, txtValue;
    var is = [];
    filter = searchText.toUpperCase();
    list = document.getElementById("s1Editor-list-items");
    span = list.querySelectorAll("span");
    for (i = 0; i < span.length; i++) {
      span[i].parentElement.parentElement.style.display = "none";
      txtValue = span[i].textContent || span[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        is.push(span[i].parentElement.parentElement);
      } else {
        span[i].parentElement.parentElement.style.display = "none";
      }
      is.forEach((t) => (t.style.display = ""));
    }
  };
  useEffect(() => {
    setEditorData(data);
    selected != "" && setSelectedRow(selected);
    var index;
    if (element.selectorVF != "") {
      index = editorData.model.findIndex((object) => object.name == element.selectorVF.split("|")[0].split(".")[1]);
    } else {
      index = editorData.model.findIndex((object) => object.name == element.editor.split("|")[2]);
    }
    setReturnIndex(index);
  }, []);

  return (
    <Modal
      animation={false}
      show={openModal}
      centered
      fullscreen
      className="overlapped-on-another-modal"
      dialogClassName="p-2"
      contentClassName="rounded overflow-hidden"
      backdropClassName="overlapped-on-another-modal"
    >
      <Modal.Header>
        <InputGroup className="crm-floating-with-icon">
          <FloatingLabel
            controlId={`Editor-${element.name}`}
            label="Αναζήτηση"
            style={{ whiteSpace: "nowrap" }}
            className="w-100"
          >
            <Form.Control
              type="search"
              className="crm-input form-control ps-1"
              placeholder="Αναζήτηση"
              style={{ border: "0" }}
              value={searchValue}
              autoCapitalize="off"
              onChange={(e) => handleSearch(e.target.value)}
              ref={(r) => ((r || {}).onsearch = () => handleSearch(searchValue))}
            />
          </FloatingLabel>
          {searchValue != "" && (
            <div
              className="d-flex align-items-center justify-content-center"
              onClick={() => {
                setSearchValue("");
                handleSearch("");
              }}
            >
              <TiDeleteOutline size="1.75rem" />
            </div>
          )}
        </InputGroup>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div id="s1Editor-list-items">
          {openModal &&
            editorData.data.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleClickOnRow(item)}
                  className={`s1editor-row border px-1 ${
                    selectedRow != "" && selectedRow.split(",").indexOf(item[returnIndex]) >= 0
                      ? "rounded border-warning shadow"
                      : ""
                  }`}
                  style={{
                    backgroundColor:
                      selectedRow != "" && selectedRow.split(",").indexOf(item[returnIndex]) >= 0
                        ? "var(--bs-gray-400)"
                        : "transparent",
                  }}
                >
                  <div className="my-3 mx-4">
                    {editorData.model.map((yy, i) => {
                      return (
                        <span key={i} className="me-2" style={{ display: "inline-block" }}>
                          {item[i]}
                        </span>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </Modal.Body>
      <Modal.Footer className="w-100 bg-dark shadow">
        <ButtonGroup className="w-100">
          <Button
            variant="outline-warning"
            className="w-50"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Ακύρωση
          </Button>
          <Button
            className="w-50"
            variant="warning"
            onClick={() => {
              handleChange(selectedRow.replaceAll("&", "_"));
              setOpenModal(false);
            }}
          >
            Εφαρμογή
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  serialnumber: state.systemparams.serialnumber,
  clientID: state.session.clientID,
  filters: GETFILTERS(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetFilters: (value) => dispatch(setFilter(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MultiSelectModal);
