import React from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { GETBROWSERDATA, GETBROWSERINFO, GETSCREENSTATE } from "../../../../../redux/selectors";
import PullToRefresh from "react-simple-pull-to-refresh";
import { setLoading } from "../../../../../redux/actions/modals";
import getBrowserInfo from "../../../../../services/getBrowserInfo";
import { SetBrowserData, SetBrowserInfo } from "../../../../../redux/actions/screen";
import PullingContent from "../browser/PullingContent";
import RefreshingContent from "../browser/RefreshingContent";
import getBrowserData from "../functions/getBrowserData";
import useFetch from "../../../../../hooks/useFetch";
import NoData from "../NoData";
import ItemCard from "./card/ItemCard";

const ShowRoomList = ({
  browserinfo,
  browserdata,
  screenState,
  handleLoading,
  handleSetGetBrowserInfo,
  handleSetGetBrowserData,
}) => {
  const [hasImage, setHasImage] = React.useState(false);
  const [imageIndex, setImageIndex] = React.useState(-1);
  const [codeIndex, setCodeIndex] = React.useState(-1);
  const [nameIndex, setNameIndex] = React.useState(-1);
  const [start, setStart] = React.useState(0);
  const { isLoading, isError, error, hasNextPage } = useFetch({ start });

  const listRef = React.useRef();
  const intObserver = React.useRef();
  const lastRowRef = React.useCallback(
    (row) => {
      if (isLoading) return;
      if (intObserver.current) intObserver.current.disconnect();

      intObserver.current = new IntersectionObserver((rows) => {
        if (rows[0].isIntersecting && hasNextPage && screenState == "list") {
          browserinfo?.totalcount > parseInt(process.env.REACT_APP_GETBROWSERDATA_LIMIT) &&
            setStart((prev) => prev + 1);
        }
      });

      if (row) intObserver.current.observe(row);
    },
    [isLoading, hasNextPage]
  );

  const handleRefresh = async () => {
    handleLoading(true);
    const browserInfo = await getBrowserInfo();
    if (browserInfo.success) {
      console.log("New ReqID: ", browserInfo.reqID);
      browserInfo.upddate = new Date();
      handleSetGetBrowserInfo(browserInfo);
      if (browserInfo.totalcount > 0) {
        const browserData = await getBrowserData({
          reqID: browserInfo.reqID,
          start: 0,
        });
        if (browserData.success) {
          setStart(0);
          handleSetGetBrowserData(browserData);
          handleLoading(false);
        } else {
          handleLoading(false);
          console.log(browserData);
        }
      } else {
        handleLoading(false);
        console.log("no data");
      }
    } else {
      handleLoading(false);
      console.log(browserInfo);
    }
  };

  React.useEffect(() => {
    if (browserinfo?.fields) {
      for (var i = 0; i < browserinfo.fields.length; i++) {
        if (browserinfo.fields[i].name == "ITEM.CODE") {
          setCodeIndex(i);
        } else if (browserinfo.fields[i].name == "ITEM.NAME") {
          setNameIndex(i);
        }
        if (browserinfo.fields[i].type == "image") {
          setHasImage(true);
          setImageIndex(i);
        }
      }
    }
  }, []);

  React.useEffect(() => {
    setStart(0);
  }, [browserinfo?.upddate]);

  return (
    <div
      className={`${browserinfo?.totalcount > 0 ? "" : "h-100"}`}
      style={{ display: screenState === "list" ? "block" : "none" }}
    >
      <PullToRefresh
        onRefresh={handleRefresh}
        maxPullDownDistance={130}
        pullDownThreshold={120}
        pullingContent={<PullingContent />}
        refreshingContent={<RefreshingContent />}
      >
        {browserinfo?.totalcount > 0 && browserdata ? (
          <div className={`${browserinfo?.totalcount > 0 ? "" : "h-100"}`}>
            <Container fluid className="p-0">
              {browserdata?.rows &&
                browserdata.rows.map((item, index) => {
                  if (browserdata.rows.length === index + 1) {
                    return (
                      <ItemCard
                        item={item}
                        hasImage={hasImage}
                        imageIndex={imageIndex}
                        codeIndex={codeIndex}
                        nameIndex={nameIndex}
                        key={index}
                        ref={lastRowRef}
                      />
                    );
                  } else {
                    return (
                      <ItemCard
                        item={item}
                        hasImage={hasImage}
                        imageIndex={imageIndex}
                        codeIndex={codeIndex}
                        nameIndex={nameIndex}
                        key={index}
                      />
                    );
                  }
                })}
              {isLoading && start > 0 && (
                <div className="bouncing-loader ">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
            </Container>
          </div>
        ) : (
          <NoData />
        )}
      </PullToRefresh>
    </div>
  );
};
const mapStateToProps = (state) => ({
  browserinfo: GETBROWSERINFO(state),
  browserdata: GETBROWSERDATA(state),
  screenState: GETSCREENSTATE(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleLoading: (value) => dispatch(setLoading(value)),
  handleSetGetBrowserInfo: (value) => dispatch(SetBrowserInfo(value)),
  handleSetGetBrowserData: (value) => dispatch(SetBrowserData(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowRoomList);
