import React, { useState } from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { SetBrowserData, SetBrowserInfo, SetScreenState } from "../../../../redux/actions/screen";
import delData from "../../../../services/delData";
import getBrowserInfo from "../../../../services/getBrowserInfo";
import getBrowserData from "../../../../services/getBrowserData";
import Spinner from "../../Spinner";
import { HiX, HiCheck } from "react-icons/hi";

const ModalDelete = ({ show, setShow, id, handleSetScreenState, handleSetBrowserInfo, handleSetBrowserData }) => {
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    setLoading(true);
    try {
      const Deldata = await delData({ key: id });
      if (Deldata.success) {
        const browserInfo = await getBrowserInfo();
        if (browserInfo.success) {
          browserInfo.upddate = new Date();
          handleSetBrowserInfo(browserInfo);
          const browserData = await getBrowserData({
            reqID: browserInfo.reqID,
            start: 0,
          });
          if (browserData.success) {
            handleSetBrowserData(browserData);
          }
        }
        setLoading(true);
        handleSetScreenState("list");
        setShow(false);
      } else {
        setLoading(true);
        console.log(Deldata);
      }
    } catch (error) {
      setLoading(true);
      console.log(error);
    }
  };

  return (
    <Modal
      contentClassName="bg-dark text-light border-warning"
      className="crm-delete-record"
      show={show}
      onHide={() => setShow(false)}
      size="sm"
      centered
    >
      {loading && <Spinner colorSpin="#ffc107" />}
      <Modal.Body className="d-flex py-4 justify-content-center text-center align-items-center">
        Επιβεβαίωση διαγραφής;
      </Modal.Body>
      <Modal.Footer className="p-0 w-100 bg-dark shadow border-0">
        <ButtonGroup className="m-0 w-100">
          <Button
            variant="outline-warning"
            className="w-50 d-flex justify-content-center align-items-center rounded-0"
            onClick={() => setShow(false)}
          >
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Κλείσμο
            </div>
          </Button>
          <Button
            className="w-50 d-flex justify-content-center align-items-center rounded-0"
            variant="outline-warning"
            onClick={handleDelete}
          >
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Διαγραφή
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  handleSetScreenState: (value) => dispatch(SetScreenState(value)),
  handleSetBrowserInfo: (value) => dispatch(SetBrowserInfo(value)),
  handleSetBrowserData: (value) => dispatch(SetBrowserData(value)),
});

export default connect(null, mapDispatchToProps)(ModalDelete);
