import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { InitReport, setReportData, SetReportInfo } from "../../../../redux/actions/screen";
import { setLoading } from "../../../../redux/actions/modals";
import ModalDialog from "../S1 Dialog/ModalDialog";
import NoData from "../NoData";
import { GETCURRENTSCREEN, GETREPORTDATA, GETREPORTINFO, GETURL } from "../../../../redux/selectors";
import getReportInfo from "../../../../services/getReportInfo";
import getReportData from "../../../../services/getReportData";
import getFilename from "../../../../services/getFilename";
import Error from "../edit master/Error";

const Report = ({
  currentscreen,
  reportinfo,
  reportdata,
  handleSetGetReportInfo,
  handleSetGetReportData,
  handleLoading,
  handleInitReport,
  url,
}) => {
  const [hasError, setHasError] = useState("");
  const [loading, setLoading] = useState(false);

  const fetchReport = async () => {
    const reportInfo = await getReportInfo();
    if (reportInfo.success) {
      if (reportInfo.npages > 0) {
        console.log(reportInfo?.type);
        handleSetGetReportInfo(reportInfo);
        if (reportInfo?.type != "") {
          var reportData = await getFilename({ filename: `temp/SO${reportInfo.reqID}.${reportInfo?.type}` });
        } else {
          var reportData = await getReportData({
            reqID: reportInfo.reqID,
            pagenum: 1,
          });
        }
        if (reportData.success) {
          reportData.pagenum = 1;
          handleSetGetReportData(reportData);
        } else {
          setHasError(reportData.error);
          console.log(reportData);
        }
      } else {
        console.log("no dataa");
      }
    } else {
      setHasError(reportInfo.error);
      console.log(reportInfo);
    }
  };

  const openReport = async () => {
    handleLoading(true);
    setLoading(true);
    await fetchReport();
    handleLoading(false);
    setLoading(false);
  };

  useEffect(() => {
    handleInitReport();
    openReport();
  }, [currentscreen]);

  return (
    !loading && (
      <>
        {hasError !== "" ? (
          <Error error={hasError} />
        ) : reportinfo?.npages > 0 ? (
          <Container
            style={{
              overflow: "auto",
            }}
            fluid
          >
            <div
              dangerouslySetInnerHTML={{ __html: reportdata?.data }}
              style={{
                overflow: "scroll",
                overflowX: "scroll",
                overflowY: "scroll",
                height: "100%",
                width: "100%",
              }}
            />
          </Container>
        ) : (
          <NoData />
        )}
        <ModalDialog />
      </>
    )
  );
};

const mapStateToProps = (state) => ({
  currentscreen: GETCURRENTSCREEN(state),
  reportinfo: GETREPORTINFO(state),
  reportdata: GETREPORTDATA(state),
  url: GETURL(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetGetReportInfo: (value) => dispatch(SetReportInfo(value)),
  handleSetGetReportData: (value) => dispatch(setReportData(value)),
  handleLoading: (value) => dispatch(setLoading(value)),
  handleInitReport: () => dispatch(InitReport()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
