export const SetGadgetParams = (value) => ({
  type: "gadgets/setParams",
  value,
});

export const LoadGadgets = (value) => ({
  type: "gadgets/load",
  value,
});

export const gadgetsBrowserInfo = (key, value) => ({
  type: "gadgets/setBrowserInfo",
  key,
  value,
});

export const gadgetsSetPage = (key, value) => ({
  type: "gadgets/setPage",
  key,
  value,
});

export const gadgetsBrowserData = (key, value) => ({
  type: "gadgets/setBrowserData",
  key,
  value,
});

export const gadgetsData = (key, value) => ({
  type: "gadgets/setData",
  key,
  value,
});
