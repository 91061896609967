import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { setLoading } from "../../../../../../redux/actions/modals";
import SelectorEditorModal from "../input modals/SelectorEditorModal";
import MaskSelectorEditorModal from "../input modals/MaskSelectorEditorModal";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { TiDeleteOutline } from "react-icons/ti";
import { BsChevronDown } from "react-icons/bs";
import { GETBUFFDATA, GETDATA, GETFORMDESIGN, GETNEWDATA, GETOBJECTSTATE } from "../../../../../../redux/selectors";
import { SetChanges } from "../../../../../../redux/actions/screen";
import { calculate } from "../../functions/calculate";

const S1Selector = ({
  clientID,
  serialnumber,
  element,
  objectState,
  data,
  handleSetChange,
  handleLoading,
  formdesign,
}) => {
  const {
    caption,
    dataType,
    decimals,
    editor,
    flex,
    index,
    maskEnabled,
    name,
    readOnly,
    redirector,
    required,
    selectorVF,
    visible,
  } = element;
  const [viewValue, setViewValue] = React.useState("");
  const [keyValue, setKeyValue] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [editorData, setEditorData] = React.useState({});
  const [editorString, setEditorString] = React.useState("");
  const table = name.split(".")[0];
  const key = name.split(".")[1];
  const model = formdesign.model[table].fields.filter((el) => el.name == key)[0];

  const handleChange = (value) => {
    handleSetChange(table, key, 0, value);
    model?.updates != "" && calculate();
  };

  // const handleChangeObj = (value) => {
  //   handleSetChange(table, `D1_${key}_OBJ`, 0, value);
  //   model?.updates != "" && calculate();
  // };

  const handleEditor = async (e) => {
    e.target.blur();
    handleLoading(true);
    var editoR = editor;
    try {
      const EditorArguments = Array.from(editor.matchAll(/\[([^\][]*)]/g), (x) => x[1]);
      EditorArguments.forEach((y) => {
        var splitted = y.split(".");
        var tableName = splitted[0];
        var fieldName = splitted[1];
        var replaceValue;
        if (data[tableName][0].hasOwnProperty(fieldName)) {
          replaceValue = data[tableName][0][fieldName].split("|")[0];
        } else {
          const replaceModel = formdesign.model[tableName].fields.filter((el) => el.name == fieldName)[0];
          console.log(replaceModel);
          if (replaceModel?.editType == "Selector") {
            replaceValue = 0;
          } else {
            replaceValue = "";
          }
        }

        editoR = editor.replaceAll(`[${y}]`, replaceValue);
      });
      const GetSelectorData = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
        url: `http://${serialnumber}.oncloud.gr/s1services`,
        data: {
          service: "GetSelectorData",
          clientID,
          appId: process.env.REACT_APP_SOFTONE_APPID,
          editor: editoR,
          VALUE: "",
        },
      });
      if (GetSelectorData.data.success) {
        setEditorData(GetSelectorData.data);
        setEditorString(editor);
        setOpenModal(true);
      } else {
        console.log(GetSelectorData.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleLoading(false);
    }
  };

  //calculate Changes
  React.useEffect(() => {
    if (data?.[table]?.[0]?.[key]) {
      var value = "";
      if (selectorVF !== "") {
        const selectorArray = selectorVF.split("|");
        selectorArray.forEach((field) => {
          const splitted = field.split(".");
          const tableName = splitted[0];
          const fieldName = splitted[1];
          value += `${data?.[tableName]?.[0]?.[fieldName] ?? ""} `;
        });
      } else {
        value = data?.[table]?.[0]?.[key] ?? "";
      }
      setViewValue(value);
      setKeyValue(data?.[table]?.[0]?.[key] ?? "");
    }
  }, [data?.[table]?.[0]?.[key]]);

  return (
    <div className="s1selector w-100" style={{ flex: `${flex * 100}%`, overflow: "hidden" }} key={index}>
      <InputGroup
        className={`crm-floating-with-icon ${required && keyValue == "" ? "input-required" : ""}`}
        hidden={!visible}
      >
        <FloatingLabel controlId={index} label={caption} style={{ whiteSpace: "nowrap" }} className="w-100">
          <Form.Control
            value={viewValue}
            onChange={(e) => {
              setViewValue(e.target.value);
              setKeyValue(e.target.value);
            }}
            inputMode={maskEnabled ? "text" : "none"}
            className="crm-input form-control ps-1"
            placeholder={caption}
            name={name}
            disabled={objectState == "view"}
            readOnly={readOnly}
            onClick={(e) => !maskEnabled && handleEditor(e)}
            style={{ border: "0" }}
            //   onBlur={(e) => handleChangeField(e)}
          />
        </FloatingLabel>
        {objectState != "view" && keyValue != "" && !readOnly && (
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={() => {
              setViewValue("");
              setKeyValue("");
              handleChange(undefined);
              // handleChangeObj({});
            }}
          >
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
        {objectState != "view" && !readOnly && maskEnabled && (
          <div className="d-flex align-items-center justify-content-center" onClick={handleEditor}>
            <div className="vr m-1"></div>
            <BsChevronDown size="1.5rem" color={"var(--bs-primary)"} />
          </div>
        )}
      </InputGroup>
      {openModal &&
        (maskEnabled ? (
          <MaskSelectorEditorModal
            element={element}
            openModal={openModal}
            setShow={setOpenModal}
            show={editorData}
            editor={editorString}
            setKeyValue={setKeyValue}
            setViewValue={setViewValue}
            predifinedKey={keyValue}
            handleChange={handleChange}
          />
        ) : (
          <SelectorEditorModal
            element={element}
            show={openModal}
            setShow={setOpenModal}
            data={editorData}
            editor={editorString}
            setKeyValue={setKeyValue}
            setViewValue={setViewValue}
            predifinedKey={keyValue}
            handleChange={handleChange}
            // handleChangeObj={handleChangeObj}
          />
        ))}
    </div>
  );
};

const mapStateToProps = (state) => ({
  serialnumber: state.systemparams.serialnumber,
  clientID: state.session.clientID,
  objectState: GETOBJECTSTATE(state),
  data: { view: GETDATA(state)?.data, edit: GETBUFFDATA(state), new: GETNEWDATA(state) }[GETOBJECTSTATE(state)],
  formdesign: GETFORMDESIGN(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleLoading: (value) => dispatch(setLoading(value)),
  handleSetChange: (table, key, index, value) => dispatch(SetChanges(table, key, index, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1Selector);
