import React from "react";
import { Button, Modal, ButtonGroup, InputGroup, FloatingLabel, Form } from "react-bootstrap";
import { TiDeleteOutline } from "react-icons/ti";
import { connect } from "react-redux";
import { SetGridChanges } from "../../../../../../redux/actions/relJob";
import getSelectorData from "../../../../../../services/relJob/getSelectorData";
import selectorFields from "../../../../../../services/selectorFields";
import Spinner from "./../../../../Spinner";
import { HiX, HiCheck } from "react-icons/hi";

function SelectorEditorModal({
  show,
  setShow,
  element,
  data,
  editor,
  predifinedKey,
  handleSetChanges,
  currentLine,
  setViewValue,
  setKeyValue,
}) {
  const [loading, setLoading] = React.useState(false);
  const [editorData, setEditorData] = React.useState(data);
  const [selectedRow, setSelectedRow] = React.useState();
  const [searchValue, setSearchValue] = React.useState("");

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const hasSelectorVf = element?.selectorVF && element.selectorVF != "";
  const keyname = element.editor.split("|")[2];
  const tablename = element.editor.split("|")[1];
  const underScore1 = keyname;
  const underScore2 = tablename;
  const underScore3 = key.split("_")[key.split("_").length - 1];

  const handleChange = async (keyvalue) => {
    setLoading(true);
    try {
      const filters = editor.split("|")[4];
      const resultfields = [...new Set(editorData.model.map((i) => i.name))];
      var keyForVF = [];
      const SelectorFields = await selectorFields({
        filters,
        tablename,
        keyname,
        keyvalue,
        resultfields: resultfields.join(","),
      });
      if (SelectorFields.success) {
        var value = "";
        console.log({ element, SelectorFields });
        if (hasSelectorVf) {
          element.selectorVF.split("|").forEach((i) => {
            var dbIndex = i.split(".")[1].split("_");
            keyForVF.push(dbIndex[dbIndex.length - 1]);
            value += SelectorFields.rows[0][dbIndex[dbIndex.length - 1]] + " ";
          });
        } else {
          value = SelectorFields.rows[0]?.[underScore3];
        }
        Object.keys(SelectorFields.rows[0]).forEach((key) => {
          handleSetChanges(table, `${underScore1}_${underScore2}_${key}`, currentLine, SelectorFields.rows[0][key]);
        });
        handleSetChanges(table, keyname, currentLine, SelectorFields.rows[0][keyname]);
        setViewValue(value);
        setKeyValue(SelectorFields.rows[0][keyname]);
        setLoading(false);
        setShow(false);
      } else {
        setLoading(false);
        console.log(SelectorFields);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSearch = async (value) => {
    setLoading(true);
    try {
      const GetSelectorData = await getSelectorData({ editor, value });
      if (GetSelectorData.success) {
        setEditorData(GetSelectorData);
      } else {
        console.log(GetSelectorData);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    setEditorData(data);
    if (predifinedKey != "") {
      var keyname = editor.split("|")[2];
      var keyIndex, i;
      for (i = 0; i < data.model.length; i++) {
        if (data.model[i].name == keyname) {
          keyIndex = i;
          break;
        }
      }
      for (i = 0; i < data.data.length; i++) {
        if (data.data[i][keyIndex] == predifinedKey) {
          setSelectedRow(i);
          break;
        }
      }
    }
  }, []);
  return (
    <Modal
      animation={false}
      show={show}
      centered
      fullscreen
      dialogClassName="p-3"
      contentClassName="rounded border border-warning shadow overflow-hidden bg-dark"
    >
      {loading && <Spinner colorSpin="#ffc107" />}
      <Modal.Header className="px-2 pt-0">
        <InputGroup className="crm-floating-with-icon">
          <FloatingLabel
            controlId={`Editor-${element.name}`}
            label="Αναζήτηση"
            style={{ whiteSpace: "nowrap" }}
            className="w-100"
          >
            <Form.Control
              value={searchValue}
              type="search"
              onChange={(e) => setSearchValue(e.target.value)}
              className="crm-input form-control ps-1"
              placeholder="Αναζήτηση"
              ref={(r) =>
                ((r || {}).onsearch = (e) => {
                  e.target.blur();
                  handleSearch(searchValue);
                })
              }
              style={{ border: "0" }}
            />
          </FloatingLabel>
          {searchValue != "" && (
            <div
              className="d-flex align-items-center justify-content-center"
              onClick={() => {
                handleSearch("");
                setSearchValue("");
              }}
            >
              <TiDeleteOutline size="1.75rem" />
            </div>
          )}
        </InputGroup>
      </Modal.Header>
      <Modal.Body className="p-0 text-light">
        <div id="s1Editor-list-items">
          {show &&
            editorData.data.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => setSelectedRow(index)}
                  className={`s1editor-row border border-start-0 border-end-0 px-1 ${
                    selectedRow == index ? "border-warning shadow" : ""
                  }`}
                  style={{
                    backgroundColor: selectedRow == index ? "var(--bs-gray-400)" : "transparent",
                    color: selectedRow == index ? "var(--bs-dark)" : "white",
                  }}
                >
                  <div className="m-3 mx-2">
                    {editorData.model.slice(1).map((yy, i) => {
                      return (
                        <span key={i} className="me-3" style={{ display: "inline-block" }}>
                          {item[i + 1]}
                        </span>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </Modal.Body>
      <Modal.Footer className="p-0 w-100 bg-dark shadow border-0">
        <ButtonGroup className="m-0 w-100">
          <Button
            variant="outline-warning"
            className="w-50 d-flex justify-content-center align-items-center"
            onClick={() => setShow(false)}
          >
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Ακύρωση
            </div>
          </Button>
          <Button
            className="w-50 d-flex justify-content-center align-items-center"
            variant="outline-warning"
            onClick={() => handleChange(editorData.data[selectedRow][0])}
          >
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Εφαρμογή
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  handleSetChanges: (table, key, index, value) => dispatch(SetGridChanges(table, key, index, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectorEditorModal);
