import React from "react";
import { Navbar } from "react-bootstrap";
import { connect } from "react-redux";
import TopBarOnReport from "./rel job/TopBarOnReport";
import TopBarOnEditMaster from "./rel job/TopBarOnEditMaster";
// import TopBarOnBatchJob from "./rel job/TopBarOnBatchJob";

const TopBarOnRelJob = ({ screen, mainNavigatorHeight, setTopBarHeight }) => {
  return (
    <div id="crm-top-bar" style={{ position: "sticky", top: mainNavigatorHeight, zIndex: 1000 }}>
      <Navbar
        style={{
          backgroundColor: "var(--bs-gray-800)",
          borderBottom: "2px solid var(--bs-gray-700)",
          borderTop: "2px solid var(--bs-gray-700)",
        }}
      >
        {{ Report: <TopBarOnReport />, Dialog: <>Dialog</>, EditMaster: <TopBarOnEditMaster /> }[screen.cmd]}
      </Navbar>
    </div>
  );
};

const mapStateToProps = (state) => ({
  screen: state.screen.relJob,
});

export default connect(mapStateToProps, null)(TopBarOnRelJob);
