import { setAccessRights } from "./screen/accessrights";
import { setGetBrowserData, setNextBrowserData } from "./screen/browserdata";
import { setGetBrowserInfo } from "./screen/browserinfo";
import { setGetDialog, showDialog } from "./screen/dialog";
import {
  resetBuffFilters,
  setDateRanges,
  setFilter,
  setFilterText,
  setSearchFilter,
  setSelectorFilter,
} from "./screen/filters";
import { setGetReportData } from "./screen/reportdata";
import { setGetReportInfo } from "./screen/reportinfo";

const screen = (
  state = {
    currentscreen: "dashboard",
    isOnRelJob: false,
    screens: {
      dashboard: "dashboard",
      user: "user",
    },
  },
  action
) => {
  switch (action.type) {
    case "general/setPageY":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            pageY: action.value,
            formPageY: undefined,
          },
        },
      };
    case "general/setFormPageY":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            formPageY: action.value,
          },
        },
      };
    case "general/AddScreenFromURL":
      return {
        ...state,
        screens: {
          ...state.screens,
          URLScreen: action.screen,
        },
      };
    case "general/setCurrentScreen":
      return { ...state, currentscreen: action.value };
    case "general/setWebMenu":
      action.value.dashboard = "dashboard";
      action.value.user = "user";
      return {
        ...state,
        screens: action.value,
      };
    case "dialog/set":
      return setGetDialog(state, action);
    case "dialog/show":
      return showDialog(state, action);
    case "general/setAccessRights":
      return setAccessRights(state, action);

    case "dialog/setFilter":
      return setFilter(state, action);
    case "dialog/setSelectorText":
      return setSelectorFilter(state, action);
    case "dialog/setFilterText":
      return setFilterText(state, action);
    case "dialog/setSearchFilter":
      return setSearchFilter(state, action);
    case "dialog/resetBuffFilters":
      return resetBuffFilters(state);
    case "dialog/setDateRanges":
      return setDateRanges(state, action);

    case "editMaster/setBrowserInfo":
      return setGetBrowserInfo(state, action);
    case "editMaster/setBrowserData":
      return setGetBrowserData(state, action);
    case "editMaster/setNextBrowserData":
      return setNextBrowserData(state, action);
    case "report/setReportInfo":
      return setGetReportInfo(state, action);
    case "report/setReportData":
      return setGetReportData(state, action);
    case "report/setReportPage":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            reportdata: {
              ...state.screens[state.currentscreen].reportdata,
              pagenum: action.value,
            },
          },
        },
      };
    case "editMaster/setSelectedRecord":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            browserdata: {
              ...state.screens[state.currentscreen].browserdata,
              selectedrow: action.value,
            },
          },
        },
      };
    case "editMaster/setRelJobMenus":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            reljobmenu: action.value,
          },
        },
      };
    case "editMaster/CanSignature":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            canSignature: true,
          },
        },
      };
    case "editMaster/SetCheckInOut":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            checkInOut: {
              ...state.screens[state.currentscreen]?.checkInOut,
              [action.checkType]: action.value,
            },
          },
        },
      };

    case "relJob/Init":
      return {
        ...state,
        relJob: action.value,
      };
    case "relJob/Open":
      return {
        ...state,
        isOnRelJob: true,
      };
    case "relJob/Close":
      return {
        ...state,
        isOnRelJob: false,
        relJob: undefined,
      };
    case "relJob/setAccessRights":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          accessRights: action.value,
        },
      };
    case "relJob/setFormDesign":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          formDesign: action.value,
        },
      };
    case "relJob/prepareNew":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          caption: action.caption,
          prepare_new: action.value,
          data_buffer: action.value,
          newdata: action.value,
        },
      };
    case "relJob/objectState":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          objectState: action.value,
        },
      };
    case "relJob/setChanges":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          newdata: {
            ...state.relJob?.newdata,
            [action.table]: [
              {
                ...state.relJob?.newdata?.[action.table]?.[action.index],
                [action.key]: action.value,
              },
            ],
          },
          data_buffer: {
            ...state.relJob?.data_buffer,
            [action.table]: [
              {
                ...state.relJob?.data_buffer?.[action.table]?.[action.index],
                [action.key]: action.value,
              },
            ],
          },
        },
      };
    case "relJob/changeToBuffer":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          data_buffer: {
            ...state.relJob.getdata.data,
          },
        },
      };
    case "relJob/cancelEdits":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          objectState: "view",
          newdata: undefined,
        },
      };
    case "relJob/AddDetailGrid":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          grids: {
            ...state.screens[state.currentscreen].grids,
            [action.gridName]: action.gridValue,
          },
        },
      };
    case "relJob/setCurrentLine":
      return {
        ...state,
        relJob: {
          ...state.relJob,

          grids: {
            ...state.relJob.grids,
            [action.gridName]: {
              ...state.relJob.grids[action.gridName],
              currentLineIndex: action.lineIndex,
              currentLine: action.line,
            },
          },
        },
      };
    case "relJob/setGridChanges":
      return {
        ...state,
        relJob: {
          ...state.relJob,

          grids: {
            ...state.relJob.grids,
            [action.table]: {
              ...state.relJob.grids[action.table],
              currentLine: {
                ...state.relJob.grids[action.table]?.currentLine,
                [action.key]: action.value,
              },
            },
          },
        },
      };
    case "relJob/triggerGridChange":
      var gridLine = JSON.parse(JSON.stringify(state.relJob?.data_buffer?.[action.gridName]));
      var newArry = gridLine.reduce((acum, cur) => [...acum, { LINENUM: cur.LINENUM }], []);
      return {
        ...state,
        relJob: {
          ...state.relJob,
          newdata: {
            ...state.relJob.newdata,
            [action.gridName]: newArry,
          },
        },
      };
    case "relJob/addLineToGrid":
      var copyNewData = JSON.parse(JSON.stringify(state.relJob?.newdata?.[action.gridName] ?? []));
      var copyNewBuffData = JSON.parse(JSON.stringify(state.relJob?.data_buffer?.[action.gridName] ?? []));
      copyNewData.push(action.value);
      copyNewBuffData.push(action.value);
      return {
        ...state,
        relJob: {
          ...state.relJob,
          newdata: {
            ...state.relJob.newdata,
            [action.gridName]: copyNewData,
          },
          data_buffer: {
            ...state.relJob.data_buffer,
            [action.gridName]: copyNewBuffData,
          },
          grids: {
            ...state.relJob.grids,
            [action.gridName]: {
              ...state.relJob.grids[action.gridName],
              currentLineIndex: undefined,
              currentLine: undefined,
            },
          },
        },
      };
    case "relJob/deleteGridLine":
      var buffDataLines = JSON.parse(JSON.stringify(state.relJob.data_buffer[action.grid]));
      buffDataLines.splice(action.lineIndex, 1);
      var newDataLines = JSON.parse(JSON.stringify(state.relJob.newdata[action.grid]));
      newDataLines.splice(action.lineIndex, 1);
      return {
        ...state,
        relJob: {
          ...state.relJob,
          data_buffer: {
            ...state.relJob.data_buffer,
            [action.grid]: buffDataLines,
          },
          newdata: {
            ...state.relJob.newdata,
            [action.grid]: newDataLines,
          },
        },
      };
    case "relJob/setCalculateData":
      switch (state.relJob.objectState) {
        case "new":
          return {
            ...state,
            relJob: {
              ...state.relJob,
              newdata: action.value,
            },
          };
        case "edit":
          return {
            ...state,
            relJob: {
              ...state.relJob,
              data_buffer: action.value,
            },
          };
        default:
          break;
      }
    case "relJob/UpdateLineOfGrid":
      var copyNewBuffData = JSON.parse(JSON.stringify(state.relJob?.data_buffer?.[action.gridName] ?? []));
      var copyNewData = JSON.parse(JSON.stringify(state.relJob?.newdata?.[action.gridName] ?? []));
      copyNewData[action.index] = action.line;
      copyNewBuffData[action.index] = action.line;

      return {
        ...state,
        relJob: {
          ...state.relJob,
          newdata: {
            ...state.relJob.newdata,
            [action.gridName]: copyNewData,
          },
          data_buffer: {
            ...state.relJob.data_buffer,
            [action.gridName]: copyNewBuffData,
          },
          grids: {
            ...state.relJob.grids,
            [action.gridName]: {
              ...state.relJob.grids[action.gridName],
              currentLineIndex: undefined,
              currentLine: undefined,
            },
          },
        },
      };
    case "relJob/setData":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          getdata: action.value,
          newdata: undefined,
          data_buffer: undefined,
        },
      };
    case "relJob/report/initialize":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          reportinfo: undefined,
          filters: undefined,
          screenState: "list",
          objectState: "view",
        },
      };
    case "relJob/report/setReportInfo":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          reportinfo: action.value,
        },
      };
    case "relJob/report/setReportData":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          reportdata: action.value,
        },
      };
    case "relJob/report/setReportPage":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          reportdata: {
            ...state.relJob.reportdata,
            pagenum: action.value,
          },
        },
      };
    case "relJob/report/error":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          error: action.value,
        },
      };
    case "relJob/report/noData":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          noData: action.value,
        },
      };

    case "relJob/dialog/set":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          dialog: action.value,
        },
      };
    case "relJob/dialog/show":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          dialog: {
            ...state.relJob.dialog,
            show: action.value,
          },
          filters: {
            ...state.relJob.filters,
            reset: false,
          },
        },
      };
    case "relJob/dialog/setFilter":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          filters: {
            ...state.relJob?.filters,
            reset: false,
            buffdata: {
              ...state.relJob?.filters?.buffdata,
              [action.table]: {
                ...state.relJob?.filters?.buffdata?.[action.table],
                [action.key]: action.value,
              },
            },
          },
        },
      };
    case "relJob/dialog/setSelectorText":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          filters: {
            ...state.relJob?.filters,
            reset: false,
            selector: {
              ...state.relJob?.filters?.selector,
              [action.table]: {
                ...state.relJob?.filters?.selector?.[action.table],
                [action.key]: action.viewValue,
              },
            },
            buffdata: {
              ...state.relJob?.filters?.buffdata,
              [action.table]: {
                ...state.relJob?.filters?.buffdata?.[action.table],
                [action.key]: action.keyValue,
              },
            },
          },
        },
      };
    case "relJob/dialog/setFilterText":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          filters: {
            ...state.relJob.filters,
            text: action.value,
            selectorText: action.selectorValue,
            search: undefined,
          },
        },
      };
    case "relJob/dialog/setSearchFilter":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          filters: {
            ...state.relJob?.filters,
            search: action.value,
          },
        },
      };
    case "relJob/dialog/resetBuffFilters":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          filters: {
            ...state.relJob.filters,
            buffdata: undefined,
            selector: undefined,
            reset: true,
            daterange: undefined,
          },
        },
      };
    case "relJob/dialog/setDateRanges":
      return {
        ...state,
        relJob: {
          ...state.relJob,
          filters: {
            ...state.relJob?.filters,
            daterange: {
              ...state.relJob?.filters?.daterange,
              [action.key]: action.value,
            },
          },
        },
      };
    case "editMaster/setFormDesign":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            formdesign: action.value,
          },
        },
      };
    case "editMaster/setData":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            getdata: action.value,
            newdata: undefined,
            data_buffer: undefined,
          },
        },
      };
    case "general/screenState":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            screenState: action.value,
          },
        },
      };
    case "general/objectState":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            objectState: action.value,
          },
        },
      };
    case "editMaster/cancelEdits":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            objectState: "view",
            newdata: undefined,
          },
        },
      };

    case "SET_EDITMASTER_CHANGES":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            newdata: {
              ...state.screens[state.currentscreen]?.newdata,
              [action.table]: [
                {
                  ...state.screens[state.currentscreen]?.newdata?.[action.table]?.[action.index],
                  [action.key]: action.value,
                },
              ],
            },
            data_buffer: {
              ...state.screens[state.currentscreen]?.data_buffer,
              [action.table]: [
                {
                  ...state.screens[state.currentscreen]?.data_buffer?.[action.table]?.[action.index],
                  [action.key]: action.value,
                },
              ],
            },
          },
        },
      };
    case "SET_GRID_CHANGES":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            grids: {
              ...state.screens[state.currentscreen].grids,
              [action.table]: {
                ...state.screens[state.currentscreen].grids[action.table],
                currentLine: {
                  ...state.screens[state.currentscreen].grids[action.table]?.currentLine,
                  [action.key]: action.value,
                },
              },
            },
          },
        },
      };
    case "TRIGGER_GRID_CHANGE":
      var gridLine = JSON.parse(JSON.stringify(state.screens[state.currentscreen]?.data_buffer?.[action.gridName]));
      var newArry = gridLine.reduce((acum, cur) => [...acum, { LINENUM: cur.LINENUM }], []);
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            newdata: {
              ...state.screens[state.currentscreen].newdata,
              [action.gridName]: newArry,
            },
          },
        },
      };
    case "editMaster/setCalculateData":
      switch (state.screens[state.currentscreen].objectState) {
        case "new":
          return {
            ...state,
            screens: {
              ...state.screens,
              [state.currentscreen]: {
                ...state.screens[state.currentscreen],
                newdata: action.value,
              },
            },
          };
        case "edit":
          return {
            ...state,
            screens: {
              ...state.screens,
              [state.currentscreen]: {
                ...state.screens[state.currentscreen],
                data_buffer: action.value,
              },
            },
          };
        default:
          break;
      }
    case "editMaster/initialize":
      return {
        ...state,
        isOnRelJob: false,
        relJob: undefined,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            browserinfo: undefined,
            browserdata: undefined,
            pageY: undefined,
            getdata: undefined,
            data_buffer: undefined,
            filters: undefined,
            screenState: "list",
            objectState: "view",
          },
        },
      };
    case "report/initialize":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            reportinfo: undefined,
            filters: undefined,
            screenState: "list",
            objectState: "view",
          },
        },
      };
    case "INITIALIZE_BATCH":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            dialod: undefined,
            filters: undefined,
            results: undefined,
            start: undefined,
            end: undefined,
          },
        },
      };
    case "ADD_DETAILGRID":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            grids: {
              ...state.screens[state.currentscreen].grids,
              [action.gridName]: action.gridValue,
            },
          },
        },
      };
    case "SET_CURRENT_LINE":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            grids: {
              ...state.screens[state.currentscreen].grids,
              [action.gridName]: {
                ...state.screens[state.currentscreen].grids[action.gridName],
                currentLineIndex: action.lineIndex,
                currentLine: action.line,
              },
            },
          },
        },
      };
    case "DELETE_GRID_LINE":
      var buffDataLines = JSON.parse(JSON.stringify(state.screens[state.currentscreen].data_buffer[action.grid]));
      buffDataLines.splice(action.lineIndex, 1);
      var newDataLines = JSON.parse(JSON.stringify(state.screens[state.currentscreen].newdata[action.grid]));
      newDataLines.splice(action.lineIndex, 1);
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            data_buffer: {
              ...state.screens[state.currentscreen].data_buffer,
              [action.grid]: buffDataLines,
            },
            newdata: {
              ...state.screens[state.currentscreen].newdata,
              [action.grid]: newDataLines,
            },
          },
        },
      };

    case "UPDATE_LINE_OF_GRID":
      var copyNewBuffData = JSON.parse(
        JSON.stringify(state.screens[state.currentscreen]?.data_buffer?.[action.gridName] ?? [])
      );
      var copyNewData = JSON.parse(
        JSON.stringify(state.screens[state.currentscreen]?.newdata?.[action.gridName] ?? [])
      );
      copyNewData[action.index] = action.line;
      copyNewBuffData[action.index] = action.line;

      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            newdata: {
              ...state.screens[state.currentscreen].newdata,
              [action.gridName]: copyNewData,
            },
            data_buffer: {
              ...state.screens[state.currentscreen].data_buffer,
              [action.gridName]: copyNewBuffData,
            },
            grids: {
              ...state.screens[state.currentscreen].grids,
              [action.gridName]: {
                ...state.screens[state.currentscreen].grids[action.gridName],
                currentLineIndex: undefined,
                currentLine: undefined,
              },
            },
          },
        },
      };
    case "ADD_LINE_TO_GRID":
      var copyNewData = JSON.parse(
        JSON.stringify(state.screens[state.currentscreen]?.newdata?.[action.gridName] ?? [])
      );
      var copyNewBuffData = JSON.parse(
        JSON.stringify(state.screens[state.currentscreen]?.data_buffer?.[action.gridName] ?? [])
      );
      copyNewData.push(action.value);
      copyNewBuffData.push(action.value);
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            newdata: {
              ...state.screens[state.currentscreen].newdata,
              [action.gridName]: copyNewData,
            },
            data_buffer: {
              ...state.screens[state.currentscreen].data_buffer,
              [action.gridName]: copyNewBuffData,
            },
            grids: {
              ...state.screens[state.currentscreen].grids,
              [action.gridName]: {
                ...state.screens[state.currentscreen].grids[action.gridName],
                currentLineIndex: undefined,
                currentLine: undefined,
              },
            },
          },
        },
      };
    case "editMaster/changeToBuffer":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            data_buffer: {
              ...state.screens[state.currentscreen].getdata.data,
            },
          },
        },
      };
    case "editMaster/prepareNew":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            prepare_new: action.value,
            data_buffer: action.value,
            newdata: action.value,
          },
        },
      };
    case "SET_BATCH_DIALOG":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            dialog: action.value,
          },
        },
      };
    case "SET_BATCH_FILTER":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            filters: {
              ...state.screens[state.currentscreen]?.filters,
              buffdata: {
                ...state.screens[state.currentscreen]?.filters?.buffdata,
                [action.table]: {
                  ...state.screens[state.currentscreen]?.filters?.buffdata?.[action.table],
                  [action.key]: action.value,
                },
              },
            },
          },
        },
      };
    case "SET_BATCH_START":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            start: action.value,
            end: "",
            results: undefined,
          },
        },
      };
    case "SET_BATCH_END":
      return {
        ...state,
        screens: {
          ...state.screens,
          [state.currentscreen]: {
            ...state.screens[state.currentscreen],
            results: action.value,
            end: new Date(),
          },
        },
      };

    case "session/clear":
      return {
        currentscreen: "dashboard",
        screens: {
          dashboard: "dashboard",
          user: "user",
        },
      };
    default:
      return state;
  }
};

export default screen;
