import React from "react";
import { Container, Table } from "react-bootstrap";
import { connect } from "react-redux";
import { gadgetEditor } from "../../functions";

const GadgetData = ({ gadget, property }) => {
  return (
    <Container fluid className="p-0 h-100 bg-dark overflow-auto">
      <Table striped bordered hover variant="dark">
        <thead style={{ position: "sticky", top: "0px" }}>
          <tr>
            {gadget[property]?.browserinfo &&
              gadget[property].browserinfo.columns.map((header, index) => (
                <th
                  className="text-nowrap overflow-hidden"
                  style={{
                    minWidth: "20px",
                    maxWidth: `${header.width + 24}px`,
                    textAlign: { left: "left", right: "right", center: "center" }[header.align] ?? "left",
                  }}
                  key={index}
                >
                  {header.header}
                </th>
              ))}
          </tr>
        </thead>

        <tbody>
          {gadget[property].browserdata &&
            gadget[property].browserdata.rows.map((row, Findex) => {
              return (
                <tr key={Findex}>
                  {row.map(
                    (columns, Sindex) =>
                      Sindex != 0 && (
                        <td
                          className="text-nowrap overflow-hidden"
                          style={{
                            minWidth: "20px",
                            maxWidth: `${gadget[property].browserinfo.columns[Sindex - 1]?.width + 24}px`,
                            textAlign:
                              {
                                left: "left",
                                right: "right",
                                center: "center",
                              }[gadget[property].browserinfo.columns[Sindex - 1]?.align] ?? "left",
                          }}
                          key={Sindex}
                        >
                          {gadgetEditor(gadget[property].browserinfo.fields[Sindex].type, columns)}
                        </td>
                      )
                  )}
                </tr>
              );
            })}
        </tbody>
      </Table>
      {/* {gadget[property].browserdata &&
        gadget[property].browserdata.rows.map((row, index) => {
          return (
            <div key={index} style={{ borderBottom: "1px solid black" }}>
              <div className="p-1">
                {gadget[property].browserinfo.columns.map((column, y) => {
                  return (
                    <div
                      key={y}
                      style={{ display: "inline-block" }}
                      className="me-4"
                    >
                      <span
                        style={{
                          color: "#727c90",
                          fontWeight: "400",
                        }}
                      >{`${column.header}: `}</span>
                      <span style={{ fontWeight: "600" }}>{row[y + 1]}</span>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })} */}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  gadget: state.gadget.gadgets,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GadgetData);
