import React, { useEffect, useState } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { HiDotsVertical } from "react-icons/hi";
import { Button, Container, Offcanvas } from "react-bootstrap";
import { connect } from "react-redux";
import { setLoading } from "../../../../redux/actions/modals";
import ModalPrintForm from "../../modals/rel job/ModalPrintForm";
import SideMenuOnForm from "../side menus/SideMenuOnForm";
import { setGetData, SetObjectState, SetScreenState } from "../../../../redux/actions/relJob";
import { GETBROWSERDATA, GETBROWSERINFO, GETOBJECTSTATE, GETSCREENOBJ } from "../../../../redux/relJobSelectors";
import ModalDelete from "../modals/rel job/ModalDelete";
import printTemplates from "../../../../services/relJob/printTemplates";
import { CloseRelJob } from "../../../../redux/actions/screen";

const TopBarOnForm = ({
  handleSetBackToList,
  handleSetObjectState,
  isOnRelJob,
  screen,
  relJobScreen,
  objectState,
  handleSetLoading,
  handleUnloadData,
  handleCloseRelJob,
}) => {
  const [show, setShow] = useState(false);
  const [print, setPrint] = useState(false);
  const [deleteM, setDelete] = useState(false);
  const [id, setId] = useState("");
  const [templates, setTemplates] = useState([]);
  const [barHeight, setBarHeight] = useState(0);
  const { innerWidth } = window;

  const handleGoBack = async () => {
    handleSetBackToList("list");
    handleSetObjectState("view");
    handleUnloadData();
  };

  const fetchTemplates = async () => {
    setShow(false);
    handleSetLoading(true);
    try {
      const templates = await printTemplates();
      if (templates.success) {
        setTemplates(templates.data);
        setPrint(true);
      } else {
        console.log(templates);
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleSetLoading(false);
    }
  };

  useEffect(() => {
    const navBar = document.getElementById("crm-bars");
    setBarHeight(navBar.offsetHeight);
  }, []);
  return (
    <Container fluid>
      <Button
        variant="warning"
        className="me-2"
        onClick={() => (screen.autoExec == 2 ? handleCloseRelJob() : handleGoBack())}
      >
        <AiOutlineArrowLeft />
      </Button>
      <div className="text-light me-2 overflow-hidden text-nowrap">
        {/* {
          {
            true: relJobScreen?.getdata?.caption ?? (relJobScreen?.caption || ""),
            false: objectState != "new" && (screen?.getdata?.caption ?? ""),
          }[isOnRelJob]
        } */}
      </div>
      <div className="d-flex">
        {!isOnRelJob && (
          <>
            <Button className="ms-1" variant="warning" onClick={() => setShow((e) => !e)}>
              <HiDotsVertical />
            </Button>

            <Offcanvas
              backdropClassName="crm-relative-job-menu---"
              show={show}
              onHide={() => setShow(false)}
              placement="end"
              style={{
                top: barHeight,
                maxWidth: innerWidth * 0.8,
                backgroundColor: "var(--bs-gray-900)",
                borderLeft: "1px solid var(--bs-warning",
                borderTop: "1px solid var(--bs-warning",
              }}
            >
              <Offcanvas.Body>
                <SideMenuOnForm fetchTemplates={fetchTemplates} setId={setId} setShow={setShow} setDelete={setDelete} />
              </Offcanvas.Body>
            </Offcanvas>
          </>
        )}
      </div>
      <ModalPrintForm print={print} setPrint={setPrint} templates={templates} />
      <ModalDelete show={deleteM} setShow={setDelete} id={id} />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  screen: GETSCREENOBJ(state),
  browserinfo: GETBROWSERINFO(state),
  browserdata: GETBROWSERDATA(state),
  objectState: GETOBJECTSTATE(state),
  isOnRelJob: state.screen.isOnRelJob,
  relJobScreen: state.screen.relJob,
});

const mapDispatchToProps = (dispatch) => ({
  handleSetBackToList: (value) => dispatch(SetScreenState(value)),
  handleSetObjectState: (value) => dispatch(SetObjectState(value)),
  handleUnloadData: () => dispatch(setGetData(undefined)),
  handleSetLoading: (value) => dispatch(setLoading(value)),
  handleCloseRelJob: () => dispatch(CloseRelJob()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBarOnForm);
