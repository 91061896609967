import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import printDoc from "../../../services/printDoc";
import Spinner from "../Spinner";
import { HiX, HiCheck } from "react-icons/hi";

const ModalPrintForm = ({ print, setPrint, outputformats, screen, serialnumber, templates }) => {
  const [format, setFormat] = useState("none");
  const [templ, setTempl] = useState("none");
  const [loading, setLoading] = React.useState(false);

  const handlePrint = async () => {
    if (format !== null && templ !== null) {
      var key = screen.formdesign.key;
      var keyValue = screen.getdata.data[`${key.split(".")[0]}`][0][`${key.split(".")[1]}`];
      setLoading(true);
      try {
        const doc = await printDoc({ format, template: templ, key: keyValue });
        if (doc.success) {
          window.open(
            `https://${serialnumber}.oncloud.gr/s1services/?filename=${doc.filename}`,
            "",
            "location=yes,scrollbars=yes,status=yes"
          );
          setPrint(false);
        } else {
          console.log(doc);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (print) {
      setFormat("none");
      setTempl("none");
    }
  }, [print]);

  return (
    <Modal
      contentClassName="bg-dark text-light border-warning"
      className="crm-print-form"
      show={print}
      onHide={() => setPrint(false)}
      size="lg"
      centered
    >
      {loading && <Spinner />}
      <Modal.Body>
        {templates.length === 0 ? (
          <div className="d-flex flex-column align-items-center">
            <div className="my-2">Δεν υπάρχουν φόρμες εκτύπωσης</div>
            <Button
              variant="secondary"
              onClick={() => {
                setPrint(false);
              }}
              className="mb-2"
            >
              OK
            </Button>
          </div>
        ) : (
          <>
            <Form.Select className="bg-dark text-light" onChange={(e) => setFormat(e.target.value)} value={format}>
              <option value="none" disabled>
                Επιλέξτε εκτυπωτή
              </option>
              {Object.entries(outputformats ?? {}).map(([key, value], i) => {
                return (
                  <option key={i} value={key}>
                    {value}
                  </option>
                );
              })}
            </Form.Select>
            <Form.Select className="bg-dark text-light mt-2" onChange={(e) => setTempl(e.target.value)} value={templ}>
              <option value="none" disabled>
                Επιλέξτε Φόρμα
              </option>
              {templates.map(([key, value], i) => {
                return (
                  <option key={i} value={key}>
                    {`${key} - ${value}`}
                  </option>
                );
              })}
            </Form.Select>
          </>
        )}
      </Modal.Body>
      {templates.length !== 0 && (
        <Modal.Footer className="p-0 w-100 bg-dark shadow border-0">
          <ButtonGroup className="m-0 w-100">
            <Button
              variant="outline-warning"
              className="w-50 d-flex justify-content-center align-items-center rounded-0"
              onClick={() => {
                console.log(templates.length);
                setPrint(false);
              }}
            >
              <HiX size={25} color="var(--bs-danger)" className="me-2" />
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ fontSize: 20, color: "white" }}
              >
                Ακύρωση
              </div>
            </Button>
            <Button
              className="w-50 d-flex justify-content-center align-items-center rounded-0"
              variant="outline-warning"
              onClick={handlePrint}
              disabled={format == "none" || templ == "none"}
            >
              <HiCheck size={25} color="var(--bs-success)" className="me-2" />
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ fontSize: 20, color: "white" }}
              >
                Εφαρμογή
              </div>
            </Button>
          </ButtonGroup>
        </Modal.Footer>
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  outputformats: state.systemparams?.outputformats?.form,
  serialnumber: state.systemparams.serialnumber,
  screen: state.screen.screens[`${state.screen.currentscreen}`],
});

export default connect(mapStateToProps)(ModalPrintForm);
