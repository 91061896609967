import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal, ButtonGroup, InputGroup, FloatingLabel, Form } from "react-bootstrap";
import { TiDeleteOutline } from "react-icons/ti";
import { connect } from "react-redux";
import Spinner from "./../../../Spinner";

function SelectorEditorModal({
  serialnumber,
  clientID,
  openModal,
  setOpenModal,
  element,
  data,
  editor,
  predifinedKey,
  handleChange,
}) {
  const [loading, setLoading] = useState(false);
  const [editorData, setEditorData] = useState(data);
  const [selectedRow, setSelectedRow] = useState();
  const [searchValue, setSearchValue] = useState("");

  const setCurrentVal = async (index, val) => {
    setSelectedRow(index);
    setLoading(true);
    try {
      var filters = editor.split("|")[4];
      var tablename = editor.split("|")[1];
      var keyname = editor.split("|")[2];
      var keyvalue = val[0];
      var resultfields = [...new Set(data.model.map((i) => i.name))];
      var keyForVF = [];
      const selectorFields = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
        url: `http://${serialnumber}.oncloud.gr/s1services`,
        data: {
          service: "selectorFields",
          clientID,
          APPID: process.env.REACT_APP_SOFTONE_APPID,
          filters,
          tablename,
          keyname,
          keyvalue,
          resultfields: resultfields.join(","),
        },
      });
      if (selectorFields.data.success) {
        var value = "";
        if (element.selectorVF != "") {
          element.selectorVF.split("|").forEach((i) => {
            var dbIndex = i.split(".")[1].split("_");
            keyForVF.push(dbIndex[dbIndex.length - 1]);
            value += selectorFields.data.rows[0][dbIndex[dbIndex.length - 1]] + " ";
          });
        } else {
          value = selectorFields.data.rows[0][keyname] ?? selectorFields.data.rows[0].CODE;
          keyname = selectorFields.data.rows[0][keyname] ?? selectorFields.data.rows[0].CODE;
        }
        handleChange(value, selectorFields.data.rows[0][keyname]);
        setLoading(false);
        setOpenModal(false);
      } else {
        setLoading(false);
        console.log(selectorFields.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSearch = async (searchText) => {
    setLoading(true);
    setSearchValue(searchText);
    try {
      const GetSelectorData = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
        url: `http://${serialnumber}.oncloud.gr/s1services`,
        data: {
          service: "GetSelectorData",
          clientID,
          appId: process.env.REACT_APP_SOFTONE_APPID,
          editor,
          VALUE: searchText,
        },
      });
      if (GetSelectorData.data.success) {
        setEditorData(GetSelectorData.data);
      } else {
        console.log(GetSelectorData.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setEditorData(data);
    if (predifinedKey != "") {
      var keyname = editor.split("|")[2];
      var keyIndex, i;
      for (i = 0; i < data.model.length; i++) {
        if (data.model[i].name == keyname) {
          keyIndex = i;
          break;
        }
      }
      for (i = 0; i < data.data.length; i++) {
        if (data.data[i][keyIndex] == predifinedKey) {
          setSelectedRow(i);
          break;
        }
      }
    }
  }, []);
  return (
    <Modal
      animation={false}
      show={openModal}
      centered
      fullscreen
      className="overlapped-on-another-modal"
      dialogClassName="p-2"
      contentClassName="rounded overflow-hidden"
      backdropClassName="overlapped-on-another-modal"
    >
      {loading && <Spinner colorSpin="#ffc107" />}
      <Modal.Header>
        <InputGroup className="crm-floating-with-icon">
          <FloatingLabel
            controlId={`Editor-${element.name}`}
            label="Αναζήτηση"
            style={{ whiteSpace: "nowrap" }}
            className="w-100"
          >
            <Form.Control
              type="search"
              className="crm-input form-control ps-1"
              placeholder="Αναζήτηση"
              style={{ border: "0" }}
              value={searchValue}
              autoCapitalize="off"
              onChange={(e) => setSearchValue(e.target.value)}
              ref={(r) => ((r || {}).onsearch = () => handleSearch(searchValue))}
            />
          </FloatingLabel>
          {searchValue != "" && (
            <div
              className="d-flex align-items-center justify-content-center"
              onClick={() => {
                setSearchValue("");
                handleSearch("");
              }}
            >
              <TiDeleteOutline size="1.75rem" />
            </div>
          )}
        </InputGroup>
        {/* <div className="form-floating w-100 ">
          <input
            id={`Editor-${element.name}`}
            type="search" 
            className="form-control"
            placeholder="Αναζήτηση"
            ref={(r) => ((r || {}).onsearch = (e) => handleSearch(e))}
          />
          <label htmlFor={`Editor-${element.name}`}>Αναζήτηση</label>
        </div> */}
      </Modal.Header>
      <Modal.Body className="p-0">
        <div id="s1Editor-list-items">
          {openModal &&
            editorData.data.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => setCurrentVal(index, item)}
                  className={`s1editor-row border px-1 ${selectedRow == index ? "rounded border-warning shadow" : ""}`}
                  style={{
                    backgroundColor: selectedRow == index ? "var(--bs-gray-400)" : "transparent",
                  }}
                >
                  <div className="m-3">
                    {editorData.model.slice(1).map((yy, i) => {
                      return (
                        <span key={i} className="me-3" style={{ display: "inline-block" }}>
                          {item[i + 1]}
                        </span>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </Modal.Body>
      <Modal.Footer className="w-100 bg-dark shadow">
        <ButtonGroup className="w-100">
          <Button
            variant="outline-warning"
            className="w-50"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Ακύρωση
          </Button>
          <Button
            className="w-50"
            variant="warning"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Εφαρμογή
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  serialnumber: state.systemparams.serialnumber,
  clientID: state.session.clientID,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SelectorEditorModal);
