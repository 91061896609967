const login = (state = JSON.parse(localStorage.getItem("login")), action) => {
  switch (action.type) {
    case "login/setLogin":
      localStorage.setItem("login", JSON.stringify(action.value));
      return action.value;
    case "login/setNewPassword":
      localStorage.setItem("login", JSON.stringify(action.value));
      return action.value;
    case "session/clear":
      localStorage.setItem("login", null);
      return null;
    default:
      return state;
  }
};

export default login;
