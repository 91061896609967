import React from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import {
  GETBUFFDATA,
  GETDATA,
  GETFORMDESIGN,
  GETNEWDATA,
  GETOBJECTSTATE,
} from "../../../../../../redux/relJobSelectors";
import { SetChanges } from "../../../../../../redux/actions/relJob";
import { calculate } from "../../functions/calculate";

const S1NumberField = ({ element, objectState, formdesign, data, handleSetChange }) => {
  const {
    index,
    flex,
    group,
    xtype,
    name,
    caption,
    value,
    editor,
    dataType,
    maskEnabled,
    visible,
    required,
    readOnly,
    selectorVF,
    redirector,
    decimals,
  } = element;
  const [viewValue, setViewValue] = React.useState("");
  const table = name.split(".")[0];
  const key = name.split(".")[1];

  const model = formdesign.model[table].fields.filter((el) => el.name == key)[0];

  const change = (value) => {
    //pattern="(d*[.])?d+"
    const Value = value.replace(",", ".");
    const dotsNo = Value.replace(/[^.]/g, "").length;
    const regEx = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;
    if (dotsNo < 2 || regEx.test(Value)) setViewValue(Value);
  };

  const handleChangeField = (value) => {
    setViewValue(value);
    handleSetChange(table, key, 0, value);
    model?.updates != "" && calculate();
  };

  //calculate Changes & Initialization
  React.useEffect(() => {
    if (data?.[table]?.[0]?.[key]) {
      setViewValue(data?.[table]?.[0]?.[key] ?? "");
    }
  }, [data?.[table]?.[0]?.[key]]);

  return (
    <div className="s1numberfield w-100" style={{ flex: `${flex * 100}%`, overflow: "hidden" }}>
      <InputGroup className="crm-floating-with-icon" hidden={!visible}>
        <FloatingLabel controlId={index} label={caption} style={{ whiteSpace: "nowrap" }} className="w-100">
          <Form.Control
            className="crm-input ps-1"
            type="text"
            placeholder={caption}
            disabled={objectState === "view"}
            readOnly={readOnly}
            name={name}
            style={{ border: "0" }}
            value={viewValue}
            onChange={(e) => change(e.target.value)}
            onBlur={(e) => handleChangeField(e.target.value)}
          />
        </FloatingLabel>
        {objectState != "view" && viewValue != "" && !readOnly && (
          <div className="d-flex align-items-center justify-content-center" onClick={() => handleChangeField("")}>
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
  data: { view: GETDATA(state)?.data, edit: GETBUFFDATA(state), new: GETNEWDATA(state) }[GETOBJECTSTATE(state)],
  formdesign: GETFORMDESIGN(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetChange: (table, key, index, value) => dispatch(SetChanges(table, key, index, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1NumberField);
