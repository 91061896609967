import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, ButtonGroup, Form, InputGroup, FloatingLabel } from "react-bootstrap";
import { TiDeleteOutline } from "react-icons/ti";
import { connect } from "react-redux";
import Spinner from "../../../../Spinner";
import { HiX, HiCheck } from "react-icons/hi";

function MemoryTableEditorModal({
  serialnumber,
  clientID,
  setViewValue,
  setKeyValue,
  show,
  setShow,
  element,
  data,
  editor,
  predifinedKey,
  handleChange,
}) {
  const [loading, setLoading] = useState(false);
  const listRef = useRef();
  const [selectedRow, setSelectedRow] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [keyIndex, setKeyIndex] = useState(0);

  const handleAccept = async () => {
    try {
      setLoading(true);
      const filters = editor.split("|")[4];
      const tablename = editor.split("|")[1];
      const keyname = editor.split("|")[2];
      const keyvalue = data.data[selectedRow][keyIndex];
      const resultfields = element.selectorVF.split("|").map((i) => i.split(".")[1]);
      var key = resultfields[0];
      var value = resultfields[resultfields.length - 1];
      const selectorFields = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
        url: `http://${serialnumber}.oncloud.gr/s1services`,
        data: {
          service: "selectorFields",
          clientID,
          APPID: process.env.REACT_APP_SOFTONE_APPID,
          filters,
          tablename,
          keyname,
          keyvalue,
          resultfields: resultfields.join(","),
        },
      });
      if (selectorFields.data.success) {
        setViewValue(selectorFields.data.rows[0][value]);
        setKeyValue(selectorFields.data.rows[0][key]);
        handleChange(
          element.name.split(".")[0],
          element.name.split(".")[1],
          0,
          `${selectorFields.data.rows[0][key]}|${selectorFields.data.rows[0][value]}`
        );
        setLoading(true);
        setShow(false);
      } else {
        setLoading(true);
        console.log(selectorFields.data);
      }
    } catch (error) {
      setLoading(true);
      console.log(error);
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    var filter, span, i, txtValue;
    var is = [];
    filter = value.toUpperCase();
    span = listRef.current.querySelectorAll("span");
    for (i = 0; i < span.length; i++) {
      span[i].parentElement.parentElement.style.display = "none";
      txtValue = span[i].textContent || span[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        is.push(span[i].parentElement.parentElement);
      } else {
        span[i].parentElement.parentElement.style.display = "none";
      }
      is.forEach((t) => (t.style.display = ""));
    }
  };

  useEffect(() => {
    const keyname = editor.split("|")[2];
    var keyIndex, i;
    for (i = 0; i < data.model.length; i++) {
      if (data.model[i].name == keyname) {
        keyIndex = i;
        setKeyIndex(i);
        break;
      }
    }
    if (predifinedKey != "") {
      for (i = 0; i < data.data.length; i++) {
        if (data.data[i][keyIndex] == predifinedKey) {
          setSelectedRow(i);
          break;
        }
      }
    }
  }, []);

  const handleClearEditor = () => {
    setSearchValue("");
    listRef.current.childNodes.forEach((t) => (t.style.display = ""));
  };

  return (
    <Modal
      animation={false}
      show={show}
      centered
      fullscreen
      dialogClassName="p-3"
      contentClassName="rounded border border-warning shadow overflow-hidden bg-dark"
    >
      {loading && <Spinner colorSpin="#ffc107" />}
      <Modal.Header className="px-2 pt-0">
        <InputGroup className="crm-floating-with-icon">
          <FloatingLabel
            controlId={`Editor-${element.name}`}
            label="Αναζητηση"
            style={{ whiteSpace: "nowrap" }}
            className="w-100"
          >
            <Form.Control
              value={searchValue}
              autoCapitalize="off"
              className="crm-input form-control ps-1"
              placeholder="Αναζητηση"
              style={{ border: "0" }}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </FloatingLabel>
          {searchValue != "" && (
            <div className="d-flex align-items-center justify-content-center" onClick={handleClearEditor}>
              <TiDeleteOutline size="1.75rem" />
            </div>
          )}
        </InputGroup>
      </Modal.Header>
      <Modal.Body className="p-0 text-light">
        <div ref={listRef}>
          {show &&
            data.data.map((item, Findex) => {
              return (
                <div
                  key={Findex}
                  onClick={() => setSelectedRow(Findex)}
                  className={`s1editor-row border-top border-bottom px-1 ${
                    selectedRow == Findex ? "rounded border-warning shadow" : ""
                  }`}
                  style={{
                    backgroundColor: selectedRow == Findex ? "var(--bs-gray-400)" : "transparent",
                    color: selectedRow == Findex ? "var(--bs-dark)" : "white",
                  }}
                >
                  <div className="my-3 mx-2">
                    {item.map((field, Sindex) => {
                      return (
                        <span key={Sindex} className="me-2" style={{ display: "inline-block" }}>
                          {field}
                        </span>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </Modal.Body>
      <Modal.Footer className="p-0 w-100 bg-dark shadow border-0">
        <ButtonGroup className="m-0 w-100">
          <Button
            variant="outline-warning"
            className="w-50 d-flex justify-content-center align-items-center"
            onClick={() => setShow(false)}
          >
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Ακύρωση
            </div>
          </Button>
          <Button
            className="w-50 d-flex justify-content-center align-items-center"
            variant="outline-warning"
            onClick={handleAccept}
          >
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Εφαρμογή
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  serialnumber: state.systemparams.serialnumber,
  clientID: state.session.clientID,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MemoryTableEditorModal);
