export const SetCurrentScreen = (value) => ({ type: "general/setCurrentScreen", value });

export const SetScreenState = (value) => ({ type: "general/screenState", value });

export const SetObjectState = (value) => ({ type: "general/objectState", value });

export const CancelEdits = () => ({ type: "editMaster/cancelEdits" });

export const SetAccessRights = (value) => ({ type: "general/setAccessRights", value });

export const SetPageY = (value) => ({ type: "general/setPageY", value });

export const SetWebMenu = (value) => ({ type: "general/setWebMenu", value });

//---------------------------- EDIT MASTER ---------------------------

export const InitObject = () => ({ type: "editMaster/initialize" });

export const SetBrowserInfo = (value) => ({ type: "editMaster/setBrowserInfo", value });

export const SetBrowserData = (value) => ({ type: "editMaster/setBrowserData", value });

export const SetNextBrowserData = (value, nextstart) => ({ type: "editMaster/setNextBrowserData", value, nextstart });

export const SetSelectedRecord = (value) => ({ type: "editMaster/setSelectedRecord", value });

export const SetFormDesign = (value) => ({ type: "editMaster/setFormDesign", value });

export const setGetData = (value) => ({ type: "editMaster/setData", value });

export const CanSignature = () => ({ type: "editMaster/CanSignature" });

export const SetCheckInOut = (checkType, value) => ({ type: "editMaster/SetCheckInOut", checkType, value });

export const InitRelJob = (value) => ({ type: "relJob/Init", value });

export const OpenRelJob = (value) => ({ type: "relJob/Open", value });

export const CloseRelJob = () => ({ type: "relJob/Close" });

export const prepareNew = (value) => ({ type: "editMaster/prepareNew", value });

export const setCalculateData = (value) => ({ type: "editMaster/setCalculateData", value });

export const changeToBuff = () => ({ type: "editMaster/changeToBuffer" });

export const setRelJobMenu = (value) => ({ type: "editMaster/setRelJobMenus", value });

export const AddDetailGrid = (gridName, gridValue) => ({ type: "ADD_DETAILGRID", gridValue, gridName });

export const SetCurrentLine = (gridName, lineIndex, line) => ({ type: "SET_CURRENT_LINE", gridName, line, lineIndex });

export const DeleteGridLine = (grid, lineIndex) => ({ type: "DELETE_GRID_LINE", grid, lineIndex });

export const SetChanges = (table, key, index, value) => ({
  type: "SET_EDITMASTER_CHANGES",
  table,
  key,
  index,
  value,
});

export const SetGridChanges = (table, key, index, value) => ({
  type: "SET_GRID_CHANGES",
  table,
  key,
  index,
  value,
});

export const AddLineToGrid = (gridName, value) => ({ type: "ADD_LINE_TO_GRID", gridName, value });

export const UpdateLineOfGrid = (gridName, index, line) => ({
  type: "UPDATE_LINE_OF_GRID",
  gridName,
  index,
  line,
});

export const TriggerGridChange = (gridName) => ({ type: "TRIGGER_GRID_CHANGE", gridName });

export const AddURLScreen = (screen) => ({ type: "general/AddScreenFromURL", screen });
//---------------------------- REPORT ---------------------------

export const InitReport = () => ({ type: "report/initialize" });

export const SetReportInfo = (value) => ({ type: "report/setReportInfo", value });

export const setReportData = (value) => ({ type: "report/setReportData", value });

export const setReportPage = (value) => ({ type: "report/setReportPage", value });

//---------------------------- DIALOG ---------------------------

export const setShowDialog = (value) => ({ type: "dialog/show", value });

export const setGetDialog = (value) => ({ type: "dialog/set", value });

export const setFilter = (table, key, value) => ({ type: "dialog/setFilter", table, key, value });

export const SetSearchFilter = (value) => ({ type: "dialog/setSearchFilter", value });

export const setSelectorFilter = (table, key, keyValue, viewValue) => ({
  type: "dialog/setSelectorText",
  table,
  key,
  keyValue,
  viewValue,
});

export const setFilterText = (value, selectorValue) => ({ type: "dialog/setFilterText", value, selectorValue });

export const resetBuffFilters = () => ({ type: "dialog/resetBuffFilters" });

export const setDateRanges = (key, value) => ({ type: "dialog/setDateRanges", key, value });

//---------------------------- BATCH JOB ---------------------------
export const InitBatchJob = () => ({ type: "INITIALIZE_BATCH" });

export const SetBatchDialog = (value) => ({ type: "SET_BATCH_DIALOG", value });

export const setBatchFilter = (table, key, value) => ({ type: "SET_BATCH_FILTER", table, key, value });

export const setBatchStart = (value) => ({ type: "SET_BATCH_START", value });

export const setBatchEnd = (value) => ({ type: "SET_BATCH_END", value });
