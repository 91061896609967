import axios from "axios";
import React, { useEffect, useState } from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { setFilter } from "../../../../../redux/actions/screen";
import { TiDeleteOutline } from "react-icons/ti";
import { GETFILTERS } from "../../../../../redux/selectors";
import MultiSelectModal from "../modals/MultiSelectModal";
import Spinner from "../../../Spinner";
import getValueFromFilterArray from "../functions/getValueFromFilterArray";

const S1MemoryTable = ({ clientID, serialnumber, element, filters, handleSetFilters }) => {
  const [viewValue, setViewValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [editorData, setEditorData] = useState({});
  const [editorString, setEditorString] = useState("");
  const [loading, setLoading] = useState(false);
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const handleEditor = async (e) => {
    e.target.blur();
    setLoading(true);
    try {
      const EditorArguments = Array.from(element.editor.matchAll(/\[([^\][]*)]/g), (x) => x[1]);
      var editor = element.editor;
      EditorArguments.forEach((y) => {
        var splitted = y.split(".");
        var tableName = splitted[0];
        var fieldName = splitted[1];
        var replaceValue =
          fieldName == "COMPANY"
            ? ":X.SYS.MAINCOMPANY"
            : filters?.buffdata?.[tableName]?.[fieldName].split("|")[0] ?? "";
        editor = editor.replaceAll(`[${y}]`, replaceValue);
      });
      const GetSelectorData = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
        url: `http://${serialnumber}.oncloud.gr/s1services`,
        data: {
          service: "GetSelectorData",
          clientID,
          appId: process.env.REACT_APP_SOFTONE_APPID,
          editor,
          VALUE: "",
        },
      });
      if (GetSelectorData.data.success) {
        setEditorData(GetSelectorData.data);
        setEditorString(editor);
        setOpenModal(true);
      } else {
        console.log(GetSelectorData.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (value) => {
    setViewValue(value);
    handleSetFilters(table, key, value);
  };

  useEffect(() => {
    const value = filters?.text ? getValueFromFilterArray(table, key) : element.value;
    setViewValue(value);
    value != "" && handleSetFilters(table, key, value);
  }, []);

  //For Reset
  useEffect(() => {
    if (filters?.reset) {
      setViewValue("");
    }
  }, [filters?.reset]);

  return (
    <div
      className={`s1-multiselect ${element.visible ? "" : "s1-hidden"} w-100`}
      style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}
      key={element.index}
    >
      {loading && <Spinner colorSpin="#ffc107" />}
      <InputGroup className={`crm-floating-with-icon${element.required && viewValue == "" ? " input-required" : ""}`}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            value={viewValue}
            editor={element.editor}
            inputMode="none"
            className="crm-input form-control ps-1"
            placeholder={element.caption}
            name={element.name}
            disabled={element.readOnly}
            readOnly={element.readOnly}
            style={{ border: "0" }}
            onClick={handleEditor}
            onChange={(e) => setViewValue(e.target.value)}
          />
        </FloatingLabel>
        {viewValue != "" && !element.readOnly && (
          <div className="d-flex align-items-center justify-content-center pt-3" onClick={() => handleChange("")}>
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
      {openModal && (
        <MultiSelectModal
          element={element}
          openModal={openModal}
          setOpenModal={setOpenModal}
          data={editorData}
          editor={editorString}
          setViewValue={setViewValue}
          selected={viewValue}
          handleChange={handleChange}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  serialnumber: state.systemparams.serialnumber,
  clientID: state.session.clientID,
  filters: GETFILTERS(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetFilters: (table, key, value) => dispatch(setFilter(table, key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1MemoryTable);
