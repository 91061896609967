import React from "react";
import { Row } from "react-bootstrap";
import { connect } from "react-redux";

const CompanyDetails = ({ session, systemparams }) => {
  const blcdate = new Date(session.blcdate);
  const address = [
    systemparams.companyinfo.address,
    " " + systemparams.companyinfo.district,
    " " + systemparams.companyinfo.zip,
  ];
  address.filter((word) => word !== " ");
  const render = [
    { name: "Επωνυμία:", value: systemparams.companyinfo.name },
    { name: "Διευθυνση:", value: address.join(",") },
    { name: "ΑΦΜ:", value: systemparams.companyinfo.afm },
    { name: "ΔΟΥ:", value: systemparams.companyinfo.irsdata },
    { name: "SN:", value: systemparams.serialnumber },
    { name: "Χρήστες WebCRM:", value: session.users },
    { name: "Λήξη WebCRM:", value: dateformat(blcdate) },
  ];
  return (
    <>
      {render.map((value, index) => (
        <React.Fragment key={index}>
          <Row>
            <span
              style={{
                color: "#b1b1b1",
                fontWeight: "400",
              }}
            >
              {value.name}
            </span>
          </Row>

          <Row className="text-light">
            <span style={{ fontWeight: "600" }}>{value.value}</span>
          </Row>
        </React.Fragment>
      ))}
    </>
  );
};

const mapStateToProps = (state) => ({
  systemparams: state.systemparams,
  session: state.session,
});

export default connect(mapStateToProps)(CompanyDetails);

const dateformat = (d) => {
  return [d.getDate().padLeft(), (d.getMonth() + 1).padLeft(), d.getFullYear()].join("/");
};
