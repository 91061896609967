import axios from "axios";
import { GETCLIENTID, GETCOMMAND, GETFILTERS, GETSN, GETURL } from "../../redux/relJobSelectors";
import store from "../../redux/store";

const getReportInfo = async () => {
  const state = store.getState();
  const url = GETURL(state);
  const clientID = GETCLIENTID(state);
  const serialnumber = GETSN(state);
  const command = GETCOMMAND(state);
  const filters = GETFILTERS(state)?.text ?? "";
  const object = `${serialnumber}/object=${command}`;

  return await axios
    .post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
      url,
      data: {
        service: "getReportInfo",
        clientID,
        object,
        filters,
        appID: process.env.REACT_APP_SOFTONE_APPID,
      },
    })
    .then((res) => res.data)
    .catch((er) => ({ success: false, error: er.message }));
};

export default getReportInfo;
