import React, { useState } from "react";
import { Button, Card, ButtonGroup } from "react-bootstrap";
import { motion } from "framer-motion";
import { AiOutlineClose } from "react-icons/ai";
import { connect } from "react-redux";
import { ImCancelCircle, ImCheckmark } from "react-icons/im";
import { IoWarningOutline } from "react-icons/io5";
import Spinner from "../Spinner";
import axios from "axios";
import { ClearSession } from "../../../redux/actions/session";
import { setLogoutModal } from "../../../redux/actions/modals";
import { HiX, HiCheck } from "react-icons/hi";

const ModalLogOut = ({ show, setShow, loginid, handleLogOut }) => {
  const [loading, setLoading] = useState(false);
  const handleConfirm = async () => {
    try {
      setLoading(true);
      const logout = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/logout`, {
        key: loginid,
      });
      setShow(false);
      setLoading(false);
      handleLogOut();
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  const variants = {
    open: { opacity: 1, pointerEvents: "auto" },
    closed: {
      opacity: 0,
      pointerEvents: "none",
    },
  };

  return (
    <motion.div
      variants={variants}
      initial="closed"
      exit="closed"
      transition={{ duration: 0.5, ease: "easeInOut" }}
      animate={`${show ? "open" : "closed"}`}
      className="crm-modal-logout Overlay d-flex align-items-center justify-content-center"
    >
      {loading && <Spinner colorSpin="#ffc107" />}
      <div
        className="justify-content-center
        align-items-center bg-dark rounded shadow w-100 mx-2"
        style={{ maxWidth: "600px" }}
      >
        <div className="border border-warning border-1 rounded">
          <Card className="bg-dark pb-0 px-0 text-light shadow border border-warning rounded p-4">
            <Card.Body>
              <Button onClick={() => setShow(false)} variant="outline-warning" className="closeButton rounded-0 shadow">
                <AiOutlineClose />
              </Button>
              <div className="text-center text-light">
                <h4>
                  <b>Αποσύνδεση</b>
                </h4>
                <div className="py-4">
                  <IoWarningOutline fontSize={"6rem"} />
                </div>

                <h5>Επιθυμείτε να συνεχίσετε;</h5>
              </div>
            </Card.Body>
            <Card.Footer
              style={{
                background: "transparent",
                borderBlockColor: "transparent",
              }}
              className="p-0 w-100 shadow border-0"
            >
              {/* <ButtonGroup className="w-100">
                <Button
                  variant="outline-warning"
                  className="w-50 d-flex align-content-center
                          justify-content-center align-items-center"
                  onClick={() => setShow(false)}
                >
                  <ImCancelCircle className="pe-none me-2" />
                  Όχι
                </Button>

                <Button 
                  variant="warning"
                  className="w-50 d-flex align-content-center
                          justify-content-center align-items-center"
                  onClick={handleConfirm}
                >
                  <ImCheckmark className="pe-none me-2" />
                  Ναί
                </Button>
              </ButtonGroup> */}
              <ButtonGroup className="m-0 w-100">
                <Button
                  variant="outline-warning"
                  className="w-50 d-flex justify-content-center align-items-center rounded-0"
                  onClick={() => {
                    setShow(false);
                  }}
                >
                  <HiX size={25} color="var(--bs-danger)" className="me-2" />
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ fontSize: 20, color: "white" }}
                  >
                    Κλείσμο
                  </div>
                </Button>
                <Button
                  className="w-50 d-flex justify-content-center align-items-center rounded-0"
                  variant="outline-warning"
                  onClick={handleConfirm}
                >
                  <HiCheck size={25} color="var(--bs-success)" className="me-2" />
                  <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ fontSize: 20, color: "white" }}
                  >
                    Αποσύνδεση
                  </div>
                </Button>
              </ButtonGroup>
            </Card.Footer>
          </Card>
        </div>
      </div>
    </motion.div>
  );
};

const mapStateToProps = (state) => ({
  loginid: state.session.loginid,
  url: state.session.url,
  show: state.modals.logout,
});

const mapDispatchToProps = (dispatch) => ({
  handleLogOut: () => dispatch(ClearSession()),
  setShow: (value) => dispatch(setLogoutModal(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalLogOut);
