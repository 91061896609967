import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { GETOUTPUTFORMATS, GETSCREENOBJ, GETSN } from "../../../../redux/relJobSelectors";
import Spinner from "../../Spinner";
import getReportInfo from "../../../../services/relJob/getReportInfo";
import getBrowserInfo from "../../../../services/relJob/getBrowserInfo";
import print from "../../../../services/relJob/print";

const ModalPrintList = ({ serialnumber, show, setShow, outputformats, cmd }) => {
  const [format, setFormat] = useState("none");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    show && setFormat("none");
  }, [show]);

  const handlePrint = async () => {
    setLoading(true);
    var reqID = "";
    setLoading(true);
    switch (cmd) {
      case "Report":
        const reportInfo = await getReportInfo();
        if (reportInfo.success) {
          reqID = reportInfo.reqID;
        }
        break;
      case "EditMaster":
        const browserInfo = await getBrowserInfo();
        if (browserInfo.success) {
          reqID = browserInfo.reqID;
        }
        break;
      default:
        console.log("den exeis kanei print gia ", cmd);
        break;
    }
    const printDoc = await print({ reqID, format });
    if (printDoc.success) {
      setShow(false);
      window.open(
        `https://${serialnumber}.oncloud.gr/s1services/?filename=${printDoc.filename}`,
        "",
        "location=yes,scrollbars=yes,status=yes"
      );
    } else {
      console.log("error whilw printing:", printDoc);
    }

    setLoading(false);
  };

  return (
    <Modal
      contentClassName="bg-dark text-light border-warning"
      className="crm-print-list"
      show={show}
      onHide={() => setShow(false)}
      size="lg"
      centered
    >
      {loading && <Spinner colorSpin="#ffc107" />}
      <Modal.Body>
        <Form.Select className="bg-dark text-light" onChange={(e) => setFormat(e.target.value)} value={format}>
          <option value="none" disabled>
            Επιλέξτε εκτυπωτή
          </option>
          {Object.entries(outputformats ?? {}).map(([key, value], i) => {
            return (
              <option key={i} value={key}>
                {value}
              </option>
            );
          })}
        </Form.Select>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-evenly">
        <Button variant="secondary" onClick={() => setShow(false)}>
          Ακύρωση
        </Button>
        <Button variant="primary" disabled={format == "none"} onClick={handlePrint}>
          Αποδοχή
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  outputformats: GETOUTPUTFORMATS(state),
  cmd: GETSCREENOBJ(state)?.cmd,
  serialnumber: GETSN(state),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ModalPrintList);
