import axios from "axios";
import React, { useEffect, useState } from "react";
import { Form, Button, Modal, ButtonGroup, InputGroup, FloatingLabel } from "react-bootstrap";
import { TiDeleteOutline } from "react-icons/ti";
import { connect } from "react-redux";
import Spinner from "../../../../Spinner";
import { HiX, HiCheck } from "react-icons/hi";

function MaskTextFieldModal({
  serialnumber,
  clientID,
  setViewValue,
  show,
  setShow,
  element,
  data,
  editor,
  handleChange,
}) {
  const [loading, setLoading] = useState(false);
  const [editorData, setEditorData] = useState(data);
  const [searchValue, setSearchValue] = useState("");
  const [selectedRow, setSelectedRow] = useState();
  const [hasIdentifier, setHasIdentifier] = useState(false);
  const [identifierCol, setIdentifierCol] = useState(false);

  const handleAccept = async () => {
    setLoading(true);
    try {
      const filters = editor.split("|")[4];
      const tablename = editor.split("|")[1];
      const keyname = editor.split("|")[2];
      const keyvalue = data.data[selectedRow][identifierCol];
      const resultfields = [...new Set(data.model.map((i) => i.name))];
      const keyForVF = [];
      const selectorFields = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
        url: `http://${serialnumber}.oncloud.gr/s1services`,
        data: {
          service: "selectorFields",
          clientID,
          APPID: process.env.REACT_APP_SOFTONE_APPID,
          filters,
          tablename,
          keyname,
          keyvalue,
          resultfields: resultfields.join(","),
        },
      });
      if (selectorFields.data.success) {
        var value = "";
        if (element.selectorVF != "") {
          element.selectorVF.split("|").forEach((i) => {
            var dbIndex = i.split(".")[1].split("_");
            keyForVF.push(dbIndex[dbIndex.length - 1]);
            value += selectorFields.data.rows[0][dbIndex[dbIndex.length - 1]] + " ";
          });
        } else {
          value = selectorFields.data?.rows?.[0]?.[keyname] ?? keyvalue;
        }
        setViewValue(value);
        setLoading(false);
        await handleChange(element.name.split(".")[0], element.name.split(".")[1], 0, value);
        setShow(false);
      } else {
        setLoading(false);
        console.log(selectorFields.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleSearch = async (value) => {
    setSelectedRow(undefined);
    setLoading(true);
    try {
      const GetSelectorData = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
        url: `http://${serialnumber}.oncloud.gr/s1services`,
        data: {
          service: "GetSelectorData",
          clientID,
          appId: process.env.REACT_APP_SOFTONE_APPID,
          editor,
          value,
        },
      });
      if (GetSelectorData.data.success) {
        setEditorData(GetSelectorData.data);
      } else {
        console.log(GetSelectorData.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setEditorData(data);
    const keyname = editor.split("|")[2];
    for (let index = 0; index < data.model.length; index++) {
      if (data.model[index].name == keyname) {
        setHasIdentifier(true);
        setIdentifierCol(index);
        break;
      }
    }
  }, []);
  return (
    <Modal
      animation={false}
      show={show}
      centered
      fullscreen
      dialogClassName="p-3"
      contentClassName="rounded border border-warning shadow overflow-hidden bg-dark"
    >
      {loading && <Spinner colorSpin="#ffc107" />}
      <Modal.Header className="px-2 text-light pt-0">
        <InputGroup className="crm-floating-with-icon">
          <FloatingLabel
            controlId={`Editor-${element.name}`}
            label="Αναζητηση"
            style={{ whiteSpace: "nowrap" }}
            className="w-100"
          >
            <Form.Control
              value={searchValue}
              type="search"
              autoCapitalize="off"
              className="crm-input form-control ps-1"
              placeholder="Αναζητηση"
              style={{ border: "0" }}
              onChange={(e) => setSearchValue(e.target.value)}
              ref={(r) => ((r || {}).onsearch = (e) => handleSearch(e.target.value))}
            />
          </FloatingLabel>
          {searchValue != "" && (
            <div
              className="d-flex align-items-center justify-content-center"
              onClick={() => {
                setSearchValue("");
                handleSearch("");
              }}
            >
              <TiDeleteOutline size="1.75rem" />
            </div>
          )}
        </InputGroup>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div id="s1Editor-list-items">
          {show &&
            editorData.data.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => setSelectedRow(index)}
                  className={`s1editor-row border px-1 ${selectedRow == index ? "rounded border-warning shadow" : ""}`}
                  style={{
                    backgroundColor: selectedRow == index ? "var(--bs-gray-400)" : "transparent",
                  }}
                >
                  <div className="my-3 mx-2">
                    {hasIdentifier ? (
                      <span key={index} className="me-2">
                        {item[identifierCol]}
                      </span>
                    ) : (
                      editorData.model.map((yy, i) => {
                        return (
                          <span key={i} className="me-2">
                            {item[i]}
                          </span>
                        );
                      })
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </Modal.Body>
      <Modal.Footer className="p-0 w-100 bg-dark shadow border-0">
        <ButtonGroup className="m-0 w-100">
          <Button
            variant="outline-warning"
            className="w-50 d-flex justify-content-center align-items-center"
            onClick={() => setShow(false)}
          >
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Ακύρωση
            </div>
          </Button>
          <Button
            className="w-50 d-flex justify-content-center align-items-center"
            variant="outline-warning"
            onClick={handleAccept}
          >
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Εφαρμογή
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  serialnumber: state.systemparams.serialnumber,
  clientID: state.session.clientID,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MaskTextFieldModal);
