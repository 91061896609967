import React from "react";
import { connect } from "react-redux";
import { GETBUFFDATA, GETDATA, GETNEWDATA, GETOBJECTSTATE } from "../../../../../redux/selectors";
import { SetCurrentLine } from "../../../../../redux/actions/screen";
import { ImBin } from "react-icons/im";
import AddLine from "./input modals/AddLine";
import ModalDeleteLine from "./ModalDeleteLine";

const GridRows = ({ grid, data, objectState, handleSetCurrentLine }) => {
  const [openLineModal, setOpenLineModal] = React.useState(false);
  const [openDeleteLine, setOpenDeleteLine] = React.useState(false);
  const [deleteIndex, setDeleteIndex] = React.useState(-1);

  const handleClickOnRow = (index, line) => {
    if (line.LINENUM > 9000000) {
      handleSetCurrentLine(grid.model, index, line);
    } else {
      handleSetCurrentLine(grid.model, index, { LINENUM: line.LINENUM });
    }
    setOpenLineModal(true);
  };

  const handleDeleteLine = (index) => {
    setDeleteIndex(index);
    setOpenDeleteLine(true);
  };

  return (
    <>
      {data?.[grid.model] &&
        data[grid.model].map((item, index) => {
          return (
            <div
              key={index}
              className="crm-grid-line border-bottom my-1 d-flex justify-content-between align-items-center"
            >
              <div className="px-1 py-2" onClick={() => handleClickOnRow(index, item)}>
                {grid.columns.map((sItem, sIndex) => {
                  var name = sItem.name.split(".");
                  name = name[name.length - 1];
                  var value =
                    sItem.dataType == "Float"
                      ? parseFloat(item?.[name] && item?.[name] != "" ? item[name] : 0).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                        })
                      : item[name];

                  return (
                    <div key={sIndex} style={{ display: "inline-block" }} className="me-4">
                      <span
                        style={{
                          color: "#b1b1b1",
                          fontWeight: "400",
                        }}
                      >{`${sItem.caption}: `}</span>
                      <span className="grid-editor-field" style={{ color: "white", fontWeight: "600" }}>
                        {value}
                      </span>
                    </div>
                  );
                })}
              </div>
              {objectState != "view" && (
                <div className="pe-1" onClick={() => handleDeleteLine(index)}>
                  <ImBin color="var(--bs-danger)" size={"1rem"} />
                </div>
              )}
            </div>
          );
        })}
      {openLineModal && (
        <AddLine
          show={openLineModal}
          setShow={setOpenLineModal}
          grid={grid}
          fields={grid.columns}
          gridSelector={grid.gridSelector}
        />
      )}
      <ModalDeleteLine show={openDeleteLine} grid={grid.model} setShow={setOpenDeleteLine} index={deleteIndex} />
    </>
  );
};

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
  data: { view: GETDATA(state)?.data, edit: GETBUFFDATA(state), new: GETNEWDATA(state) }[GETOBJECTSTATE(state)],
});

const mapDispatchToProps = (dispatch) => ({
  handleSetCurrentLine: (gridName, lineIndex, line) => dispatch(SetCurrentLine(gridName, lineIndex, line)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GridRows);
