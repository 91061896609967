import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import { HiX } from "react-icons/hi";
import { connect } from "react-redux";
import { setLoading } from "../../../../../redux/actions/modals";
import { SetBrowserData, SetBrowserInfo, SetSearchFilter } from "../../../../../redux/actions/relJob";
import { GETFILTERS } from "../../../../../redux/relJobSelectors";

const SearchOnList = ({
  serialnumber,
  clientID,
  screen,
  handleSetGetBrowserInfo,
  handleSetGetBrowserData,
  handleLoading,
  handleSetFilters,
  searching,
  setSearching,
  filters,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = async (e) => {
    var value = e?.target?.value ?? e;
    e?.target?.value && e.preventDefault();
    e?.target?.value && e.target.blur();
    window.scrollTo({ top: 0, left: 0 });
    handleSetFilters(value == "" ? undefined : value);
    var PostFilters = `${filters?.text ?? ""}&SEARCH=${value}`;
    try {
      handleLoading(true);
      const getBrowserInfo = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
        url: `http://${serialnumber}.oncloud.gr/s1services`,
        data: {
          service: "GetBrowserInfo",
          clientID,
          object: `${serialnumber}/object=${screen.command}`,
          sort: filters?.sort ?? "",
          filters: PostFilters,
          appID: process.env.REACT_APP_SOFTONE_APPID,
        },
      });
      if (getBrowserInfo.data.success) {
        getBrowserInfo.data.upddate = new Date();
        handleSetGetBrowserInfo(getBrowserInfo.data);
        if (getBrowserInfo.data.totalcount > 0) {
          const getBrowserData = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
            url: `http://${serialnumber}.oncloud.gr/s1services`,
            data: {
              service: "GetBrowserData",
              clientID,
              reqid: getBrowserInfo.data.reqID,
              start: 0,
              limit: process.env.REACT_APP_GETBROWSERDATA_LIMIT,
              appID: process.env.REACT_APP_SOFTONE_APPID,
            },
          });
          if (getBrowserData.data.success) {
            handleSetGetBrowserData(getBrowserData.data);
          } else {
            console.log(getBrowserData.data);
          }
        } else {
          console.log("no data for such search");
        }
      } else {
        console.log(getBrowserInfo.data);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      handleLoading(false);
    }
  };

  useEffect(() => {
    setSearchValue(filters?.search ?? "");
  }, []);

  useEffect(() => {
    if (!filters?.search) {
      setSearchValue("");
      setSearching(false);
    }
  }, [filters]);

  return (
    <div className="search-box w-100 d-flex justify-content-end" style={{ transition: "0.4s" }}>
      {searching && (
        <>
          <InputGroup>
            <Form.Control
              type="search"
              className="crm-search-on-list text-light bg-dark"
              placeholder="Αναζήτηση"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              ref={(x) => ((x || {}).onsearch = (e) => handleSearch(e))}
            />
          </InputGroup>
          {searchValue && (
            <Button
              onClick={() => {
                setSearchValue("");
                handleSearch("");
              }}
              style={{
                borderColor: "transparent",
                backgroundColor: "transparent",
              }}
            >
              <HiX />
            </Button>
          )}
        </>
      )}

      <Button className="ms-1 b-0" style={{ borderColor: "transparent", backgroundColor: "transparent" }}>
        <FaSearch className="search-btn" color="var(--bs-warning)" onClick={() => setSearching((x) => !x)} />
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  serialnumber: state.systemparams.serialnumber,
  clientID: state.session.clientID,
  screen: state.screen.screens[`${state.screen.currentscreen}`],
  filters: GETFILTERS(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetGetBrowserInfo: (value) => dispatch(SetBrowserInfo(value)),
  handleSetGetBrowserData: (value) => dispatch(SetBrowserData(value)),
  handleLoading: (value) => dispatch(setLoading(value)),
  handleSetFilters: (value) => dispatch(SetSearchFilter(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchOnList);
