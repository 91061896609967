import axios from "axios";
import React from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { SetChanges } from "../../../../../../redux/actions/screen";
import { setLoading } from "../../../../../../redux/actions/modals";
import MemoryTableEditorModal from "../input modals/MemoryTableEditorModal";
import { TiDeleteOutline } from "react-icons/ti";
import { GETBUFFDATA, GETDATA, GETFORMDESIGN, GETNEWDATA, GETOBJECTSTATE } from "../../../../../../redux/selectors";
import { calculate } from "../../functions/calculate";

const S1MemoryTable = ({
  clientID,
  serialnumber,
  element,
  objectState,
  data,
  handleLoading,
  handleSetChange,
  formdesign,
}) => {
  const [viewValue, setViewValue] = React.useState("");
  const [keyValue, setKeyValue] = React.useState("");
  const [openModal, setOpenModal] = React.useState(false);
  const [editorData, setEditorData] = React.useState({});
  const [editorString, setEditorString] = React.useState("");

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const model = formdesign.model[table].fields.filter((el) => el.name == key)[0];

  const handleEditor = async (e) => {
    e.target.blur();
    handleLoading(true);
    try {
      const EditorArguments = Array.from(element.editor.matchAll(/\[([^\][]*)]/g), (x) => x[1]);
      var editor = element.editor;
      EditorArguments.forEach((y) => {
        const splitted = y.split(".");
        const tableName = splitted[0];
        const fieldName = splitted[1];
        const replaceValue = (data?.[tableName]?.[0]?.[fieldName] ?? "").split("|")[0];
        editor = editor.replaceAll(`[${y}]`, replaceValue);
      });
      const GetSelectorData = await axios.post(`${process.env.REACT_APP_BACKEND_DOMAIN}/d1services`, {
        url: `http://${serialnumber}.oncloud.gr/s1services`,
        data: {
          service: "GetSelectorData",
          clientID,
          appId: process.env.REACT_APP_SOFTONE_APPID,
          editor,
          VALUE: "",
        },
      });
      if (GetSelectorData.data.success) {
        setEditorData(GetSelectorData.data);
        setEditorString(editor);
        setOpenModal(true);
      } else {
        console.log(GetSelectorData.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      handleLoading(false);
    }
  };

  const handleChange = (table, key, index, value) => {
    handleSetChange(table, key, index, value);
    model?.updates != "" && calculate();
  };

  //calculate Changes And Initialization
  React.useEffect(() => {
    if (data?.[table]?.[0]?.[key]) {
      var values = (data?.[table]?.[0]?.[key] ?? "").split("|");
      setViewValue(values[values.length - 1]);
      setKeyValue(values[0]);
    }
  }, [data?.[table]?.[0]?.[key]]);

  return (
    <div
      className="s1memorytable w-100"
      style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}
      key={element.index}
    >
      <InputGroup
        className={`crm-floating-with-icon ${element.required && viewValue == "" ? " input-required" : ""}`}
        hidden={!element.visible}
      >
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            value={viewValue}
            softonekey={keyValue}
            inputMode="none"
            className="crm-input form-control ps-1"
            placeholder={element.caption}
            name={element.name}
            disabled={objectState == "view"}
            readOnly={element.readOnly}
            onClick={(e) => !element.readOnly && handleEditor(e)}
            style={{ border: "0" }}
            onChange={(e) => setViewValue(e.target.value)}
            //   onBlur={(e) => handleChangeField(e)}
          />
        </FloatingLabel>
        {objectState != "view" && viewValue != "" && !element.readOnly && (
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={() => {
              setViewValue("");
              setKeyValue("");
              handleChange(table, key, 0, "");
            }}
          >
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
      {openModal && (
        <MemoryTableEditorModal
          element={element}
          show={openModal}
          setShow={setOpenModal}
          data={editorData}
          editor={editorString}
          setKeyValue={setKeyValue}
          setViewValue={setViewValue}
          predifinedKey={keyValue}
          handleChange={handleChange}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  serialnumber: state.systemparams.serialnumber,
  clientID: state.session.clientID,
  objectState: GETOBJECTSTATE(state),
  data: { view: GETDATA(state)?.data, edit: GETBUFFDATA(state), new: GETNEWDATA(state) }[GETOBJECTSTATE(state)],
  new_data: GETNEWDATA(state),
  formdesign: GETFORMDESIGN(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleLoading: (value) => dispatch(setLoading(value)),
  handleSetChange: (table, key, index, value) => dispatch(SetChanges(table, key, index, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1MemoryTable);
