import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { HiOutlineMenu } from "react-icons/hi";
import { connect } from "react-redux";
import TopBarOnMenu from "./bars/TopBarOnMenu";
// import { newStackScreen } from "../../redux/actions/stackscreens";
// import ScreenStack from "./bars/top bar/ScreenStack";
import S1WebMenu from "./bars/side menus/S1WebMenu";
import { GETCURRENTSCREEN } from "../../redux/selectors";

function MainNavigator({ webMenuData, show, setShow, setMainNavigatiorHeight, currentscreen }) {
  const [currentMenus, setCurrentMenus] = useState(webMenuData);
  const [previousStack, setPreviousStack] = useState([]);
  const [currrentTitle, setCurrentTitle] = useState("");

  const { innerWidth } = window;

  useEffect(() => {
    const height = document.querySelector("#crm-main-navigator").offsetHeight;
    setMainNavigatiorHeight(height);
  }, []);

  useEffect(() => {
    if (currentscreen == "dashboard") {
      setCurrentMenus(webMenuData);
      setPreviousStack([]);
    }
  }, [currentscreen]);

  return (
    <div id="crm-main-navigator" className="crm-main-navigator " style={{ position: "sticky", top: "0", zIndex: 1030 }}>
      <Navbar bg="dark">
        <Container fluid>
          <Button variant="warning" onClick={() => setShow(true)} className="me-2">
            <HiOutlineMenu />
          </Button>
          {/* <ScreenStack /> */}
          <Navbar.Offcanvas
            show={show}
            onHide={() => setShow(false)}
            style={{
              maxWidth: innerWidth * 0.85,
              backgroundColor: "var(--bs-gray-dark)",
              borderRight: "1px solid var(--bs-warning",
            }}
          >
            <TopBarOnMenu setShow={setShow} />
            <S1WebMenu
              setShow={setShow}
              currentMenus={currentMenus}
              setCurrentMenus={setCurrentMenus}
              previousStack={previousStack}
              setPreviousStack={setPreviousStack}
              currrentTitle={currrentTitle}
              setCurrentTitle={setCurrentTitle}
            />
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentscreen: GETCURRENTSCREEN(state),
});

export default connect(mapStateToProps)(MainNavigator);
