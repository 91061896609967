import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, FloatingLabel, Form, InputGroup, Modal } from "react-bootstrap";
import { TiDeleteOutline } from "react-icons/ti";
import { connect } from "react-redux";
import { setBatchFilter } from "../../../../../redux/actions/screen";

const MemoryTableModal = ({
  openModal,
  setOpenModal,
  element,
  data,
  editor,
  predifinedKey,
  setViewValue,
  setKeyValue,
  handleSetBatchFilter,
}) => {
  const [editorData, setEditorData] = useState(data);
  const [selectedRow, setSelectedRow] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [returnIndex, setReturnIndex] = useState(0);

  const handleClickRow = (row) => {
    if (element.selectorVF != "") {
      var keyIndex = 0;
      const viewField =
        element.selectorVF.split("|").length > 1
          ? element.selectorVF.split("|")[1].split(".")[1]
          : element.selectorVF.split("|")[0].split(".")[1];
      for (var i = 0; i < data.model.length; i++) {
        if (data.model[i].name == viewField) {
          keyIndex = i;
          break;
        }
      }
      setKeyValue(row[returnIndex]);
      setViewValue(row[keyIndex]);
      setOpenModal(false);
    } else {
      setViewValue(row[returnIndex]);
      setKeyValue(row[returnIndex]);
      setOpenModal(false);
    }
    handleSetBatchFilter(element.name.split(".")[0], element.name.split(".")[1], row[returnIndex]);
  };

  const handleSearch = (e) => {
    setSearchValue(e);
    var filter, list, span, i, txtValue;
    var is = [];
    filter = e.toUpperCase();
    list = document.getElementById("s1Editor-list-items");
    span = list.querySelectorAll("span");
    for (i = 0; i < span.length; i++) {
      span[i].parentElement.parentElement.style.display = "none";
      txtValue = span[i].textContent || span[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        is.push(span[i].parentElement.parentElement);
      } else {
        span[i].parentElement.parentElement.style.display = "none";
      }
      is.forEach((t) => (t.style.display = ""));
    }
  };

  useEffect(() => {
    setEditorData(data);
    var keyname = editor.split("|")[2];
    var keyIndex, i;
    for (i = 0; i < data.model.length; i++) {
      if (data.model[i].name == keyname) {
        keyIndex = i;
        setReturnIndex(i);
        break;
      }
    }
    if (predifinedKey) {
      for (i = 0; i < data.data.length; i++) {
        if (data.data[i][keyIndex] == predifinedKey) {
          setSelectedRow(i);
          break;
        }
      }
    } else {
      setSelectedRow("");
    }
  }, []);

  return (
    <Modal
      animation={false}
      show={openModal}
      centered
      fullscreen
      className="overlapped-on-another-modal"
      dialogClassName="p-2"
      contentClassName="rounded overflow-hidden"
      backdropClassName="overlapped-on-another-modal"
    >
      <Modal.Header>
        <InputGroup className="crm-floating-with-icon">
          <FloatingLabel
            controlId={`Editor-${element.name}`}
            label="Αναζήτηση"
            style={{ whiteSpace: "nowrap" }}
            className="w-100"
          >
            <Form.Control
              type="search"
              className="crm-input form-control ps-1"
              placeholder="Αναζήτηση"
              style={{ border: "0" }}
              value={searchValue}
              autoCapitalize="off"
              onChange={(e) => handleSearch(e.target.value)}
            />
          </FloatingLabel>
          {searchValue != "" && (
            <div
              className="d-flex align-items-center justify-content-center"
              onClick={() => {
                setSearchValue("");
                handleSearch("");
              }}
            >
              <TiDeleteOutline size="1.75rem" />
            </div>
          )}
        </InputGroup>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div id="s1Editor-list-items">
          {openModal &&
            editorData.data.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleClickRow(item)}
                  className={`s1editor-row border px-1 ${selectedRow === index ? "rounded border-warning shadow" : ""}`}
                  style={{
                    backgroundColor: selectedRow === index ? "var(--bs-gray-400)" : "transparent",
                  }}
                >
                  <div className="my-3 mx-4">
                    {editorData.model.map((yy, i) => {
                      return (
                        <span key={i} className="me-2" style={{ display: "inline-block" }}>
                          {item[i]}
                        </span>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </Modal.Body>
      <Modal.Footer className="w-100 bg-dark shadow">
        <ButtonGroup className="w-100">
          <Button
            variant="outline-warning"
            className="w-50"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Ακύρωση
          </Button>
          <Button
            className="w-50"
            variant="warning"
            onClick={() => {
              setOpenModal(false);
            }}
          >
            Εφαρμογή
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  handleSetBatchFilter: (table, key, value) => dispatch(setBatchFilter(table, key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MemoryTableModal);
