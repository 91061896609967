import React from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import {
  GETBUFFDATA,
  GETDATA,
  GETFORMDESIGN,
  GETNEWDATA,
  GETOBJECTSTATE,
} from "../../../../../../redux/relJobSelectors";
import { calculate } from "../../functions/calculate";
import { SetChanges } from "../../../../../../redux/actions/relJob";

const S1IntField = ({ element, objectState, data, formdesign, handleSetChange }) => {
  const [viewValue, setViewValue] = React.useState("");

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const model = formdesign.model[table].fields.filter((el) => el.name == key)[0];

  const handleChange = (value) => {
    handleSetChange(table, key, 0, value);
    model?.updates != "" && calculate();
  };

  const change = (e) => {
    const regEx = /^[-+]?\d+$/;
    (regEx.test(e.target.value) || e.target.value == "") && setViewValue(e.target.value);
  };

  //calculate Changes And Initialization
  React.useEffect(() => {
    console.log(element);
    if (data?.[table]?.[0]?.[key]) {
      const value = data?.[table]?.[0]?.[key] ?? "";
      setViewValue(value);
    }
  }, [data?.[table]?.[0]?.[key]]);

  return (
    <div className="s1infield w-100" style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}>
      <InputGroup className="crm-floating-with-icon" hidden={!element.visible}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            className="crm-input ps-1"
            type="text"
            step="1"
            placeholder={element.caption}
            disabled={objectState === "view"}
            readOnly={element.readOnly}
            name={element.name}
            style={{ border: "0" }}
            value={viewValue}
            onChange={change}
            onBlur={(e) => handleChange(e.target.value)}
          />
        </FloatingLabel>
        {objectState != "view" && viewValue != "" && !element.readOnly && (
          <div
            className="d-flex align-items-center justify-content-center"
            onClick={() => {
              setViewValue("");
            }}
          >
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
      </InputGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
  data: { view: GETDATA(state)?.data, edit: GETBUFFDATA(state), new: GETNEWDATA(state) }[GETOBJECTSTATE(state)],
  formdesign: GETFORMDESIGN(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetChange: (table, key, index, value) => dispatch(SetChanges(table, key, index, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1IntField);
