import React from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { GETBUFFDATA, GETDATA, GETFORMDESIGN, GETNEWDATA, GETOBJECTSTATE } from "../../../../../../redux/selectors";

const S1SubForm = ({ element, objectState, data }) => {
  const { index, flex, name, dataType, decimals, caption, maskEnabled, visible, required, readOnly } = element;

  const [viewValue, setViewValue] = React.useState("");
  const table = name.split(".")[0];
  const key = name.split(".")[1];

  React.useEffect(() => {
    if (data?.[table]?.[0]?.[key]) {
      const value = data?.[table]?.[0]?.[key] ?? "";
      if (dataType == "Float" && value != "") {
        setViewValue(
          parseFloat(value.replace(".", ","))
            .toFixed(decimals ?? 2)
            .replace(".", ",")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        );
      } else {
        setViewValue(value);
      }
    }
  }, [data?.[table]?.[0]?.[key]]);

  return (
    <div className="s1textfield w-100" style={{ flex: `${flex * 100}%`, overflow: "hidden" }}>
      <InputGroup
        className={`crm-floating-with-icon${required && viewValue == "" ? " input-required" : ""}`}
        hidden={!visible}
      >
        <FloatingLabel controlId={index} label={caption} style={{ whiteSpace: "nowrap" }} className="w-100">
          <Form.Control
            className="crm-input ps-1"
            autoCapitalize="off"
            placeholder={caption}
            disabled={objectState === "view"}
            readOnly={true}
            name={name}
            style={{ border: "0" }}
            defaultValue={viewValue}
          />
        </FloatingLabel>
        {/* {objectState != "view" && viewValue != "" && !readOnly && (
            <div
              className="d-flex align-items-center justify-content-center"
              onClick={() => {
                setViewValue("");
                handleChangeField(table, key, 0, "");
              }}
            >
              <TiDeleteOutline size="1.75rem" />
            </div>
          )}
          {objectState == "view" && isMap && viewValue != "" && (
            <div className="d-flex align-items-center justify-content-center" onClick={openMap}>
              <BsPinMapFill size="1.6rem" color={"var(--bs-primary)"} />
            </div>
          )}
          {objectState == "view" && isPhone && viewValue != "" && (
            <div className="d-flex align-items-center justify-content-center" onClick={callNumber}>
              <BsTelephone size="1.6rem" color={"var(--bs-primary)"} />
            </div>
          )}
          {objectState != "view" && !readOnly && maskEnabled && (
            <div className="d-flex align-items-center justify-content-center" onClick={handleEditor}>
              <div className="vr m-1"></div>
              <BsChevronDown size="1.5rem" color={"var(--bs-primary)"} />
            </div>
          )} */}
      </InputGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  objectState: GETOBJECTSTATE(state),
  data: { view: GETDATA(state)?.data, edit: GETBUFFDATA(state), new: GETNEWDATA(state) }[GETOBJECTSTATE(state)],
  formdesign: GETFORMDESIGN(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(S1SubForm);
