import React from "react";
import { FloatingLabel, Form, InputGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { setFilter } from "../../../../../../redux/actions/relJob";
import { GETFILTERS } from "../../../../../../redux/relJobSelectors";
import { TiDeleteOutline } from "react-icons/ti";
import { BsCalendar3 } from "react-icons/bs";
import getValueFromFilterArray from "../../functions/rel job/getValueFromFilterArray";

const S1DateRange = ({ element, parentIndex, dateranges, handleSetFilters, filters }) => {
  const dateRef = React.useRef();
  const [viewValue, setViewValue] = React.useState("");
  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];
  const isDateTime = element.dataType == "DateTime";

  //For Reset
  React.useEffect(() => {
    if (filters?.reset) {
      setViewValue("");
    }
  }, [filters?.reset]);

  React.useEffect(() => {
    var value = "";
    const getDtValue = () => {
      if (element.value != "") {
        const dtIndex = element.value.split(":")[1].slice(-2);
        const fromOrTo = element.value.split(":")[1].substring(0, 1) == "1" ? "from" : "to";
        value = dateranges?.[`1${dtIndex}`]?.[fromOrTo] ?? "";
      } else {
        value = element.value;
      }
    };
    getDtValue();

    value = filters?.text ? getValueFromFilterArray(table, key) : value;
    value = value != "" ? dateConverter(value) : "";
    setViewValue(value);
    handleSetFilters(table, key, value != "" ? (isDateTime ? dateTimeConverter(value) : dateConverter(value)) : "*");
  }, []);

  React.useEffect(() => {
    if (filters?.daterange?.[parentIndex]) {
      const dtIndex = filters?.daterange?.[parentIndex].slice(-2);
      const fromOrTo = element.value.split(":")[1].substring(0, 1) == "1" ? "from" : "to";
      var value = dateranges?.[`1${dtIndex}`]?.[fromOrTo];
      handleChange(value == "1899-12-30" ? "" : value);
      setViewValue(value == "1899-12-30" ? "" : dateConverter(value));
    }
  }, [filters?.daterange?.[parentIndex]]);

  const handleChange = (value) => {
    setViewValue(value);
    handleSetFilters(table, key, isDateTime ? dateTimeConverter(value) : dateConverter(value));
  };

  return (
    <div
      className={`s1daterange ${element.visible ? "" : "s1-hidden"} w-100`}
      style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}
    >
      <InputGroup className="crm-floating-with-icon" style={{ borderBottom: "none" }}>
        <FloatingLabel
          controlId={element.index}
          label={element.caption}
          style={{ whiteSpace: "nowrap" }}
          className="w-100"
        >
          <Form.Control
            className={`crm-input ps-1 ${viewValue != "" ? "date-input-has-value" : ""}`}
            type="date"
            ref={dateRef}
            placeholder={element.caption}
            disabled={element.readOnly}
            readOnly={element.readOnly}
            name={element.name}
            style={{ border: "0" }}
            value={viewValue}
            onChange={(e) => handleChange(e.target.value)}
          />
        </FloatingLabel>
        {viewValue != "" && !element.readOnly && (
          <div className="d-flex align-items-center justify-content-center pt-3" onClick={() => handleChange("")}>
            <TiDeleteOutline size="1.75rem" />
          </div>
        )}
        {!element.readOnly && viewValue == "" && (
          <div
            className="d-flex align-items-center justify-content-center m-1 pt-3"
            onClick={() => {
              dateRef.current.showPicker();
              dateRef.current.click();
              dateRef.current.focus();
            }}
          >
            {/* <div className="vr m-1"></div> */}
            <BsCalendar3 size="1.5rem" color={"var(--bs-primary)"} />
          </div>
        )}
      </InputGroup>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dateranges: state.systemparams.dateranges,
  filters: GETFILTERS(state),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetFilters: (table, key, value) => dispatch(setFilter(table, key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1DateRange);

const dateConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-");
  } else {
    return value;
  }
};

const dateTimeConverter = (value) => {
  if (value != "") {
    var d = new Date(value);
    return `${[d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-")} 00:00:00`;
  } else {
    return value;
  }
};
