const stackscreens = (state = { stack: [], active: undefined }, action) => {
  switch (action.type) {
    case "NEW_STACK_SCREEN":
      var i = state.stack.findIndex((e) => e.id === action.value.id);
      if (i > -1) return { ...state, active: action.value.id };
      else
        return {
          ...state,
          stack: [...state.stack, action.value],
          active: action.value.id,
        };
    case "SET_ACTIVE_SCREEN":
      return { ...state, active: action.value };
    case "POP_STACK_SCREEN":
      return {
        ...state,
        stack: [...state.stack.filter((item) => item.id != action.value)],
      };
    default:
      return state;
  }
};

export default stackscreens;
