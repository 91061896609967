import React from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { TiDeleteOutline } from "react-icons/ti";
import { BiSearch } from "react-icons/bi";
import AddLine from "./input modals/AddLine";
import { GETCURRENTLINE, GETDATA, GETNEWDATA, GETOBJECTSTATE } from "../../../../../redux/selectors";
import { HiX } from "react-icons/hi";
import { SetCurrentLine } from "../../../../../redux/actions/screen";
import GridRows from "./GridRows";
import { HiDotsVertical } from "react-icons/hi";

const ViewGridModal = ({ setShow, element, grid, data, handleSetCurrentLine, getCurentLine }) => {
  const [searchValue, setSearchValue] = React.useState("");
  const [openViewGrid, setOpenViewGrid] = React.useState(false);

  const handleAddSingle = () => {
    handleSetCurrentLine(element.model, -1, {});
    setOpenViewGrid(true);
  };

  const handleSearch = (e) => {
    var filter, list, span, i, txtValue;
    var is = [];
    filter = e.target.value.toUpperCase();
    list = document.getElementById("grid-view-item-list");
    span = list.querySelectorAll("span.grid-editor-field");
    for (i = 0; i < span.length; i++) {
      span[i].parentElement.parentElement.parentElement.setAttribute("style", "display:none !important");
      txtValue = span[i].textContent || span[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        is.push(span[i].parentElement.parentElement.parentElement);
      } else {
        span[i].parentElement.parentElement.parentElement.setAttribute("style", "display:none !important");
      }
      is.forEach((t) => (t.style.display = ""));
    }
  };

  const handleClearEditor = (e) => {
    var list = document.getElementById("grid-view-item-list");
    list.childNodes.forEach((t) => (t.style.display = ""));
  };

  return (
    <Modal
      animation={false}
      show={true}
      centered
      fullscreen
      dialogClassName="p-3"
      contentClassName="rounded border border-warning shadow overflow-hidden bg-dark"
    >
      <Modal.Header className="px-2">
        <div className="crm-floating-with-icon text-light">
          <input
            className="form-control ps-1 py-2 text-light"
            onKeyUp={(e) => handleSearch(e)}
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            placeholder="Αναζήτηση"
            style={{ border: "0px" }}
          />
          {searchValue != "" && (
            <div className="d-flex align-items-center justify-content-center">
              <TiDeleteOutline
                size="1.75rem"
                onClick={() => {
                  setSearchValue("");
                  handleClearEditor();
                }}
              />
            </div>
          )}
          <div className="d-flex align-items-center justify-content-center ms-1">
            <BiSearch size="1.60rem" color="var(--bs-primary)" />
          </div>
        </div>
        <Dropdown className="d-flex align-items-center justify-content-center h-100 ms-1">
          <Dropdown.Toggle className="d-flex align-items-center justify-content-center h-100 ms-1">
            {/* <HiDotsVertical /> */}
          </Dropdown.Toggle>

          <Dropdown.Menu variant="dark" className="shadow">
            <Dropdown.Item onClick={handleAddSingle}>Εισαγωγή</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {/* <Button className="d-flex align-items-center justify-content-center h-100 ms-1">
          <HiDotsVertical />
        </Button> */}
      </Modal.Header>
      <Modal.Body className="p-0 px-1 text-light" id="grid-view-item-list">
        <GridRows grid={element} />
      </Modal.Body>
      <Modal.Footer className="p-0 w-100 bg-dark shadow border-0">
        <Button
          variant="outline-warning"
          className="m-0 w-50 d-flex justify-content-center align-items-center rounded-0"
          onClick={() => {
            setShow(false);
          }}
        >
          <HiX size={25} color="var(--bs-danger)" className="me-2" />
          <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
            Κλείσμο
          </div>
        </Button>
      </Modal.Footer>
      {openViewGrid && (
        <AddLine
          show={openViewGrid}
          setShow={setOpenViewGrid}
          grid={element}
          fields={element.columns}
          gridSelector={element.gridSelector}
        />
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  objectState: state.screen.screens[`${state.screen.currentscreen}`].objectState,
  data: GETOBJECTSTATE(state) == "view" ? GETDATA(state).data : GETNEWDATA(state),
  screen: state.screen.screens[`${state.screen.currentscreen}`],
  currentscreen: state.screen.currentscreen,
  getCurentLine: (grid) => GETCURRENTLINE(state, grid),
});

const mapDispatchToProps = (dispatch) => ({
  handleSetCurrentLine: (gridName, lineIndex, line) => dispatch(SetCurrentLine(gridName, lineIndex, line)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewGridModal);
