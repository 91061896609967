import React from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { HiX, HiCheck } from "react-icons/hi";
import { connect } from "react-redux";
import NoData from "../../object/edit master/NoData";
import Row from "./cart/Row";

const CartModal = ({ show, setShow, basket }) => {
  return (
    <Modal
      //   animation={false}
      show={show}
      onHide={() => setShow(false)}
      centered
      fullscreen
      dialogClassName="p-3"
      contentClassName="rounded border border-warning shadow overflow-hidden bg-dark"
    >
      <Modal.Header className="text-light">Καλάθι Ειδών</Modal.Header>
      <Modal.Body className="text-light">
        {Object.keys(basket ?? {}).filter((x) => basket[x]?.qty > 0).length > 0 ? (
          Object.keys(basket)
            .filter((x) => basket[x]?.qty > 0)
            .map((mtrl) => <Row id={mtrl} item={basket[mtrl]} />)
        ) : (
          <NoData />
        )}
      </Modal.Body>
      <Modal.Footer className="p-0 w-100 bg-dark shadow border-0">
        <ButtonGroup className="m-0 w-100">
          <Button
            variant="outline-warning"
            className="w-50 d-flex justify-content-center align-items-center rounded-0"
            onClick={() => setShow(false)}
          >
            <HiX size={25} color="var(--bs-danger)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Κλείσμο
            </div>
          </Button>
          <Button
            className="w-50 d-flex justify-content-center align-items-center rounded-0"
            variant="outline-warning"
            // onClick={handleDelete}
          >
            <HiCheck size={25} color="var(--bs-success)" className="me-2" />
            <div className="d-flex justify-content-center align-items-center" style={{ fontSize: 20, color: "white" }}>
              Συνέχεια
            </div>
          </Button>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  basket: state.showRoom?.basket,
});

export default connect(mapStateToProps)(CartModal);
