import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setBatchFilter } from "../../../../../redux/actions/screen";

const S1CheckBox = ({ element, handleSetBatchFilter }) => {
  const [value, setViewValue] = useState(false);

  const table = element.name.split(".")[0];
  const key = element.name.split(".")[1];

  const handleChangeField = () => {
    setViewValue(!value);
    const valueToSet = !value ? 1 : 0;
    handleSetBatchFilter(table, key, valueToSet);
  };

  useEffect(() => {
    handleSetBatchFilter(table, key, element.value ?? 0);
    setViewValue(element.value == 1);
  }, []);

  return (
    <div
      className={`s1checkbox ${
        element.visible ? "" : "s1-hidden"
      } w-100 form-check d-flex flex-column flex-wrap p-0 m-0`}
      style={{ flex: `${element.flex * 100}%`, overflow: "hidden" }}
    >
      <label
        className="form-check-label py-1"
        htmlFor={element.index}
        style={{
          whiteSpace: "nowrap",
          opacity: "0.65",
          fontSize: "0.85rem",
        }}
      >
        {element.caption}
      </label>
      <input
        id={element.index}
        className={`form-check-input m-0 ps-1`}
        // ${element.required && !value ? "checkbox-required" : ""}
        type="checkbox"
        name={element.name}
        disabled={element.readOnly}
        readOnly={element.readOnly}
        checked={value}
        onChange={(e) => handleChangeField(e)}
        style={{ minHeight: "2rem", minWidth: "2rem" }}
      />
    </div>
  );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  handleSetBatchFilter: (table, key, value) => dispatch(setBatchFilter(table, key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(S1CheckBox);
