import React from "react";
import { connect } from "react-redux";
import { SetObjectState } from "../../../../../redux/actions/relJob";
import FormScreen from "./FormScreen";

const EditMaster = ({ screen, handleSetObjectState }) => {
  React.useEffect(() => {
    screen?.autoExec == 2 && handleSetObjectState("new");
  }, []);
  return (
    {
      2: <FormScreen />,
      1: <>autoExec = 1</>,
    }[screen?.autoExec] || <>Default</>
  );
};

const mapStateToProps = (state) => ({
  screen: state.screen.relJob,
});

const mapDispatchToProps = (dispatch) => ({
  handleSetObjectState: (value) => dispatch(SetObjectState(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditMaster);
