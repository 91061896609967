import React from "react";

const GadgetNoData = () => {
  return (
    <div className="d-flex flex-column text-light text-center justify-content-center align-items-center h-100 w-100">
      <div className="s1-no-search-data" />
      <br />
      <div>Δεν υπάρχουν δεδομένα για το συγκεκριμένο gadget</div>
    </div>
  );
};

export default GadgetNoData;
