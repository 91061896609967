import React from "react";
import { AiOutlinePrinter } from "react-icons/ai";
import { BsSortDownAlt } from "react-icons/bs";
import { connect } from "react-redux";
import { GETCLIENTID, GETSCREENOBJ, GETSN } from "../../../../../redux/relJobSelectors";

const SideMenuOnList = ({ screen, setPrint, setShow, setSort }) => {
  return (
    <div className="p-2 text-light">
      <div style={{ fontSize: "1.7em" }}>Ενέργειες</div>
      <hr />
      <div
        className="side-menu-action rel-job-print"
        onClick={() => {
          setShow(false);
          setPrint(true);
        }}
        style={{
          color: screen.objectState == "view" ? "var(--bs-primary)" : "var(--bs-gray-500)",
        }}
      >
        <AiOutlinePrinter className="me-4" />
        <span>Εκτύπωση</span>
      </div>
      <div
        className="side-menu-action rel-job-sort"
        onClick={() => {
          setShow(false);
          setSort(true);
        }}
        style={{
          color: screen.objectState == "view" ? "var(--bs-primary)" : "var(--bs-gray-500)",
        }}
      >
        <BsSortDownAlt className="me-4" />
        <span>Ταξινόμηση</span>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  serialnumber: GETSN(state),
  clientID: GETCLIENTID(state),
  user: state.systemparams.userid,
  screen: GETSCREENOBJ(state),
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SideMenuOnList);
