import React from "react";
import { Button } from "react-bootstrap";
import { BsFillCartFill } from "react-icons/bs";
import { connect } from "react-redux";
import CartModal from "./modals/CartModal";

const Cart = ({ basket }) => {
  const [showModal, setShowModal] = React.useState(false);
  return (
    <>
      <Button className="ms-1 position-relative" variant="warning" onClick={() => setShowModal(true)}>
        {Object.keys(basket ?? {}).filter((x) => basket[x]?.qty > 0).length > 0 && (
          <div
            className="position-absolute text-center"
            style={{
              right: 0,
              top: 0,
              borderRadius: "50%",
              border: "1px solid black",
              width: 19,
              height: 19,
              backgroundColor: "red",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: 11,
            }}
          >
            {Object.keys(basket).filter((x) => basket[x]?.qty > 0).length}
          </div>
        )}
        <BsFillCartFill />
      </Button>
      <CartModal show={showModal} setShow={setShowModal} />
    </>
  );
};

const mapStateToProps = (state) => ({
  basket: state.showRoom.basket,
});

export default connect(mapStateToProps)(Cart);
